import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import { pageLinks } from '../../Routes/PageLinks';
import ClientRoute from '../../API/Routes/ClientRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import PlanRoute from '../../API/Routes/PlanRoute';
import SelectTextField from '../../Views/Components/SelectTextField';

class PlansPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      items: [],
      itemsDisabled: [],
      isShowDisabled: false,
      checkedList: [],
      selectedItem: '',
      deleteClients: [],
      individualOptions: [
        { id: '0', label: 'Apagar plano', action: (clientDocuments) => this.setState({ deleteClients: [clientDocuments] })}
      ],
      fastOptions: [
        { id: '0', label: 'Apagar planos selecionados', action: (clientDocuments) => this.setState({ deleteClients: clientDocuments })}
      ]
    }
    this.props.loadingAction(false)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.selectItemAction = this.selectItemAction.bind(this)
  }

  loadRows = (values) => {
    this.setState({ deleteClients: [] })
    this.props.loadingAction(false)
    this.setState(
        {
            items: values.filter((element)=>{return element.active}),
            itemsDisabled: values.filter((element)=>{return !element.active})
        }
    );
  }

  deleteClientsAction = () => {
    this.props.loadingAction(true)
    this.props.setAlert('success', 'Tudo certo!', 'Plano deletado com sucesso')
    new ClientRoute().fetchDeleteClients(this.state.deleteClients, this.loadRows, this.errorAction)
    if (this.state.deleteClients.length > 1) {window.location.reload();}
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  selectItemAction = (value) => {
      console.log(value);
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    let plan = items.find((element) => { return element._id == value})
    this.props.history.push({
      pathname: pageLinks.planDetail,
      state: {plan: plan}
    })

    // this.props.history.push({
    //   pathname: pageLinks.clientDetail,
    //   state: {document: Helpers.onlyNumbers(value), clientCreated: false}  
    // })
  }

  updateListChecked = (value) => {
    this.setState({ checkedList: value })
  }

  fastAction = (value) => {
    console.log(value);
    const client = this.state.fastOptions.find((element) => { return element.id == value})
    console.log(client);
    client.action(this.state.checkedList)
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId;})
    client.action(value.rowId)
  }

  newClientAction() {
    this.props.history.push(pageLinks.newPlan)
  }

  componentDidMount() {
    let route = new PlanRoute()
    route.fetchAll(this.loadRows, this.errorAction)
  }

  getTable() {
    var rows = new Array()
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    items.forEach(element => {
      const data = {
          title: element.title,
          desc: element.desc,
          id: element._id
        }
      rows.push(data)
    });
    return rows
  }

  render() {
    const headCells = [
      { id: 'title', label: 'Nome', isButton: true },
      { id: 'desc', label: 'Descrição', isButton: false },
      { id: 'id', label: '', isButton: false, isHidden: true }
    ];

    {console.log(this.state.selectedItem)}

    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={this.state.deleteClients.length == 1 ? "Tem certeza que deseja deletar este plano?" : "Tem certeza que deseja deletar estes planos?"} isShow={this.state.deleteClients.length > 0} defaultAction={this.deleteClientsAction} closeAction={() => { if (this.state.deleteClients.length > 1) {window.location.reload();}; this.setState({ deleteClients: [] }); } } buttonTitle="Sim" closeTitle="Cancelar"/>
        <PageTitle title="Planos"/>
        <div class="row">
          <TopButton title="NOVO PLANO" icon="plus" clickAction={this.newClientAction} />
        </div>

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            components={
                this.state.items.length == 0 ? <div></div> : <SelectTextField id="plan" defaultValue={this.state.isShowDisabled ? 'disabled' : 'enabled'} title="Status" isRequired={true} delegate={(key, value)=>{this.setState({ isShowDisabled: value == 'disabled' })}} width="25ch" options={[
                    { id: 'enabled', label: 'Planos Ativos' },
                    { id: 'disabled', label: 'Planos Inativos' },
                ]} />
            }
            titleTable='Planos'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='name'
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(PlansPage);