import User from "../../Models/Auth/User"
import DashInfo from "../../Models/Dashboard/DashInfo"
import Invoice from "../../Models/Invoice/Invoice"
import APIManager from "../APIManager"

export default class DashboardRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `dashboard`
  }

  fetchInfos = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company, null, 'GET', function (response) {
      try {
          const info = DashInfo.init(response);
        callback(info);
      } catch {
        error(response.message);
      }
    })
  }

  fetchInfosClient = (callback, error) => {
    const company = APIManager.loadCache("company")
    const client = APIManager.loadCache(User.keyClass)
    this.apiManager.fetchJSON(this.endPoint + `/infosClient/` + company + '/' + client._id , null, 'GET', function (response) {
      try {
        var invoices = new Array()
        response["billings"].forEach(element => {
          if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
          const invoice = Invoice.init(element);
          invoices.push(invoice)
        });
        callback(invoices)
      } catch {
        error(response.message);
      }
    })
  }

}