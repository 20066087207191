import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import QRCode from "react-qr-code";
import Helpers from '../../Helpers/Helpers';
import APIManager from '../../API/APIManager';
import { pageLinks } from '../../Routes/PageLinks';
import Invoice from '../../Models/Invoice/Invoice';
import User from '../../Models/Auth/User';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from '../../Views/Components/DatePicker';
import CurrencyField from '../../Views/Components/CurrencyTextField';

class InvoiceDetailPage extends Component {
  constructor(props) {
    super(props)

    this.props.setAlert()
    this.state = {
      invoice: this.props.location.state.invoice,
      isPaying: false,
      isCancelling: false,
      paymentDate: new Date(),
      paymentAmount: Helpers.stringToAmountNumber((this.props.location.state.invoice.totalWithTax ?? this.props.location.state.invoice.amountTotal).replace(/[^\d,]/g, '').trim())
    }

    this.props.setAlert()
    this.errorAction = this.errorAction.bind(this)

    this.loadInvoice()
  }

  loadInvoice = () => {
    this.props.loadingAction(true)
    let route = new InvoiceRoute()
    route.fetchInvoiceDetail(this.props.location.state.invoice._id, this.loadedInvoice, this.errorAction)
  }

  loadedInvoice = (invoice) => {
    const isPaying = this.state.isPaying
    const isCancelling = this.state.isCancelling
    this.props.loadingAction(false)
    this.setState({
      invoice: invoice,
      isPaying: false,
      isCancelling: false,
    });
    this.props.setAlert((isPaying || isCancelling) ? 'success' : null, 'Tudo certo!', 'A cobrança foi ' + (isPaying ? 'quitada' : 'cancelada') + ' com sucesso.')
  }

  savedClient = (client) => {
    this.props.loadingAction(false)
    this.props.setAlert('success', 'Tudo certo!', 'O cliente foi alterado com sucesso.')
    this.setState({
      invoice: this.props.location.state.invoice,
      isEditing: false
    });
  }

  errorAction = (values) => {
    this.setState({
      values
    });
  }

  cancelInvoiceAction = () => {
    this.props.loadingAction(true)
    this.setState({ isPaying: false })
    new InvoiceRoute().fetchCancelItems({ "ids": [this.state.invoice._id] }, this.loadInvoice, this.errorAction)
  }

  payInvoiceAction = () => {
    this.props.loadingAction(true)
    this.setState({ isPaying: false })
    let paymentDic = { "date": this.state.paymentDate, "ids": [this.state.invoice._id], "amounts": [this.state.paymentAmount] }
    console.log(paymentDic);
    new InvoiceRoute().fetchManualPayItems(paymentDic, this.loadInvoice, this.errorAction)
  }

  renegociationAction = () => {
    const newInvoice = new Invoice()
    newInvoice.amount = Helpers.stringToAmountNumber(this.state.invoice.amountTotal ?? "0")
    newInvoice.desc = "Renegociação da cobrança " + this.state.invoice.provider.idTransaction + " vencida em " + Helpers.formatDate(this.state.invoice.venciment)
    this.props.history.push({
      pathname: pageLinks.newInvoice,
      state: { invoiceRenegociation: newInvoice, isRenegociationInvoiceId: this.state.invoice._id, client: this.props.location.state.client }
    })
  }

  showPdfInNewTab(base64Data) {  
    var byteCharacters = atob(base64Data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  openClientDetail() {
    this.props.history.push({
      pathname: pageLinks.clientDetail,
      state: { document: Helpers.onlyNumbers(this.state.invoice.client.document), clientCreated: false }
    })
  }

  render() {
    const venciment = new Date(this.state.invoice.venciment)
    const datePayed = new Date(this.state.invoice.datePayed)
    const client = APIManager.loadCache(User.keyClass)

    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja cancelar esta cobrança?"} isShow={this.state.isCancelling} defaultAction={this.cancelInvoiceAction} closeAction={() => { this.setState({ isCancelling: false }); }} buttonTitle="Sim" closeTitle="Não" />

        <Modal show={this.state.isPaying} onHide={() => { { this.setState({ isPaying: false }); } }}>
        <Modal.Header closeButton>
          <Modal.Title>{"Pagamento manual"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p class="text-muted">{"Para quitar dessa cobrança manualmente informe a data do pagamento e o valor pago."}</p>
          <DatePicker id="datePay" defaultValue={this.state.paymentDate} title="Data do pagamento" isRequired={true} delegate={(key, value) => this.setState({ paymentDate: value })} width="25ch" />
          <CurrencyField id="paymentAmount" defaultValue={this.state.paymentAmount} title="Valor" isRequired={true} delegate={(key, value) => this.setState({ paymentAmount: value })} width="20ch" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.payInvoiceAction}>{"Quitar Cobrança"}</Button>
          <Button variant="primary" onClick={() => { { this.setState({ isPaying: false }); } }}>{"Fechar"}</Button>
        </Modal.Footer>
        </Modal>
        
        <PageTitle title="Cobrança" history={this.props.history} />
        <div class="row" hidden={this.state.invoice.status.id == "cancelled" || this.state.invoice.status.id == "allpayed"}>
          <TopButton title="QUITAR COBRANÇA" icon="dollar-sign" clickAction={() => { this.setState({ isPaying: true }) }} />
          <TopButton hidden={this.state.invoice.status.id != "overdue"} title="RENEGOCIAR COBRANÇA" icon="dollar-sign" clickAction={this.renegociationAction} />
          <TopButton title="CANCELAR COBRANÇA" icon="ban" clickAction={() => { this.setState({ isCancelling: true }) }} />
        </div>
        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Informações da Cobrança</h6>
            {/* <BarButton title="Cancelar cobrança" clickAction={() => { this.setState({ isCancelling: true }) }} hidden={this.state.invoice.status.id == "cancelled" || this.state.invoice.status.id == "allpayed"} /> */}
          </div>
          <div class="card-body-no-padding" hidden={this.state.isEditing}>
            <div class="row">
              <div class="col-sm-5">
                <div class="row">
                  <div class="col-sm" hidden={this.state.invoice.status == null} >
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Cliente: </h8><br />
                      <div onClick={() => { this.openClientDetail() }}>
                        <strong><span class="ml-2 text-primary pointer" onClick={() => { this.openClientDetail() }}>{this.state.invoice.client.name}</span></strong>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-5" hidden={this.state.invoice.status == null}>
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Número: </h8><br />
                      <strong><span class="ml-2">{this.state.invoice.provider.idTransaction}</span></strong>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-5" hidden={this.state.invoice.provider.idTransaction == null}>
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Tipo: </h8><br />
                      <strong><span class="ml-2">{this.state.invoice.paymentType.name}</span></strong>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-5" hidden={this.state.invoice.venciment == null}>
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Vencimento: </h8><br />
                      <strong><span class="ml-2">{venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear()}</span></strong>
                    </div>
                    <div class="float-end mt-3 mt-sm-6" hidden={this.state.invoice.desc == null}>
                      <h8>Descrição: </h8><br />
                      <strong><span class="ml-2">{this.state.invoice.desc}</span></strong>
                    </div>
                  </div>
                  <div class="col-sm-5" hidden={this.state.invoice.paymentType.name == null}>
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Status: </h8><br />
                      <strong><span class="ml-2" style={{ color: this.state.invoice.status.color, backgroundColor: this.state.invoice.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{this.state.invoice.status.name}</span></strong>
                    </div>
                  </div>
                </div>
                <div class="row padding-bottom-075">
                  <div class="col-sm-7" hidden={this.state.invoice.datePayed == null}>
                    <div class="float-end mt-3 mt-sm-6"><h8>Data do Pagamento: </h8><br />
                      <strong><span class="ml-2">{datePayed.getUTCDate() + '/' + (datePayed.getUTCMonth() + 1) + '/' + datePayed.getFullYear()}</span></strong>
                    </div>
                  </div>
                </div>
                <div class="row padding-bottom">
                  <div class="col-sm-6" hidden={this.state.invoice.provider.pixKey == null || this.state.invoice.status.id == "cancelled" || this.state.invoice.paymentType.id == "cash"}>
                    <div class="float-end mt-3 mt-sm-6" align="left">
                      <p><QRCode value={this.state.invoice.provider.pixKey ?? ""} size={106} fgColor="#000000" /></p>
                      <a className="pointer btn-sm btn-primary" onClick={() => { navigator.clipboard.writeText(this.state.invoice.provider.pixKey); this.props.setAlert('success', 'Tudo certo!', 'Código PIX copiado com sucesso!') }} >
                        <span class="h8 font-weight-bold text-white">Copiar Código</span>
                      </a>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-6" hidden={this.state.invoice.provider.pixKey != null || this.state.invoice.provider.digitableLine == null || this.state.invoice.status.id == "cancelled" || this.state.invoice.paymentType.id == "cash"}>
                    <div class="float-end mt-3 mt-sm-6">
                      <center>
                        <div class={`pointer card background-white`} onClick={() => { navigator.clipboard.writeText(this.state.invoice.provider.digitableLine); this.props.setAlert('success', 'Tudo certo!', 'Código de barras copiado com sucesso!') }}>
                          <div class="card-body">
                            <div class="row no-gutters align-items-center">
                              <div class="col-auto">
                                <div class="icon-circle background-icon">
                                  <i class={`fas fa-copy fa-1x text-primary`}></i>
                                </div>
                              </div>
                              <div class="col ml-5" align="right">
                                <div class="h7 mb-0 font-weight-bold text-gray-800">Copiar Código de Barras</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </center>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-6" hidden={this.state.invoice.paymentType.id == "pix" || this.state.invoice.provider.digitableLine == null || this.state.invoice.status.id == "cancelled" || this.state.invoice.paymentType.id == "cash"}>
                    <div class="float-end mt-3 mt-sm-6">
                      <center>
                        <div class={`pointer card background-white`} onClick={() => { this.showPdfInNewTab(this.state.invoice.provider.billetArchive); }}>
                          <div class="card-body">
                            <div class="row no-gutters align-items-center">
                              <div class="col-auto">
                                <div class="icon-circle background-icon">
                                  <i class={`fas fa-file-download fa-1x text-primary`}></i>
                                </div>
                              </div>
                              <div class="col ml-4" align="right">
                                <div class="h7 mb-0 font-weight-bold text-gray-800">Baixar Boleto PDF</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </center>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="float-end mt-3 mt-sm-6" hidden={this.state.invoice.enrollmentText == null}>
                  <h8>Curso/Turma: </h8><br />
                  <strong><span class="ml-2">{this.state.invoice.enrollmentText}</span></strong>
                </div>
                <div class="float-end mt-3 mt-sm-6" hidden={this.state.invoice.officeName == null}>
                  <h8>Unidade/Sede: </h8><br />
                  <strong><span class="ml-2">{this.state.invoice.officeName}</span></strong>
                </div>
                <div hidden={this.state.invoice.plan == null} class="float-end mt-3 mt-sm-6"><h8>Plano: </h8><br />
                  <strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{this.state.invoice.plan}</span></strong>
                </div>
              </div>
              <div class="background-detail vl-left col-sm-4" >
                <div class="float-end mt-3 mt-sm-6" align="right">
                  <h7><b>SUB-TOTAL</b></h7>
                  <p><h5><b>{this.state.invoice.amount}</b></h5></p>
                  <h7 hidden={this.state.invoice.amountDiscount == null}><b>- {this.state.invoice.amountDiscount}</b> <span class="float-end">de desconto</span></h7><br />
                  <h7 hidden={this.state.invoice.amountPenalty == null}><b>+ {this.state.invoice.amountPenalty}</b> <span class="float-end">de multa</span></h7><br />
                  <p><h7 hidden={this.state.invoice.amountFees == null}><b>+ {this.state.invoice.amountFees}</b> <span class="float-end">de juros</span></h7></p>
                  <hr hidden={this.state.invoice.totalWithTax == null} />
                  <h7 hidden={this.state.invoice.totalWithTax == null}><b>TOTAL PAGO</b></h7>
                  <h4 hidden={this.state.invoice.totalWithTax == null}><b>{this.state.invoice.amountTotal}</b> <span class="float-end"></span></h4>
                  <p><h7 hidden={this.state.invoice.amountTax == null}><b>- {this.state.invoice.amountTax}</b> <span class="float-end">taxa de transação <i class={`tooltip fas fa-info-circle fa-1x text-primary`}><span class="tooltiptext tooltip-left">{"Uma taxa de transação, de " + (client.company.plan ? client.company.plan.transactionTax : "") +", é cobrada pelo banco quando o pagamento de uma cobrança é efetuado."}</span></i></span></h7></p>
                  <hr />
                  <h7><b>{this.state.invoice.status.id == "allpayed" ? 'VALOR RECEBIDO' : 'TOTAL A PAGAR'}</b></h7>
                  <h2 class={`text-primary`}><b>{this.state.invoice.totalWithTax ?? this.state.invoice.amountPayed ?? this.state.invoice.amountTotal}</b></h2>
                  <strong><h8 class="ml-2 padding-bottom-075" hidden={this.state.invoice.status.id != "allpayed" || this.state.invoice.isTranferedValue == null}  style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{this.state.invoice.isTranferedValue ? 'O repasse foi efetuado para sua conta!' : 'O repasse está em processamento'}</h8></strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InvoiceDetailPage);