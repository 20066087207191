import React, { Component } from 'react'

export default class ColoredButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div hidden={this.props.hidden} class="col-lg-12 mt-4 mr-5">
          <a className="pointer colored-button btn-lg btn-primary shadow-sm mr-3" onClick={this.props.clickAction} >
          <span class="h6 ml-4 mr-4 font-weight-bold text-white">{this.props.title}</span>
          </a>
      </div>
    )
  }
}