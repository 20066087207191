import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../../Views/Components/TopButton';
import EnhancedTable from '../../../Views/Components/EnhancedTable';
import { pageLinks } from '../../../Routes/PageLinks';
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PageTitle from '../../../Views/Components/PageTitle';
import SelectTextField from '../../../Views/Components/SelectTextField';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';

class OfficesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      items: [],
      itemsDisabled: [],
      isShowDisabled: false,
      checkedList: [],
      selectedItem: '',
      deleteItems: [],
      individualOptions: [
        { id: '0', label: 'Apagar unidade', action: (clientDocuments) => this.setState({ deleteItems: [clientDocuments] })}
      ],
      fastOptions: [
        { id: '0', label: 'Apagar unidades selecionados', action: (clientDocuments) => this.setState({ deleteItems: clientDocuments })}
      ]
    }
    this.props.loadingAction(true)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.selectItemAction = this.selectItemAction.bind(this)
  }

  loadRows = (values) => {
    this.setState({ deleteItems: [] })
    this.props.loadingAction(false)
    this.setState({ items: values });
  }

  deleteItemsAction = () => {
    this.props.loadingAction(true)
    new PedagogicRoute().fetchDeleteOffices({ "ids": this.state.deleteItems }, this.loadRows, this.errorAction)
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  selectItemAction = (value) => {
    const items = this.state.items
    let item = items.find((element) => { return element._id == value})
    this.props.history.push({
      pathname: pageLinks.newPedagogicOffice,
      state: {office: item}
    })
  }

  updateListChecked = (value) => {
    this.setState({ checkedList: value })
  }

  fastAction = (value) => {
    const client = this.state.fastOptions.find((element) => { return element.id == value})
    client.action(this.state.checkedList)
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId;})
    client.action(value.rowId)
  }

  newClientAction() {
    this.props.history.push({
      pathname: pageLinks.newPedagogicOffice,
      state: {office: null}
    })
  }

  componentDidMount() {
    let route = new PedagogicRoute()
    route.fetchAllOffices(this.loadRows, this.errorAction)
  }

  getTable() {
    var rows = new Array()
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    items.forEach(element => {
      const data = {
          fantasy: element.fantasy,
          name: element.name,
          document: element.document,
          id: element._id
        }
      rows.push(data)
    });
    return rows
  }

  render() {
    const headCells = [
      { id: 'fantasy', label: 'Nome Fantasia', isButton: true },
      { id: 'name', label: 'Razão Social', isButton: true },
      { id: 'document', label: 'CNPJ', isButton: false },
      { id: 'id', label: '', isButton: false, isHidden: true }
    ];

    {console.log(this.state.selectedItem)}

    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja apagar este curso?" : "Tem certeza que deseja apagar estes cursos?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) { window.location.reload(); }; this.setState({ deleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <PageTitle title="Unidades"/>
        <div class="row">
          <TopButton title="NOVA UNIDADE" icon="plus" clickAction={this.newClientAction} />
        </div>

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Unidades'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='year'
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(OfficesPage);