import Address from "../Address";
import ModelJSON from "../ModelJSON";
import moment from "moment";
import ClientStatus from "./ClientStatus";
import ClientPlan from "./ClientPlan";
import Responsible from "./Responsible";

export default class Client extends ModelJSON {
  constructor(_id, name, phone, document, documentGov, genre, fatherName, motherName, birthDate = moment(new Date()).format(), email, address = new Address(), obs, status = new ClientStatus(), plan, levelTitle, responsible, enrollmentId, level, fatherPhone, motherPhone) {
    super()
    this._id = _id
    this.name = name;
    this.phone = phone;
    this.document = document;
    this.documentGov = documentGov;
    this.genre = genre;
    this.fatherName = fatherName;
    this.motherName = motherName;
    this.email = email
    this.birthDate = birthDate
    this.address = address
    this.obs = obs
    this.status = status
    this.plan = plan
    this.levelTitle = levelTitle
    this.responsible = responsible
    this.enrollmentId = enrollmentId
    this.level = level
    this.fatherPhone = fatherPhone;
    this.motherPhone = motherPhone;
  }

  static initForList(json) {
    return new Client(
      json._id,
      json.name,
      null,
      json.document,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      json.status == null ? null : ClientStatus.init(json.status),
      json.plan == null ? null : ClientPlan.init(json.plan),
      null,
      null,
      json.enrollmentId,
      null
    )
  }

  static init(json) {
    return new Client(
      json._id,
      json.name,
      json.phone,
      json.document,
      json.documentGov,
      json.genre,
      json.fatherName,
      json.motherName,
      json.birthDate,
      json.email,
      Address.init(json.address),
      json.obs,
      ClientStatus.init(json.status),
      json.plan == null ? null : ClientPlan.init(json.plan),
      json.levelTitle,
      Responsible.init(json.responsible),
      json.enrollmentId,
      null,
      json.fatherPhone,
      json.motherPhone
    )
  }

  static initEmployee(json) {
    return new Client(
      json._id,
      json.name,
      json.phone,
      json.document,
      json.documentGov,
      null,
      null,
      null,
      null,
      json.email,
      Address.init(json.address),
      json.obs,
      ClientStatus.init(json.status),
      null,
      json.levelTitle,
      null,
      null,
      json.level,
      null,
      null
    )
  }
}