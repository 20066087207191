import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../../Views/Components/TopButton';
import EnhancedTable from '../../../Views/Components/EnhancedTable';
import { pageLinks } from '../../../Routes/PageLinks';
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PageTitle from '../../../Views/Components/PageTitle';
import SelectTextField from '../../../Views/Components/SelectTextField';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import ImportButton from '../../../Views/Components/ImportButton';
import APIManager from '../../../API/APIManager';
import User from '../../../Models/Auth/User';

class PeriodsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      items: [],
      itemsDisabled: [],
      isShowDisabled: false,
      checkedList: [],
      selectedItem: '',
      deleteItems: [],
      individualOptions: [
        // { id: '0', label: 'Apagar período letivo', action: (clientDocuments) => this.setState({ deleteItems: [clientDocuments] })}
      ],
      fastOptions: [
        // { id: '0', label: 'Apagar períodos letivos selecionados', action: (clientDocuments) => this.setState({ deleteItems: clientDocuments })}
      ]
    }
    this.props.loadingAction(true)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.exportAction = this.exportAction.bind(this)
    this.importAction = this.importAction.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.selectItemAction = this.selectItemAction.bind(this)
  }

  loadRows = (values) => {
    this.setState({ deleteItems: [] })
    this.props.loadingAction(false)
    this.setState({ items: values });
  }

  deleteItemsAction = () => {
    this.props.loadingAction(true)
    new PedagogicRoute().fetchDeletePeriods({ "ids": this.state.deleteItems }, this.loadRows, this.errorAction)
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  selectItemAction = (value) => {
    const items = this.state.items
    let item = items.find((element) => { return element._id == value})
    this.props.history.push({
      pathname: pageLinks.newPedagogicPeriod,
      state: {period: item}
    })
  }

  updateListChecked = (value) => {
    this.setState({ checkedList: value })
  }

  fastAction = (value) => {
    const client = this.state.fastOptions.find((element) => { return element.id == value})
    client.action(this.state.checkedList)
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId;})
    client.action(value.rowId)
  }

  newClientAction() {
    this.props.history.push({
      pathname: pageLinks.newPedagogicPeriod,
      state: {period: null}
    })
  }

  componentDidMount() {
    let route = new PedagogicRoute()
    route.fetchAllPeriods(this.loadRows, this.errorAction)
  }

  getTable() {
    var rows = new Array()
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    items.forEach(element => {
      const data = {
          name: element.name,
          year: element.year,
          type: Helpers.getPeriodTypeName(element.type),
          id: element._id
        }
      rows.push(data)
    });
    return rows
  }

  exportAction() {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchExport('periods', (csvContent)=> {
      this.props.loadingAction(false)
      Helpers.downloadCSV("periods", csvContent)
    }, this.errorAction)
  }

  importAction(file) {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchImport('periods', file, (csvContent)=> {
      this.props.loadingAction(false)
      window.location.reload()
      alert("Dados importados com sucesso!")
    }, this.errorAction)
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  render() {
    const headCells = [
      { id: 'name', label: 'Nome', isButton: true },
      { id: 'year', label: 'Ano de Referência', isButton: false },
      { id: 'type', label: 'Tipo do Período Letivo', isButton: false },
      { id: 'id', label: '', isButton: false, isHidden: true }
    ];

    const isHiddenImports = APIManager.loadCache(User.keyClass).level < 99

    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja apagar este período letivo?" : "Tem certeza que deseja apagar estes períodos letivos?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) { window.location.reload(); }; this.setState({ deleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <PageTitle title="Períodos Letivos"/>
        <div class="row">
          <TopButton title="NOVO PERÍODO LETIVO" icon="plus" clickAction={this.newClientAction} />
          <ImportButton hidden={isHiddenImports} title="IMPORTAR PERÍODOS" icon="file-upload" changeAction={this.importAction} />
          <TopButton title="EXPORTAR PERÍODOS" icon="file-download" clickAction={this.exportAction} />
        </div>

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Períodos Letivos'
            rows={this.getTable()}
            headCells={headCells}
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(PeriodsPage);