import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PeriodPedagogic from '../../../Models/Pedagogic/Period';
import APIManager from '../../../API/APIManager';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import ColoredButton from '../../../Views/Components/ColoredButton';
import PageTitle from '../../../Views/Components/PageTitle';
import FormComponent from '../../../Views/Components/FormComponent';
import SelectTextField from '../../../Views/Components/SelectTextField';
import SimpleTextField from '../../../Views/Components/SimpleTextField';
import ClassPedagogic from '../../../Models/Pedagogic/Class';
import CoursePedagogic from '../../../Models/Pedagogic/Course';
import OfficePedagogic from '../../../Models/Pedagogic/Office';
import EnhancedTable from '../../../Views/Components/EnhancedTable';
import BarButton from '../../../Views/Components/BarButton';
import SimpleButton from '../../../Views/Components/SimpleButton';
import ClientRoute from '../../../API/Routes/ClientRoute';
import { Button, Modal } from 'react-bootstrap';
import EnrollmentPedagogic from '../../../Models/Pedagogic/Enrollment';

class ClassDetailPage extends Component {
    constructor(props) {
        super(props)

        this.headCells = [
            { id: 'name', label: 'Nome', isButton: true },
            { id: 'document', label: 'Nº do Aluno', isButton: false },
            { id: 'status', label: 'Situação', isButton: false },
            { id: 'id', label: '', isButton: false, isHidden: true }
        ];

        this.user = APIManager.loadCache(User.keyClass)
        this.company = this.user.company

        this.props.setAlert()

        let classEducation = this.props.location.state.classEducation

        this.state = {
            Class: new ClassPedagogic(classEducation._id, classEducation.name, classEducation.desc, classEducation.course._id, classEducation.office._id, classEducation.period._id),
            reenrollment: new EnrollmentPedagogic(),
            reenrollments: [],
            printEnrollments: [],
            disableClients: [],
            coursesFiltered: [],
            classesFiltered: [],
            periods: [],
            courses: [],
            coursesFiltered: [],
            offices: [],
            students: [],
            classes: [],
            levels: [],
            isPrintMatriculas: false,
            printEnrollment: {
                period: null,
                office: null,
                course: null,
                class: null,
                periods: [],
                courses: [],
                coursesFiltered: null,
                offices: [],
                classes: [],
                classesFiltered: null,
                levels: [],
                selectedCourseLevel: null
            },
            selectedCourseLevel: classEducation.course.levelId,
            deleteClients: [],
            concluedClients: [],
            printBoletimClients: [],
            isEditing: false,
            individualOptions: [
                { id: '0', label: 'Cancelar matrícula do aluno', action: (clientDocuments) => this.setState({ deleteClients: [clientDocuments] }) },
                { id: '1', label: 'Alterar matrícula para concluída', action: (clientDocuments) => this.setState({ concluedClients: [clientDocuments] }) },
                { id: '2', label: 'Imprimir boletim de notas', action: (clientDocuments) => this.printBoletimClientsAction([clientDocuments]) },
                { id: '4', label: 'Gerar ficha de matrícula em PDF', action: (printEnrollments) => {this.fetchLoadEnrollment(); this.setState({ printEnrollments: [printEnrollments] })} },
            ],
            fastOptions: [
                { id: '0', label: 'Cancelar matrícula dos alunos selecionados', action: (clientDocuments) => this.setState({ deleteClients: clientDocuments }) },
                { id: '1', label: 'Alterar matrícula selecionada para concluída', action: (clientDocuments) => this.setState({ concluedClients: clientDocuments }) },
                { id: '2', label: 'Imprimir boletins de notas', action: (clientDocuments) => this.printBoletimClientsAction(clientDocuments) },
                { id: '3', label: 'Rematricular alunos selecionados', action: (reenrollments) => this.setState({ reenrollments: reenrollments }) },
                { id: '4', label: 'Gerar ficha de matrícula em PDF para os alunos selecionados', action: (printEnrollments) => {this.fetchLoadEnrollment(); this.setState({ printEnrollments: printEnrollments })} },
                {
                    id: '5', label: 'Desativar alunos selecionados', action: (disableClients) => {
                        let students = this.state.students
                        var ids = []
                        students.forEach(student => {
                            disableClients.forEach(student2 => {
                                if (student.enrollmentId == student2) {
                                    ids.push(student.document)
                                }
                            })
                        })
                        this.setState({ disableClients: ids })
                    }
                },
            ]
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
        this.showPdfInNewTab = this.showPdfInNewTab.bind(this)
        this.fetchStacks()
    }

    fetchLoadEnrollment = () => {
        this.state.printEnrollment = {
            periods: this.state.periods, 
            offices: this.state.offices,
            courses: this.state.courses,
            classes: this.state.classes,
            levels: this.state.levels
        }
        this.setState({ printEnrollment: this.state.printEnrollment, isPrintMatriculas: true })
    }

    changeStatePrintEnrollment = (key, value) => {
        if (key == "period") {
            this.state.printEnrollment.period = value
        }
        if (key == "class") {
            this.state.printEnrollment.class = value
        }
        if (key == "course") {
            this.state.printEnrollment.course = value
        }
        if (key == "office") {
            this.state.printEnrollment.office = value
        }
        this.setState({ printEnrollment: this.state.printEnrollment })
    }

    changeStatePrintCourseLevel = (key, value) => {
        this.state.printEnrollment.selectedCourseLevel = value
        this.state.printEnrollment.coursesFiltered = this.state.printEnrollment.courses.filter(item => { return ((item.level.id == value)) })
        this.setState({ printEnrollment: this.state.printEnrollment })
    }

    changeStatePrintCourse = (key, value) => {
        this.state.printEnrollment.course = value
        this.state.printEnrollment.classesFiltered = this.state.printEnrollment.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.printEnrollment.period)) })
        this.setState({ printEnrollment: this.state.printEnrollment })
    }

    printFichaMatriculaAction = () => {
        if (this.state.printEnrollment.period == null || this.state.printEnrollment.class == null || this.state.printEnrollment.course == null || this.state.printEnrollment.selectedCourseLevel == null) {
            this.setState({ errorMessage: "Você precisa preencher todos os campos." })
            return
        }
        var periodName = this.state.printEnrollment.periods.filter(period => period._id == this.state.printEnrollment.period)[0].name
        var className = this.state.printEnrollment.classes.filter(classObj => classObj._id == this.state.printEnrollment.class)[0].name
        var courseName = this.state.printEnrollment.courses.filter(course => course._id == this.state.printEnrollment.course)[0].name
        var courseLevel = this.state.printEnrollment.levels.filter(level => level.id == this.state.printEnrollment.selectedCourseLevel)[0].title
        var ids = this.state.printEnrollments.map(enrollId => this.state.students.filter(student => student.enrollmentId == enrollId)[0]._id)
        
        this.props.loadingAction(true)
        new ClientRoute().fetchGetEnrollmentPrint(ids, periodName, className, courseName, courseLevel, this.showPdfInNewTab, this.errorAction)
    }

    fetchStacks = () => {
        let route = new PedagogicRoute()
        route.fetchAllCourses((courses) => { this.setState({ courses, coursesFiltered: courses.filter(item => { return ((item.level.id == this.state.selectedCourseLevel)) }) }) }, this.errorAction)
        route.fetchAllPeriods((periods) => { this.setState({ periods }) }, this.errorAction)
        route.fetchAllOffices((offices) => { this.setState({ offices }) }, this.errorAction)
        route.fetchAllClasses((classes) => { this.setState({ classes }) }, this.errorAction)
        route.fetchCourseLevelList((levels) => { this.setState({ levels }) }, this.errorAction)
        route.fetchAllStudentsInClasses(this.state.Class._id, (students) => { this.setState({ students: students.filter((student) => student.status.id != "blocked") }) }, this.errorAction)
    }

    changeState = (key, value) => {
        this.state.Class.change(key, value)
        this.setState({ Class: this.state.Class })
    }

    loadedPeriod = (period) => {
        this.props.loadingAction(false)
        this.state.printEnrollment.period = period
        this.setState({ period: period, printEnrollment: this.state.printEnrollment });
    }

    errorAction = (values) => {
        this.props.loadingAction(false)
        this.setState({ values });
    }

    saveAction = () => {
        if (this.periodValidate()) {
            this.props.loadingAction(true)
            const route = new PedagogicRoute()
            route.fetchUpdateClass(this.state.Class, this.successAction, (error) => {
                this.props.setAlert('warning', 'Ops!', error)
            })
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    successAction = (Class) => {
        this.props.history.push({
            pathname: pageLinks.pedagogicClass,
            state: { Class: Class, ClassCreated: true }
        })
    }

    periodValidate = () => {
        return !Helpers.isEmptyTexts(
            [this.state.Class.name]
        )
    }

    changeStateCourseLevel = (key, value) => {
        this.state.selectedCourseLevel = value
        this.setState({ selectedCourseLevel: this.state.selectedCourseLevel, coursesFiltered: this.state.courses.filter(item => { return ((item.level.id == value)) }) })
    }

    selectItemAction = (value) => {
        let clientId = this.state.students.filter(item => { return ((item.enrollmentId == value)) })[0]._id
        this.props.history.push({
            pathname: pageLinks.clientDetail,
            state: { document: clientId, clientCreated: false }
        })
    }

    updateListChecked = (value) => {
        this.setState({ checkedList: value })
    }

    fastAction = (value) => {
        console.log(value);
        const client = this.state.fastOptions.find((element) => { return element.id == value })
        console.log(client);
        client.action(this.state.checkedList)
    }

    individualAction = (value) => {
        const client = this.state.individualOptions.find((element) => { return element.id == value.optionId; })
        client.action(value.rowId)
    }

    deleteClientsAction = () => {
        window.location.reload()
        this.props.loadingAction(true)
        this.props.setAlert('success', 'Tudo certo!', 'Matrícula do aluno cancelada com sucesso')
        new PedagogicRoute().fetchUpdateStatusEnrollments({ "ids": this.state.deleteClients, "isDisable": true, "isConclued": false }, this.loadRows, this.errorAction)
    }

    concluedClientsAction = () => {
        window.location.reload()
        this.props.loadingAction(true)
        this.props.setAlert('success', 'Tudo certo!', 'Matrícula do aluno concluída com sucesso')
        new PedagogicRoute().fetchUpdateStatusEnrollments({ "ids": this.state.concluedClients, "isDisable": false, "isConclued": true }, this.loadRows, this.errorAction)
    }

    reenrollmentsAction = () => {
        window.location.reload()
        this.props.loadingAction(true)
        this.props.setAlert('success', 'Tudo certo!', 'Alunos rematriculados na nova turma com sucesso')
        new PedagogicRoute().fetchReEnrollments({ "ids": this.state.reenrollments, "enrollment": this.state.reenrollment }, this.loadRows, this.errorAction)
    }

    disableClientsAction = () => {
        window.location.reload()
        this.props.loadingAction(true)
        this.props.setAlert('success', 'Tudo certo!', 'Alunos desativados com sucesso')
        new ClientRoute().fetchDeleteClients(this.state.disableClients, this.loadRows, this.errorAction)
    }

    getBoletimId = () => {
        return this.props.location.state.classEducation.course.boletim
    }

    printBoletimClientsAction = (ids) => {
        this.props.loadingAction(true)
        ids.forEach(element => {
            new PedagogicRoute().fetchGetPDFBoletim(element, this.getBoletimId(), this.showPdfInNewTab, this.errorAction)
        })
    }

    showPdfInNewTab(base64Data) {
        this.props.loadingAction(false)
        var byteCharacters = atob(base64Data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    getTable() {
        var rows = new Array()
        const items = this.state.students
        items.forEach(element => {
            const data = {
                name: element.name,
                document: element.document,
                status: <span class="ml-0" style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}><i class={`fas fa-` + element.status.iconName + ` fa-1x`}></i> {element.status.title}</span>,
                id: element.enrollmentId
            }
            rows.push(data)
        });
        return rows
    }

    changeStateEnrollment = (key, value) => {
        this.state.reenrollment.change(key, value)
        this.setState({ reenrollment: this.state.reenrollment })
    }

    changeStateCourseLevel = (key, value) => {
        this.state.selectedCourseLevel = value
        this.setState({ selectedCourseLevel: this.state.selectedCourseLevel, coursesFiltered: this.state.courses.filter(item => { return ((item.level.id == value)) }) })
    }

    changeStateCourse = (key, value) => {
        this.state.reenrollment.change(key, value)
        this.setState({ reenrollment: this.state.reenrollment, classesFiltered: this.state.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.reenrollment.period)) }) })
    }

    render() {
        if (this.state.printEnrollment.period == null && this.state.printEnrollment.periods.length > 0) {
            this.state.printEnrollment.period = (this.state.printEnrollment.periods[0]._id)
            this.setState({ printEnrollment: this.state.printEnrollment })
        }
        let classEducation = this.props.location.state.classEducation
        if (this.state.reenrollment.period == null && this.state.periods.length > 0) {
            this.state.reenrollment.period = (this.state.periods[0]._id)
            this.setState({ reenrollment: this.state.reenrollment })
        }
        return (
            <div class="container-fluid">

                <Modal show={this.state.isPrintMatriculas == true} onHide={() => { if (this.state.isPrintMatriculas == true) { window.location.reload(); }; this.setState({ isPrintMatriculas: false }); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{"Ficha de matrícula"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p class="text-muted">{"Selecione os dados de turma para imprimir a ficha de matrícula."}</p>
                        {<SelectTextField id="period" defaultValue={this.state.printEnrollment.period ?? (this.state.printEnrollment.periods.length > 0 ? this.state.printEnrollment.periods[0]._id : 1)} title="Período Letivo *" isRequired={true} delegate={this.changeStatePrintEnrollment} width="25ch" options={this.state.printEnrollment.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                        {<SelectTextField id="office" defaultValue={this.state.printEnrollment.office ?? 1} title="Unidade *" isRequired={true} delegate={this.changeStatePrintEnrollment} width="25ch" options={this.state.printEnrollment.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                        {<SelectTextField id="selectedCourseLevel" defaultValue={this.state.printEnrollment.selectedCourseLevel ?? -1} title="Nível *" isRequired={true} delegate={this.changeStatePrintCourseLevel} width="25ch" options={this.state.printEnrollment.levels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
                        {this.state.printEnrollment.coursesFiltered == null ? null : <SelectTextField id="course" defaultValue={this.state.printEnrollment.course ?? 1} title="Curso *" isRequired={true} delegate={this.changeStatePrintCourse} width="25ch" options={this.state.printEnrollment.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                        {this.state.printEnrollment.classesFiltered == null ? null : <SelectTextField id="class" defaultValue={this.state.printEnrollment.class ?? 1} title="Turma *" isRequired={true} delegate={this.changeStatePrintEnrollment} width="25ch" options={this.state.printEnrollment.classesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.printFichaMatriculaAction}>{"Gerar Ficha de Matrícula"}</Button>
                        <Button variant="primary" onClick={() => { if (this.state.isPrintMatriculas == true) { window.location.reload(); }; this.setState({ isPrintMatriculas: false }); }}>{"Fechar"}</Button>
                    </Modal.Footer>
                </Modal>

                <ModalAlertSimple title="Atenção" desc={this.state.deleteClients.length == 1 ? "Tem certeza que deseja remover este aluno da turma?" : "Tem certeza que deseja remover estes alunos da turma?"} isShow={this.state.deleteClients.length > 0} defaultAction={this.deleteClientsAction} closeAction={() => { if (this.state.deleteClients.length > 1) { window.location.reload(); }; this.setState({ deleteClients: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
                <ModalAlertSimple title="Atenção" desc={this.state.concluedClients.length == 1 ? "Tem certeza que deseja concluir a matrícula selecionada?" : "Tem certeza que deseja concluir as matrículas selecionadas?"} isShow={this.state.concluedClients.length > 0} defaultAction={this.concluedClientsAction} closeAction={() => { if (this.state.concluedClients.length > 1) { window.location.reload(); }; this.setState({ concluedClients: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
                <ModalAlertSimple title="Atenção" desc={this.state.disableClients.length == 1 ? "Tem certeza que deseja desativar este aluno?" : "Tem certeza que deseja desativar estes alunos?"} isShow={this.state.disableClients.length > 0} defaultAction={this.disableClientsAction} closeAction={() => { if (this.state.disableClients.length > 1) { window.location.reload(); }; this.setState({ disableClients: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
                <Modal show={this.state.reenrollments.length > 0} onHide={() => { if (this.state.reenrollments.length > 0) { window.location.reload(); }; this.setState({ reenrollments: [] }); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{"Rematricula"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {<SelectTextField id="period" defaultValue={this.state.reenrollment.period ?? (this.state.periods.length > 0 ? this.state.periods[0]._id : 1)} title="Período Letivo *" isRequired={true} delegate={this.changeStateEnrollment} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                        {<SelectTextField id="office" defaultValue={this.state.reenrollment.office ?? 1} title="Unidade *" isRequired={true} delegate={this.changeStateEnrollment} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                        {<SelectTextField id="selectedCourseLevel" defaultValue={this.state.selectedCourseLevel ?? -1} title="Nível *" isRequired={true} delegate={this.changeStateCourseLevel} width="25ch" options={this.state.levels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
                        {<SelectTextField id="course" defaultValue={this.state.reenrollment.course ?? 1} title="Curso *" isRequired={true} delegate={this.changeStateCourse} width="25ch" options={this.state.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                        {<SelectTextField id="class" defaultValue={this.state.reenrollment.class ?? 1} title="Turma *" isRequired={true} delegate={this.changeStateEnrollment} width="25ch" options={this.state.classesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { if (this.state.reenrollments.length > 0) { window.location.reload(); }; this.setState({ reenrollments: [] }); }}>{"Cancelar"}</Button>
                        <Button variant="primary" onClick={this.reenrollmentsAction}>{"Rematricular"}</Button>
                    </Modal.Footer>
                </Modal>
                <PageTitle title="Informações da Turma" history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Informações da Turma</h6>
                    </div>
                    <div class="card-body" hidden={this.state.isEditing}>
                        <div class="text-left row">
                            <div class="col-sm-2" hidden={this.state.Class.name == null}><h8>Turma</h8><br></br><strong><span class="ml-2">{this.state.Class.name}</span></strong></div>
                            <div class="col-sm-2" hidden={classEducation.course == null}><h8>Curso</h8><br></br><strong><span class="ml-2">{classEducation.course.name}</span></strong></div>
                            {this.state.levels.length == 0 ? <div /> : <div class="col-sm-2" hidden={classEducation.course.levelId == null}><h8>Nível</h8><br></br><strong><span class="ml-2">{this.state.levels[classEducation.course.levelId].title}</span></strong></div>}
                            <div class="col-sm-2" hidden={classEducation.period == null}><h8>Período Letivo</h8><br></br><strong><span class="ml-2">{classEducation.period.name}</span></strong></div>
                            <div class="col-sm-2" hidden={classEducation.office == null}><h8>Unidade</h8><br></br><strong><span class="ml-2">{classEducation.office.name}</span></strong></div>
                        </div>
                        <ColoredButton title="Editar" clickAction={() => { this.setState({ isEditing: true }) }} />
                    </div>
                    <div class="card-body" hidden={!this.state.isEditing}>
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="name" defaultValue={this.state.Class.name} title="Nome" isRequired={true} delegate={this.changeState} width="25ch" />
                                    <SimpleTextField id="desc" defaultValue={this.state.Class.desc} title="Descrição" isRequired={true} delegate={this.changeState} width="25ch" />
                                    <SelectTextField id="office" defaultValue={this.state.Class.office ?? 1} title="Unidade" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />
                                    {<SelectTextField id="period" defaultValue={this.state.Class.period ?? 1} title="Período Letivo" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                    {<SelectTextField id="selectedCourseLevel" defaultValue={this.state.selectedCourseLevel ?? -1} title="Nível" isRequired={true} delegate={this.changeStateCourseLevel} width="25ch" options={this.state.levels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
                                    {<SelectTextField id="course" defaultValue={this.state.Class.course ?? 1} title="Curso" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                </div>,

                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                <SimpleButton title="Cancelar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
                <div class="card shadow mb-4" hidden={this.state.students.length == 0}>
                    <EnhancedTable
                        titleTable='Alunos Matriculados na Turma'
                        rows={this.getTable()}
                        headCells={this.headCells}
                        defaultOrderId='name'
                        delegate={this.selectItemAction}
                        selectedList={this.updateListChecked}
                        fastOptions={this.state.fastOptions}
                        selectedFastAction={this.fastAction}
                        individualOptions={this.state.individualOptions}
                        selectedIndividualAction={this.individualAction}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(ClassDetailPage);