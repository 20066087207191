import React, { Component } from 'react'

export default class PageTitle extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let isHistory = this.props.history != null
        return (
            <h1 class={"h4 mb-3 text-gray-800 " + (isHistory ? "pointer" : "")} onClick={isHistory ? () => {this.props.history.goBack()} : null}><i hidden={this.props.history == null} class={`fas fa-arrow-left fa-1x`}></i><strong> {this.props.title}</strong></h1>
        )
    }
}