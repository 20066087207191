import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../../Views/Components/TopButton';
import EnhancedTable from '../../../Views/Components/EnhancedTable';
import { pageLinks } from '../../../Routes/PageLinks';
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PageTitle from '../../../Views/Components/PageTitle';
import SelectTextField from '../../../Views/Components/SelectTextField';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import ImportButton from '../../../Views/Components/ImportButton';
import APIManager from '../../../API/APIManager';
import User from '../../../Models/Auth/User';
import { Button, Modal } from 'react-bootstrap';

class ClassesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      items: [],
      monthSelected: 1,
      months: [
        {id: 1, name: 'Janeiro'},
        {id: 2, name: 'Fevereiro'},
        {id: 3, name: 'Março'},
        {id: 4, name: 'Abril'},
        {id: 5, name: 'Maio'},
        {id: 6, name: 'Junho'},
        {id: 7, name: 'Julho'},
        {id: 8, name: 'Agosto'},
        {id: 9, name: 'Setembro'},
        {id: 10, name: 'Outubro'},
        {id: 11, name: 'Novembro'},
        {id: 12, name: 'Dezembro'},
      ],
      type: "",
      itemsDisabled: [],
      isShowDisabled: false,
      checkedList: [],
      selectedItem: '',
      deleteItems: [],
      periods: [],
      courses: [],
      coursesFiltered: [],
      offices: [],
      coursesLevels: [],
      listCallClasses: [],
      selected: {
        selectedPeriod: null,
        selectedCourse: null,
        selectedCourseLevel: null,
        selectedOffice: null
      },
      individualOptions: [
        { id: '0', label: 'Apagar turma', action: (clientDocuments) => this.setState({ deleteItems: [clientDocuments] }) },
        { id: '1', label: 'Imprimir lista de chamada', action: (clientDocuments) => {
          this.state.listCallClasses = [clientDocuments]
          this.setState({ listCallClasses: [clientDocuments] })
        } }
      ],
      fastOptions: [
        { id: '0', label: 'Apagar turmas selecionadas', action: (clientDocuments) => this.setState({ deleteItems: clientDocuments }) },
        { id: '1', label: 'Imprimir lista de chamada das turmas selecionadas', action: (clientDocuments) => {
          this.state.listCallClasses = clientDocuments
          this.setState({ listCallClasses: clientDocuments })
        } }
      ]
    }
    this.props.loadingAction(true)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.exportAction = this.exportAction.bind(this)
    this.importAction = this.importAction.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.selectItemAction = this.selectItemAction.bind(this)
  }

  fetchStacks = () => {
    let route = new PedagogicRoute()
    route.fetchAllCourses((courses) => { this.setState({ courses, coursesFiltered: courses }) }, this.errorAction)
    route.fetchAllPeriods((periods) => {
      if (periods.length > 0) { this.changeStatePeriod(null, periods[0]._id) }
      this.setState({ periods }) 
    }, this.errorAction)
    route.fetchAllOffices((offices) => { this.setState({ offices }) }, this.errorAction)
    route.fetchCourseLevelList((coursesLevels)=>{ this.setState({ coursesLevels })}, this.errorAction)
  }

  loadRows = (values) => {
    this.setState({ deleteItems: [] })
    this.props.loadingAction(false)
    this.setState({ items: values });
  }

  deleteItemsAction = () => {
    this.props.loadingAction(true)
    new PedagogicRoute().fetchDeleteClasses({ "ids": this.state.deleteItems }, ()=>{ new PedagogicRoute().fetchAllClassesFiltered(null, null, null, this.loadRows, this.errorAction) }, this.errorAction)
    new PedagogicRoute().fetchGetPDFCallList({ "ids": this.state.deleteItems, "company": APIManager.loadCache("company") }, ()=>{ new PedagogicRoute().fetchAllClassesFiltered(null, null, null, this.loadRows, this.errorAction) }, this.errorAction)
  }

  requestPrintCallListAction = () => {
    this.setState({ listCallClasses: [] });
    this.props.loadingAction(true)
    this.state.listCallClasses.forEach((classe)=>{
      new PedagogicRoute().fetchGetPDFCallList(classe, this.state.monthSelected, this.downloadPDF, this.errorAction)
    })
  }

  downloadPDF = (base64Data) => {
    this.props.loadingAction(false)
    const base64String = base64Data; // Substitua isso pela sua string base64 do PDF
    const fileName = 'lista de chamada.pdf'; // Nome do arquivo a ser baixado

    // Converte a string base64 para um array de bytes
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Cria um Blob a partir do array de bytes
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Cria uma URL para o Blob
    const url = URL.createObjectURL(blob);

    // Cria um link de download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    // Adiciona o link ao corpo do documento
    document.body.appendChild(a);

    // Clica no link para iniciar o download
    a.click();

    // Remove o link após um curto período
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 1000);
    window.location.reload();
  };

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({ values });
  }

  selectItemAction = (value) => {
    const items = this.state.items
    let classEducation = items.find((element) => { return element._id == value })
    this.props.history.push({
      pathname: pageLinks.detailPedagogicClass,
      state: { classEducation: classEducation }
    })
  }

  updateListChecked = (value) => {
    this.setState({ checkedList: value })
  }

  fastAction = (value) => {
    const client = this.state.fastOptions.find((element) => { return element.id == value })
    client.action(this.state.checkedList)
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId; })
    client.action(value.rowId)
  }

  newClientAction() {
    this.props.history.push(pageLinks.newPedagogicClass)
  }

  componentDidMount() {
    let route = new PedagogicRoute()
    route.fetchAllClassesFiltered(null, null, null,(result)=>{this.loadRows(result); this.fetchStacks()}, this.errorAction)
  }

  changeStatePeriod = (key, value) => {
    this.state.selected.selectedPeriod = value
    this.setState({ selected: this.state.selected })
    new PedagogicRoute().fetchAllClassesFiltered(this.state.selected.selectedPeriod, this.state.selected.selectedCourse, this.state.selected.selectedOffice, this.loadRows, this.errorAction)
  }

  changeStateCourse = (key, value) => {
    this.state.selected.selectedCourse = value
    this.setState({ selected: this.state.selected })
    new PedagogicRoute().fetchAllClassesFiltered(this.state.selected.selectedPeriod, this.state.selected.selectedCourse, this.state.selected.selectedOffice, this.loadRows, this.errorAction)
  }

  changeStateOffice = (key, value) => {
    this.state.selected.selectedOffice = value
    this.setState({ selected: this.state.selected })
    new PedagogicRoute().fetchAllClassesFiltered(this.state.selected.selectedPeriod, this.state.selected.selectedCourse, this.state.selected.selectedOffice, this.loadRows, this.errorAction)
  }

  changeStateCourseLevel = (key, value) => {
    this.state.selected.selectedCourseLevel = value
    this.setState({ selected: this.state.selected, coursesFiltered: this.state.courses.filter(item => { return ((item.level.id == value)) }) })
  }

  getTable() {
    var rows = new Array()
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    items.forEach(element => {
      const data = {
        name: element.name,
        course: element.course.name,
        level: this.state.coursesLevels.length == 0 ? "" : this.state.coursesLevels.filter(item => { return (item.id == element.course.levelId) })[0].title,
        period: element.period.name,
        office: element.office.name,
        id: element._id
      }
      rows.push(data)
    });
    return rows
  }

  exportAction(type) {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchExport(type, (csvContent)=> {
      this.props.loadingAction(false)
      Helpers.downloadCSV(type, csvContent)
    }, this.errorAction)
  }

  importAction(file) {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchImport(this.state.type, file, (csvContent)=> {
      this.props.loadingAction(false)
      window.location.reload()
      alert("Dados importados com sucesso!")
    }, this.errorAction)
  }

  render() {
    const headCells = [
      { id: 'name', label: 'Nome', isButton: true },
      { id: 'course', label: 'Curso', isButton: false },
      { id: 'level', label: 'Nível', isButton: false },
      { id: 'period', label: 'Periodo Letívo', isButton: false },
      { id: 'office', label: 'Unidade', isButton: false },
      { id: 'id', label: '', isButton: false, isHidden: true }
    ];
    const isHiddenImports = APIManager.loadCache(User.keyClass).level < 99
    // console.log(this.state.monthSelected)
    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja apagar essa turma?" : "Tem certeza que deseja apagar essas turmas?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) { window.location.reload(); }; this.setState({ deleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <Modal show={this.state.listCallClasses.length > 0} onHide={() => { if (this.state.listCallClasses.length > 0) { window.location.reload(); }; this.setState({ listCallClasses: [] }); }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Selecione o mês da lista de chamada"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {<SelectTextField id="month" defaultValue={this.state.monthSelected == null ? this.state.months[0].id : this.state.monthSelected} title="Mês *" isRequired={true} delegate={(month, value)=>{this.state.monthSelected = value; this.setState({ monthSelected: value })}} width="25ch" options={this.state.months.map((obj) => { return { id: obj.id, label: obj.name } })} />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => { if (this.state.listCallClasses.length > 0) { window.location.reload(); }; this.setState({ listCallClasses: [] }); }}>{"Cancelar"}</Button>
            <Button variant="primary" onClick={this.requestPrintCallListAction}>{"Imprimir"}</Button>
          </Modal.Footer>
        </Modal>
        <PageTitle title="Turmas" />
        <div class="row">
          <TopButton title="NOVA TURMA" icon="plus" clickAction={this.newClientAction} />
          <ImportButton hidden={isHiddenImports} title="IMPORTAR TURMAS" icon="file-upload" changeAction={this.importAction} openAction={r => {this.state.type = "classes"}} />
          <TopButton title="EXPORTAR TURMAS" type="classes" icon="file-download" clickAction={this.exportAction} />
          <ImportButton hidden={isHiddenImports} title="IMPORTAR MATRICULAS" icon="file-upload" changeAction={this.importAction} openAction={r => {this.state.type = "enrollments"}} />
          <TopButton title="EXPORTAR MATRICULAS"  type="enrollments" icon="file-download" clickAction={this.exportAction} />
          <ImportButton hidden={isHiddenImports} title="IMPORTAR NOTAS" icon="file-upload" changeAction={this.importAction} openAction={r => {this.state.type = "grades"}} />
          <TopButton title="EXPORTAR NOTAS" type="grades" icon="file-download" clickAction={this.exportAction} />
        </div>
        <div class="card shadow mb-4">
          <div class="card-body">
            <div>
              {<SelectTextField id="selectedPeriod" defaultValue={this.state.selected.selectedPeriod ?? 1} title="Período Letivo" isRequired={true} delegate={this.changeStatePeriod} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
              {<SelectTextField id="selectedOffice" defaultValue={this.state.selected.selectedOffice ?? 1} title="Unidade" isRequired={true} delegate={this.changeStateOffice} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
              {<SelectTextField id="selectedCourseLevel" defaultValue={this.state.selected.selectedCourseLevel ?? -1} title="Nível" isRequired={true} delegate={this.changeStateCourseLevel} width="25ch" options={this.state.coursesLevels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
              {<SelectTextField id="selectedCourse" defaultValue={this.state.selected.selectedCourse ?? 1} title="Curso" isRequired={true} delegate={this.changeStateCourse} width="25ch" options={this.state.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
            </div>
          </div>
        </div>
        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Turmas'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='year'
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(ClassesPage);