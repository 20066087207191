import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import PageTitle from '../../Views/Components/PageTitle';
import FormComponent from '../../Views/Components/FormComponent';
import SimpleTextField from '../../Views/Components/SimpleTextField';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import APIManager from '../../API/APIManager';
import SelectImage from '../../Views/Components/SelectImage';
import ColorInput from '../../Views/Components/ColorInput';
import CurrencyField from '../../Views/Components/CurrencyTextField';
import User from '../../Models/Auth/User';
import CompanyLayout from '../../Models/Auth/CompanyLayout';
import AuthRoute from '../../API/Routes/AuthRoute';
import ClientRoute from '../../API/Routes/ClientRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import Helpers from '../../Helpers/Helpers';
import UIAvatar from 'react-ui-avatars';
import BarButton from '../../Views/Components/BarButton';
import UserRequestLogin from '../../Models/Auth/UserRequestLogin';

class ClientConfigPage extends Component {
  constructor(props) {
    super(props);

    const user = APIManager.loadCache(User.keyClass)
    const layout = APIManager.loadCache(CompanyLayout.keyClass)

    this.state = {
      user: user,
      isEditing: false,
      layout: layout,
      titleMessage: null,
      errorMessage: null,
      actionCloseMessage: null
    }

    this.props.loadingAction(false)
    this.props.setAlert()
    this.savedAction = this.savedAction.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.changeStateZipCode = this.changeStateZipCode.bind(this)
  }

  errorAction = (values) => {
    this.setState({
      values
    });
  }

  editState = () => {
    this.setState({ isEditing: true })
  }

  cancelEditState = () => {
    this.setState({ isEditing: false })
  }

  changeState = (key, value) => {
    this.state.user[key] = value
    this.setState({ user: this.state.user })
  }

  changeStateAddress = (key, value) => {
    this.state.user.address[key] = value
    this.setState({ user: this.state.user })
  }

  changeStateZipCode = (key, value) => {
    this.state.user.address[key] = value
    this.setState({ user: this.state.user })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressWithZipCode, function (error) { })
    }
  }

  updateAddressWithZipCode = (address) => {
    this.state.user.address = address
    this.setState({ user: this.state.user })
  }

  saveAction = () => {
    this.props.loadingAction(true)
    new ClientRoute().fetchUpdate(this.state.user, this.savedAction, this.errorAction)
  }

  changePasswordAction = () => {
    this.props.loadingAction(true)
    let user = new UserRequestLogin('', this.state.user.document, APIManager.loadCache('company'))
    new AuthRoute().fetchMailLostPassword(user, this.passEmailSendedAction, this.showErrorAction)
  }

  passEmailSendedAction = (user) => {
    this.props.loadingAction(false)
    this.setState({ actionCloseMessage: null, titleMessage: "Tudo certo!", errorMessage: 'Um link de verificação foi enviado para o email, basta clicar no link enviado para alterar a sua senha', isLoading: false })
  }

  showErrorAction = (error) => {
    this.props.loadingAction(false)
    this.setState({ titleMessage: null, actionCloseMessage: null, errorMessage: error, isLoading: false })
  }

  savedAction = (callback) => {
    const user = APIManager.loadCache(User.keyClass)

    user.name = callback.name
    user.email = callback.email
    user.phone = callback.phone
    user.address = callback.address
    APIManager.saveCache(User.keyClass, user)
    this.props.loadingAction(false)
    this.setState({ actionCloseMessage: () => { window.location.reload() }, titleMessage: "Tudo certo!", errorMessage: 'As alterações foram salvas com sucesso!', isLoading: false })
  }

  getCompleteAddress() {
    const address = this.state.user.address
    return address.addressStreet + (address.number != "" ? " " + address.number : "") + (address.complement != "" ? ", " + address.complement : "") + ", " + address.neighborhood + ", " + address.city + " - " + address.uf + ", " + address.zipCode
  }

  render() {
    return (
      <div class="container-fluid">
        <ModalAlertSimple title={this.state.titleMessage == null ? "Ops, algo deu errado..." : this.state.titleMessage} desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={this.state.actionCloseMessage == null ? () => this.setState({ errorMessage: null }) : this.state.actionCloseMessage} />
        <PageTitle title="Meu Usuário" history={this.props.history} />

        <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Informações</h6>
            {/* <BarButton title="Alterar senha" clickAction={this.changePasswordAction} /> */}
          </div>
          <div class="card-body" hidden={this.state.isEditing}>
            <div class="row">
              <div class="col-sm">
                <div class="row">
                  <div class="col-sm" hidden={this.state.user == null} >
                    <div class="float-end mt-3 mt-sm-6">
                    <div class={"ml-5 mt-5"}>
                        <UIAvatar className="card-image img-profile rounded-circle" name={this.state.user.name} color={this.state.layout.primaryColor}/>
                      </div>
                     <div hidden={this.state.user.name == null} >
                        <h8>Nome: </h8><br />
                        <strong><span class="ml-2">{this.state.user.name}</span></strong><br/><br/>
                      </div>
                      <div hidden={this.state.user.document == null} >
                        <h8>{Helpers.documentTitle(this.state.user.document)}: </h8><br />
                        <strong><span class="ml-2">{Helpers.documentFormat(this.state.user.document)}</span></strong><br/><br/>
                      </div>
                      <div hidden={this.state.user.email == null} >
                        <h8>Email: </h8><br />
                        <strong><span class="ml-2">{this.state.user.email}</span></strong><br/><br/>
                      </div>
                      <div hidden={this.state.user.phone == null} >
                        <h8>Telefone: </h8><br />
                        <strong><span class="ml-2">{this.state.user.phone}</span></strong><br/><br/>
                      </div>
                      <div hidden={this.state.user.address['addressStreet'] == null} >
                        <h8>Endereço: </h8><br />
                        <strong><span class="ml-2">{this.getCompleteAddress()}</span></strong>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <ColoredButton hidden={this.state.user.level < 5} title="Editar" clickAction={this.editState} />
          </div>
          <div class="card-body" hidden={!this.state.isEditing}>
            <FormComponent components={
              [
                <SimpleTextField id="name" defaultValue={this.state.user.name} title="Nome da Empresa" isRequired={false} delegate={this.changeState} width="80%" />,
                <SimpleTextField isDisabled={true} id="document" defaultValue={this.state.user.document} title="CNPJ" isRequired={true} delegate={this.changeState} width="39%" mask={new String(this.state.user.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />,
                <SimpleTextField type="email" id="email" defaultValue={this.state.user.email} title="Email" isRequired={true} delegate={this.changeState} width="40%" />,
                <SimpleTextField type="phone" id="phone" defaultValue={this.state.user.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.user.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
              ]
            } />
          </div>
          <div class="card-header py-3" hidden={!this.state.isEditing}>
            <h6 class="m-0 font-weight-bold text-primary">Endereço</h6>
          </div>
          <div class="card-body" hidden={!this.state.isEditing}>
            <FormComponent components={
              [
                <div>
                  <SimpleTextField id="zipCode" defaultValue={this.state.user.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateZipCode} width="20ch" mask="99999-999" />
                </div>,
                <div hidden={this.state.user.address.addressStreet == null}>
                  <SimpleTextField id="addressStreet" isLoad defaultValue={this.state.user.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeStateAddress} width="61%" />
                  <SimpleTextField id="number" isLoad defaultValue={this.state.user.address.number} title="Número" isRequired={true} delegate={this.changeStateAddress} width="15%" />
                  <SimpleTextField id="complement" isLoad defaultValue={this.state.user.address.complement} title="Complemento" isRequired={false} delegate={this.changeStateAddress} width="15%" />
                  <SimpleTextField id="neighborhood" isLoad defaultValue={this.state.user.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeStateAddress} width="15%" />
                  <SimpleTextField id="city" isLoad defaultValue={this.state.user.address.city} title="Cidade" isRequired={true} delegate={this.changeStateAddress} width="20ch" />
                  <SimpleTextField id="uf" isLoad defaultValue={this.state.user.address.uf} title="Estado" isRequired={true} delegate={this.changeStateAddress} width="20ch" />
                </div>,

                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                <SimpleButton title="Cancelar" clickAction={this.cancelEditState} />
              ]
            } />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ClientConfigPage);