import ModelJSON from "../ModelJSON";
import moment from "moment";
import InvoiceStatus from "./InvoiceStatus";
import InvoiceType from "./InvoiceType";
import InvoiceProvider from "./InvoiceProvider";
import InvoiceClient from "./InvoiceClient";

export default class Invoice extends ModelJSON {
  constructor(_id, desc = "", amount, amountPenalty, amountFees, amountDiscount, amountTotal, amountPayed, amountTax, totalWithTax, venciment = moment(new Date()).format(), status = new InvoiceStatus(), paymentType = new InvoiceType(), plan, provider = new InvoiceProvider(), datePayed, client = new InvoiceClient(), clientAsaasId, isTranferedValue, enrollment, enrollmentText, officeId, officeName) {
    super()
    this._id = _id
    this.desc = desc;
    this.amount = amount;
    this.amountPenalty = amountPenalty;
    this.amountFees = amountFees
    this.amountDiscount = amountDiscount
    this.amountTotal = amountTotal
    this.amountPayed = amountPayed
    this.amountTax = amountTax
    this.totalWithTax = totalWithTax
    this.venciment = venciment
    this.status = status
    this.paymentType = paymentType
    this.plan = plan
    this.provider = provider
    this.datePayed = datePayed
    this.client = client
    this.clientAsaasId = clientAsaasId
    this.isTranferedValue = isTranferedValue
    this.enrollment = enrollment
    this.enrollmentText = enrollmentText
    this.officeId = officeId
    this.officeName = officeName
  }

  static init(json) {
    return new Invoice(
      json._id,
      json.desc,
      json.amount,
      json.amountPenalty,
      json.amountFees,
      json.amountDiscount,
      json.amountTotal,
      json.amountPayed,
      json.amountTax,
      json.totalWithTax,
      json.venciment,
      InvoiceStatus.init(json.status),
      InvoiceType.init(json.paymentType),
      json.plan,
      InvoiceProvider.init(json.provider),
      json.datePayed,
      InvoiceClient.init(json.client),
      json.clientAsaasId,
      json.isTranferedValue,
      json.enrollment,
      json.enrollmentText,
      json.officeId,
      json.officeName
    )
  }
}