import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import ColoredButton from '../../Views/Components/ColoredButton';
import BarButton from '../../Views/Components/BarButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import ClientRoute from '../../API/Routes/ClientRoute';
import NewClient from '../../Models/Client/Client';
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import TopButton from '../../Views/Components/TopButton';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import DatePicker from '../../Views/Components/DatePicker';

class SysInvoicesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      itemsFiltered: [],
      deleteItems: [],
      checkedList: [],
      payItems: [],
      errorMessage: null,
      isCancellingPlan: false,
      isEditing: false,
      statusList: [],
      search: {
        status: '',
        name: '',
        document: '',
        year: new Date().getFullYear(),
      },
      individualOptions: [
        // { id: '0', label: 'Quitar cobrança', action: (itemId) => this.setState({ payItems: [itemId] }) },
        // { id: '1', label: 'Cancelar cobrança', action: (itemId) => this.setState({ deleteItems: [itemId] }) }
      ],
      fastOptions: [
        // { id: '0', label: 'Quitar cobranças selecionados', action: (itemId) => this.setState({ payItems: itemId }) },
        // { id: '1', label: 'Cancelar cobranças selecionadas', action: (itemId) => this.setState({ deleteItems: itemId }) }
      ]
    }

    this.props.loadingAction(true)
    this.props.setAlert()
    this.loadedInvoices = this.loadedInvoices.bind(this)
    this.editState = this.editState.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.cancelEditState = this.cancelEditState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.loadStatusList = this.loadStatusList.bind(this)

    new InvoiceRoute().fetchInvoiceAllStatus(this.loadStatusList, this.errorAction)
  }

  loadStatusList = (values) => {
    this.props.loadingAction(true)
    var rows = [{ id: 'all', label: 'Todas' }]
    values.forEach(element => {
      const data = { id: element.id, label: element.name }
      rows.push(data)
    });
    this.state.search.status = rows[0].id
    this.setState({
      statusList: rows,
      search: this.state.search
    });
    
    new InvoiceRoute().fetchAllInvoicesBySystem(this.loadedInvoices, this.errorAction)
  }

  newInvoiceAction() {
    this.props.history.push({
      pathname: pageLinks.clients,
      state: {}  
    })
  }

  editState = () => {
    this.setState({ isEditing: true })
  }

  cancelEditState = () => {
    this.setState({ isEditing: false })
  }

  errorAction = (values) => {
    this.setState({
      values
    });
  }

  saveAction = () => {
    if (this.clientValidate()) {
      this.props.loadingAction(true)
      if (this.state.client.statusId != null) {
        this.state.client.statusId = this.state.client.status.id
      }
      const route = new ClientRoute()
      route.fetchUpdate(this.state.client, this.savedClient, (error) => {
        this.props.setAlert('warning', 'Ops!', error)
      })
    } else {
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  getTable() {
    var rows = new Array()
    const items = this.state.itemsFiltered.length > 0 ? this.state.itemsFiltered : []
    items.forEach(element => {
      const venciment = new Date(element.venciment)
      const data = {
        id: element.provider.idTransaction,
        venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
        amount: element.amount,
        discount: element.amountDiscount,
        penalty: element.amountPenalty,
        fees: element.amountFees,
        amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
        paymentType: element.paymentType.name,
        status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
      }
      rows.push(data)
    });
    return rows
  }

  getYearList = () => {
    var rows = [{id: 0, label: 'Todos'}]
    let actualYear = new Date().getFullYear()
    for (let index = 0; index < 100; index++) {
      rows.push({id: actualYear - index, label: actualYear - index})
    }
    return rows
  }

  filterByYear = (invoice) => {
    let check = (new Date(invoice.venciment).getFullYear() >= this.state.search.year)
    return check
  }

  selectItemAction = (value) => {
    let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value})
    this.props.history.push({
      pathname: pageLinks.sysInvoiceDetail,
      state: {invoice: invoice, client: this.state.client}  
    })
  }

  updateListChecked = (values) => {
    const invoices = []
    values.forEach(value => {
      let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value && element.status.id != "allpayed" && element.status.id != "cancelled"})
      if (invoice != null){ invoices.push(invoice) }
    })
    this.setState({ checkedList: invoices })
  }

  fastAction = (value) => {
    if (this.state.checkedList.length == 0) {
      this.setState({ errorMessage: "Não é possível alterar o status destas cobranças!" })
    } else {
      const invoice = this.state.fastOptions.find((element) => { return element.id == value})
      invoice.action(this.state.checkedList)
    }
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId;})
    let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value.rowId && element.status.id != "allpayed" && element.status.id != "cancelled"})
    if (invoice == null) {
      this.setState({ errorMessage: "Não é possível alterar o status desta cobrança!" })
    } else {
      client.action(invoice)
    }
  }

  loadedInvoices = (invoices) => {
    this.props.loadingAction(false)
    this.setState({items: invoices});
    this.setState({itemsFiltered: this.getFiltered()});
  }

  deleteItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.deleteItems.map(function (obj) {return obj._id; })
    console.log("ids: " + ids);
    new InvoiceRoute().fetchCancelItems({"ids": ids}, () => {window.location.reload();}, this.errorAction)
  }

  payItemsAction = () => {
    if (this.state.payItems.length > 0) {window.location.reload();}
    this.props.loadingAction(true)
    const ids = this.state.payItems.map(function (obj) {return obj._id; })
    const amounts = this.state.payItems.map(function (obj) {return Helpers.stringToAmountNumber(obj.amountTotal); })
    new InvoiceRoute().fetchManualPayItems({"ids": ids, "amounts": amounts}, () => {}, this.errorAction)
  }

  changeSearchDate = (key, value) => {
    this.state.search[key] = value
    this.setState({ search: this.state.search })
    this.props.loadingAction(true)
    new InvoiceRoute().fetchAllInvoices(Helpers.formatAmericanDate(this.state.search.initialDate), Helpers.formatAmericanDate(this.state.search.endDate), this.loadedInvoices, this.errorAction)
  }

  changeSearch = (key, value) => {
    this.state.search[key] = value
    this.setState({ search: this.state.search })
    this.setState({
      itemsFiltered: this.getFiltered()
    })
  }

  getFiltered(){
    return this.state.items.filter(invoice => {return (this.filterByYear(invoice) && (this.state.search.status == 'all' ? (!invoice.status.id.includes("cancelled")) : (invoice.status.id.includes(this.state.search.status))))})
  }

  render() {
    const headCells = [
        { id: 'id', label: 'Número', isButton: true },
        { id: 'venciment', label: 'Vencimento', isButton: false },
        { id: 'amount', label: 'Valor', isButton: false },
        { id: 'discount', label: 'Desconto', isButton: false },
        { id: 'penalty', label: 'Multa', isButton: false },
        { id: 'fees', label: 'Juros', isButton: false },
        { id: 'amountTotal', label: 'Total', isButton: false },
        { id: 'paymentType', label: 'Tipo', isButton: false },
        { id: 'status', label: 'Situação', isButton: false }
    ];
    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja cancelar esta cobrança?" : "Tem certeza que deseja cancelar estas cobranças?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) {window.location.reload();}; this.setState({ deleteItems: [] }); } } buttonTitle="Sim" closeTitle="Cancelar"/>
        <ModalAlertSimple title="Atenção" desc={this.state.payItems.length == 1 ? "Tem certeza que deseja quitar esta cobrança?" : "Tem certeza que deseja quitar estas cobranças?"} isShow={this.state.payItems.length > 0} defaultAction={this.payItemsAction} closeAction={() => { if (this.state.payItems.length > 1) {window.location.reload();}; this.setState({ payItems: [] }); } } buttonTitle="Sim" closeTitle="Cancelar"/>
        <ModalAlertSimple title="Atenção" desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={() => { this.setState({ errorMessage: null }); } } closeTitle="OK"/>
        <PageTitle title="Faturas do Sistema"/>
        <div class="row">
          {/* <TopButton title="NOVA COBRANÇA" icon="plus" clickAction={this.newInvoiceAction} /> */}
        </div>
        {/* <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Cliente</h6>
          </div>
        </div> */}
        {console.log(this.state.items)}

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Filtrar Faturas</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
                <div>
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="status" defaultValue={this.state.search.status} title="Situação" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.state.statusList} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="year" defaultValue={this.state.search.year} title="Ano" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.getYearList()} />}
                </div>
              ]
            } />
          </div>
        </div>
        

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Lista de Faturas'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='venciment'
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>

      </div>
    )
  }
}

export default withRouter(SysInvoicesPage);