import ModelJSON from "../ModelJSON";
import CoursePedagogic from "./Course";
import OfficePedagogic from "./Office";
import PeriodPedagogic from "./Period";

export default class ClassPedagogic extends ModelJSON {
  constructor(id, name, desc, course, office, period) {
    super()
    this._id = id;
    this.name = name;
    this.desc = desc;
    this.course = course;
    this.office = office;
    this.period = period;
  }

  static init(json) {
    return new ClassPedagogic(
      json._id,
      json.name,
      json.desc,
      CoursePedagogic.initWithStringDisciplines(json.course),
      OfficePedagogic.init(json.office),
      PeriodPedagogic.init(json.period)
    )
  }
}