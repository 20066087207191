import ModelJSON from "../ModelJSON";

export default class GradePedagogic extends ModelJSON {
  constructor(id, title, type, value) {
    super()
    this.id = id;
    this.title = title;
    this.type = type;
    this.value = value;
  }

  static init(json) {
    return new GradePedagogic(
      json.id,
      json.title,
      json.type,
      json.value
    )
  }
}