import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PeriodPedagogic from '../../../Models/Pedagogic/Period';
import APIManager from '../../../API/APIManager';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import ColoredButton from '../../../Views/Components/ColoredButton';
import PageTitle from '../../../Views/Components/PageTitle';
import FormComponent from '../../../Views/Components/FormComponent';
import SelectTextField from '../../../Views/Components/SelectTextField';
import SimpleTextField from '../../../Views/Components/SimpleTextField';
import OfficePedagogic from '../../../Models/Pedagogic/Office';
import ClientRoute from '../../../API/Routes/ClientRoute';
import Bank from '../../../Models/Bank';

class NewOfficePage extends Component {
    constructor(props) {
        super(props)

        this.user = APIManager.loadCache(User.keyClass)
        this.company = this.user.company

        this.props.setAlert()
        this.state = {
            office: new OfficePedagogic()
        }

        let editItem = this.props.location.state.office
        if (editItem != null) {
            this.state = {
                office: new OfficePedagogic(editItem._id, editItem.name, editItem.fantasy, editItem.document, editItem.phone, editItem.email, editItem.address, editItem.bank)
            }
        } else {
            this.state = {
                office: new OfficePedagogic()
            }
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
    }

    changeState = (key, value) => {
        this.state.office.change(key, value)
        this.setState({ office: this.state.office })
    }

    changeStateBank = (key, value) => {
        this.state.office.bank = new Bank("", "", "", "", "", "")
        this.setState({ office: this.state.office })
        this.changeState(key, value)
    }

    loadedPeriod = (period) => {
        this.props.loadingAction(false)
        this.setState({ period: period });
    }

    errorAction = (values) => {
        this.setState({ values });
    }

    saveAction = () => {
        if (this.periodValidate()) {
            this.props.loadingAction(true)
            const route = new PedagogicRoute()
            if (this.props.location.state.office == null) {
                route.fetchCreateOffice(this.state.office, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            } else {
                route.fetchUpdateOffice(this.state.office, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            }
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    successAction = (office) => {
        this.props.history.push({
            pathname: pageLinks.pedagogicOffice,
            state: { office: office, officeCreated: true }
        })
    }

    periodValidate = () => {
        return !Helpers.isEmptyTexts(
            [this.state.office.name]
        )
    }

    changeStateZipCode = (key, value) => {
        this.state.office.change(key, value)
        this.setState({ office: this.state.office })

        if (value.replace(/\D/g, "").length == 8) {
            const route = new ClientRoute()
            route.fetchZipCodeDetails(value, this.updateAddressWithZipCode, function (error) { })
        }
    }

    updateAddressWithZipCode = (address) => {
        this.state.office.address = address
        this.setState({ office: this.state.office })
    }

    render() {
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja desativar este periodo letivo?"} isShow={this.state.isCancelling} defaultAction={this.cancelPeriodAction} closeAction={() => { this.setState({ isCancelling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja ativar este periodo letivo?"} isShow={this.state.isEnabling} defaultAction={this.enablePeriodAction} closeAction={() => { this.setState({ isEnabling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <PageTitle title="Informações da Unidade" history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="name" defaultValue={this.state.office.name} title="Razão Social" isRequired={true} delegate={this.changeState} width="25ch" />
                                    <SimpleTextField id="fantasy" defaultValue={this.state.office.fantasy} title="Nome Fantasia" isRequired={false} delegate={this.changeState} width="25ch" />
                                    <SimpleTextField id="document" isDisabled={this.props.location.state.office != null} defaultValue={this.state.office.document} title="CNPJ" isRequired={false} delegate={this.changeState} width="20ch" mask={"99.999.999/9999-99"} />
                                    <div>
                                        <SimpleTextField type="phone" id="phone" defaultValue={this.state.office.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.office.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                                        <SimpleTextField type="email" id="email" defaultValue={this.state.office.email} title="Email" isRequired={false} delegate={this.changeState} width="78%" />
                                    </div>
                                    <div>
                                    <br/>
                                    <div > <p class="text-muted"><strong>Dados bancários</strong><span class="ml-2">{" (A titularidade da conta precisa pertencer ao CNPJ informado acima.)"}</span></p> </div>
                                        <SelectTextField id="bank/code" defaultValue={this.state.office.bank.code ?? 1} title="Banco" isRequired={true} delegate={this.changeStateBank} width="60ch" options={Bank.getBankCodeList()} />
                                        <SelectTextField id="bank/type" defaultValue={this.state.office.bank.type ?? 1} title="Tipo" isRequired={true} delegate={this.changeState} width="25ch" options={Bank.getTypeList()} />
                                        <br/>
                                        <SimpleTextField id="bank/agency" defaultValue={this.state.office.bank.agency} title="Agência" isRequired={true} delegate={this.changeState} width="20ch" />
                                        <SimpleTextField id="bank/agencyDigit" defaultValue={this.state.office.bank.agencyDigit} title="Dígito" isRequired={false} delegate={this.changeState} width="3ch" mask={"9"} />
                                        <SimpleTextField id="bank/number" defaultValue={this.state.office.bank.number} title="Conta" isRequired={true} delegate={this.changeState} width="20ch" />
                                        <SimpleTextField id="bank/numberDigit" defaultValue={this.state.office.bank.numberDigit} title="Dígito" isRequired={false} delegate={this.changeState} width="3ch" mask={"9"} />
                                    </div>
                                    <div>
                                        <div>
                                        <br/>
                                        <div > <p class="text-muted"><strong>Endereço comercial</strong></p> </div>
                                            <SimpleTextField id="address/zipCode" defaultValue={this.state.office.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateZipCode} width="20ch" mask="99999-999" />
                                        </div>
                                        <div hidden={this.state.office.address.addressStreet == null}>
                                            <SimpleTextField id="address/addressStreet" isLoad defaultValue={this.state.office.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeState} width="61%" />
                                            <SimpleTextField id="address/number" isLoad defaultValue={this.state.office.address.number} title="Número" isRequired={true} delegate={this.changeState} width="15%" />
                                            <SimpleTextField id="address/complement" isLoad defaultValue={this.state.office.address.complement} title="Complemento" isRequired={false} delegate={this.changeState} width="15%" />
                                            <SimpleTextField id="address/neighborhood" isLoad defaultValue={this.state.office.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeState} width="15%" />
                                            <SimpleTextField id="address/city" isLoad defaultValue={this.state.office.address.city} title="Cidade" isRequired={true} delegate={this.changeState} width="20ch" />
                                            <SimpleTextField id="address/uf" isLoad defaultValue={this.state.office.address.uf} title="Estado" isRequired={true} delegate={this.changeState} width="20ch" />
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>,

                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                // <SimpleButton title="Limpar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewOfficePage);