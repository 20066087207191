import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import Helpers from '../../Helpers/Helpers';
import ColoredButton from '../../Views/Components/ColoredButton';
import ItemListWithTitle from '../../Views/Components/ItemListWithTitle';
import SimpleTextField from '../../Views/Components/SimpleTextField';
import FormComponent from '../../Views/Components/FormComponent';
import SimpleButton from '../../Views/Components/SimpleButton';
import CurrencyField from '../../Views/Components/CurrencyTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import { pageLinks } from '../../Routes/PageLinks';
import Publication from '../../Models/Publication/Publication';
import PublicationRoute from '../../API/Routes/PublicationRoute';
import SelectImage from '../../Views/Components/SelectImage';
import EmptyState from '../../Views/EmptyState/EmptyState';
import MultilineTextField from '../../Views/Components/MultilineTextField';

class NewPublicationPage extends Component {
    constructor(props) {
        super(props)

        this.props.setAlert()
        this.state = {
            publication: this.props.location.state.publication ?? new Publication(),
            isEditing: this.props.location.state.isEditing ?? false,
            isDeleting: false
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
    }

    changeState = (key, value) => {
        if (this.state.publication.title == null) {
            this.state.publication = new Publication()
            this.setState({ publication: this.state.publication })
        }
        this.state.publication.change(key, value)
        this.setState({ publication: this.state.publication })
    }

    loadedpublication = (publication) => {
        const isCancelling = this.state.isCancelling
        const isEnabling = this.state.isEnabling
        this.props.loadingAction(false)
        this.setState({
            publication: publication,
            isCancelling: false,
            isEnabling: false,
        });
        this.props.setAlert((isEnabling || isCancelling) ? 'success' : null, 'Tudo certo!', 'O publicationo foi ' + (isEnabling ? 'ativado' : 'desativado') + ' com sucesso.')
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }


    saveAction = () => {
        if (this.publicationValidate()) {
            this.props.loadingAction(true)
            const route = new PublicationRoute()
            if (this.state.isEditing) {
                route.fetchUpdate(this.state.publication, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            } else {
                route.fetchCreate(this.state.publication, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            }
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    deleteAction = () => {
        this.props.loadingAction(true)
        const route = new PublicationRoute()
        route.fetchDelete(this.state.publication._id, this.successAction, (error) => {
            this.props.setAlert('warning', 'Ops!', error)
        })
    }

    successAction = (publication) => {
        this.props.history.push({
            pathname: pageLinks.publications
        })
    }

    publicationValidate = () => {
        return !Helpers.isEmptyTexts(
            [
                this.state.publication.title,
                this.state.publication.desc,
            ]
        )
    }

    render() {
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja deletar essa publicação?"} isShow={this.state.isDeleting} defaultAction={this.deleteAction} closeAction={() => { this.setState({ isDeleting: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <PageTitle title={this.state.isEditing ? "Publicação" : "Nova Publicação"} history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="title" defaultValue={this.state.publication.title} title="Nome" isRequired={true} delegate={this.changeState} width="98%" />
                                    <MultilineTextField id="desc" defaultValue={this.state.publication.desc} title="Descrição" isRequired={true} delegate={this.changeState} width="98%" />
                                    <SimpleTextField id="link" defaultValue={this.state.publication.link} title="Link para redirecionamento" isRequired={false} delegate={this.changeState} width="98%" />
                                    <SelectImage isBig="true" image={this.state.publication.image64} isEditable={true} changeAction={(image) => { this.changeState("image64", image) }} />
                                </div>,

                                <ColoredButton title={this.state.isEditing ? "Publicar Alterações" : "Publicar"} clickAction={this.saveAction} />,
                                <ColoredButton hidden={!this.state.isEditing} title="Excluir" clickAction={() => { this.setState({ isDeleting: true }); }} />
                            ]
                        } />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewPublicationPage);