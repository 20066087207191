import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import ColoredButton from '../../Views/Components/ColoredButton';
import BarButton from '../../Views/Components/BarButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import ClientRoute from '../../API/Routes/ClientRoute';
import NewClient from '../../Models/Client/Client';
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import TopButton from '../../Views/Components/TopButton';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import DatePicker from '../../Views/Components/DatePicker';
import APIManager from '../../API/APIManager';
import LoadingOverlay from 'react-loading-overlay';
import QRCode from 'react-qr-code';
import AuthRoute from '../../API/Routes/AuthRoute';

class SysLockOverdue extends Component {
    constructor(props) {
        super(props)

        this.state = {
            items: [],
            itemsFiltered: [],
            deleteItems: [],
            checkedList: [],
            payItems: [],
            errorMessage: null,
            isCancellingPlan: false,
            isEditing: false,
            individualOptions: [],
            fastOptions: [],
            contactAdmin: {}
        }

        this.loadedInvoices = this.loadedInvoices.bind(this)
        this.errorAction = this.errorAction.bind(this)

        this.loadedInvoices()

        new AuthRoute().fetchContactSupportAdmin((response)=>{
            this.state.contactAdmin = response
            this.setState({contactAdmin: this.state.contactAdmin})
        }, this.errorAction)
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }

    getTable() {
        var rows = new Array()
        const items = APIManager.loadCache("overdueInvoicesSys").length > 0 ? APIManager.loadCache("overdueInvoicesSys") : []
        items.forEach(element => {
            const venciment = new Date(element.venciment)
            const data = {
                id: element.provider.idTransaction,
                venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
                amount: element.amount,
                discount: element.amountDiscount,
                penalty: element.amountPenalty,
                fees: element.amountFees,
                amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
                paymentType: element.paymentType.name,
                status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
            }
            rows.push(data)
        });
        return rows
    }

    loadedInvoices = () => {
        this.state.items = APIManager.loadCache("overdueInvoicesSys")
        this.setState({ items: this.state.items });
    }

    selectItemAction = (value) => {
        let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value })
        APIManager.saveCache("overdueInvoiceSys", invoice)
        window.location.href = pageLinks.sysInvoiceOverdueDetail;
    }

    sendToLoginAction = () => {
        window.location.href = pageLinks.auth.replace(":company", APIManager.loadCache("company"));
    }

    render() {
        this.state.items = APIManager.loadCache("overdueInvoicesSys")
        const headCells = [
            { id: 'id', label: 'Número', isButton: true },
            { id: 'venciment', label: 'Vencimento', isButton: false },
            { id: 'amount', label: 'Valor', isButton: false },
            { id: 'discount', label: 'Desconto', isButton: false },
            { id: 'penalty', label: 'Multa', isButton: false },
            { id: 'fees', label: 'Juros', isButton: false },
            { id: 'amountTotal', label: 'Total', isButton: false },
            { id: 'paymentType', label: 'Tipo', isButton: false },
            { id: 'status', label: 'Situação', isButton: false }
        ];
        return (
            <div class="container-fluid">
                {/* <div class={"alert alert-danger pointer"} onClick={this.openInvoicesListAction} hidden={this.state.overdueSysInvoices == null || this.state.overdueSysInvoices.length == 0}><strong>Atenção!  </strong>{(this.state.overdueSysInvoices?.length > 1 ? "Existem " : "Existe ") + (this.state.overdueSysInvoices?.length) + (this.state.overdueSysInvoices?.length > 1 ? " faturas do sistema vencidas" : " fatura do sistema vencida") + ", evite o bloqueio! clique aqui para verificar"}</div> */}
                <div class="account-pages mt-5 mb-5">
                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-12 text-center">
                                <p class="text-muted"><a onClick={this.sendToLoginAction} class="text-muted ml-1"><b className="pointer">Voltar para o Login</b></a></p>
                            </div>
                        </div>
                        <PageTitle title="Sistema bloqueado" />
                        <h6>O seu sistema foi bloqueado por conta de uma pendência financeira, por favor efetue o pagamento da(s) seguinte(s) fatura(s) em atraso, para liberação do sistema!</h6><br></br>

                        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
                            <EnhancedTable
                                titleTable='Lista de Cobranças Atrasadas'
                                rows={this.getTable()}
                                headCells={headCells}
                                defaultOrderId='venciment'
                                delegate={this.selectItemAction}
                                selectedList={this.updateListChecked}
                                fastOptions={this.state.fastOptions}
                                selectedFastAction={this.fastAction}
                                individualOptions={this.state.individualOptions}
                                selectedIndividualAction={this.individualAction}
                            />
                        </div>

                        <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">SAC</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                        <strong><span class="ml-4">Em caso de qualquer dúvida ou problema encontrado, por favor entrar em contato com um de nossos canais de atendimento abaixo:</span></strong><br />
                            <div class="col-sm-5">
                                <div class="float-end mt-3 mt-sm-6">
                                    <div class="col-sm-9" >
                                        <div class="float-end mt-3 mt-sm-6" align="left">
                                            <h8>Email: </h8><br /><br />
                                            <strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{(this.state.contactAdmin == null) ? "" : this.state.contactAdmin.email ?? ""}</span></strong><br /><br />
                                            <h8>Website: </h8><br /><br />
                                            <strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{(this.state.contactAdmin == null) ? "" : this.state.contactAdmin.website ?? ""}</span></strong>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="col-sm">
                                <div class="float-end mt-3 mt-sm-6">
                                    <h8>Whatsapp: </h8><br /><br />
                                    <p><QRCode value={"https://wa.me/" + ((this.state.contactAdmin == null) ? "" : this.state.contactAdmin.whatsapp ?? "")} size={130} fgColor="#000000" /></p>
                                    <a className="pointer btn-sm btn-primary" onClick={() => { window.open("https://wa.me/" + (this.state.contactAdmin.whatsapp ?? "")); }} >
                                        <span class="h8 font-weight-bold text-white">Enviar Mensagem</span>
                                    </a>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SysLockOverdue);