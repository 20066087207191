import ModelJSON from "../ModelJSON";

export default class ClientPlan extends ModelJSON {
  constructor(id, name) {
    super()
    this.id = id;
    this.name = name;
  }

  static init(json) {
    return new ClientPlan(
      json.id,
      json.name
    )
  }
}