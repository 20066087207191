import React, { Component } from 'react'

export default class LoadMore extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div hidden={this.props.hidden} class="row float-end mt-0 mt-sm-6 mb-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1rem 0rem 1rem'}}>
                <a className="pointer colored-button-center btn-lg btn-tertiary" onClick={this.props.clickAction} >
                <span class="h8 ml-2 mr-2 font-weight-bold text-primary">Carregar mais</span>
                </a>
            </div>
        )
    }
}