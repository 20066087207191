import ModelJSON from "../ModelJSON";

export default class DepositDay extends ModelJSON {
  constructor(DepositDate, Amount, IsTransferred, Message, Extracts, PaymentNumber, HashConfirmation, Tax) {
    super()
    this.DepositDate = DepositDate;
    this.Amount = Amount;
    this.IsTransferred = IsTransferred;
    this.Message = Message;
    this.Extracts = Extracts;
    this.PaymentNumber = PaymentNumber;
    this.HashConfirmation = HashConfirmation;
    this.Tax = Tax;
  }

  static init(json) {
    var depositsList = []
    json.Extracts.forEach(element => {
      const invoice = DepositDayList.init(element);
      depositsList.push(invoice)
    });
    return new DepositDay(
      json.DepositDate,
      json.Amount,
      json.IsTransferred,
      json.Message,
      depositsList,
      json.PaymentNumber,
      json.HashConfirmation,
      json.Tax
    )
  }
}

class DepositDayList extends ModelJSON {
  constructor(PaymentMethod, Amount, Tax, Description, IdTransaction) {
    super()
    this.PaymentMethod = PaymentMethod;
    this.Amount = Amount;
    this.Tax = Tax;
    this.Description = Description;
    this.IdTransaction = IdTransaction;
  }

  static init(json) {
    return new DepositDayList(
      DepositDayPaymentMethod.init(json.PaymentMethod),
      json.Amount,
      json.Tax,
      json.Description,
      json.IdTransaction
    )
  }
}

class DepositDayPaymentMethod extends ModelJSON {
    constructor(Name) {
      super()
      this.Name = Name;
    }
  
    static init(json) {
      return new DepositDayPaymentMethod(
        json.Name
      )
    }
  }