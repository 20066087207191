import { Modal, Button } from "react-bootstrap";
import React, { Component } from 'react'
import SimpleButton from "./SimpleButton";
import ColoredButton from "./ColoredButton";

export default class ModalAlertSimple extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
    <Modal show={this.props.isShow} onHide={this.props.closeAction}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.desc}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.closeAction}>{this.props.closeTitle == null ? "Fechar" : this.props.closeTitle}</Button>
          <Button variant="primary" onClick={this.props.defaultAction} hidden={this.props.buttonTitle == null}>{this.props.buttonTitle}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}