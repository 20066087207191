import ModelJSON from "../ModelJSON";

export default class Chat extends ModelJSON {
  constructor(id, clientId, company, title, lastMessageText, lastMessageDate, messagesNotReadCount) {
    super()
    this.id = id
    this.clientId = clientId;
    this.company = company;
    this.title = title;
    this.lastMessageText = lastMessageText
    this.lastMessageDate = lastMessageDate
    this.messagesNotReadCount = messagesNotReadCount
  }

  static init(json) {
    return new Chat(
        json._id,
        json.clientId,
        json.company,
        json.title,
        json.lastMessageText,
        json.lastMessageDate,
        json.messagesNotReadCount
    )
  }
}