import React, { Component } from 'react'
import EmptyState from '../EmptyState/EmptyState';
import EmptyStateEnum from '../EmptyState/EmptyStateEnum';

export default class SelectImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
    }

    this.changeAction = this.changeAction.bind(this)
  }

  changeAction = (value) => {
    this.getBase64(value, (result) => {
      if (this.props.changeAction != null) {
        if (value.size > 2097152) {
          alert("O arquivo deve possuir no máximo um tamanho de 2 mb");
          return
        };
        const image = result.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "")
        this.state.image = image
        this.setState({ image: image });
        this.props.changeAction(image)
      }
    })
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

  render() {
    return (
      <div>
        <div class={"card-image-box mt-4 mr-4 " + (this.props.isEditable ? "pointer" : "")} style={{overflow: 'clip'}} hidden={this.props.isBig}>
          <a hidden={!this.props.isEditable} className='card-image-edit text-white' onClick={()=>{document.getElementById('file-id').click()}}>Editar</a>
          <img className={this.props.isEditable ? "card-image" : "card-image-no-editable"} src={"data:image/png;base64, " + this.state.image} />
          <input hidden='true' id="file-id" type="file" onChange={e => this.changeAction(e.target.files[0])} accept="image/png, image/jpeg"></input>
        </div>
        <div class={"card-image-box-big mt-4 mr-4 " + (this.props.isEditable ? "pointer" : "")} style={{overflow: 'clip'}} hidden={!this.props.isBig}>
          <a hidden={!this.props.isEditable || this.state.image == null} className='card-image-edit-big text-white' onClick={()=>{document.getElementById('file-id').click()}}>Alterar Imagem</a>
          <EmptyState stateImage={EmptyStateEnum.ImageEnum.noImages} stateText={EmptyStateEnum.TextEnum.noImages} hidden={this.state.image != null}/>
          <a hidden={!this.props.isEditable || this.state.image != null} className='card-image-edit-big-empty text-white' onClick={()=>{document.getElementById('file-id').click()}}>Selecionar Imagem</a>
          <img hidden={this.state.image == null} className="card-image" src={"data:image/png;base64, " + this.state.image} />
          <input hidden='true' id="file-id" type="file" onChange={e => this.changeAction(e.target.files[0])} accept="image/png, image/jpeg"></input>
        </div>
      </div>
    )
  }
}