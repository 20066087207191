import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import { pageLinks } from '../../Routes/PageLinks';
import ClientRoute from '../../API/Routes/ClientRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import PublicationRoute from '../../API/Routes/PublicationRoute';
import PublicationItem from '../../Views/Components/PublicationItem';
import Publication from '../../Models/Publication/Publication';
import BarButton from '../../Views/Components/BarButton';
import LoadMore from '../../Views/Components/LoadMore';
import EmptyState from '../../Views/EmptyState/EmptyState';
import EmptyStateEnum from '../../Views/EmptyState/EmptyStateEnum';

class PublicationsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      items: [],
      selectedItem: '',
      actualPage: 0,
    }
    this.props.loadingAction(true)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.editItemAction = this.editItemAction.bind(this)
  }

  loadRows = (values) => {
    this.setState({ deleteClients: [] })
    this.props.loadingAction(false)
    this.setState(
        {
            items: this.state.items.concat(values.filter((element)=>{return element.active})),
            itemsDisabled: values.filter((element)=>{return !element.active})
        }
    );
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  newClientAction() {
    this.props.history.push(pageLinks.newPublication)
    this.props.history.push({
        pathname: pageLinks.newPublication,
        state: {isEditing: false, publication: new Publication()}
      })
  }

  componentDidMount() {
    let route = new PublicationRoute()
    route.fetchByPage(0, this.loadRows, this.errorAction)
  }

  editItemAction = (item) => {
    this.props.history.push({
      pathname: pageLinks.newPublication,
      state: {isEditing: true, publication: item}
    })
  }

  loadMoreAction = () => {
    this.props.loadingAction(true)
    let route = new PublicationRoute()
    this.state.actualPage += 1
    this.setState({actualPage: this.state.actualPage});
    route.fetchByPage(this.state.actualPage, this.loadRows, this.errorAction)
  }

  getTable() {
    var rows = new Array()
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    items.forEach(element => {
      const data = <PublicationItem title={element.title} desc={element.desc} date={element.date} image={element.image64} link={element.link} editAction={()=>{this.editItemAction(element)}}/>
      rows.push(data)
    });
    return rows
  }

  render() {
    return (
      <div class="container-fluid">
        <PageTitle title="Publicações"/>
        <div class="row">
          <TopButton title="NOVA PUBLICAÇÃO" icon="plus" clickAction={this.newClientAction} />
        </div>

        <div class="">
            {this.getTable()}
        </div>
        <EmptyState hidden={this.state.items.length > 0} stateImage={EmptyStateEnum.ImageEnum.noResults} stateText={EmptyStateEnum.TextEnum.noResults}/>
        <LoadMore hidden={this.state.items.length < (PublicationRoute.perPageNumber * (this.state.actualPage + 1))} clickAction={this.loadMoreAction} />

      </div>
    )
  }
}

export default withRouter(PublicationsPage);