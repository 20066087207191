import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import DisciplinePedagogic from '../../../Models/Pedagogic/Discipline';
import APIManager from '../../../API/APIManager';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import ColoredButton from '../../../Views/Components/ColoredButton';
import PageTitle from '../../../Views/Components/PageTitle';
import FormComponent from '../../../Views/Components/FormComponent';
import SelectTextField from '../../../Views/Components/SelectTextField';
import SimpleTextField from '../../../Views/Components/SimpleTextField';
import MultilineTextField from '../../../Views/Components/MultilineTextField';

class NewDisciplinePage extends Component {
    constructor(props) {
        super(props)

        this.user = APIManager.loadCache(User.keyClass)
        this.company = this.user.company

        this.props.setAlert()

        let editItem = this.props.location.state.discipline
        if (editItem != null) {
            this.state = {
                discipline: new DisciplinePedagogic(editItem._id, editItem.name, editItem.sort, editItem.details)
            }
        } else {
            this.state = {
                discipline: DisciplinePedagogic.init({'name': '', 'details': '', 'sort': 1})
            }
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
    }

    changeState = (key, value) => {
        this.state.discipline.change(key, value)
        this.setState({ discipline: this.state.discipline })
    }

    loadedDiscipline = (discipline) => {
        this.props.loadingAction(false)
        this.setState({discipline: discipline});
    }

    errorAction = (values) => {
        this.setState({values});
    }

    saveAction = () => {
        if (this.disciplineValidate()) {
            this.props.loadingAction(true)
            const route = new PedagogicRoute()
            if (this.props.location.state.discipline == null) {
                route.fetchCreateDiscipline(this.state.discipline, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            } else {
                route.fetchUpdateDiscipline(this.state.discipline, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            }
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    successAction = (discipline) => {
        this.props.history.push({
          pathname: pageLinks.pedagogicDiscipline,
          state: {discipline: discipline, disciplineCreated: true}  
        })
      }

    disciplineValidate = () => {
        return !Helpers.isEmptyTexts([this.state.discipline.name]) && Number(this.state.discipline.sort) >= 0
    }

    render() {
        return (
            <div class="container-fluid">
                <PageTitle title="Informações da disciplina" history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="name" defaultValue={this.state.discipline.name} title="Nome" isRequired={true} delegate={this.changeState} width="25ch" />
                                    <MultilineTextField id="details" defaultValue={this.state.discipline.details} title="Ementa" isRequired={false} delegate={this.changeState} width="98%" />
                                    <SimpleTextField id="sort" defaultValue={this.state.discipline.sort} title="Ordem" isRequired={false} delegate={this.changeState} width="25ch" />
                                </div>,

                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                // <SimpleButton title="Limpar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewDisciplinePage);