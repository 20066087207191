import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import PedagogicRoute from '../../API/Routes/PedagogicRoute';
import { isEqual } from 'date-fns';

class DepositPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total: 0,
            items: [],
            periods: [],
            offices: [],
            errorMessage: null,
            selected: {
                selectedPeriod: null,
                selectedMonth: this.months[new Date().getMonth()]._id,
                selectedOffice: null
            }
        }

        this.props.loadingAction(true)
        this.props.setAlert()
        this.loadedInvoices = this.loadedInvoices.bind(this)
        this.errorAction = this.errorAction.bind(this)
    }

    months = [
        { name: 'Janeiro', _id: 1 },
        { name: 'Fevereiro', _id: 2 },
        { name: 'Março', _id: 3 },
        { name: 'Abril', _id: 4 },
        { name: 'Maio', _id: 5 },
        { name: 'Junho', _id: 6 },
        { name: 'Julho', _id: 7 },
        { name: 'Agosto', _id: 8 },
        { name: 'Setembro', _id: 9 },
        { name: 'Outubro', _id: 10 },
        { name: 'Novembro', _id: 11 },
        { name: 'Dezembro', _id: 12 },
    ]

    fetchStacks = () => {
        let route = new PedagogicRoute()
        route.fetchAllPeriods((periods) => { 
            this.setState({ periods }) 
            route.fetchAllOffices((offices) => {
                this.setState({ offices });
                this.fetchDeposits();
            }, this.errorAction)
    }, this.errorAction)
    }

    fetchDeposits = () => {
        new InvoiceRoute().fetchDeposits((this.state.selected.selectedOffice != null ? this.state.selected.selectedOffice : this.state.offices[0]._id), (this.state.selected.selectedMonth != null ? this.state.selected.selectedMonth : this.months[new Date().getMonth()]._id), ((this.state.selected.selectedPeriod != null && this.state.periods.length > 0) ? this.state.periods.filter((period)=>{return period._id == this.state.selected.selectedPeriod})[0].name : new Date().getFullYear()), this.loadedInvoices, this.errorAction)
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }

    getTable() {
        var rows = new Array()
        const items = this.state.items.length > 0 ? this.state.items : []
        const primaryColor = Helpers.primaryColor()
        const backColor = Helpers.primaryClearColor()
        items.forEach(element => {
            const venciment = new Date(element.DepositDate)
            const number = Number(element.PaymentNumber)
            const data = {
                id: ("0" + venciment.getUTCDate()).slice(-2) + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
                Amount: <b class="text-gray-800">{element.Amount > 0 ? Helpers.currency(element.Amount) : ""}</b>,
                PaymentNumber: number > 0 ? number : "",
                status: <h6 class="ml-0" align='center' style={{ color: element.IsTransferred ? "#279855" : "#747474", backgroundColor: element.IsTransferred ? "#CAF8DD" : "#E6E6E6", borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.Message}</b></h6>
            }
            rows.push(data)
        });
        return rows
    }

    loadedInvoices = (invoices) => {
        this.props.loadingAction(false)
        this.setState({ items: invoices.Deposits.filter((item) =>{
            return item.Amount > 0
        }), total: invoices.AmountDeposit });
    }

    changeSearchPedagogic = (key, value) => {
        this.state.selected[key] = String(value) == "" ? null : value
        this.setState({ selected: this.state.selected })
        this.fetchDeposits()
    }

    componentDidMount() {
        this.fetchStacks()
    }

    selectItemAction = (value) => {
        const deposit = this.state.items.filter((item)=>{
            const date = new Date(item.DepositDate.split('-')[0] + '-' + Number(item.DepositDate.split('-')[1]) + '-' + (Number(item.DepositDate.split('-')[2])));
            const valueDate = new Date(value.split('/')[2] + '-' + Number(value.split('/')[1]) + '-' + Number(value.split('/')[0]))
            return isEqual(date, valueDate)
        })[0]
        if (deposit != null, deposit.IsTransferred == true) {
            const [dia, mes, ano] = value.split('/');
            const date = new Date(`${ano}-${mes}-${dia}`);
            this.props.history.push({
            pathname: pageLinks.depositDayDetail,
            state: {date: date, office: this.state.selected.selectedOffice == null ? (this.state.offices.length > 0 ? this.state.offices[0] : 1) : this.state.offices.filter(dep => {return dep._id == this.state.selected.selectedOffice})[0]}
            })
        }
      }

    render() {
        if (this.state.selected.selectedPeriod == null && this.state.periods.length > 0) {
            this.state.selected.selectedPeriod = (this.state.periods[0]._id)
            this.setState({ selected: this.state.selected })
        }

        const headCells = [
            { id: 'id', label: 'Data', isButton: true },
            { id: 'Amount', label: 'Valor', isButton: false },
            { id: 'PaymentNumber', label: 'Número do Depósito', isButton: false },
            { id: 'status', label: 'Status', isButton: false }
        ];
        
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={() => { this.setState({ errorMessage: null }); }} closeTitle="OK" />
                <PageTitle title="Depósitos" />
                <div class="row">
                </div>
                {/* <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Cliente</h6>
          </div>
        </div> */}
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Filtrar Depósitos</h6>
                    </div>

                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedMonth" defaultValue={this.state.selected.selectedMonth ?? (1)} title="Mês" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.months.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                    {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedPeriod" defaultValue={this.state.selected.selectedPeriod ?? (this.state.periods.length > 0 ? this.state.periods[0]._id : 1)} title="Ano" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                    {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedOffice" defaultValue={this.state.selected.selectedOffice ?? (this.state.offices.length > 0 ? this.state.offices[0]._id : 1)} title="Unidade" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                </div>
                            ]
                        } />
                    </div>
                </div>
                <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Total Depositado</h6>
            {/* <BarButton title="Cancelar cobrança" clickAction={() => { this.setState({ isCancelling: true }) }} hidden={this.state.invoice.status.id == "cancelled" || this.state.invoice.status.id == "allpayed"} /> */}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm" hidden={false} >
                    <div class="float-end mt-1 mt-sm-6">
                  <h2 class={`text-primary`}><b>{Helpers.currency(this.state.total)}</b></h2>
                  <h7><b>{'VALOR DEPOSITADO EM ' + this.months[this.state.selected.selectedMonth - 1].name.toUpperCase() + ' DE ' + (this.state.selected.selectedPeriod != null && this.state.periods.length > 0 ? this.state.periods.filter((period)=>{return period._id == this.state.selected.selectedPeriod})[0].name : new Date().getFullYear()) + " NA UNIDADE " + (this.state.selected.selectedOffice != null ? this.state.offices.filter((office)=>{return office._id == this.state.selected.selectedOffice})[0].name : this.state.offices.length > 0 ? this.state.offices[0].name : "").toUpperCase()}</b></h7>
                    </div>
                    <div class="float-end mt-1 mt-sm-6"></div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
                    <EnhancedTable
                        titleTable='Lista de Depósitos'
                        rows={this.getTable()}
                        headCells={headCells}
                        defaultOrderId='DepositDate'
                        delegate={this.selectItemAction}
                        selectedList={null}
                        fastOptions={[]}
                        selectedFastAction={null}
                        individualOptions={[]}
                        selectedIndividualAction={null}
                    />
                </div>

            </div>
        )
    }
}

export default withRouter(DepositPage);