import React, { Component } from 'react'

export default class ColorInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
        color: this.props.color,
      }
    this.changeAction = this.changeAction.bind(this)
  }

  changeAction = (value) => {
    this.state.color = value
    this.setState({ color: this.state.color });
    this.props.changeAction(value)
  }

  render() {
    
    return (
      <div class="col-lg-12">
          <a className="cardContainer box-lg ml-2 mr-2" style={{borderColor: "#c4c4c4"}}>
          <span class="h7 ml-0 mr-0 font-weight-normal" style={{color: "#757575"}}>{this.props.title}: </span>
          <input className="ml-3" type="color" value={this.state.color} style={{backgroundColor: this.state.color}} onChange={e => this.changeAction(e.target.value)} />
          </a>
      </div>
    )
  }
}