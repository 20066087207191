import ModelJSON from "../ModelJSON";

export default class Publication extends ModelJSON {
  constructor(_id, active, title, desc, date, image64, link) {
    super()
    this._id = _id
    this.active = active
    this.title = title
    this.desc = desc;
    this.date = date
    this.image64 = image64
    this.link = link
  }

  static init(json) {
    return new Publication(
      json._id,
      json.active,
      json.title,
      json.desc,
      json.date,
      json.image64,
      json.link
    )
  }
}