import React, { Component } from 'react'
import UIAvatar from 'react-ui-avatars';
import Helpers from '../../../Helpers/Helpers';

export default class ChatItemTopMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const primaryColor = Helpers.primaryColor()
      return (
          <a className="dropdown-item d-flex align-items-center" href="/support">
              <div className="dropdown-list-image mr-3">
                <UIAvatar className="img-profile rounded-circle mr-1" width="40" height="40" name={this.props.chat.title} color={primaryColor} />
              </div>
              <div>
                  <div className="text-truncate">{this.props.chat.title} <div class="badge badge-danger float-right">{this.props.chat.messagesNotReadCount == 0 ? null : this.props.chat.messagesNotReadCount}</div></div>
                  <div className="small text-gray-500">{this.props.chat.lastMessageDate}</div>
              </div>
          </a>
      )
  }
}