import ModelJSON from "../ModelJSON";
import GradePedagogic from "./Grade";

export default class BoletimPedagogic extends ModelJSON {
  constructor(id, name, grades) {
    super()
    this._id = id;
    this.name = name;
    this.grades = grades;
  }

  static init(json) {
    let gradesArray = new Array()
    if (json.grades != null) {

      json.grades.forEach(element => {
        let dis = GradePedagogic.init(element)
        gradesArray.push(dis)
      });
    }
    return new BoletimPedagogic(
      json._id,
      json.name,
      gradesArray
    )
  }
}