export const pageLinks = {
  auth: "/",
  changePass: "/changePass",
  lostPass: "/lostPass",
  dashboard: "/dashboard",
  companyConfig: "/companyConfig",
  userConfig: "/userConfig",

  //Client
  clientDashboard: "/client",
  clientInvoiceList: "/client/invoice",
  clientInvoiceDetail: "/client/invoice/detail",

  //Deposit
  depositList: "/deposit",
  depositDayDetail: "/deposit/detail",

  //Publications
  publications: "/publications",
  newPublication: "/publications/new",
  
  //Clients
  clients: "/clients",
  newClient: "/clients/new",
  clientDetail: "/clients/detail",
  clientPlan: "/clients/plan",

  //Employees
  employees: "/employees",
  newEmployee: "/employees/new",
  employeeDetail: "/employees/detail",

  //Plans
  plans: "/plans",
  newPlan: "/plans/new",
  planDetail: "/plans/detail",

  //Invoices
  invoices: "/invoices",
  newInvoice: "/invoices/new",
  invoiceDetail: "/invoices/detail",

  //Invoices
  sysInvoices: "/sysinvoices",
  sysInvoiceDetail: "/sysinvoices/detail",
  sysInvoiceOverdue: "/sysLockOverdue",
  sysInvoiceOverdueDetail: "/sysLockOverdueDetail",

  responsibles: "/responsibles",
  teachers: "/teachers",
  charges: "/charges",
  reports: "/reports",
  settings: "/settings",
  support: "/support",

  //Pedagogic
  pedagogicPeriod: "/pedagogic/period",
  newPedagogicPeriod: "/pedagogic/period/new",
  pedagogicCourses: "/pedagogic/course",
  newPedagogicCourse: "/pedagogic/course/new",
  pedagogicClass: "/pedagogic/class",
  newPedagogicClass: "/pedagogic/class/new",
  detailPedagogicClass: "/pedagogic/class/detail",
  pedagogicOffice: "/pedagogic/office",
  newPedagogicOffice: "/pedagogic/office/new",
  newPedagogicEnrollment: "/client/pedagogic/enrollment/new",
  pedagogicDiscipline: "/pedagogic/discipline",
  newPedagogicDiscipline: "/pedagogic/discipline/new"
}