import Invoice from "../Invoice/Invoice";
import ModelJSON from "../ModelJSON";
import DashBillingInfo from "./DashBillingInfo";
import DashClientInfo from "./DashClientInfo";

export default class DashInfo extends ModelJSON {
  constructor(clientInfo = new DashClientInfo(), billingInfo = new DashBillingInfo(), lastPayedInvoices = new Array()) {
    super()
    this.clientInfo = clientInfo
    this.billingInfo = billingInfo
    this.lastPayedInvoices = lastPayedInvoices
  }

  static init(json) {
    var invoices = new Array()
    json.lastPayedBillings.forEach(element => {
      console.log(element);
      const invoice = Invoice.init(element);
      invoices.push(invoice)
    });
    return new DashInfo(
        DashClientInfo.init(json.usersInfo),
        DashBillingInfo.init(json.billingsInfo),
        invoices
    )
  }
}