import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import ColoredButton from '../../Views/Components/ColoredButton';
import BarButton from '../../Views/Components/BarButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import ClientRoute from '../../API/Routes/ClientRoute';
import NewClient from '../../Models/Client/Client';
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import TopButton from '../../Views/Components/TopButton';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import DatePicker from '../../Views/Components/DatePicker';
import Responsible from '../../Models/Client/Responsible';
import Address from '../../Models/Address';
import PedagogicRoute from '../../API/Routes/PedagogicRoute';
import APIManager from '../../API/APIManager';
import User from '../../Models/Auth/User';
import { Button, Modal } from 'react-bootstrap';

class ClientDetailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      client: new NewClient(),
      boletimItems: [],
      updateBoletimItems: [],
      boletimEnrollmentSelected: '',
      enrollmentsItems: [],
      enrollmentsFiltered: [],
      enrollmentsActiveItems: [],
      enrollmentsConcluedItems: [],
      enrollmentsDeleteItems: [],
      items: [],
      itemsFiltered: [],
      deleteItems: [],
      checkedList: [],
      checkedListEnrollments: [],
      payItems: [],
      isPrintMatriculas: false,
      errorMessage: null,
      isCancellingPlan: false,
      isEditing: false,
      isEditingResponsible: false,
      responsibleIsShowView: false,
      clientIsShowView: true,
      paymentDate: new Date(),
      statusList: [],
      search: {
        status: '',
        name: '',
        document: '',
        year: new Date().getFullYear(),
      },
      printEnrollment: {
        period: null,
        office: null,
        course: null,
        class: null,
        periods: [],
        courses: [],
        coursesFiltered: null,
        offices: [],
        classes: [],
        classesFiltered: null,
        levels: [],
        selectedCourseLevel: null
      },
      individualOptions: [
        { id: '0', label: 'Quitar cobrança', action: (itemId) => this.setState({ payItems: [itemId] }) },
        { id: '1', label: 'Cancelar cobrança', action: (itemId) => this.setState({ deleteItems: [itemId] }) }
      ],
      fastOptions: [
        { id: '0', label: 'Quitar cobranças selecionados', action: (itemId) => this.setState({ payItems: itemId }) },
        { id: '1', label: 'Cancelar cobranças selecionadas', action: (itemId) => this.setState({ deleteItems: itemId }) },
        { id: '2', label: 'Download boletos das cobranças selecionadas', action: (itemId) => this.downloadItemsAction(itemId) }
      ],
      individualEnrollmentOptions: [
        { id: '0', label: 'Cancelar matrícula', action: (itemId) => this.setState({ enrollmentsDeleteItems: [itemId] }) },
        { id: '1', label: 'Ativar matrícula', action: (itemId) => this.setState({ enrollmentsActiveItems: [itemId] }) },
        { id: '2', label: 'Concluir matrícula', action: (itemId) => this.setState({ enrollmentsConcluedItems: [itemId] }) }
      ],
      fastEnrollmentOptions: [
        { id: '0', label: 'Cancelar matrículas selecionadas', action: (itemId) => this.setState({ enrollmentsDeleteItems: itemId }) },
        { id: '1', label: 'Ativar matrículas selecionadas', action: (itemId) => this.setState({ enrollmentsActiveItems: itemId }) },
        { id: '2', label: 'Concluir matrículas selecionadas', action: (itemId) => this.setState({ enrollmentsConcluedItems: itemId }) },
      ]
    }

    this.props.loadingAction(true)
    this.props.setAlert()
    this.updateAddressWithZipCode = this.updateAddressWithZipCode.bind(this)
    this.changeStateZipCode = this.changeStateZipCode.bind(this)
    this.loadClient = this.loadClient.bind(this)
    this.loadInvoices = this.loadInvoices.bind(this)
    this.loadedInvoices = this.loadedInvoices.bind(this)
    this.newInvoiceAction = this.newInvoiceAction.bind(this)
    this.newEnrollmentAction = this.newEnrollmentAction.bind(this)
    this.showPlanAction = this.showPlanAction.bind(this)
    this.cancelPlanAction = this.cancelPlanAction.bind(this)
    this.editState = this.editState.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.changeState = this.changeState.bind(this)
    this.cancelEditState = this.cancelEditState.bind(this)
    this.copyClientDates = this.copyClientDates.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.disableClientAction = this.disableClientAction.bind(this)
    this.loadStatusList = this.loadStatusList.bind(this)
    this.exportAction = this.exportAction.bind(this)
    this.showPdfInNewTab = this.showPdfInNewTab.bind(this)
    this.getBoletimValue = this.getBoletimValue.bind(this)
    this.changeSelectEnrollmentBoletim = this.changeSelectEnrollmentBoletim.bind(this)
    this.downloadBoletimPDF = this.downloadBoletimPDF.bind(this)

    let route = new ClientRoute()
    if ((this.props.location.state.document + '') != 'undefined') {
      route.fetchClientDetail(this.props.location.state.document, this.loadClient, this.errorAction)
    } else {
      route.fetchClientDetailByName(this.props.location.state.name, this.loadClient, this.errorAction)
    }
  }

  fetchLoadEnrollment = () => {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchAllPeriods((periods) => {
      route.fetchAllOffices((offices) => {
        route.fetchAllCourses((courses) => {
          route.fetchAllClasses((classes) => {
            route.fetchCourseLevelList((levels) => {
              this.props.loadingAction(false);
              this.state.printEnrollment = {
                periods, offices, courses, classes, levels
              }
              this.setState({ printEnrollment: this.state.printEnrollment, isPrintMatriculas: true })
            }, this.errorAction)
          },
            this.errorAction)
        }, this.errorAction)
      }, this.errorAction)
    }, this.errorAction)
  }

  changeStateEnrollment = (key, value) => {
    if (key == "period") {
      this.state.printEnrollment.period = value
    }
    if (key == "class") {
      this.state.printEnrollment.class = value
    }
    if (key == "course") {
      this.state.printEnrollment.course = value
    }
    if (key == "office") {
      this.state.printEnrollment.office = value
    }
    this.setState({ printEnrollment: this.state.printEnrollment })
  }

  loadedPeriod = (period) => {
    this.state.printEnrollment.period = period
    this.setState({ printEnrollment: this.state.printEnrollment });
  }

  changeStateCourseLevel = (key, value) => {
    this.state.printEnrollment.selectedCourseLevel = value
    this.state.printEnrollment.coursesFiltered = this.state.printEnrollment.courses.filter(item => { return ((item.level.id == value)) })
    this.setState({ printEnrollment: this.state.printEnrollment })
  }

  changeStateCourse = (key, value) => {
    this.state.printEnrollment.course = value
    this.state.printEnrollment.classesFiltered = this.state.printEnrollment.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.printEnrollment.period)) })
    this.setState({ printEnrollment: this.state.printEnrollment })
  }

  printFichaMatriculaAction = () => {
    if (this.state.printEnrollment.period == null || this.state.printEnrollment.class == null || this.state.printEnrollment.course == null || this.state.printEnrollment.selectedCourseLevel == null) {
      this.setState({ errorMessage: "Você precisa preencher todos os campos." })
      return
    }
    var periodName = this.state.printEnrollment.periods.filter(period => period._id == this.state.printEnrollment.period)[0].name
    var className = this.state.printEnrollment.classes.filter(classObj => classObj._id == this.state.printEnrollment.class)[0].name
    var courseName = this.state.printEnrollment.courses.filter(course => course._id == this.state.printEnrollment.course)[0].name
    var courseLevel = this.state.printEnrollment.levels.filter(level => level.id == this.state.printEnrollment.selectedCourseLevel)[0].title
    this.props.loadingAction(true)
    new ClientRoute().fetchGetEnrollmentPrint([this.state.client._id], periodName, className, courseName, courseLevel, this.showPdfInNewTab, this.errorAction)
  }

  loadStatusList = (values) => {
    var rows = [{ id: 'all', label: 'Ativo' }]
    values.forEach(element => {
      const data = { id: element.id, label: element.name }
      rows.push(data)
    });
    this.state.search.status = rows[0].id
    this.setState({
      statusList: rows,
      search: this.state.search
    });
  }

  getYearList = () => {
    var rows = [{ id: 0, label: 'Todos' }]
    let actualYear = new Date().getFullYear()
    for (let index = 0; index < 100; index++) {
      rows.push({ id: actualYear - index, label: actualYear - index })
    }
    return rows
  }

  filterByYear = (invoice) => {
    let check = (new Date(invoice.venciment).getFullYear() == this.state.search.year)
    return check
  }

  loadClient = (client) => {
    this.props.setAlert(this.props.location.state.clientCreated ? 'success' : null, 'Tudo certo!', 'O cliente foi cadastrado com sucesso.')
    this.setState({
      client: client
    });
    new InvoiceRoute().fetchInvoiceAllStatus(this.loadStatusList, this.errorAction)
    this.loadEnrollments()
    this.loadInvoices()
  }

  savedClient = (client) => {
    this.props.loadingAction(false)
    this.props.setAlert('success', 'Tudo certo!', 'O cliente foi alterado com sucesso.')
    this.setState({
      client: client,
      isEditing: false,
      isEditingResponsible: false
    });
  }

  cancelledPlan = () => {
    this.props.loadingAction(false)
    this.props.setAlert('success', 'Tudo certo!', 'O plano foi cancelado com sucesso!')
    this.setState({ isCancellingPlan: false });
    window.location.reload()
  }

  changeState = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })
  }

  changeStateZipCode = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressWithZipCode, function (error) { })
    }
  }

  updateAddressWithZipCode = (address) => {
    this.state.client.address = address
    this.setState({ client: this.state.client })
  }

  changeStateResponsibleZipCode = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressResponsibleWithZipCode, function (error) { })
    }
  }

  updateAddressResponsibleWithZipCode = (address) => {
    this.state.client.responsible.address = address
    this.setState({ client: this.state.client })
  }

  newInvoiceAction() {
    this.props.history.push({
      pathname: pageLinks.newInvoice,
      state: { client: this.state.client }
    })
  }

  newEnrollmentAction() {
    this.props.history.push({
      pathname: pageLinks.newPedagogicEnrollment,
      state: { client: this.state.client }
    })
  }

  showPlanAction() {
    this.props.history.push({
      pathname: pageLinks.clientPlan,
      state: { client: this.state.client }
    })
  }

  cancelPlanAction() {
    this.props.loadingAction(true)
    const route = new ClientRoute()
    route.fetchCancelPlan(this.state.client.document, this.cancelledPlan, this.errorAction)
  }

  editState = () => {
    this.setState({ isEditing: true })
  }

  cancelEditState = () => {
    this.setState({ isEditing: false, isEditingResponsible: false })
  }

  errorAction = (values) => {
    this.setState({
      values
    });
  }

  saveAction = () => {
    if (this.clientValidate()) {
      this.props.loadingAction(true)
      if (this.state.client.statusId != null) {
        this.state.client.statusId = this.state.client.status.id
      }
      const route = new ClientRoute()
      route.fetchUpdate(this.state.client, this.savedClient, (error) => {
        this.props.setAlert('warning', 'Ops!', error)
      })
    } else {
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  disableClientAction = () => {
    this.props.loadingAction(true)
    const route = new ClientRoute()
    this.state.client.statusId = this.state.client.status.id == "active" ? "blocked" : "active"
    route.fetchUpdate(this.state.client, this.savedClient, (error) => {
      this.props.setAlert('warning', 'Ops!', error)
    })
  }

  clientValidate = () => {
    return !Helpers.isEmptyTexts(
      [this.state.client.document,
      this.state.client.name,
      this.state.client.address.zipCode,
      this.state.client.address.addressStreet,
      this.state.client.address.number,
      this.state.client.address.neighborhood,
      this.state.client.address.city,
      this.state.client.address.uf,
      this.state.client.responsible.document,
      this.state.client.responsible.name,
      this.state.client.responsible.address.zipCode,
      this.state.client.responsible.address.addressStreet,
      this.state.client.responsible.address.number,
      this.state.client.responsible.address.neighborhood,
      this.state.client.responsible.address.city,
      this.state.client.responsible.address.uf
      ]
    )
  }

  getTable() {
    var rows = new Array()
    console.log(this.state.search.year);
    const items = this.state.search.year > 0 ? this.state.itemsFiltered : this.state.items
    items.forEach(element => {
      const venciment = new Date(element.venciment)
      const data = {
        id: element.provider.idTransaction,
        venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
        plan: <div><h8 hidden={element.plan != null}>-</h8><h6 hidden={element.plan == null} class="ml-0" align='center' style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.plan}</b></h6></div>,
        amount: element.amount,
        discount: element.amountDiscount,
        penalty: element.amountPenalty,
        fees: element.amountFees,
        amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
        paymentType: element.paymentType.name,
        status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
      }
      rows.push(data)
    });
    return rows
  }

  getTableEnrollments() {
    var rows = new Array()
    const items = this.state.enrollmentsFiltered.length > 0 ? this.state.enrollmentsFiltered : this.state.enrollmentsItems
    items.forEach(element => {
      const data = {
        id: element._id,
        class: element.class.name,
        course: element.class.course.name,
        period: element.class.period.name,
        office: element.class.office.name,
        status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.title}</b></h6>
      }
      rows.push(data)
    });
    return rows
  }

  getTableBoletim() {
    let changeBoletimValues = this.changeBoletimValues
    let getBoletimValue = this.getBoletimValue
    return this.state.boletimItems.length == 0 ? null : this.state.boletimItems.map(function (obj) {
      let row = { "Disciplina": obj.name }
      let isShowRecuperation = (obj.grades.filter(function (obj2) { return (obj2.type == "result" && obj2.value == "") }).length == 0 && !(obj.grades.filter(function (obj2) { return (obj2.type == "result" && obj2.value == "Aprovado") }).length != 0 && obj.grades.filter(function (obj2) { return (obj2.type == "recuperation" && obj2.value == null) }).length != 0))
      obj.grades.forEach(function (obj2) {
        row[obj2.title] = obj2.type == "grade" || (obj2.type == "recuperation" && isShowRecuperation) ? <SimpleTextField id={{ "disciplineId": obj._id, "valueBoletimId": obj2.id }} defaultValue={getBoletimValue(obj._id + obj2.id)?.value ?? obj2.value ?? ""} title="" delegate={changeBoletimValues} width="10ch" /> : obj2.value
      })
      return row
    })
  }

  getBoletimValue(id) {
    return this.state.updateBoletimItems[id]
  }

  selectEnrollmentAction = (value) => {

  }

  fastEnrollmentAction = (value) => {
    if (this.state.checkedListEnrollments.length == 0) {
      this.setState({ errorMessage: "Não é possível alterar o status destas matrículas!" })
    } else {
      const item = this.state.fastEnrollmentOptions.find((element) => { return element.id == value })
      item.action(this.state.checkedListEnrollments)
    }
  }

  individualEnrollmentAction = (value) => {
    const client = this.state.individualEnrollmentOptions.find((element) => { return element.id == value.optionId; })
    let item = this.state.enrollmentsItems.find((element) => { return element._id == value.rowId })
    if (item == null) {
      this.setState({ errorMessage: "Não é possível alterar o status desta matrícula!" })
    } else {
      client.action(item)
    }
  }

  selectItemAction = (value) => {
    let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value })
    this.props.history.push({
      pathname: pageLinks.invoiceDetail,
      state: { invoice: invoice, client: this.state.client }
    })
  }

  copyClientDates = () => {
    var client = this.state.client
    client.responsible = new Responsible(client._id, client.name, client.documentGov, client.phone, client.email, new Address(client.address.zipCode, client.address.addressStreet, client.address.number, client.address.complement, client.address.city, client.address.uf, client.address.neighborhood))
    this.setState({ client })
  }

  updateListChecked = (values) => {
    const invoices = []
    values.forEach(value => {
      let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value && element.status.id != "allpayed" && element.status.id != "cancelled" })
      if (invoice != null) { invoices.push(invoice) }
    })
    this.setState({ checkedList: invoices })
  }

  fastAction = (value) => {
    if (this.state.checkedList.length == 0) {
      this.setState({ errorMessage: "Não é possível alterar o status destas cobranças!" })
    } else {
      const invoice = this.state.fastOptions.find((element) => { return element.id == value })
      invoice.action(this.state.checkedList)
    }
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId; })
    let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value.rowId && element.status.id != "allpayed" && element.status.id != "cancelled" })
    if (invoice == null) {
      this.setState({ errorMessage: "Não é possível alterar o status desta cobrança!" })
    } else {
      client.action(invoice)
    }
  }

  loadEnrollments = () => {
    this.props.loadingAction(true)
    new PedagogicRoute().fetchAllEnrollmentsByUser(this.state.client._id, this.loadedEnrollments, this.errorAction)
  }

  loadedEnrollments = (items) => {
    this.props.loadingAction(false)
    this.state.boletimEnrollmentSelected = items.length > 0 ? items[0]?._id : ''
    this.setState({
      enrollmentsItems: items,
      enrollmentsFiltered: items.filter(item => { return ((!item.status.id.includes("cancelled"))) }),
      boletimEnrollmentSelected: this.state.boletimEnrollmentSelected
    });
    new PedagogicRoute().fetchBoletimByEnrollment(this.state.boletimEnrollmentSelected, this.getBoletimId(), this.loadedBoletim, this.errorAction)
  }

  getBoletimId = () => {
    return this.state.enrollmentsItems.filter((i) => i._id == this.state.boletimEnrollmentSelected)[0]["class"]["course"]["boletim"]
  }

  downloadBoletimPDF = () => {
    this.props.loadingAction(true)
    new PedagogicRoute().fetchGetPDFBoletim(this.state.boletimEnrollmentSelected, this.getBoletimId(), this.showPdfInNewTab, this.errorAction)
  }

  loadedBoletim = (items) => {
    this.props.loadingAction(false)
    this.state.updateBoletimItems = []
    this.state.boletimItems = items
    this.setState({
      updateBoletimItems: this.state.updateBoletimItems,
      boletimItems: this.state.boletimItems
    });
  }

  updateEnrollmentListChecked = (values) => {
    const array = []
    values.forEach(value => {
      let item = this.state.enrollmentsItems.find((element) => { return element._id == value })
      if (array != null) { array.push(item) }
    })
    this.setState({ checkedListEnrollments: array })
  }

  deleteEnrollmentItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.enrollmentsDeleteItems.map(function (obj) { return obj._id; })
    new PedagogicRoute().fetchUpdateStatusEnrollments({ "ids": ids, "isDisable": true, "isConclued": false }, () => { window.location.reload() }, this.errorAction)
  }

  concluedEnrollmentItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.enrollmentsConcluedItems.map(function (obj) { return obj._id; })
    new PedagogicRoute().fetchUpdateStatusEnrollments({ "ids": ids, "isDisable": false, "isConclued": true }, () => { window.location.reload() }, this.errorAction)
  }

  activeEnrollmentItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.enrollmentsActiveItems.map(function (obj) { return obj._id; })
    new PedagogicRoute().fetchUpdateStatusEnrollments({ "ids": ids, "isDisable": false, "isConclued": false }, () => { window.location.reload() }, this.errorAction)
  }

  loadInvoices = () => {
    this.props.loadingAction(true)
    new InvoiceRoute().fetchAllInvoicesByUser(this.state.client._id, this.loadedInvoices, this.errorAction)
  }

  loadedInvoices = (invoices) => {
    this.props.loadingAction(false)
    this.setState({
      items: invoices,
      itemsFiltered: invoices.filter(invoice => { return ((!invoice.status.id.includes("cancelled") && this.filterByYear(invoice))) })
    });
  }

  deleteItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.deleteItems.map(function (obj) { return obj._id; })
    console.log("ids: " + ids);
    new InvoiceRoute().fetchCancelItems({ "ids": ids }, () => { window.location.reload(); }, this.errorAction)
  }

  showPdfInNewTab(base64Data) {
    this.props.loadingAction(false)
    var byteCharacters = atob(base64Data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  downloadItemsAction = (items) => {
    this.props.loadingAction(true)
    const ids = items.map(function (obj) { return obj._id; })
    new InvoiceRoute().fetchDownloadItems({ "ids": ids }, this.showPdfInNewTab, this.errorAction)
  }

  payItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.payItems.map(function (obj) { return obj._id; })
    const amounts = this.state.payItems.map(function (obj) { return Helpers.stringToAmountNumber(obj.amountTotal); })
    new InvoiceRoute().fetchManualPayItems({ "date": this.state.paymentDate, "ids": ids, "amounts": amounts }, () => { window.location.reload() }, this.errorAction)
  }

  saveBoletimAction = () => {
    this.props.loadingAction(true)
    let enrollmentSelected = this.state.boletimEnrollmentSelected
    let boletimId = this.getBoletimId()
    const boletins = Object.values(this.state.updateBoletimItems).map(function (obj) {
      return {
        "value": obj.value,
        "valueBoletimId": obj.valueBoletimId,
        "discipline": obj.disciplineId,
        "boletim": boletimId,
        "enrollment": enrollmentSelected
      }
    })
    new PedagogicRoute().fetchSaveBoletim(boletins, this.loadedBoletim, this.errorAction)
  }

  changeSearch = (key, value) => {
    this.state.search[key] = value
    this.setState({ search: this.state.search })
    this.setState({
      itemsFiltered: this.state.items.filter(client => { return ((this.state.search.status == 'all' ? (!client.status.id.includes("cancelled")) : (client.status.id.includes(this.state.search.status)))) && this.filterByYear(client) })
    })
  }

  changeSelectEnrollmentBoletim = (key, value) => {
    this.props.loadingAction(true)
    this.state.boletimEnrollmentSelected = value
    this.setState({ boletimEnrollmentSelected: this.state.boletimEnrollmentSelected })
    new PedagogicRoute().fetchBoletimByEnrollment(value, this.getBoletimId(), this.loadedBoletim, this.errorAction)
  }

  changeBoletimValues = (key, value) => {
    console.log(key);
    key["value"] = value.replace(/[^\d.-]/g, '');
    this.state.updateBoletimItems[key.disciplineId + key.valueBoletimId] = key
    this.setState({ updateBoletimItems: this.state.updateBoletimItems })
  }

  exportAction() {
    this.props.loadingAction(true)
    let route = new InvoiceRoute()
    route.fetchExportUser(this.state.client._id, (csvContent) => {
      this.props.loadingAction(false)
      Helpers.downloadCSV(this.state.client.name, csvContent)
    }, this.errorAction)
  }

  render() {
    if (this.state.printEnrollment.period == null && this.state.printEnrollment.periods.length > 0) {
      this.state.printEnrollment.period = (this.state.printEnrollment.periods[0]._id)
      this.setState({ printEnrollment: this.state.printEnrollment })
    }
    const client = APIManager.loadCache(User.keyClass)

    const headCells = [
      { id: 'id', label: 'Número', isButton: true },
      { id: 'venciment', label: 'Vencimento', isButton: false },
      { id: 'plan', label: 'Plano', isButton: false },
      { id: 'amount', label: 'Valor', isButton: false },
      { id: 'discount', label: 'Desconto', isButton: false },
      { id: 'penalty', label: 'Multa', isButton: false },
      { id: 'fees', label: 'Juros', isButton: false },
      { id: 'amountTotal', label: 'Total', isButton: false },
      { id: 'paymentType', label: 'Tipo', isButton: false },
      { id: 'status', label: 'Situação', isButton: false }
    ];

    const headEnrollmentCells = [
      { id: 'class', label: 'Turma', isButton: false },
      { id: 'course', label: 'Curso', isButton: false },
      { id: 'period', label: 'Periodo', isButton: false },
      { id: 'office', label: 'Unidade', isButton: false },
      { id: 'status', label: 'Situação', isButton: false },
      { id: 'id', label: '', isButton: false, isHidden: true }
    ];

    const headBoletimCells = this.state.boletimItems.length == 0 ? [] : this.state.boletimItems[0].grades.map(function (obj) { return { id: obj.title, label: obj.title, isButton: false, isHidden: false } })
    headBoletimCells.unshift({ id: "Disciplina", label: "Disciplina", isButton: false, isHidden: false })
    const rowsBoletimCells = this.getTableBoletim()

    const rows = this.getTable()
    const rowsEnrollments = this.getTableEnrollments()
    let responsible = this.state.client.responsible
    return (
      <div class="container-fluid">

        <Modal show={this.state.isPrintMatriculas == true} onHide={() => { if (this.state.isPrintMatriculas == true) { window.location.reload(); }; this.setState({ isPrintMatriculas: false }); }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Ficha de matrícula"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p class="text-muted">{"Selecione os dados de turma para imprimir a ficha de matrícula."}</p>
            {<SelectTextField id="period" defaultValue={this.state.printEnrollment.period ?? (this.state.printEnrollment.periods.length > 0 ? this.state.printEnrollment.periods[0]._id : 1)} title="Período Letivo *" isRequired={true} delegate={this.changeStateEnrollment} width="25ch" options={this.state.printEnrollment.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
            {<SelectTextField id="office" defaultValue={this.state.printEnrollment.office ?? 1} title="Unidade *" isRequired={true} delegate={this.changeStateEnrollment} width="25ch" options={this.state.printEnrollment.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
            {<SelectTextField id="selectedCourseLevel" defaultValue={this.state.printEnrollment.selectedCourseLevel ?? -1} title="Nível *" isRequired={true} delegate={this.changeStateCourseLevel} width="25ch" options={this.state.printEnrollment.levels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
            {this.state.printEnrollment.coursesFiltered == null ? null : <SelectTextField id="course" defaultValue={this.state.printEnrollment.course ?? 1} title="Curso *" isRequired={true} delegate={this.changeStateCourse} width="25ch" options={this.state.printEnrollment.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
            {this.state.printEnrollment.classesFiltered == null ? null : <SelectTextField id="class" defaultValue={this.state.printEnrollment.class ?? 1} title="Turma *" isRequired={true} delegate={this.changeStateEnrollment} width="25ch" options={this.state.printEnrollment.classesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.printFichaMatriculaAction}>{"Gerar Ficha de Matrícula"}</Button>
            <Button variant="primary" onClick={() => { if (this.state.isPrintMatriculas == true) { window.location.reload(); }; this.setState({ isPrintMatriculas: false }); }}>{"Fechar"}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.payItems.length > 0} onHide={() => { if (this.state.payItems.length > 1) { window.location.reload(); }; this.setState({ payItems: [] }); }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Pagamento manual"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p class="text-muted">{this.state.payItems.length > 1 ? "Para quitar essas cobranças manualmente informe a data do pagamento." : "Para quitar essa cobrança manualmente informe a data do pagamento."}</p>
            <DatePicker id="datePay" defaultValue={this.state.paymentDate} title="Data do pagamento" isRequired={true} delegate={(key, value) => this.setState({ paymentDate: value })} width="25ch" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.payItemsAction}>{"Quitar Cobrança"}</Button>
            <Button variant="primary" onClick={() => { if (this.state.payItems.length > 1) { window.location.reload(); }; this.setState({ payItems: [] }); }}>{"Fechar"}</Button>
          </Modal.Footer>
        </Modal>

        <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja cancelar esta cobrança?" : "Tem certeza que deseja cancelar estas cobranças?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) { window.location.reload(); }; this.setState({ deleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <ModalAlertSimple title="Atenção, tem certeza que deseja cancelar este plano?" desc={"Isso acarretará no cancelamento de todas as cobranças deste plano que ainda não foram pagas!"} isShow={this.state.isCancellingPlan} defaultAction={this.cancelPlanAction} closeAction={() => { if (this.state.isCancellingPlan) { window.location.reload(); }; this.setState({ isCancellingPlan: false }); }} buttonTitle="Sim" closeTitle="Não" />
        <ModalAlertSimple title="Atenção" desc={this.state.enrollmentsDeleteItems.length == 1 ? "Tem certeza que deseja cancelar esta matrícula?" : "Tem certeza que deseja cancelar estas matrículas?"} isShow={this.state.enrollmentsDeleteItems.length > 0} defaultAction={this.deleteEnrollmentItemsAction} closeAction={() => { if (this.state.enrollmentsDeleteItems.length > 1) { window.location.reload(); }; this.setState({ enrollmentsDeleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <ModalAlertSimple title="Atenção" desc={this.state.enrollmentsConcluedItems.length == 1 ? "Tem certeza que deseja concluir esta matrícula?" : "Tem certeza que deseja concluir estas matrículas?"} isShow={this.state.enrollmentsConcluedItems.length > 0} defaultAction={this.concluedEnrollmentItemsAction} closeAction={() => { if (this.state.enrollmentsConcluedItems.length > 1) { window.location.reload(); }; this.setState({ enrollmentsConcluedItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <ModalAlertSimple title="Atenção" desc={this.state.enrollmentsActiveItems.length == 1 ? "Tem certeza que deseja ativar esta matrícula?" : "Tem certeza que deseja ativar estas matrículas?"} isShow={this.state.enrollmentsActiveItems.length > 0} defaultAction={this.activeEnrollmentItemsAction} closeAction={() => { if (this.state.enrollmentsActiveItems.length > 1) { window.location.reload(); }; this.setState({ enrollmentsActiveItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <ModalAlertSimple title="Atenção" desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={() => { this.setState({ errorMessage: null }); }} closeTitle="OK" />
        <PageTitle title="Aluno" history={this.props.history} />
        <div class="row">
          <TopButton hidden={client.level < 4} title="NOVA COBRANÇA" icon="plus" clickAction={this.newInvoiceAction} />
          <TopButton hidden={this.state.client.plan != null || client.level < 4} title="ASSOCIAR PLANO" icon="file-invoice-dollar" clickAction={this.showPlanAction} />
          <TopButton hidden={this.state.client.plan == null || client.level < 4} title="CANCELAR PLANO" icon="file-invoice-dollar" clickAction={() => this.setState({ isCancellingPlan: true })} />
          <TopButton hidden={client.level >= 3 && client.level < 4.3} title="NOVA MATRICULA" icon="plus" clickAction={this.newEnrollmentAction} />
          <TopButton hidden={client.level >= 3 && client.level < 4.3} title="FICHA DE MATRICULA" icon="file-pdf" clickAction={() => { this.fetchLoadEnrollment() }} />
          <TopButton title="EXPORTAR CLIENTE" icon="file-download" clickAction={this.exportAction} />
        </div>
        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Aluno</h6>
            <BarButton title={this.state.client.status.id == "active" ? "Desativar cliente" : "Ativar cliente"} clickAction={this.disableClientAction} />
          </div>
          <div class="card-body" hidden={this.state.isEditing}>
            <div class="text-left row">
              <h5 class="col-sm-12 mb-3" hidden={this.state.client.name == null}><strong>{this.state.client.name}</strong><h8 class="ml-3" style={{ color: this.state.client.status.color, backgroundColor: this.state.client.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}><i class={`fas fa-` + this.state.client.status.iconName + ` fa-1x`}></i> <strong>{this.state.client.status.title}</strong></h8></h5>
            </div>
            <div class="text-left row">
              <div class="col-sm-2" hidden={this.state.client.document == null}><h8>Nº do Aluno</h8><br></br><strong><span class="ml-2">{this.state.client.document}</span></strong></div>
              <div class="col-sm-2" hidden={this.state.client.documentGov == null || this.state.client.documentGov == ""}><h8>{"CPF/CNPJ"}</h8><br></br><strong><span class="ml-2">{Helpers.documentFormat(this.state.client.documentGov)}</span></strong></div>
              <div class="col-sm-2" hidden={this.state.client.birthDate == null}><h8>Data de Nascimento</h8><br></br><strong><span class="ml-2">{Helpers.formatDate(this.state.client.birthDate)}</span></strong></div>
              <div class="col-sm-2" hidden={this.state.client.genre == null}><h8>Gênero</h8><br></br><strong><span class="ml-2">{Helpers.getGenreName(this.state.client.genre)}</span></strong></div>
              <div class="col-sm-2" hidden={this.state.client.phone == null}><h8>Telefone</h8><br></br><strong><span class="ml-2">{this.state.client.phone}</span></strong></div>
              <div hidden={this.state.clientIsShowView}>
                <div class="col-sm-12" hidden={this.state.client.email == null}><h8>Email</h8><br></br><strong><span class="ml-2">{this.state.client.email}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.fatherName == null}><h8>Nome do Pai</h8><br></br><strong><span class="ml-2">{this.state.client.fatherName}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.fatherPhone == null}><h8>Telefone do Pai</h8><br></br><strong><span class="ml-2">{this.state.client.fatherPhone}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.motherName == null}><h8>Nome da Mãe</h8><br></br><strong><span class="ml-2">{this.state.client.motherName}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.motherPhone == null}><h8>Telefone da Mãe</h8><br></br><strong><span class="ml-2">{this.state.client.motherPhone}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.address.zipCode == null}><h8>Endereço</h8><br></br><strong><span class="ml-2">{this.state.client.address.getCompleteAddress()}</span></strong></div>
                <div class="col-sm-5" hidden={this.state.client.obs == null}><h8>Observações</h8><br></br><strong><span class="ml-2">{this.state.client.obs}</span></strong></div>
                {this.state.client.plan == null ? <div /> : <div class="col-sm-5" hidden={this.state.client.plan == null}><h8>Plano Atual :</h8><br></br><strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{this.state.client.plan.name}</span></strong></div>}
              </div>
            </div>
            <ColoredButton title="Editar" clickAction={this.editState} />
            <a className="pointer btn-sm btn-secondary" onClick={() => { this.setState({ clientIsShowView: !this.state.clientIsShowView }) }} >
              <span class="h8 font-weight-bold text-primary">{(this.state.clientIsShowView ? "Ver mais detalhes ↓" : "Ver menos ↑")}</span>
            </a>
          </div>
          <div class="card-body" hidden={!this.state.isEditing}>
            <FormComponent components={
              [
                <div>
                  <SimpleTextField isDisabled={true} id="document" defaultValue={this.state.client.document} title="Nº do Aluno" isRequired={true} delegate={this.changeState} width="20ch" />
                  <SimpleTextField id="documentGov" defaultValue={this.state.client.documentGov} title={"CPF/CNPJ"} isRequired={true} delegate={this.changeState} width="20ch" mask={new String(this.state.client.documentGov).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                  <SimpleTextField id="name" defaultValue={this.state.client.name} title="Nome" isRequired={true} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                  {/* <DatePicker id="birthDate" defaultValue={this.state.client.birthDate} title="Data de Nascimento" isRequired={false} delegate={this.changeState} width="20ch" /> */}
                  <SimpleTextField type="phone" id="phone" defaultValue={this.state.client.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                  <SimpleTextField type="email" id="email" defaultValue={this.state.client.email} title="Email" isRequired={false} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                  <DatePicker id="birthDate" defaultValue={this.state.client.birthDate} title="Data de Nascimento" isRequired={true} delegate={this.changeState} width="18ch" />
                  <SelectTextField id="genre" defaultValue={this.state.client.genre + ""} title="Gênero" isRequired={false} delegate={this.changeState} width="20ch" options={Helpers.genreList()} />
                  <SimpleTextField id="fatherName" defaultValue={this.state.client.fatherName} title="Nome do Pai" isRequired={true} delegate={this.changeState} width="78%" />
                  <SimpleTextField type="phone" id="fatherPhone" defaultValue={this.state.client.fatherPhone} title="Telefone do Pai" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.fatherPhone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                  <SimpleTextField id="motherName" defaultValue={this.state.client.motherName} title="Nome da Mãe" isRequired={true} delegate={this.changeState} width="78%" />
                  <SimpleTextField type="phone" id="motherPhone" defaultValue={this.state.client.motherPhone} title="Telefone da Mãe" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.motherPhone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />

                  <div>
                    <SimpleTextField id="address/zipCode" defaultValue={this.state.client.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateZipCode} width="18ch" mask="99999-999" />
                    <SimpleTextField id="address/addressStreet" isLoad defaultValue={this.state.client.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeState} width="61%" />
                    <SimpleTextField id="address/number" isLoad defaultValue={this.state.client.address.number} title="Número" isRequired={true} delegate={this.changeState} width="15%" />
                    <SimpleTextField id="address/complement" isLoad defaultValue={this.state.client.address.complement} title="Complemento" isRequired={false} delegate={this.changeState} width="15%" />
                    <SimpleTextField id="address/neighborhood" isLoad defaultValue={this.state.client.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeState} width="15%" />
                    <SimpleTextField id="address/city" isLoad defaultValue={this.state.client.address.city} title="Cidade" isRequired={true} delegate={this.changeState} width="20ch" />
                    <SimpleTextField id="address/uf" isLoad defaultValue={this.state.client.address.uf} title="Estado" isRequired={true} delegate={this.changeState} width="20ch" />
                  </div>
                  <div>
                    <SimpleTextField id="obs" defaultValue={this.state.client.obs} title="Observações" isRequired={false} delegate={this.changeState} width="98%" />
                  </div>
                </div>,

                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                <SimpleButton title="Cancelar" clickAction={this.cancelEditState} />
              ]
            } />
          </div>
        </div>

        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Responsável Financeiro</h6>
            <BarButton title={<i class={`fas fa-` + (this.state.responsibleIsShowView ? `chevron-up` : `chevron-down`) + ` fa-1x`}></i>} clickAction={() => { this.setState({ responsibleIsShowView: !this.state.responsibleIsShowView }) }} />
          </div>
          {responsible != null ?
            <div class="card-body" hidden={this.state.isEditingResponsible || !this.state.responsibleIsShowView}>
              <div class="text-left row">
                <h5 class="col-sm-12 mb-3" hidden={this.state.client.responsible.name == null}><strong>{this.state.client.responsible.name}</strong></h5>
              </div>
              <div class="text-left row">
                <div class="col-sm-2" hidden={this.state.client.responsible.document == null}><h8>CPF/CNPJ</h8><br></br><strong><span class="ml-2">{Helpers.documentFormat(this.state.client.responsible.document)}</span></strong></div>
                <div class="col-sm-3" hidden={this.state.client.responsible.phone == null}><h8>Telefone</h8><br></br><strong><span class="ml-2">{this.state.client.responsible.phone}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.responsible.email == null}><h8>Email</h8><br></br><strong><span class="ml-2">{this.state.client.responsible.email}</span></strong></div>
                <div class="col-sm-12" hidden={this.state.client.responsible.address.zipCode == null}><h8>Endereço</h8><br></br><strong><span class="ml-2">{this.state.client.responsible.address.getCompleteAddress()}</span></strong></div>
              </div>
              <ColoredButton title="Editar" clickAction={() => { this.setState({ isEditingResponsible: true }) }} />
            </div>
            : <div></div>
          }
          {responsible != null ?
            <div class="card-body" hidden={!this.state.isEditingResponsible || !this.state.responsibleIsShowView}>
              <FormComponent components={
                [
                  <div>
                    <SimpleTextField id="responsible/document" defaultValue={this.state.client.responsible.document} title={"CPF/CNPJ"} isRequired={true} delegate={this.changeState} width="20ch" mask={new String(this.state.client.responsible.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                    <SimpleTextField id="responsible/name" defaultValue={this.state.client.responsible.name} title="Nome" isRequired={true} delegate={this.changeState} width="78%" />
                  </div>,
                  <div>
                    <SimpleTextField type="phone" id="responsible/phone" defaultValue={this.state.client.responsible.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.responsible.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                    <SimpleTextField type="email" id="responsible/email" defaultValue={this.state.client.responsible.email} title="Email" isRequired={false} delegate={this.changeState} width="78%" />
                  </div>,
                  <div>
                    <div>
                      <SimpleTextField id="responsible/address/zipCode" defaultValue={this.state.client.responsible.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateResponsibleZipCode} width="18ch" mask="99999-999" />
                      <SimpleTextField id="responsible/address/addressStreet" isLoad defaultValue={this.state.client.responsible.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeState} width="61%" />
                      <SimpleTextField id="responsible/address/number" isLoad defaultValue={this.state.client.responsible.address.number} title="Número" isRequired={true} delegate={this.changeState} width="15%" />
                      <SimpleTextField id="responsible/address/complement" isLoad defaultValue={this.state.client.responsible.address.complement} title="Complemento" isRequired={false} delegate={this.changeState} width="15%" />
                      <SimpleTextField id="responsible/address/neighborhood" isLoad defaultValue={this.state.client.responsible.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeState} width="15%" />
                      <SimpleTextField id="responsible/address/city" isLoad defaultValue={this.state.client.responsible.address.city} title="Cidade" isRequired={true} delegate={this.changeState} width="20ch" />
                      <SimpleTextField id="responsible/address/uf" isLoad defaultValue={this.state.client.responsible.address.uf} title="Estado" isRequired={true} delegate={this.changeState} width="20ch" />
                    </div>
                  </div>,

                  <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                  <SimpleButton title="O aluno é o responsável financeiro" clickAction={this.copyClientDates} />,
                  <SimpleButton title="Cancelar" clickAction={this.cancelEditState} />
                ]
              } />
            </div>
            : <div></div>
          }
        </div>

        <div class="card shadow mb-4" hidden={this.state.enrollmentsItems.length == 0 || (client.level >= 3 && client.level < 4.3)}>
          <EnhancedTable
            titleTable='Matrículas do Aluno'
            showBarResize={true}
            defaultResizableShow={false}
            rows={rowsEnrollments}
            headCells={headEnrollmentCells}
            defaultRowsPerPage={12}
            delegate={this.selectEnrollmentAction}
            selectedList={this.updateEnrollmentListChecked}
            fastOptions={this.state.fastEnrollmentOptions}
            selectedFastAction={this.fastEnrollmentAction}
            individualOptions={this.state.individualEnrollmentOptions}
            selectedIndividualAction={this.individualEnrollmentAction}
          />
        </div>

        {headBoletimCells == null || rowsBoletimCells == null ? <div /> : <div class="card shadow mb-4" hidden={this.state.boletimItems.length == 0 || (client.level >= 3 && client.level < 4.3)}>
          <EnhancedTable
            titleTable='Boletim do Aluno'
            showBarResize={true}
            defaultResizableShow={false}
            components={<FormComponent components={
              [
                <div>
                  {this.state.enrollmentsItems.length == 0 ? <div></div> : <SelectTextField id="enrollments" defaultValue={this.state.boletimEnrollmentSelected} title="Matrículas" isRequired={false} delegate={this.changeSelectEnrollmentBoletim} width="25ch" options={this.state.enrollmentsItems.map((enrollment) => { return { id: enrollment._id, label: enrollment.class.name + " - " + enrollment.class.course.name } })} />}
                  <BarButton title={"Imprimir Boletim"} clickAction={this.downloadBoletimPDF} />
                </div>
              ]
            } />}
            rows={rowsBoletimCells}
            headCells={headBoletimCells}
            defaultOrderId='Disciplina'
            defaultRowsPerPage={100}
            delegate={() => { }}
            fastOptions={[]}
            individualOptions={[]}
            isHidePagination={true}
            buttonTitle='Salvar Notas'
            buttonAction={this.saveBoletimAction}
          />
        </div>
        }

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Filtrar Cobranças</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
                <div>
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="status" defaultValue={this.state.search.status} title="Situação" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.state.statusList} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="year" defaultValue={this.state.search.year} title="Ano" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.getYearList()} />}
                </div>
              ]
            } />
          </div>
        </div>

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Lista de Cobranças'
            rows={rows}
            headCells={headCells}
            defaultOrderId='name'
            defaultRowsPerPage={12}
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>

      </div>
    )
  }
}

export default withRouter(ClientDetailPage);