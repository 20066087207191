import 'date-fns';
import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import { withStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import brLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import APIManager from '../../API/APIManager';
import Helpers from '../../Helpers/Helpers';

const primaryColor = Helpers.primaryColor()
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: primaryColor }
  },
});

const styles = {
  root: {
    '& label.Mui-focused': {
      color: primaryColor,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: primaryColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: primaryColor,
      },
    },
  },
  input: {
    color: "black",
    '&.MuiPickersDay-daySelected': {
      backgroundColor: "black",
    }
  }
};
//new Date('2014-08-18T21:11:54')

function MaterialUIPickers(props) {
  const { classes } = props;
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.delegate(props.id, date)
  };
  
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
    <KeyboardDatePicker
    disableToolbar
    required={props.isRequired}
    className={classes.root}
    variant="inline"
    format="dd/MM/yyyy"
    inputVariant="outlined" 
    margin="normal"
    id={props.isRequired ? "outlined-required" : "outlined"}
    label={props.title}
    value={props.defaultValue == '' ? new Date() : props.defaultValue}
    style ={{width: props.width, minWidth: "15ch"}}
    onChange={handleDateChange}
    InputProps={{
      className: classes.input
    }}
    />
    </MuiPickersUtilsProvider>
    </ThemeProvider>
    );
  }
  
  export default withStyles(styles)(MaterialUIPickers);