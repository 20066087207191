import React, { Component } from 'react'
import EmptyStateEnum from './EmptyStateEnum';

export default class EmptyState extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let emptyImage = this.props.stateImage
        let emptyText = this.props.stateText
        return (
            <div class="row" hidden={this.props.hidden}>
                <div class="empty-container" style={{color: '#CFCFCF', padding: '10% 0rem 0rem 0rem'}}>
                    <img class={"empty-image"} src={emptyImage} />
                    <h7 class="mt-0" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1rem 0rem 1rem'}}>{emptyText}</h7>
                </div>
            </div>
        )
    }
}