import ModelJSON from "../ModelJSON";

export default class DepositList extends ModelJSON {
  constructor(AmountDeposit, AmountTax, Deposits) {
    super()
    this.AmountDeposit = AmountDeposit;
    this.AmountTax = AmountTax
    this.Deposits = Deposits;
  }

  static init(json) {
    var depositsList = []
    json.Deposits.forEach(element => {
      const invoice = DepositItemList.init(element);
      depositsList.push(invoice)
    });
    return new DepositList(
      json.AmountDeposit,
      json.AmountTax,
      depositsList,
    )
  }
}

class DepositItemList extends ModelJSON {
  constructor(DepositDate, Amount, IsTransferred, Message, PaymentNumber, HashConfirmation) {
    super()
    this.DepositDate = DepositDate;
    this.Amount = Amount;
    this.IsTransferred = IsTransferred;
    this.Message = Message;
    this.PaymentNumber = PaymentNumber;
    this.HashConfirmation = HashConfirmation;
  }

  static init(json) {
    return new DepositItemList(
      json.DepositDate,
      json.Amount,
      json.IsTransferred,
      json.Message,
      json.PaymentNumber,
      json.HashConfirmation
    )
  }
}