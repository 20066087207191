import React from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Helpers from '../../Helpers/Helpers';

const primaryColor = Helpers.primaryColor()
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: primaryColor }
  },
});
const styles = {
  root: {
    '& label.Mui-focused': {
      color: primaryColor,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: primaryColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: primaryColor,
      },
    },
  }
};

export default function CurrencyField(props) {
    const { classes } = props;

  function handleChange(event) {
    props.delegate(props.id, parseFloat(event.target.value.replace(".", "").replace(",", ".")))
  }

  const [value, setValue] = React.useState();

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
    <CurrencyTextField
      required={props.isRequired}
		  label={props.title}
		  variant="outlined"
		  value={value}
      defaultValue={props.defaultValue}
		  currencySymbol={props.symbol ?? "R$"}
		  minimumValue="0"
		  outputFormat="string"
      decimalPlaces="2"
      decimalPlacesShownOnBlur="2"
      decimalPlacesShownOnFocus="2"
		  decimalCharacter=","
		  digitGroupSeparator="."
      style ={styles}
		  onChange={handleChange}
    />
    </ThemeProvider>
  );
}