import CompanyLayout from "../../Models/Auth/CompanyLayout";
import User from "../../Models/Auth/User";
import Company from "../../Models/Client/Company";
import Invoice from "../../Models/Invoice/Invoice";
import { pageLinks } from "../../Routes/PageLinks";
import APIManager from "../APIManager"

export default class AuthRoute {
    constructor() {
      this.apiManager = new APIManager()
      this.endPoint = 'auth/login'
    }

    fetchVerify = (authUser, callback, error) => {
        this.apiManager.fetchJSON('user/mailPassword', authUser, 'POST', function(response) {
            try {
                const user = new User(response);
                if (!user.name) {return error(response.message);}
                callback(user);
            } catch {
                error(response.message);
            }
        })
    }

    fetchMailLostPassword = (authUser, callback, error) => {
        this.apiManager.fetchJSON('user/mailLostPassword', authUser, 'POST', function(response) {
            try {
                if (!response.token) {return error(response.message);}
                callback(response);
            } catch {
                error(response.message);
            }
        })
    }
    
    fetchLogin = (authUser, callback, callbackOverdue, error) => {
        this.apiManager.fetchJSON(this.endPoint, authUser, 'POST', function(response) {
            try {
                if (response["overdued"] == true) {
                    callbackOverdue(response["billings"])
                    return
                }
                const user = new User(response.user);
                callback(user);
            } catch {
                error(response.message);
            }
        })
    }

    fetchContactSupportAdmin = (callback, error) => {
        this.apiManager.fetchJSON('companies/contactSupportAdmin', null, 'GET', function(response) {
            try {
                callback(response);
            } catch {
                error(response.message);
            }
        })
    }

    fetchChangePass = (authUser, userId, code, callback, error) => {
        this.apiManager.fetchJSON('user/changePassword/' + userId + '/' + code, authUser, 'PUT', function(response) {
            try {
                const user = new User(response);
                if (!user._id) {return error(response.message);}
                callback(user);
            } catch {
                error(response.message);
            }
        })
    }

    fetchDetailUser = (userId, callback, error) => {
        this.apiManager.fetchJSON('user/find/' + userId, null, 'GET', function(response) {
            try {
                const user = new User(response);
                callback(user);
            } catch {
                error(response.message);
            }
        })
    }

    fetchCompanyLayout = (companyId, callback, error) => {
        this.apiManager.fetchJSON('companies/layout/' + companyId, null, 'GET', function(response) {
            try {
                const layout = new CompanyLayout(response);
                if (!layout._id) {return error(response.message);}
                callback(layout);
            } catch {
                error(response.message);
            }
        })
    }

    fetchCompanyByNameId = (nameid, callback, error) => {
        this.apiManager.fetchJSON('companies/nameid/' + nameid, null, 'GET', function(response) {
            try {
                callback(response);
            } catch {
                error(response.message);
            }
        })
    }

    fetchLoadCompanyDetails = (callback, error) => {
        var user = APIManager.loadCache(User.keyClass)
        this.apiManager.fetchJSON('companies/configs/' + user.company._id, null, 'GET', function(response) {
            try {
                var user = APIManager.loadCache(User.keyClass)
                user.company = response
                APIManager.saveCache(User.keyClass, user)
                callback(response);
            } catch {
                error(response.message);
            }
        })
    }

    fetchUpdateCompany = (company, callback, error) => {
        this.apiManager.fetchJSON('companies/update', company, 'POST', function(response) {
            try {
                callback(response);
            } catch {
                error(response.message);
            }
        })
    }

    static fetchLogout (link) {
        const company = APIManager.loadCache("company")
        APIManager.removeCache(User.keyClass)
        // APIManager.removeCache("company")
        window.location.href = link == null ? pageLinks.auth.replace(':company', company) : link
    }

  }