import ModelJSON from "../ModelJSON";

export default class DashBillingInfo extends ModelJSON {
  constructor(received = "", overdue = "", lastReceive = "", receivedAmount = "", overdueAmount = "", lastReceiveAmount = "", monthsTitle = [], monthsAmount = []) {
    super()
    this.received = received
    this.overdue = overdue
    this.lastReceive = lastReceive
    this.receivedAmount = receivedAmount
    this.overdueAmount = overdueAmount
    this.lastReceiveAmount = lastReceiveAmount
    this.monthsTitle = monthsTitle
    this.monthsAmount = monthsAmount
  }

  static init(json) {
    return new DashBillingInfo(
        json.received,
        json.overdue,
        json.lastReceive,
        json.receivedAmount,
        json.overdueAmount,
        json.lastReceiveAmount,
        json.monthsTitle,
        json.monthsAmount
    )
  }
}