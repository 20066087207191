import React from 'react'
import { Switch, Route } from "react-router-dom";
import { pageLinks } from "./PageLinks";
import DashboardPage from "../Pages/Dashboard/DashboardPage";
import ClientsPage from "../Pages/Clients/ClientsPage";
import NewClientPage from "../Pages/Clients/NewClientPage";
import SignInPage from '../Pages/Auth/SignInPage';
import ClientDetailPage from '../Pages/Clients/ClientDetailPage';
import { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import NewInvoicePage from '../Pages/Invoices/NewInvoicePage';
import InvoiceDetailPage from '../Pages/Invoices/InvoiceDetailPage';
import APIManager from '../API/APIManager';
import PlansPage from '../Pages/Plans/PlansPage';
import PlanDetailPage from '../Pages/Plans/PlanDetailPage';
import NewPlanPage from '../Pages/Plans/NewPlanPage';
import ClientPlanConnectPage from '../Pages/Clients/ClientPlanConnectPage';
import Helpers from '../Helpers/Helpers';
import InvoicesPage from '../Pages/Invoices/InvoicesPage';
import LostPasswordPage from '../Pages/Auth/LostPasswordPage';
import ClientDashboardPage from '../Pages/ClientLogged/ClientDashboardPage';
import CompanyConfigPage from '../Pages/Configure/CompanyConfigPage';
import ClientConfigPage from '../Pages/Configure/ClientConfigPage';
import PublicationsPage from '../Pages/Publications/PublicationsPage';
import NewPublicationPage from '../Pages/Publications/NewPublicationPage';
import ClientInvoiceDetailPage from '../Pages/ClientLogged/ClientInvoiceDetailPage';
import ClientInvoiceListPage from '../Pages/ClientLogged/ClientInvoiceListPage';
import ChatSupportPage from '../Pages/Support/ChatSupportPage';
import EmployeesPage from '../Pages/Employees/EmployeesPage';
import NewEmployeePage from '../Pages/Employees/NewEmployeePage';
import EmployeeDetailPage from '../Pages/Employees/EmployeeDetailPage';
import SysInvoicePage from '../Pages/SystemInvoices/SysInvoicePage';
import SysInvoiceDetailPage from '../Pages/SystemInvoices/SysInvoiceDetailPage';
import NewPeriodPage from '../Pages/Pedagogic/Period/NewPeriodPage';
import PeriodsPage from '../Pages/Pedagogic/Period/PeriodsPage';
import CoursesPage from '../Pages/Pedagogic/Course/CoursesPage';
import NewCoursePage from '../Pages/Pedagogic/Course/NewCoursePage';
import ClassesPage from '../Pages/Pedagogic/Class/ClassesPage';
import NewClassPage from '../Pages/Pedagogic/Class/NewClassPage';
import OfficesPage from '../Pages/Pedagogic/Office/OfficesPage';
import NewOfficePage from '../Pages/Pedagogic/Office/NewOfficePage';
import NewEnrollmentPage from '../Pages/Pedagogic/Enrollment/NewEnrollmentPage';
import ClassDetailPage from '../Pages/Pedagogic/Class/ClassDetailPage';
import DisciplinesPage from '../Pages/Pedagogic/Discipline/DisciplinesPage';
import NewDisciplinePage from '../Pages/Pedagogic/Discipline/NewDisciplinePage';
import DepositPage from '../Pages/Deposit/DepositPage';
import DepositDetailPage from '../Pages/Deposit/DepositDetailPage';

export default class Routes extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      isLoading: false,
      alertType: null,
      alertMessage: null,
      alertTitle: null
    }

    this.loadingAction = this.loadingAction.bind(this)
    this.setLayoutCSS = this.setLayoutCSS.bind(this)
    this.setLayoutCSS()
  }

  loadingAction = (isLoading) => {
    this.setState({ isLoading: isLoading })
  }

  setAlert = (type, title, message) => {
    this.setState({ 
      alertMessage: message,
      alertTitle: title,
      alertType: type
    })
    if (type != null) {
      this.loadingAction(false)
      window.setTimeout(() => {this.setAlert(null, null, null)}, 5000);
    }
  }

  setLayoutCSS() {
    let root = document.documentElement
    root.style.setProperty('--primary', Helpers.primaryColor());
    root.style.setProperty('--primary-hover', Helpers.primaryDetailColor());
    root.style.setProperty('--primary-clear', Helpers.primaryClearColor());
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(224, 220, 220, 0.5)'
          }),
          spinner: (base) => ({
            ...base,
            width: '70px',
            '& svg circle': {
              stroke: Helpers.primaryColor()
            }
          }),
          wrapper: {
            height: this.state.isLoading ? '500px' : 'auto',
            // overflow: this.state.isLoading ? 'hidden' : 'scroll'
          }
        }}
      >
        <div hidden={this.state.alertType == null} class={"alert alert-" + this.state.alertType} ><strong>{this.state.alertTitle}</strong> {this.state.alertMessage}</div>
        <Switch>
          <Route exact path={pageLinks.dashboard}><DashboardPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.clientDashboard}><ClientDashboardPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.clients}><ClientsPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newClient}><NewClientPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newInvoice}><NewInvoicePage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.clientDetail}><ClientDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.invoiceDetail}><InvoiceDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.auth}><SignInPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.lostPass}><LostPasswordPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.plans}><PlansPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.planDetail}><PlanDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPlan}><NewPlanPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.clientPlan}><ClientPlanConnectPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.invoices}><InvoicesPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.companyConfig}><CompanyConfigPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.userConfig}><ClientConfigPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.publications}><PublicationsPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPublication}><NewPublicationPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.clientInvoiceList}><ClientInvoiceListPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.clientInvoiceDetail}><ClientInvoiceDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.support}><ChatSupportPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.employees}><EmployeesPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newEmployee}><NewEmployeePage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.employeeDetail}><EmployeeDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.depositList}><DepositPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.depositDayDetail}><DepositDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.sysInvoices}><SysInvoicePage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.sysInvoiceDetail}><SysInvoiceDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>

          <Route exact path={pageLinks.pedagogicPeriod}><PeriodsPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPedagogicPeriod}><NewPeriodPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.pedagogicCourses}><CoursesPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPedagogicCourse}><NewCoursePage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.pedagogicClass}><ClassesPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPedagogicClass}><NewClassPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.pedagogicOffice}><OfficesPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPedagogicOffice}><NewOfficePage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPedagogicEnrollment}><NewEnrollmentPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.detailPedagogicClass}><ClassDetailPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.pedagogicDiscipline}><DisciplinesPage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
          <Route exact path={pageLinks.newPedagogicDiscipline}><NewDisciplinePage loadingAction={this.loadingAction} setAlert={this.setAlert} /></Route>
        </Switch>
      </LoadingOverlay>
    )
  }

}
// export default function Routes() {
//   return (
//     <Switch>
//       <Route exact path={pageLinks.dashboard}><DashboardPage /></Route>
//       <Route exact path={pageLinks.clients}><ClientsPage loadingAction={this.props.loadingAction} /></Route>
//       <Route exact path={pageLinks.newClient}><NewClientPage /></Route>
//       <Route exact path={pageLinks.clientDetail}><ClientDetailPage/></Route>
//       <Route exact path={pageLinks.auth}><SignInPage /></Route>
//     </Switch>
//   )
// }