import ModelJSON from "../ModelJSON";

export default class CompanyLayout extends ModelJSON {
    
    static keyClass = 'companyLayout'

    constructor(json) {
        super()
        this._id = json._id;
        this.primaryColor = json.primaryColor;
        this.primaryDetailColor = json.primaryDetailColor;
        this.secondaryColor = json.secondaryColor;
        this.logo = json.logo;
        this.name = json.name
    }
  }