import User from "../../Models/Auth/User"
import Chat from "../../Models/Chat/Chat"
import ChatMessage from "../../Models/Chat/ChatMessage"
import APIManager from "../APIManager"

export default class ChatRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `chat`
  }

  fetchGetChatList = (callback, error) => {
    const company = APIManager.loadCache(User.keyClass).company
    this.apiManager.fetchJSON(`chat/all/` + company._id, null, 'GET', function (response) {
      try {
        var chats = new Array()
        response.forEach(element => {
          const chat = Chat.init(element);
          chats.push(chat)
        });
        callback(chats);
      } catch {
        error(response.message);
      }
    })
  }

  fetchBadgeChat = (callback, error) => {
    const company = APIManager.loadCache(User.keyClass).company
    this.apiManager.fetchJSON(`chat/badge/` + company._id, null, 'GET', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchOpenChat = (clientId, title, callback, error) => {
    const user = APIManager.loadCache(User.keyClass)
    console.log(title);
    this.apiManager.fetchJSON(`chat`, {clientId: (clientId == null ? user._id : clientId), title: title, company: user.company._id}, 'POST', function (response) {
      try {
        const chat = Chat.init(response);
        callback(chat);
      } catch {
        error(response.message);
      }
    })
  }

  fetchLoadAllMessagesChat = (chatId, callback, error) => {
    const user = APIManager.loadCache(User.keyClass)
    this.apiManager.fetchJSON(`message/allMessages`, {clientId: user._id, company: user.company._id, chat: chatId}, 'POST', function (response) {
      try {
        var messages = new Array()
        response.forEach(element => {
          const message = ChatMessage.init(element);
          messages.push(message)
        });
        callback(messages);
      } catch {
        error(response.message);
      }
    })
  }

  fetchSendMessageChat = (text, chatId, callback, error) => {
    const user = APIManager.loadCache(User.keyClass)
    this.apiManager.fetchJSON(`message`, {clientId: user._id, company: user.company._id, chat: chatId, sender: user._id, text: text}, 'POST', function (response) {
      try {
        const message = ChatMessage.init(response);
        callback(message);
      } catch {
        error(response.message);
      }
    })
  }

  fetchSearchClient = (text, callback, error) => {
    const company = APIManager.loadCache(User.keyClass).company
    this.apiManager.fetchJSON("chat/search/" + company._id + "/" + text, null, 'GET', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

}