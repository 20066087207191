import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import PageTitle from '../../Views/Components/PageTitle';
import APIManager from '../../API/APIManager';
import LoadingOverlay from 'react-loading-overlay';
import QRCode from 'react-qr-code';

class SysLockOverdueDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            invoice: APIManager.loadCache("overdueInvoiceSys"),
            itemsFiltered: [],
            deleteItems: [],
            checkedList: [],
            payItems: [],
            errorMessage: null,
            isCancellingPlan: false,
            isEditing: false,
            individualOptions: [],
            fastOptions: [],
            isLoading: true
        }

        this.loadedInvoices = this.loadedInvoices.bind(this)
        this.errorAction = this.errorAction.bind(this)

        this.loadInvoice()
    }
  
    loadInvoice = () => {
      this.setState({ isLoading: true })
      let route = new InvoiceRoute()
      route.fetchInvoiceSysDetail(APIManager.loadCache("overdueInvoiceSys")._id, this.loadedInvoice, this.errorAction)
    }
  
    loadedInvoice = (invoice) => {
      this.setState({ isLoading: false })
      this.setState({
        invoice: invoice,
        isPaying: false,
        isCancelling: false,
      });
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }

    getTable() {
        var rows = new Array()
        const items = APIManager.loadCache("overdueInvoicesSys").length > 0 ? APIManager.loadCache("overdueInvoicesSys") : []
        items.forEach(element => {
            const venciment = new Date(element.venciment)
            const data = {
                id: element.provider.idTransaction,
                venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
                amount: element.amount,
                discount: element.amountDiscount,
                penalty: element.amountPenalty,
                fees: element.amountFees,
                amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
                paymentType: element.paymentType.name,
                status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
            }
            rows.push(data)
        });
        return rows
    }

    loadedInvoices = () => {
        this.state.items = APIManager.loadCache("overdueInvoicesSys")
        this.setState({ items: this.state.items });
    }

    showPdfInNewTab(base64Data) {  
        var byteCharacters = atob(base64Data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    selectItemAction = (value) => {
        let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value })
        this.props.history.push({
            pathname: pageLinks.sysInvoiceDetail,
            state: { invoice: invoice, client: this.state.client }
        })
    }

    sendToLoginAction = () => {
        APIManager.saveCache("company", APIManager.loadCache("company"));
        window.location.href = pageLinks.auth.replace(":company", this.state.userAuth.company);
    }

    render() {
        const company = APIManager.loadCache("company")
        const invoice = this.state.invoice
        const venciment = new Date(invoice.venciment)
        const datePayed = new Date(invoice.datePayed)

        return (
            <LoadingOverlay
            active={this.state.isLoading}
            spinner
            styles={{
              overlay: (base) => ({
                ...base,
                background: 'rgba(224, 220, 220, 0.5)'
              }),
              spinner: (base) => ({
                ...base,
                width: '70px',
                '& svg circle': {
                  stroke: Helpers.primaryColor()
                }
              }),
              wrapper: {
                height: this.state.isLoading ? '500px' : 'auto',
                // overflow: this.state.isLoading ? 'hidden' : 'scroll'
              }
            }}
          >
            <div class="container-fluid">
                {/* <div class={"alert alert-danger pointer"} onClick={this.openInvoicesListAction} hidden={this.state.overdueSysInvoices == null || this.state.overdueSysInvoices.length == 0}><strong>Atenção!  </strong>{(this.state.overdueSysInvoices?.length > 1 ? "Existem " : "Existe ") + (this.state.overdueSysInvoices?.length) + (this.state.overdueSysInvoices?.length > 1 ? " faturas do sistema vencidas" : " fatura do sistema vencida") + ", evite o bloqueio! clique aqui para verificar"}</div> */}
                <div class="account-pages mt-5 mb-5">
                    <div class="container">
                        <PageTitle title="Detalhe de fatura" history={this.props.history}/>

                        <div class="card shadow mb-4">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Informações da Cobrança</h6>
                                {/* <BarButton title="Cancelar cobrança" clickAction={() => { this.setState({ isCancelling: true }) }} hidden={invoice.status.id == "cancelled" || invoice.status.id == "allpayed"} /> */}
                            </div>
                            <div class="card-body-no-padding" hidden={this.state.isEditing}>
                                <div class="row">
                                    <div class="col-sm-5">
                                        <div class="row">
                                            <div class="col-sm" hidden={invoice.status == null} >
                                                <div class="float-end mt-3 mt-sm-6">
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-5" hidden={invoice.status == null}>
                                                <div class="float-end mt-3 mt-sm-6">
                                                    <h8>Número: </h8><br />
                                                    <strong><span class="ml-2">{invoice.provider.idTransaction}</span></strong>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="col-sm-5" hidden={invoice.provider.idTransaction == null}>
                                                <div class="float-end mt-3 mt-sm-6">
                                                    <h8>Tipo: </h8><br />
                                                    <strong><span class="ml-2">{invoice.paymentType.name}</span></strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-5" hidden={invoice.venciment == null}>
                                                <div class="float-end mt-3 mt-sm-6">
                                                    <h8>Vencimento: </h8><br />
                                                    <strong><span class="ml-2">{venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear()}</span></strong>
                                                </div>
                                            </div>
                                            <div class="col-sm-5" hidden={invoice.paymentType.name == null}>
                                                <div class="float-end mt-3 mt-sm-6">
                                                    <h8>Status: </h8><br />
                                                    <strong><span class="ml-2" style={{ color: invoice.status.color, backgroundColor: invoice.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{invoice.status.name}</span></strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row padding-bottom-075">
                                            <div class="col-sm-7" hidden={invoice.datePayed == null}>
                                                <div class="float-end mt-3 mt-sm-6"><h8>Data do Pagamento: </h8><br />
                                                    <strong><span class="ml-2">{datePayed.getUTCDate() + '/' + (datePayed.getUTCMonth() + 1) + '/' + datePayed.getFullYear()}</span></strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row padding-bottom">
                                            <div class="col-sm-6" hidden={invoice.provider.pixKey == null || invoice.status.id == "cancelled" || invoice.paymentType.id == "cash"}>
                                                <div class="float-end mt-3 mt-sm-6" align="left">
                                                    <p><QRCode value={invoice.provider.pixKey ?? ""} size={106} fgColor="#000000" /></p>
                                                    <a className="pointer btn-sm btn-primary-default" onClick={() => { navigator.clipboard.writeText(invoice.provider.pixKey);}} >
                                                        <span class="h8 font-weight-bold text-white">Copiar Código</span>
                                                    </a>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="col-sm-6" hidden={invoice.provider.pixKey != null || invoice.provider.digitableLine == null || invoice.status.id == "cancelled" || invoice.paymentType.id == "cash"}>
                                                <div class="float-end mt-3 mt-sm-6">
                                                    <center>
                                                        <div class={`pointer card background-white`} onClick={() => { navigator.clipboard.writeText(invoice.provider.digitableLine); this.props.setAlert('success', 'Tudo certo!', 'Código de barras copiado com sucesso!') }}>
                                                            <div class="card-body">
                                                                <div class="row no-gutters align-items-center">
                                                                    <div class="col-auto">
                                                                        <div class="icon-circle background-icon">
                                                                            <i class={`fas fa-copy fa-1x text-primary`}></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col ml-5" align="right">
                                                                        <div class="h7 mb-0 font-weight-bold text-gray-800">Copiar Código de Barras</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </center>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="col-sm-6" hidden={invoice.provider.digitableLine == null || invoice.status.id == "cancelled" || invoice.paymentType.id == "cash"}>
                                                <div class="float-end mt-3 mt-sm-6">
                                                    <center>
                                                        <div class={`pointer card background-white`} onClick={() => { this.showPdfInNewTab(invoice.provider.billetArchive); }}>
                                                            <div class="card-body">
                                                                <div class="row no-gutters align-items-center">
                                                                    <div class="col-auto">
                                                                        <div class="icon-circle background-icon-default">
                                                                            <i class={`fas fa-file-download fa-1x text-primary`}></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col ml-4" align="right">
                                                                        <div class="h7 mb-0 font-weight-bold text-gray-800">Baixar Boleto PDF</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </center>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div hidden={invoice.plan == null} class="float-end mt-3 mt-sm-6"><h8>Plano: </h8><br />
                                            <strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{invoice.plan}</span></strong>
                                        </div>
                                        <div class="float-end mt-3 mt-sm-6" hidden={invoice.desc == null}>
                                            <h8>Descrição: </h8><br />
                                            <strong><span class="ml-2">{invoice.desc}</span></strong>
                                        </div>
                                    </div>
                                    <div class="background-detail vl-left col-sm-4" >
                                        <div class="float-end mt-3 mt-sm-6" align="right">
                                            <h7><b>SUB-TOTAL</b></h7>
                                            <p><h5><b>{invoice.amount}</b></h5></p>
                                            <h7 hidden={invoice.amountDiscount == null}><b>- {invoice.amountDiscount}</b> <span class="float-end">de desconto</span></h7><br />
                                            <h7 hidden={invoice.amountPenalty == null}><b>+ {invoice.amountPenalty}</b> <span class="float-end">de multa</span></h7><br />
                                            <p><h7 hidden={invoice.amountFees == null}><b>+ {invoice.amountFees}</b> <span class="float-end">de juros</span></h7></p>
                                            <hr hidden={invoice.totalWithTax == null} />
                                            <h7 hidden={invoice.totalWithTax == null}><b>TOTAL PAGO</b></h7>
                                            <h4 hidden={invoice.totalWithTax == null}><b>{invoice.amountTotal}</b> <span class="float-end"></span></h4>
                                            <hr />
                                            <h7><b>{invoice.status.id == "allpayed" ? 'VALOR RECEBIDO' : 'TOTAL A PAGAR'}</b></h7>
                                            <h2 class={`text-primary padding-bottom-075`}><b>{invoice.totalWithTax ?? invoice.amountTotal}</b></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </LoadingOverlay>
        )
    }
}

export default withRouter(SysLockOverdueDetail);