import React, { Component } from 'react'
import ChatItem from './ChatItem';
import ChatMessageItem from './ChatMessageItem';
import UIAvatar from 'react-ui-avatars';
import Helpers from '../../Helpers/Helpers'
import Chat from '../../Models/Chat/Chat';
import User from '../../Models/Auth/User';
import APIManager from '../../API/APIManager';
import EmptyState from '../../Views/EmptyState/EmptyState';
import EmptyStateEnum from '../../Views/EmptyState/EmptyStateEnum';

export default class ChatComponent extends Component {
  constructor(props) {
    super(props);

    this.selectChatAction = this.selectChatAction.bind(this)
    this.changeText = this.changeText.bind(this)
    this.changeSearchText = this.changeSearchText.bind(this)

    this.state = {
        text: ""
    }
  }

  selectChatAction = (value) => {
      this.props.selectAction(value)
  }

  selectUserAction = (value) => {
    this.props.clearSearchButtonClicked()
    this.props.newChatAction(value)
}

  changeText = (event) => {
    this.setState({text: event.target.value})
    this.props.updateText(event.target.value)
  }

  changeSearchText = (event) => {
    this.props.updateSearchText(event.target.value)
  }

  handleKeypress = (event) => {
    if (event.key === "Enter") {
        this.handleSubmit();
    }
  }

  handleKeypressSearchText = (event) => {
    if (event.key === "Enter") {
        this.props.searchButtonClicked()
    }
  }

  handleSubmit = () => {
    this.props.sendButtonClicked()
    this.setState({text: ""})
  }

  render() {
    const user = APIManager.loadCache(User.keyClass)
    const primaryColor = Helpers.primaryColor()
    const chats = this.props.chats.map((chat) => <ChatItem chat={chat} isSelectedChat={this.props.selectedChat == null ? false : this.props.selectedChat.id == chat.id} selectAction={(chat)=>{this.selectChatAction(chat)}}/>);
    const clientChats = [<ChatItem chat={{id: '', title: "Suporte", lastMessageDate: "Canal de Atendimento"}} isSelectedChat={true} messageNotReadNumbers={null} selectAction={null}/>]
    const searchResults = this.props.searchResults.map((result) => <ChatItem chat={{id: result.id, title: result.name, lastMessageDate: result.document}} messageNotReadNumbers={null} selectAction={(result)=>{this.selectUserAction(result)}}/>);
    const messages = this.props.messages.map((message) => <ChatMessageItem username={message.sender.name} isReveicedMessage={!message.messageSendedForMe} messageText={message.text}/>);
    const showMessages = this.props.username == ""
    const isClient = user.level == 0
    const hasShowSearchResults = this.props.searchResults.length > 0
    return (
        <div class="containerWithOutMargins">
            <div class="cardWithOutBorder">
                <div class="row g-0">
                    <div hidden={isClient} class={"chat-list mt-2 col-12 col-lg-4 " + (showMessages ? "col-xl-12" : "col-xl-3")}>

                        <div class="d-none d-md-block">
                            <div class="d-flex align-items-center">
                                <input type="text" value={this.props.searchText} class="form-control my-3" placeholder="Buscar cliente" onChange={this.changeSearchText} onKeyPress={this.handleKeypressSearchText}/>
                                <div hidden={hasShowSearchResults} class="btn icon-circle background-icon ml-4" onClick={this.props.searchButtonClicked}>
                                <i class={`fas fa-search fa-1x text-primary`}></i>
                                </div>
                                <div hidden={!hasShowSearchResults} class="btn icon-circle background-icon ml-4" onClick={()=> {this.props.clearSearchButtonClicked()}}>
                                <i class={`fas fa-times fa-1x text-primary`}></i>
                                </div>
                            </div>
                        </div>
                        { hasShowSearchResults ? searchResults : chats }

                        <hr class="d-block d-lg-none mt-1 mb-0" />
                    </div>
                    <div hidden={!isClient} class={"chat-list mt-2 col-12 col-lg-4 col-xl-3 my-3"}>
                        { clientChats }
                    </div>
                    <div hidden={showMessages} class="chat-messages-background col-12 col-lg-8 col-xl-85">
                        <div class="py-2 px-4 border-bottom d-none d-lg-block">
                            <div class="d-flex align-items-center py-1">
                                <div class="position-relative">
                                <UIAvatar className="img-profile rounded-circle mr-1" width="40" height="40" name={isClient ? "Suporte" : this.props.username} color={primaryColor} />
                                </div>
                                <div class="flex-grow-1 pl-3">
                                    <strong>{isClient ? "Suporte" : this.props.username}</strong>
                                    <div class="text-muted small"><em></em></div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>

                        <div class="position-relative">
                            <div id='chat-box' class="chat-messages p-4 mt-1">
                                <EmptyState hidden={messages.length != 0} stateImage={EmptyStateEnum.ImageEnum.noMessages} stateText={EmptyStateEnum.TextEnum.noMessages}/>
                                { messages }
                            </div>
                        </div>

                        <div class="flex-grow-0 py-3 px-4 border-top">
                            <div class="input-group">
                                <input type="text" class="form-control" value={this.state.text} placeholder="Escreva sua mensagem" onChange={this.changeText} onKeyPress={this.handleKeypress}/>
                                <div class="btn icon-circle background-icon ml-4" onClick={this.handleSubmit}>
                                <i class={`fas fa-paper-plane fa-1x text-primary`}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}