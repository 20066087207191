import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import Helpers from '../../../Helpers/Helpers';

export default class DashChartBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const primaryColor = Helpers.primaryColor()
    const chatValues = {
      series: [{
        name: 'Total',
        data: this.props.data
      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        colors: [primaryColor],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: this.props.classes,
          labels: {
            style: {
              colors: ['#000000', '#000000', '#000000', '#000000', primaryColor],
              fontSize: '12px'
            }
          }
        }
      },
    }
    return (
      <div class="col-xl-4 col-lg-5" hidden={this.props.hidden}>
        <div class="cardChart shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">{this.props.title}</h6>
            <div class="dropdown no-arrow">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                <div class="dropdown-header">Dropdown Header:</div>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-pie pt-4 pb-2">
              <Chart options={chatValues.options} series={chatValues.series} type="bar" height={320} />
            </div>
            <div class="mt-4 text-center small">
              <span class="mr-2">

              </span>
              <span class="mr-2">

              </span>
              <span class="mr-2">

              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}