import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import { pageLinks } from '../../Routes/PageLinks';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import ClientRoute from '../../API/Routes/ClientRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import Helpers from '../../Helpers/Helpers';
import PageTitle from '../../Views/Components/PageTitle';
import EmployeeRoute from '../../API/Routes/EmployeeRoute';
import ImportButton from '../../Views/Components/ImportButton';

class EmployeesPage extends Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: 'Nome', isButton: true },
      { id: 'id', label: 'CPF/CNPJ', isButton: false },
      { id: 'level', label: 'Tipo', isButton: false },
      { id: 'status', label: 'Situação', isButton: false }
    ];

    this.state = {
      error: null,
      statusList: [],
      items: [],
      itemsFiltered: [],
      checkedList: [],
      selectedItem: '',
      deleteEmployees: [],
      search: {
        status: '',
        name: '',
        document: '',
      },
      individualOptions: [
        { id: '0', label: 'Apagar colaborador', action: (clientDocuments) => this.setState({ deleteEmployees: [clientDocuments] })}
      ],
      fastOptions: [
        { id: '0', label: 'Apagar colaboradores selecionados', action: (clientDocuments) => this.setState({ deleteEmployees: clientDocuments })},
        { id: '1', label: 'Desativar colaboradores selecionados', action: (clientDocuments) => this.setState({ deleteEmployees: clientDocuments })},
      ]
    }
    this.props.loadingAction(false)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)
    this.exportAction = this.exportAction.bind(this)
    this.changeSearch = this.changeSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.searchAction = this.searchAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.loadStatusList = this.loadStatusList.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.selectItemAction = this.selectItemAction.bind(this)
    this.importAction = this.importAction.bind(this)
  }

  loadRows = (values) => {
    this.setState({ deleteEmployees: [] })
    this.props.loadingAction(true)
    this.setState({items: values});
    let route = new ClientRoute()
    route.fetchClientAllStatus(this.loadStatusList, this.errorAction)
  }

  loadStatusList = (values) => {
    this.props.loadingAction(false)
    console.log(values);
    var rows = [{ id: 'all', label: 'Todos' }]
    values.forEach(element => {
      const data = { id: element.id, label: element.title }
      rows.push(data)
    });
    this.state.search.status = rows[1].id
    this.setState({
      statusList: rows,
      search: this.state.search,
      itemsFiltered: this.state.items.filter(client => {return ((client.name.toLowerCase().includes(this.state.search.name.toLowerCase())) && (client.document.includes(this.state.search.document)) && (client.status.id.includes(this.state.search.status)))})
    });
  }

  deleteEmployeesAction = () => {
    this.props.loadingAction(true)
    // this.props.setAlert('success', 'Tudo certo!', 'Colaborador deletado com sucesso')()
    new EmployeeRoute().fetchDelete(this.state.deleteEmployees, (values) => {
        if (this.state.deleteEmployees.length > 1) {window.location.reload();}
        this.setState({ deleteEmployees: [] })
        this.props.loadingAction(true)
        let route = new EmployeeRoute()
        route.fetchAll(this.loadRows, this.errorAction)
      }, this.errorAction)
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  changeSearch = (key, value) => {
    this.state.search[key] = value
    this.setState({ search: this.state.search })
  }

  clearSearch = () => {
    Object.keys(this.state.search).forEach((key) => this.state.search[key] = '')
    this.setState({ search: this.state.search })
  }

  searchAction = () => {
    this.setState({
      itemsFiltered: this.state.items.filter(client => {return ((client.name.toLowerCase().includes(this.state.search.name.toLowerCase())) && (client.document.includes(this.state.search.document)) && (this.state.search.status == 'all' ? true : (client.status.id.includes(this.state.search.status))))})
    })
  }

  selectItemAction = (value) => {
    this.props.history.push({
      pathname: pageLinks.employeeDetail,
      state: {document: Helpers.onlyNumbers(value), clientCreated: false}  
    })
  }

  updateListChecked = (value) => {
    this.setState({ checkedList: value })
  }

  fastAction = (value) => {
    console.log(value);
    const client = this.state.fastOptions.find((element) => { return element.id == value})
    console.log(client);
    client.action(this.state.checkedList)
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId;})
    client.action(value.rowId)
  }

  newClientAction() {
    this.props.history.push(pageLinks.newEmployee)
  }

  importAction(file) {
    this.props.loadingAction(true)
    let route = new ClientRoute()
    route.fetchImportClients(file, (csvContent)=> {
      this.props.loadingAction(false)
      window.location.reload()
      alert("Colaboradores importados com sucesso!")
    }, this.errorAction)
  }

  exportAction() {
    this.props.loadingAction(true)
    let route = new EmployeeRoute()
    route.fetchExport((csvContent)=> {
      this.props.loadingAction(false)
      Helpers.downloadCSV("employees", csvContent)
    }, this.errorAction)
  }

  componentDidMount() {
    let route = new EmployeeRoute()
    route.fetchAll(this.loadRows, this.errorAction)
  }

  getTable() {
    var rows = new Array()
    const items = this.state.itemsFiltered.length > 0 ? this.state.itemsFiltered : this.state.items
    items.forEach(element => {
      const data = {
        name: element.name,
        id: Helpers.documentFormat(element.document),
        level: element.level == null ? "-" : <span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{element.level.title}</span>,
        status: <span class="ml-0" style={{color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px'}}><i class={`fas fa-` + element.status.iconName + ` fa-1x`}></i> {element.status.title}</span>
      }
      rows.push(data)
    });
    return rows
  }

  render() {
    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Atenção" desc={this.state.deleteEmployees.length == 1 ? "Tem certeza que deseja deletar este colaborador?" : "Tem certeza que deseja deletar estes colaboradores?"} isShow={this.state.deleteEmployees.length > 0} defaultAction={this.deleteEmployeesAction} closeAction={() => { if (this.state.deleteEmployees.length > 1) {window.location.reload();}; this.setState({ deleteEmployees: [] }); } } buttonTitle="Sim" closeTitle="Cancelar"/>
        <PageTitle title="Colaboradores"/>
        <div class="row">
          <TopButton title="NOVO COLABORADOR" icon="plus" clickAction={this.newClientAction} />
          {/* <ImportButton title="IMPORTAR COLABORADORES" icon="file-upload" changeAction={this.importAction} />
          <TopButton title="EXPORTAR COLABORADORES" icon="file-download" clickAction={this.exportAction} /> */}
        </div>

        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Buscar Colaborador</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
              <div>
                <SimpleTextField id="name" defaultValue={this.state.search.name} title="Nome" isRequired={false} delegate={this.changeSearch} width="97%" />
              </div>,
                <div>
                  <SimpleTextField id="document" defaultValue={this.state.search.registrationNumber} title="CPF/CNPJ" isRequired={false} delegate={this.changeSearch} width="25ch" mask={new String(this.state.search.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                  <SelectTextField id="status" defaultValue={this.state.search.status} title="Situação do Colaborador" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.state.statusList} />
                </div>,

                <ColoredButton title="Buscar" clickAction={this.searchAction} />,
                <SimpleButton title="Limpar" clickAction={this.clearSearch} />
              ]
            } />
          </div>
        </div>

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Lista de Colaboradores'
            rows={this.getTable()}
            headCells={this.headCells}
            defaultOrderId='name'
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(EmployeesPage);