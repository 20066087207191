import User from "../../Models/Auth/User"
import Publication from "../../Models/Publication/Publication"
import APIManager from "../APIManager"

export default class PublicationRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `publications`
  }

  static perPageNumber = 5

  fetchCreate = (pub, callback, error) => {
    pub.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint, pub, 'POST', function (response) {
      try {
        const pub = Publication.init(response);
        callback(pub);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdate = (pub, callback, error) => {
    pub.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint, pub, 'PUT', function (response) {
      console.log(response);
      try {
        const pub = Publication.init(response);
        callback(pub);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAll = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company, null, 'GET', function (response) {
      try {
        var pubs = new Array()
        response.forEach(element => {
          const pub = Publication.init(element);
          pubs.push(pub)
        });
        callback(pubs);
      } catch {
        error(response.message);
      }
    })
  }

  fetchByPage = (page, callback, error) => {
    const company = APIManager.loadCache("company")
    const client = APIManager.loadCache(User.keyClass)
    const clientId = client == null ? "" : client._id
    this.apiManager.fetchJSON(this.endPoint + '/page/' + company, {'page': page, 'perPage': PublicationRoute.perPageNumber, 'clientId': clientId}, 'POST', function (response) {
      try {
        var pubs = new Array()
        response.forEach(element => {
          const pub = Publication.init(element);
          pubs.push(pub)
        });
        callback(pubs);
      } catch {
        error(response.message);
      }
    })
  }

  fetchpubDetail = (key, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/` + key, null, 'GET', function (response) {
      try {
        const client = Publication.init(response);
        console.log(client);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDelete = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/disable/' + company, keys, 'PUT', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchActive = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/enable/' + company, keys, 'PUT', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDelete = (id, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/delete/' + company, {'id': id}, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

}