import ModelJSON from "../ModelJSON";

export default class InvoiceClient extends ModelJSON {
  constructor(id, name, document) {
    super()
    this._id = id;
    this.name = name;
    this.document = document;
  }

  static init(json) {
    return new InvoiceClient(
      json._id,
      json.name,
      json.document
    )
  }
}