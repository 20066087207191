import User from "../Models/Auth/User";
import { pageLinks } from "../Routes/PageLinks";
import AuthRoute from "./Routes/AuthRoute";

export default class APIManager {
  constructor() {
    // this.baseURL = `http://localhost:3004/`
    this.baseURL = `https://projfin.herokuapp.com/`
  }

  fetchJSON = (endPoint, params, method, callback, forceLogout = true) => {

    var user = APIManager.loadCache(User.keyClass)
    var token = ""
    if (APIManager.loadCache(User.keyClass) != null) {
      const user = new User(APIManager.loadCache(User.keyClass))
      token = 'Bearer ' + user.token
    }

    var headers = new Headers({
      "Content-Type": "application/json",
      "X-Custom-Header": "ProcessThisImmediately",
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, Accept',
      'Authorization': token,
      'clientid': user == null ? null : user._id
    });

    var config = {
      method: method,
      headers: headers,
      mode: 'cors',
      cache: 'default',
    };

    if (params != null) {
      let company = APIManager.loadCache("company")
      if (company != null && params['company'] == null) {
        params['company'] = company
      }
      config['body'] = JSON.stringify(params)
    }

    let url = `${this.baseURL}${endPoint}`
    var request = new Request(url, config);

    fetch(request).then(function (response) {
      if (response.ok) {
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then(function (json) {
            console.log(json);
            callback(json);
          });
        } else {
          console.log("Oops, we haven't got JSON!"+request.url);
        }
      } else {
        return response.json().then(function (json) {
          console.log(json);
          if (json.code == 401 && forceLogout) {
            AuthRoute.fetchLogout()
          } else {
            callback(json);
          }
        });
      }
    })
      .catch(function (error) {
        console.log('There has been a problem with your fetch operation: ' + error.message);
      });
  }

  static saveCache(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static removeCache(key) {
    localStorage.removeItem(key)
  }

  static loadCache(key) {
    return JSON.parse(localStorage.getItem(key))
  }
}