import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import PageTitle from '../../Views/Components/PageTitle';
import APIManager from '../../API/APIManager';
import User from '../../Models/Auth/User';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import ChatComponent from './ChatComponent';
import ChatRoute from '../../API/Routes/ChatRoute';
import { io } from "socket.io-client";
import Helpers from '../../Helpers/Helpers';
import QRCode from 'react-qr-code';

class ChatSupportPage extends Component {
    constructor(props) {
        super(props);

        this.user = APIManager.loadCache(User.keyClass)
        this.socket = io(new APIManager().baseURL, { transports: ['websocket'] });
        this.hasCommunicationModule = this.user.company.plan.hasCommunicationModule ?? false

        this.state = {
            chats: [],
            messages: [],
            searchResults: [],
            selectedChat: null,
            text: ""
        }

        this.props.loadingAction(false)
        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
        this.selectChatAction = this.selectChatAction.bind(this)

        if (this.hasCommunicationModule) {
            this.fetchGetChatList(true)
            this.socket.on((this.user.level > 0 ? this.user.company._id : this.user._id), (receive) => {
                if (this.user.level > 0) { this.fetchGetChatList(false) }
                if (this.state.selectedChat != null) { this.selectChatAction(this.state.selectedChat, false) }
            })
        }
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }

    fetchGetChatList = (isLoading) => {
        if (this.user.level > 0) {
            this.props.loadingAction(isLoading)
            let route = new ChatRoute()
            route.fetchGetChatList((chats) => {
                this.props.loadingAction(false)
                this.state.chats = chats
                this.setState({ chats })
            }, this.errorAction)
        } else {
            this.newChatAction({ id: null, title: null })
        }
    }

    selectChatAction = (chat, isLoading) => {
        this.props.loadingAction(isLoading)
        let route = new ChatRoute()
        route.fetchLoadAllMessagesChat(chat.id, (messages) => {
            this.props.loadingAction(false)
            this.state.messages = messages
            this.state.selectedChat = chat
            this.setState({ messages, selectedChat: chat, searchResults: [] })
        }, this.errorAction)
    }

    sendMessageAction = () => {
        this.props.loadingAction(true)
        let route = new ChatRoute()
        route.fetchSendMessageChat(this.state.text, this.state.selectedChat.id, (message) => {
            // this.socket.emit('chatMessage', { data: this.state.selectedChat })
            this.props.loadingAction(false)
        }, this.errorAction)
    }

    newChatAction = (chat) => {
        this.props.loadingAction(true)
        let route = new ChatRoute()
        route.fetchOpenChat(chat.id, chat.title, (chat) => {
            this.props.loadingAction(false)
            this.selectChatAction(chat, true)
        }, this.errorAction)
    }

    searchMessageAction = () => {
        this.props.loadingAction(true)
        let route = new ChatRoute()
        route.fetchSearchClient(this.state.searchText, (searchResults) => {
            this.props.loadingAction(false)
            this.setState({ searchResults })
        }, this.errorAction)
    }

    render() {
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Tudo certo!" desc={"As alterações foram salvas com sucesso!"} isShow={this.state.isShowSuccess} closeAction={() => { window.location.reload() }} closeTitle="Fechar" />
                <PageTitle title="Suporte" />

                <div class="card shadow mb-4" hidden={!this.hasCommunicationModule}>
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">{this.user.level > 0 ? "Chat com Clientes" : "Chat"}</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <ChatComponent
                                chats={this.state.chats}
                                selectedChat={this.state.selectedChat}
                                messages={this.state.messages}
                                searchResults={this.state.searchResults}
                                username={this.state.selectedChat == null ? "" : this.state.selectedChat.title}
                                selectAction={this.selectChatAction}
                                newChatAction={this.newChatAction}
                                updateText={(text) => { this.setState({ text }) }}
                                sendButtonClicked={this.sendMessageAction}
                                searchText={this.state.searchText}
                                updateSearchText={(searchText) => { this.setState({ searchText }) }}
                                searchButtonClicked={this.searchMessageAction}
                                clearSearchButtonClicked={() => { this.setState({ searchText: "", searchResults: [] }) }}
                            />
                        </div>
                    </div>
                </div>

                <div class="card shadow mb-4" hidden={this.user.level == 0}>
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">SAC</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                        <strong><span class="ml-4">Em caso de qualquer dúvida ou problema encontrado, por favor entrar em contato com um de nossos canais de atendimento abaixo:</span></strong><br />
                            <div class="col-sm-5">
                                <div class="float-end mt-3 mt-sm-6">
                                    <div class="col-sm-9" >
                                        <div class="float-end mt-3 mt-sm-6" align="left">
                                            <h8>Email: </h8><br /><br />
                                            <strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{(this.user.company.companyAdmin == null) ? "" : this.user.company.companyAdmin.email ?? ""}</span></strong><br /><br />
                                            <h8>Website: </h8><br /><br />
                                            <strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{(this.user.company.companyAdmin == null) ? "" : this.user.company.companyAdmin.website ?? ""}</span></strong>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="col-sm">
                                <div class="float-end mt-3 mt-sm-6">
                                    <h8>Whatsapp: </h8><br /><br />
                                    <p><QRCode value={"https://wa.me/" + ((this.user.company.companyAdmin == null) ? "" : this.user.company.companyAdmin.whatsapp ?? "")} size={130} fgColor="#000000" /></p>
                                    <a className="pointer btn-sm btn-primary" onClick={() => { window.open("https://wa.me/" + (this.user.company.companyAdmin.whatsapp ?? "")); }} >
                                        <span class="h8 font-weight-bold text-white">Enviar Mensagem</span>
                                    </a>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(ChatSupportPage);