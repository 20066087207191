import React, { Component } from 'react'

export default class SelectLabelOptions extends Component {
    constructor(props) {
        super(props);

        this.qntOptions = this.props.options.length
    }

    clickAction = (event) => { this.props.selectAction(event.target.getAttribute('id')) }

    render() {
        return (
            <div>
                <i class={this.props.icon + ` pointer text-gray-500`} href="#" id="buildDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.text}</i>
                <div className="dropdown-menu dropdown-menu-left shadow fadeIn" aria-labelledby="buildDropdown">
                    {this.props.options.map((option, index) => (
                        <div>
                        <a id={option.id} className="dropdown-item" onClick={this.clickAction}>{option.label}</a>
                        <div className="dropdown-divider" hidden={index == this.qntOptions - 1}></div>
                      </div>
                    ))}
                </div>
            </div>
        )
        
    }
}

//`fas fa-` + `cog` + ` fa-1x pointer text-primary`

//<a className="dropdown-item" href="#">Sede</a>
//<div className="dropdown-divider"></div>