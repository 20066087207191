import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import { pageLinks } from '../../Routes/PageLinks';
import ClientRoute from '../../API/Routes/ClientRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import PublicationRoute from '../../API/Routes/PublicationRoute';
import PublicationItem from '../../Views/Components/PublicationItem';
import Publication from '../../Models/Publication/Publication';
import BarButton from '../../Views/Components/BarButton';
import LoadMore from '../../Views/Components/LoadMore';
import DashboardRoute from '../../API/Routes/DashboardRoute';
import ColoredButton from '../../Views/Components/ColoredButton';

class ClientDashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      items: [],
      selectedItem: '',
      actualPage: 0,
      nextInvoice: null,
      overdueInvoices: null
    }
    this.props.loadingAction(true)
    this.props.setAlert()
    this.newClientAction = this.newClientAction.bind(this)

    this.loadRows = this.loadRows.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.editItemAction = this.editItemAction.bind(this)
  }

  loadRows = (values) => {
    this.setState({ deleteClients: [] })
    this.loadInvoices()
    this.setState(
      {
        items: this.state.items.concat(values.filter((element) => { return element.active })),
        itemsDisabled: values.filter((element) => { return !element.active })
      }
    );
  }

  loadInvoices = () => {
    this.props.loadingAction(true)
    let route = new DashboardRoute()
    route.fetchInfosClient(this.loadedInvoices, this.errorAction)
  }

  loadedInvoices = (invoices) => {
    this.props.loadingAction(false)
    let overdueInvoices = invoices.filter((element) => { return element.status.id == "overdue" })
    let openInvoices = invoices.filter((element) => { return element.status.id == "open" })
    let nextInvoice = openInvoices.length > 0 ? openInvoices[0] : null
    this.setState(
      {
        nextInvoice: nextInvoice,
        overdueInvoices: overdueInvoices
      }
    );
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({ values });
  }

  newClientAction() {
    this.props.history.push(pageLinks.newPublication)
    this.props.history.push({
      pathname: pageLinks.newPublication,
      state: { isEditing: false, publication: new Publication() }
    })
  }

  componentDidMount() {
    let route = new PublicationRoute()
    route.fetchByPage(0, this.loadRows, this.errorAction)
  }

  editItemAction = (item) => {
    this.props.history.push({
      pathname: pageLinks.newPublication,
      state: { isEditing: true, publication: item }
    })
  }

  loadMoreAction = () => {
    this.props.loadingAction(true)
    let route = new PublicationRoute()
    this.state.actualPage += 1
    this.setState({ actualPage: this.state.actualPage });
    route.fetchByPage(this.state.actualPage, this.loadRows, this.errorAction)
  }

  getTable() {
    var rows = new Array()
    const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.items)
    items.forEach(element => {
      const data = <PublicationItem editHidden={true} title={element.title} desc={element.desc} date={element.date} image={element.image64} link={element.link} />
      rows.push(data)
    });
    return rows
  }

  payAction = () => {
    this.props.history.push({
      pathname: pageLinks.clientInvoiceDetail,
      state: {invoice: this.state.nextInvoice, client: this.state.client}
    })
  }

  openInvoicesListAction = () => {
    this.props.history.push({
      pathname: pageLinks.clientInvoiceList
    })
  }

  render() {
    const venciment = this.state.nextInvoice != null ? new Date(this.state.nextInvoice.venciment) : new Date()
    return (
      <div class="container-fluid">
        <PageTitle title="Cobranças" />
        <div class={"alert alert-danger pointer"} onClick={this.openInvoicesListAction} hidden={this.state.overdueInvoices == null || this.state.overdueInvoices.length == 0}><strong>Atenção!  </strong>{(this.state.overdueInvoices?.length > 1 ? "Existem " : "Existe ") + (this.state.overdueInvoices?.length) + (this.state.overdueInvoices?.length > 1 ? " cobranças vencidas" : " cobrança vencida") + ", clique aqui para verificar."}</div>
        <div class="card shadow mb-4" hidden={this.state.nextInvoice == null}>
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Próxima cobrança a ser paga</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="float-end mt-0 mt-sm-6">
                      <h8>Número: </h8><br />
                      <strong><span class="ml-2">{this.state.nextInvoice?.provider.idTransaction}</span></strong>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-2">
                    <div class="float-end mt-0 mt-sm-6">
                      <h8>Tipo: </h8><br />
                      <strong><span class="ml-2">{this.state.nextInvoice?.paymentType.name}</span></strong>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="float-end mt-0 mt-sm-6">
                      <h8>Vencimento: </h8><br />
                      <strong><span class="ml-2">{venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear()}</span></strong>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="float-end mt-0 mt-sm-6">
                      <h8>Valor: </h8><br />
                      <strong><span class="ml-2">{this.state.nextInvoice?.amountTotal}</span></strong>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <a className="pointer colored-button btn-lg btn-primary shadow-sm" onClick={this.payAction} >
                      <span class="h6 ml-4 mr-4 font-weight-bold text-white">Pagar</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PageTitle title="Ultimas Publicações" />
        <div class="row">
          {/* <TopButton title="NOVA PUBLICAÇÃO" icon="plus" clickAction={this.newClientAction} /> */}
        </div>

        <div class="">
          {this.getTable()}
        </div>
        <LoadMore hidden={this.state.items.length < (PublicationRoute.perPageNumber * (this.state.actualPage + 1))} clickAction={this.loadMoreAction} />

      </div>
    )
  }
}

export default withRouter(ClientDashboardPage);