import ModelJSON from "../ModelJSON";

export default class InvoiceType extends ModelJSON {
  constructor(id, title) {
    super()
    this.id = id;
    this.name = title;
    this.info = id == "pix" ?
                "Uma taxa de transação, de 0,5% do valor da cobrança, é cobrada pelo banco quando o pagamento de um PIX é efetuado." :
                "Uma taxa de transação, de R$ 1,96, é cobrada pelo banco quando o pagamento de um boleto é efetuado."
  }

  static init(json) {
    return new InvoiceType(
      json.id,
      json.name
    )
  }
}