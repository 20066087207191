import React, { Component } from 'react'

export default class ItemListWithTitle extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class={"col-sm-" + (this.props.size == null ? 'auto' : this.props.size)} hidden={this.props.value == null}>
                <div class="float-end mt-3 mt-sm-6">
                    <h8>{this.props.title}: </h8><br />
                    <strong><span class="ml-2">{this.props.value}</span></strong>
                </div>
            </div>
        )
    }
}