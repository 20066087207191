import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import { pageLinks } from '../../Routes/PageLinks';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import DatePicker from '../../Views/Components/DatePicker';
import ClientRoute from '../../API/Routes/ClientRoute';
import Invoice from '../../Models/Invoice/Invoice';
import Helpers from '../../Helpers/Helpers';
import CurrencyField from '../../Views/Components/CurrencyTextField';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import PageTitle from '../../Views/Components/PageTitle';
import PlanRoute from '../../API/Routes/PlanRoute';
import ItemListWithTitle from '../../Views/Components/ItemListWithTitle';
import Plan from '../../Models/Plan/Plan';
import PedagogicRoute from '../../API/Routes/PedagogicRoute';

class ClientPlanConnectPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      plan: new Plan(),
      invoice: new Invoice(),
      offices: [],
      enrollments: []
    }
    const client = this.props.location.state.client

    this.props.loadingAction(true)
    this.props.setAlert()
    this.changeState = this.changeState.bind(this)
    this.clearState = this.clearState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.createNewPlan = this.createNewPlan.bind(this)
    this.successAction = this.successAction.bind(this)

    let route = new PlanRoute()
    route.fetchAll(this.loadRows, this.errorAction)
    new PedagogicRoute().fetchAllOffices((offices) => { this.setState({ offices }) }, this.errorAction)
    new PedagogicRoute().fetchAllEnrollmentsByUser(client._id, (enrollments) => { this.setState({ enrollments }) }, this.errorAction)
  }

  loadRows = (values) => {
    this.props.loadingAction(false)
    this.setState(
      {
        items: values.filter((element) => { return element.active })
      }
    );
  }

  changeState = (key, value) => {
    this.state.invoice.change(key, value)
    this.setState({ invoice: this.state.invoice })
    console.log(this.state.invoice);
  }

  changeStateSelectPlan = (key, value) => {
    this.state.invoice.change(key, value)
    this.setState({ invoice: this.state.invoice, plan: this.state.items.filter((item) => { return item._id == value })[0] })
    console.log(this.state.invoice);
  }

  clearState = () => {
    this.setDefaultValues()
    this.setState({ invoice: this.state.invoice, plan: this.state.plan })
  }

  saveAction = () => {
    if (this.invoiceValidate()) {
      this.props.loadingAction(true)
      const route = new InvoiceRoute()
      const client = this.props.location.state.client
      this.state.invoice.clientAddress = client.address
      this.state.invoice.clientName = client.name
      this.state.invoice.clientDocument = client.responsible.document
      this.state.invoice.client = client._id
      this.state.invoice.desc = this.state.plan.desc
      this.state.invoice.totalParcels = this.state.plan.totalParcels
      this.state.invoice.clientAsaasId = client.customerAsaasId

      route.fetchCreate(this.state.invoice, this.successAction, (error) => {
        this.props.loadingAction(false)
        this.props.setAlert('warning', 'Ops!', error)
      })
    } else {
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  setDefaultValues() {
    this.state.plan = new Plan()
    this.state.invoice = new Invoice()
  }

  invoiceValidate = () => {
    var requireds = !Helpers.isEmptyTexts(
      [
        this.state.invoice.plan,
        this.state.invoice.venciment,
        this.state.invoice.office
      ]
    )
    return requireds
  }

  createNewPlan = () => {
    this.props.history.push(pageLinks.newPlan)
  }

  successAction = (document) => {
    this.props.loadingAction(false)
    this.props.history.goBack({
      state: { document: this.props.location.state.client.document, clientCreated: false }
    })
  }

  render() {
    return (
      <div class="container-fluid">
        <PageTitle title="Associar Plano" history={this.props.history} />
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Plano</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
                <div class="row">
                  {this.state.items.length == 0 ? <div /> : <SelectTextField id="plan" defaultValue={this.state.invoice.plan} title="Plano de Pagamento *" isRequired={true} delegate={this.changeStateSelectPlan} width="25ch" options={this.state.items.map((obj) => { return { id: obj._id, label: obj.title } })} />}
                  {<SelectTextField id="office" defaultValue={this.state.invoice.office} title="Unidade *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                  {<SelectTextField id="enrollment" defaultValue={this.state.invoice.enrollment} title="Matrícula" isRequired={false} delegate={this.changeState} width="25ch" options={this.state.enrollments.map((obj) => { return { id: obj._id, label: obj.class.course.name + " - " + obj.class.name + " - " + obj.class.period.name } })} />}
                </div>,
                <div class="row" hidden={this.state.invoice.plan == null}>
                  <ItemListWithTitle title='Valor' value={Helpers.currency(this.state.plan.amount)} />
                  <ItemListWithTitle title='Multa' value={this.state.plan.amountPenalty + '%'} />
                  <ItemListWithTitle title='Juros' value={this.state.plan.amountFees + '%'} />
                  <ItemListWithTitle title='Tipo' value={this.state.plan.paymentType.name} />
                  <ItemListWithTitle title='Recorrência' value={this.state.plan.recurrence.name} />
                  {/* <ItemListWithTitle title='Número de Parcelas' value={this.state.plan.recurrence.id == 'collection' ? this.state.plan.totalParcels + "x" : null} /> */}
                  <ItemListWithTitle title='Descrição' value={this.state.plan.desc} />
                </div>,
                <br />,
                <div class="row" hidden={this.state.invoice.plan == null}>
                  <DatePicker id="venciment" defaultValue={this.state.invoice.venciment} title="Primeiro vencimento" isRequired={true} delegate={this.changeState} width="25ch" />
                  {(this.state.plan.clientPlanRecurrence != "collection" || this.state.plan.paymentTypeId == 'pix') ? <div /> : <SelectTextField id="totalParcels" defaultValue={this.state.plan.totalParcels} title="Quantidade de parcelas *" isRequired={true} delegate={(key, value) => {
                    this.state.plan.change(key, value)
                    this.setState({ plan: this.state.plan })
                  }} width="30ch" options={
                    Array.from(Array(24).keys()).map(item => { return { id: item + 1, label: item + 1 } })
                  } />}
                  <CurrencyField id="amountDiscount" defaultValue={this.state.invoice.amountDiscount} title="Desconto até o vencimento" isRequired={false} delegate={this.changeState} width="20%" />
                </div>,
                <div hidden={this.state.invoice.plan != null}>
                  <p class="text-muted">Selecione um dos planos existêntes ou crie um <span class="m-0 font-weight-bold text-primary pointer" onClick={this.createNewPlan}>novo</span></p>
                </div>,

                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                <SimpleButton title="Limpar" clickAction={this.clearState} />
              ]
            } />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ClientPlanConnectPage);