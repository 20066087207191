import ModelJSON from "../ModelJSON";

export default class BusinessPlan extends ModelJSON {
  constructor(_id, key, title, desc, maxClients, transactionTax, hasCommunicationModule) {
    super()
    this._id = _id
    this.key = key
    this.title = title
    this.desc = desc
    this.maxClients = maxClients
    this.transactionTax = transactionTax
    this.hasCommunicationModule = hasCommunicationModule
  }

  static init(json) {
    return new BusinessPlan(
      json._id,
      json.key,
      json.title,
      json.desc,
      json.maxClients,
      json.transactionTax,
      json.hasCommunicationModule
    )
  }
}