import ModelJSON from "./ModelJSON";

export default class Address extends ModelJSON {
  
  static keyClass = 'address'

  constructor(zipCode, addressStreet, number, complement, city, uf, neighborhood) {
    super()
    this.zipCode = zipCode;
    this.addressStreet = addressStreet;
    this.number = number;
    this.complement = complement;
    this.city = city
    this.uf = uf
    this.neighborhood = neighborhood
  }

  getCompleteAddress(){
    return this.addressStreet + (this.number != "" ? " " + this.number : "") + (this.complement != "" ? ", " + this.complement : "") + ", " + this.neighborhood + ", " + this.city + " - " + this.uf + ", " + this.zipCode
  }

  static init(json) {
    return new Address(
      json.zipCode,
      json.addressStreet,
      json.number,
      json.complement,
      json.city,
      json.uf,
      json.neighborhood
    )
  }
}