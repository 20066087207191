import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import APIManager from "../../API/APIManager";
import Helpers from "../../Helpers/Helpers";

const primaryColor = Helpers.primaryColor()
const styles = {
  root: {
    '& label.Mui-focused': {
      color: primaryColor,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: primaryColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: primaryColor,
      },
    },
  },
  input: {
    color: "black",
  }
};

function CustomizedInputs(props) {
  const { classes } = props;
  var maskValue = props.isLoad ? ((props.defaultValue == null ? "" : props.defaultValue)) : props.defaultValue
  function handleChange(event) {
    props.delegate(props.id, event.target.value.charAt(0) == " " ? event.target.value.substring(1) : event.target.value)
  }

  return (
    <InputMask
    mask={props.mask == null ? '' : props.mask}
    value={maskValue}
    onChange={handleChange}
    disabled={false}
    maskChar=""
    >
    {() => <TextField
      required={props.isRequired}
      type = {props.type}
      id={props.isRequired ? "outlined-required" : "outlined"}
      label={props.title}
      variant="outlined"
      onChange={handleChange}
      value={maskValue}
      className={classes.root}
      textfield-disabled
      style ={{width: props.width, minWidth: "10ch"}}
      InputProps={{
        className: props.isDisabled ? "div-disabled" : classes.input
      }}
    />}
    </InputMask>
  );
}

CustomizedInputs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedInputs);
