import ModelJSON from "../ModelJSON";
import InvoiceType from "../Invoice/InvoiceType";

export default class Plan extends ModelJSON {
  constructor(_id, active, title, desc, amount, amountPenalty, amountFees, totalParcels, recurrence = new InvoiceType(), paymentType = new InvoiceType(), paymentTypeId, clientPlanRecurrence) {
    super()
    this._id = _id
    this.active = active
    this.title = title
    this.desc = desc;
    this.amount = amount;
    this.amountPenalty = amountPenalty;
    this.amountFees = amountFees
    this.totalParcels = totalParcels
    this.recurrence = recurrence
    this.paymentType = paymentType
    this.paymentTypeId = paymentTypeId
    this.clientPlanRecurrence = clientPlanRecurrence
  }

  static init(json) {
    return new Plan(
      json._id,
      json.active,
      json.title,
      json.desc,
      json.amount,
      json.amountPenalty,
      json.amountFees,
      json.totalParcels,
      InvoiceType.init(json.recurrence),
      InvoiceType.init(json.paymentType),
      json.paymentTypeId,
      json.clientPlanRecurrence
    )
  }
}