import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import SelectTextField from './SelectTextField';
import ColoredButton from './ColoredButton';
import SelectLabelOptions from './SelectLabelOptions';
import APIManager from '../../API/APIManager';
import Helpers from '../../Helpers/Helpers';
import BarButton from './BarButton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, titleTable, delegate, fastOptions } = props;
  const createSortHandler = (property) => (event) => {
    // onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {console.log("fastopt: " + fastOptions)}
        <TableCell hidden={fastOptions == null || fastOptions.length == 0} padding="checkbox">
          <CustomCheckbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            color='primary'
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding='default'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  hover: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
const primaryColor = Helpers.primaryColor()

const checkBoxStyles = theme => ({
  root: {
    color: primaryColor,
    '&$checked': {
      color: primaryColor,
    },
  },
  checked: {},
})
const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

// export default class EnhancedTable extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//           classes: {
//             root: {
//               width: '100%',
//             },
//             paper: {
//               width: '100%',
//             },
//             table: {
//               minWidth: 750,
//             },
//             hover: {},
//             visuallyHidden: {
//               border: 0,
//               clip: 'rect(0 0 0 0)',
//               height: 1,
//               margin: -1,
//               overflow: 'hidden',
//               padding: 0,
//               position: 'absolute',
//               top: 20,
//               width: 1,
//             },
//           },
//           order: 'asc',
//           orderBy: this.props.defaultOrderId,
//           selected: [],
//           page: 0,
//           dense: false,
//           rowsPerPage: 10,
//           emptyRows: 0
//         }
//     }

//     setOrder = (value) => {
//       this.state.order = value
//       this.setState({order: this.state.order})
//     };

//     setOrderBy = (value) => {
//       this.state.orderBy = value
//       this.setState({orderBy: this.state.orderBy})
//     };

//     setSelected = (value) => {
//       this.state.selected = value
//       this.setState({selected: this.state.selected})
//     };

//     setPage = (value) => {
//       this.state.page = value
//       this.setState({page: this.state.page})
//     };

//     setDense = (value) => {
//       this.state.dense = value
//       this.setState({dense: this.state.dense})
//     };

//     setRowsPerPage = (value) => {
//       this.state.rowsPerPage = value
//       this.setState({dense: this.state.rowsPerPage})
//     };

//     handleRequestSort = (event, property) => {
//       const isAsc = this.state.orderBy === property && this.state.order === 'asc';
//       this.setOrder(isAsc ? 'desc' : 'asc');
//       this.setOrderBy(property);
//     };

//     handleSelectAllClick = (event) => {
//       if (event.target.checked) {
//         const newSelecteds = this.props.rows.map((n) => n.id);
//         this.setSelected(newSelecteds);
//         this.props.selectedList(newSelecteds)
//         return;
//       }
//       this.setSelected([]);
//     };

//     changeOptionFast = (key, value) => {
//       this.props.selectedFastAction(value)
//     }

//     handleClick = (event, id, isBox) => {
//       if (isBox) {
//         const selectedIndex = this.state.selected.indexOf(id);
//         let newSelected = [];
  
//         if (selectedIndex === -1) {
//           newSelected = newSelected.concat(this.state.selected, id);
//         } else if (selectedIndex === 0) {
//           newSelected = newSelected.concat(this.state.selected.slice(1));
//         } else if (selectedIndex === this.state.selected.length - 1) {
//           newSelected = newSelected.concat(this.state.selected.slice(0, -1));
//         } else if (selectedIndex > 0) {
//           newSelected = newSelected.concat(
//             this.state.selected.slice(0, selectedIndex),
//             this.state.selected.slice(selectedIndex + 1),
//           );
//         }
  
//         this.setSelected(newSelected);
//         this.props.selectedList(newSelected)
//       } else {
//         this.props.delegate(id)
//       }
//     };

//     handleChangePage = (event, newPage) => {
//       this.setPage(newPage);
//     };

//     handleChangeRowsPerPage = (event) => {
//       this.setRowsPerPage(parseInt(event.target.value, 10));
//       this.setPage(0);
//     };

//     handleChangeDense = (event) => {
//       this.setDense(event.target.checked);
//     };
    
//     isSelected = (id) => this.state.selected.indexOf(id) !== -1;

//     setEmptyRows = () => {
//       this.setState({
//         emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.rows.length - this.state.page * this.state.rowsPerPage)
//       })
//     }

//     render() {
//       const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.rows.length - this.state.page * this.state.rowsPerPage)
//       return (
//         <div className={this.state.classes?.root}>
//           <div class="card-header py-3">
//             <h6 class="m-0 font-weight-bold text-primary">{this.props.titleTable}</h6>
//           </div>
//           <div class="card-body">
//             {this.props.components}
//             <div class="table-responsive">
//               <Paper className={this.state.classes?.paper}>
//                 {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
//                 <TableContainer>
//                   <Table
//                     className={this.state.classes?.table}
//                     aria-labelledby="tableTitle"
//                     size={this.state.dense ? 'small' : 'medium'}
//                     aria-label="enhanced table"
//                   >
//                     <EnhancedTableHead
//                       classes={this.state.classes}
//                       numSelected={this.state.selected?.length}
//                       order={this.state.order}
//                       orderBy={this.state.orderBy}
//                       onSelectAllClick={this.state.handleSelectAllClick}
//                       onRequestSort={this.state.handleRequestSort}
//                       rowCount={this.props.rows.length}
//                       headCells={this.props.headCells}
//                       titleTable={this.props.titleTable}
//                       delegate={this.props.delegate}
//                       fastOptions={this.props.fastOptions}
//                     />
//                     <TableBody>
//                       {stableSort(this.props.rows, getComparator(this.state.order, this.state.orderBy))
//                         .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
//                         .map((row, index) => {
//                           const isItemSelected = this.isSelected(row.id);
//                           const labelId = `enhanced-table-checkbox-${index}`;
    
//                           return (
//                             <TableRow
//                               hover
//                               role="checkbox"
//                               aria-checked={isItemSelected}
//                               tabIndex={-1}
//                               key={row.id}
//                               classes={{ selected: this.state.classes.selected }}
//                               className={this.state.classes.tableRow}
//                             // selected={isItemSelected}
//                             >
//                               <TableCell hidden={this.props.fastOptions.length == 0} padding="checkbox">
//                                 <CustomCheckbox
//                                   onClick={(event) => this.handleClick(event, row.id, true)}
//                                   checked={isItemSelected}
//                                   inputProps={{ 'aria-labelledby': labelId }}
//                                   className={this.state.classes.checkbox}
//                                   color="primary"
//                                 />
//                               </TableCell>
//                               {/* <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
//                             {row.id}
//                           </TableCell> */}
//                               {
//                                 Object.entries(row).map(([key, value]) =>
//                                   <TableCell hidden={(this.props.headCells.filter(x => x.id == key)[0].isHidden)} onClick={(event) => this.handleClick(event, row.id, false)} className={this.state.classes.tableCell} component="th" id={labelId} scope="row" align="left"><div className={(this.props.headCells.filter(x => x.id == key)[0].isButton) ? "simple-button" : ""}>{value}</div></TableCell>
//                                 )
//                               }
//                               <TableCell hidden={this.props.individualOptions.length == 0} className={this.state.classes.tableCell} align="right">
//                                 <div class="col-auto">
//                                   <SelectLabelOptions icon={`fas fa-cog fa-1x`} options={this.props.individualOptions} selectAction={(id) => {this.props.selectedIndividualAction({optionId: id, rowId: row.id})}}></SelectLabelOptions>
//                                 </div>
//                               </TableCell>
//                             </TableRow>
//                           );
//                         })}
//                       {emptyRows > 0 && (
//                         <TableRow classes={{ selected: this.state.classes.selected }} className={this.state.classes.tableRow} style={{ height: (this.state.dense ? 33 : 53) * emptyRows }}>
//                           <TableCell className={this.state.classes.tableCell} colSpan={6} />
//                         </TableRow>
//                       )}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//                 <TablePagination
//                   labelRowsPerPage="Quantidade por página"
//                   rowsPerPageOptions={[5, 10, 25, 50, 100, this.props.rows.length].sort(function (a, b) { return a - b })}
//                   component="div"
//                   count={this.props.rows.length}
//                   rowsPerPage={this.state.rowsPerPage}
//                   page={this.state.page}
//                   onChangePage={this.handleChangePage}
//                   onChangeRowsPerPage={this.handleChangeRowsPerPage}
//                 />
//               </Paper>
//               {/* <FormControlLabel
//             control={<Switch checked={dense} onChange={handleChangeDense} />}
//             label="Dense padding"
//           /> */}
//             </div>
//             <div hidden={this.props.fastOptions == null || this.props.fastOptions.count == 0}>
//               {this.props.rows.length == 0 || this.props.fastOptions.length == 0 ? <div/> : <SelectTextField id="fastOptions" defaultValue={-1} title="Ações Rápidas" isRequired={false} width="30ch" delegate={this.state.changeOptionFast} options={[]} options={this.props.fastOptions} />}
//             </div>
//           </div>
//         </div>
//       );
//     }
// }

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.defaultOrderId);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultRowsPerPage ?? 50);
  const [resizableIsShowView, setResizableShowView] = React.useState(props.defaultResizableShow ?? true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
      props.selectedList(newSelecteds)
      return;
    }
    setSelected([]);
  };

  const changeOptionFast = (key, value) => {
    props.selectedFastAction(value)
  }

  const changeOptionFastOne = (value) => {
    props.selectedFastAction(props.fastOptions[0].id)
  }

  const handleClick = (event, id, isBox) => {
    if (isBox) {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      props.selectedList(newSelected)
    } else {
      props.delegate(id)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = 0 //rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div class={props.showBarResize ? "card-header py-3 d-flex flex-row align-items-center justify-content-between" : "card-header py-3"}>
        <h6 class="m-0 font-weight-bold text-primary">{props.titleTable}</h6>
        {props.showBarResize ? <BarButton title={<i class={`fas fa-` + (resizableIsShowView ? `chevron-up` : `chevron-down`) + ` fa-1x`}></i>} clickAction={() => { setResizableShowView(!resizableIsShowView) }} /> : <div/>}
      </div>
      <div class="card-body" hidden={!resizableIsShowView}>
        {props.components}
        <div class="table-responsive">
          <Paper className={classes.paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.rows.length}
                  headCells={props.headCells}
                  titleTable={props.titleTable}
                  delegate={props.delegate}
                  fastOptions={props.fastOptions}
                />
                <TableBody>
                  {stableSort(props.rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          classes={{ selected: classes.selected }}
                          className={classes.tableRow}
                        // selected={isItemSelected}
                        >
                          <TableCell hidden={props.fastOptions.length == 0} padding="checkbox">
                            <CustomCheckbox
                              onClick={(event) => handleClick(event, row.id, true)}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              className={classes.checkbox}
                              color="primary"
                            />
                          </TableCell>
                          {/* <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell> */}
                          {
                            Object.entries(row).map(([key, value]) => {
                              return <TableCell hidden={(props.headCells.filter(x => x.id == key)[0].isHidden)} onClick={(event) => handleClick(event, row.id, false)} className={classes.tableCell} component="th" id={labelId} scope="row" align="left"><div className={(props.headCells.filter(x => x.id == key)[0].isButton) ? "simple-button" : ""}>{value}</div></TableCell>
                            })
                          }
                          <TableCell hidden={props.individualOptions.length == 0} className={classes.tableCell} align="right">
                            <div class="col-auto">
                              <SelectLabelOptions icon={`fas fa-cog fa-1x`} options={props.individualOptions} selectAction={(id) => {props.selectedIndividualAction({optionId: id, rowId: row.id})}}></SelectLabelOptions>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow classes={{ selected: classes.selected }} className={classes.tableRow} style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell className={classes.tableCell} colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Quantidade por página"
              rowsPerPageOptions={[5, 10, 25, 50, 100, props.rows.length].sort(function (a, b) { return a - b })}
              component="div"
              count={props.rows.length}
              hidden = {props.isHidePagination}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        </div>
        <div hidden={props.buttonTitle == null}>
        <ColoredButton title={props.buttonTitle} clickAction={props.buttonAction} />
        <br/><br/>
        </div>
        <div hidden={props.fastOptions == null || props.fastOptions.length == 1 || props.fastOptions.length == 0}>
          {props.rows.length == 0 || props.fastOptions.length == 0 ? <div/> : <SelectTextField id="fastOptions" defaultValue={-1} title="Ações Rápidas" isRequired={false} width="30ch" delegate={changeOptionFast} options={props.fastOptions} />}
        </div>
        <div hidden={props.fastOptions == null || props.fastOptions.length > 1 || props.fastOptions.length == 0}>
          <a className="pointer btn-sm btn-primary" onClick={changeOptionFastOne} >
              {props.fastOptions.length == 0 ? <div/> : <span class="h8 font-weight-bold text-white">{props.fastOptions[0].label}</span>}
            </a>
        </div>
      </div>
    </div>
  );
}