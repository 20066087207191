import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import { pageLinks } from '../../Routes/PageLinks';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import DatePicker from '../../Views/Components/DatePicker';
import ClientRoute from '../../API/Routes/ClientRoute';
import Invoice from '../../Models/Invoice/Invoice';
import Helpers from '../../Helpers/Helpers';
import CurrencyField from '../../Views/Components/CurrencyTextField';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import PageTitle from '../../Views/Components/PageTitle';
import User from '../../Models/Auth/User';
import APIManager from '../../API/APIManager';
import PedagogicRoute from '../../API/Routes/PedagogicRoute';

class NewInvoicePage extends Component {
  constructor(props) {
    super(props)

    this.user = APIManager.loadCache(User.keyClass)
    this.company = this.user.company
    const client = this.props.location.state.client

    this.state = {
      invoice: this.props.location.state.invoiceRenegociation ?? new Invoice(),
      isRenegociationInvoiceId: this.props.location.state.isRenegociationInvoiceId,
      offices: [],
      enrollments: []
    }

    this.props.loadingAction(false)
    this.props.setAlert()
    this.changeState = this.changeState.bind(this)
    this.clearState = this.clearState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.successAction = this.successAction.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.setDefaultValues()

    new PedagogicRoute().fetchAllOffices((offices)=>{ this.setState({ offices })}, this.errorAction)
    new PedagogicRoute().fetchAllEnrollmentsByUser(client._id, (enrollments)=>{ this.setState({ enrollments })}, this.errorAction)
  }

  typeInvoiceBillet = [
    { id: 'only', label: 'Cobrança Única' },
    { id: 'collection', label: 'Carnê de Cobrança' }
  ]

  typeInvoicePix = [
    { id: 'only', label: 'Cobrança Única' }
  ]

  changeState = (key, value) => {
    this.state.invoice.change(key, value)
    this.setState({ invoice: this.state.invoice })
    console.log(this.state.invoice);
  }

  clearState = () => {
    this.setDefaultValues()
    this.setState({ invoice: this.state.invoice })
  }

  saveAction = () => {
    if (this.invoiceValidate()) {
      this.props.loadingAction(true)
      const route = new InvoiceRoute()
      const client = this.props.location.state.client
      console.log(client);
      this.state.invoice.isMonthlyRecurrence = (this.state.invoice.recurrence == 'monthly')
      this.state.invoice.clientAddress = client.address
      this.state.invoice.clientName = client.name
      this.state.invoice.clientDocument = client.responsible.document
      this.state.invoice.clientAsaasId = client.customerAsaasId
      this.state.invoice.client = client._id

      route.fetchCreate(this.state.invoice, this.successAction, (error) => {
        this.props.loadingAction(false)
        this.props.setAlert('warning', 'Ops!', error)
      })
    } else {
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  setDefaultValues() {
    this.state.invoice.paymentTypeId = 'billet'
    this.state.invoice.recurrence = 'only'
    this.state.invoice.amountDiscount = 0
    this.state.invoice.amountFees = 0
    this.state.invoice.amountPenalty = 0
    this.state.invoice.totalParcels = 1
  }

  invoiceValidate = () => {
    var requireds = !Helpers.isEmptyTexts(
      [
        this.state.invoice.desc,
        this.state.invoice.amount,
        this.state.invoice.venciment,
        this.state.invoice.paymentTypeId,
        this.state.invoice.recurrence,
        this.state.invoice.office
      ]
    )
    return requireds
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  successAction = (document) => {
    let cancelInvoiceRenegociationId = this.state.isRenegociationInvoiceId
    if (cancelInvoiceRenegociationId != null) {
      new InvoiceRoute().fetchCancelItems({"ids": [cancelInvoiceRenegociationId]}, () => {}, this.errorAction)
    }
    this.props.loadingAction(false)
    this.props.history.push({
      pathname: pageLinks.clientDetail,
      state: { document: this.props.location.state.client.document, clientCreated: false }
    })
  }

  render() {
    this.state.invoice.amountPenalty = this.state.invoice.amountPenalty == 0 ? this.company.amountPenalty : this.state.invoice.amountPenalty
    this.state.invoice.amountFees = this.state.invoice.amountFees == 0 ? this.company.amountFees : this.state.invoice.amountFees
    return (
      <div class="container-fluid">
        <PageTitle title="Nova Cobrança" history={this.props.history}/>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Informações da Cobrança</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
                <div>
                  <SimpleTextField id="desc" defaultValue={this.state.invoice.desc} title="Descrição" isRequired={true} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                  <DatePicker id="venciment" defaultValue={this.state.invoice.venciment} title="Vencimento" isRequired={true} delegate={this.changeState} width="25ch" />
                  {/* <SelectTextField id="paymentTypeId" defaultValue={this.state.invoice.paymentTypeId} title="Tipo de Cobrança *" isRequired={true} delegate={this.changeState} width="25ch" options={[
                    { id: 'billet', label: 'Boleto' },
                    { id: 'pix', label: 'PIX' },
                    // { id: 'billetpix', label: 'Boleto + PIX' },
                  ]} /> */}
                  <SelectTextField id="recurrence" defaultValue={this.state.invoice.recurrence} title="Tipo de Recorrência *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.invoice.paymentTypeId == 'pix' ? this.typeInvoicePix : this.typeInvoiceBillet} />
                </div>,
                <div>
                  <CurrencyField id="amount" defaultValue={this.state.invoice.amount} title="Valor" isRequired={true} delegate={this.changeState} width="20%" />
                  <CurrencyField id="amountDiscount" defaultValue={this.state.invoice.amountDiscount} title="Desconto até o vencimento" isRequired={false} delegate={this.changeState} width="20%" />
                  <CurrencyField id="amountPenalty" defaultValue={this.state.invoice.amountPenalty} title="Multa por atraso" symbol="%" isRequired={false} delegate={this.changeState} width="20%" />
                  <CurrencyField id="amountFees" defaultValue={this.state.invoice.amountFees} title="Juros ao mês" symbol="%" isRequired={false} delegate={this.changeState} width="20%" />
                  <br></br>
                  {<SelectTextField id="office" defaultValue={this.state.invoice.office} title="Unidade *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                  {<SelectTextField id="enrollment" defaultValue={this.state.invoice.enrollment} title="Matrícula" isRequired={false} delegate={this.changeState} width="25ch" options={this.state.enrollments.map((obj) => { return { id: obj._id, label: obj.class.course.name + " - " + obj.class.name + " - " + obj.class.period.name } })} />}
                </div>,
                <div hidden={this.state.invoice.recurrence != "collection" || this.state.invoice.paymentTypeId == 'pix'}>
                  {(this.state.invoice.recurrence != "collection" || this.state.invoice.paymentTypeId == 'pix') ? <div/> : <SelectTextField id="totalParcels" defaultValue={this.state.invoice.totalParcels} title="Quantidade de parcelas *" isRequired={true} delegate={this.changeState} width="30ch" options={
                    Array.from(Array(24).keys()).map(item => { return { id: item + 1, label: item + 1 } })
                  } />}
                </div>,
                <div >
                  <p class="text-muted"><strong>* OBS:</strong><span class="ml-2">{"O valor mínimo para uma cobrança é de R$ 5,00"}</span></p>
                </div>,

                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                <SimpleButton title="Limpar" clickAction={this.clearState} />
              ]
            } />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(NewInvoicePage);