import React from 'react'
import { Link } from "react-router-dom";
import { Component } from "react";

export default class LeftMenuButtonCell extends Component {

  render() {
    return (
      <li className={this.props.viewModel.getStatus()} hidden={this.props.hidden}>
          <Link className="nav-link" to={this.props.viewModel.pageLink} onClick={this.props.viewModel.bindAction.bind(this, this)}>
            <i className={this.props.viewModel.pageIcon}></i>
          <span>{this.props.viewModel.pageTitle}</span>
          </Link>
      </li>
    )
  }
}