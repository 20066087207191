import ClientStatus from "../Client/ClientStatus";
import ModelJSON from "../ModelJSON";
import ClassPedagogic from "./Class";
import EnrollmentStatus from "./EnrollmentStatus";

export default class EnrollmentPedagogic extends ModelJSON {
  constructor(id, user, clas = new ClassPedagogic(), status = new EnrollmentStatus()) {
    super()
    this._id = id;
    this.user = user;
    this.class = clas;
    this.status = status;
  }

  static init(json) {
    return new EnrollmentPedagogic(
      json._id,
      json.user,
      json.class,
      EnrollmentStatus.init(json.status)
    )
  }
}