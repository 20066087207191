import ModelJSON from "../ModelJSON";

export default class InvoiceStatus extends ModelJSON {
  constructor(id, title, color, backColor) {
    super()
    this.id = id;
    this.name = title;
    this.color = color
    this.backColor = backColor
  }

  static init(json) {
    return new InvoiceStatus(
      json.id,
      json.name,
      json.color,
      json.backColor
    )
  }
}