import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import Helpers from '../../../Helpers/Helpers';

export default class DashChartLine extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const primaryColor = Helpers.primaryColor()
    const chartValues = {

      series: [{
        name: 'Valor (R$)',
        data: this.props.data
      }],
      options: {
        colors: [primaryColor],
        chart: {
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'month',
          categories: this.props.classes
        },

      },


    };
    return (
      <div class="col-xl-8 col-lg-7" hidden={this.props.hidden}>
        <div class="cardChart shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">{this.props.title}</h6>
            <div class="dropdown no-arrow">
              <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                <div class="dropdown-header">Dropdown Header:</div>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-area">
              <Chart options={chartValues.options} series={chartValues.series} type="area" height={320} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}