import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { OutlinedInput } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import APIManager from "../../API/APIManager";
import Helpers from "../../Helpers/Helpers";

const primaryColor = Helpers.primaryColor()
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  inputLabel: {
    "&.Mui-focused": {
      color: primaryColor
    }
  }
}));
const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
    },
    "&:hover $notchedOutline": {
      borderColor: primaryColor
    },
    "&$focused $notchedOutline": {
      borderColor: primaryColor
    }
  },
  focused: {},
  notchedOutline: {}
}));

export default function CustomizedForms(props) {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [values, setValues] = React.useState({
    value: props.defaultValue,
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    props.delegate(props.id, event.target.value)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel className={classes.inputLabel} ref={inputLabel} htmlFor="outlined-age-simple">{props.title}</InputLabel>
        <Select
          value={props.defaultValue}
          onChange={handleChange}
          disabled={props.disabled}
          style ={{width: props.width, minWidth: "15ch"}}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              name="value"
              id="outlined-age-simple"
              classes={outlinedInputClasses}
            />
          }
        >
        <MenuItem value=""><em>{'Selecione'}</em></MenuItem>

        {props.options != null && props.options.map((option) => (
          <MenuItem value={option.id}>{option.label}</MenuItem>
        ))}
        </Select>
      </FormControl>
  );
}