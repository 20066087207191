import React, { Component } from 'react'

export default class BarButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a className="pointer colored-button btn-lg btn-tertiary" onClick={this.props.clickAction} hidden={this.props.hidden} >
                <span class="h8 ml-2 mr-2 font-weight-bold text-primary">{this.props.title}</span>
            </a>
        )
    }
}