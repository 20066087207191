import Address from "../Address";
import ModelJSON from "../ModelJSON";

export default class Responsible extends ModelJSON {
  constructor(id, name, document, phone, email, address) {
    super()
    this.id = id;
    this.name = name;
    this.document = document;
    this.phone = phone;
    this.email = email;
    this.address = address;
  }

  static init(json) {
    return new Responsible(
      json.id,
      json.name,
      json.document,
      json.phone,
      json.email,
      Address.init(json.address)
    )
  }
}