import ModelJSON from "../ModelJSON";

export default class PeriodPedagogic extends ModelJSON {
  constructor(id, name, year, type) {
    super()
    this._id = id;
    this.name = name
    this.year = year;
    this.type = type;
  }

  static init(json) {
    return new PeriodPedagogic(
      json._id,
      json.name,
      json.year,
      json.type
    )
  }
}