import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PeriodPedagogic from '../../../Models/Pedagogic/Period';
import APIManager from '../../../API/APIManager';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import ColoredButton from '../../../Views/Components/ColoredButton';
import PageTitle from '../../../Views/Components/PageTitle';
import FormComponent from '../../../Views/Components/FormComponent';
import SelectTextField from '../../../Views/Components/SelectTextField';
import SimpleTextField from '../../../Views/Components/SimpleTextField';
import ClassPedagogic from '../../../Models/Pedagogic/Class';
import CoursePedagogic from '../../../Models/Pedagogic/Course';
import OfficePedagogic from '../../../Models/Pedagogic/Office';
import EnrollmentPedagogic from '../../../Models/Pedagogic/Enrollment';

class NewEnrollmentPage extends Component {
    constructor(props) {
        super(props)

        this.user = APIManager.loadCache(User.keyClass)
        this.company = this.user.company

        this.props.setAlert()
        this.state = {
            enrollment: new EnrollmentPedagogic(),
            periods: [],
            courses: [],
            coursesFiltered: [],
            offices: [],
            classes: [],
            classesFiltered: [],
            levels: [],
            selectedCourseLevel: null
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
        this.fetchStacks()
    }

    fetchStacks = () => {
        let route = new PedagogicRoute()
        route.fetchAllPeriods((periods)=>{ this.setState({ periods })}, this.errorAction)
        route.fetchAllOffices((offices)=>{ this.setState({ offices })}, this.errorAction)
        route.fetchAllCourses((courses)=>{ this.setState({ courses })}, this.errorAction)
        route.fetchAllClasses((classes)=>{ this.setState({ classes })}, this.errorAction)
        route.fetchCourseLevelList((levels)=>{ this.setState({ levels })}, this.errorAction)
    }

    changeState = (key, value) => {
        this.state.enrollment.change(key, value)
        this.setState({ enrollment: this.state.enrollment })
    }

    loadedPeriod = (period) => {
        this.props.loadingAction(false)
        this.setState({period: period});
    }

    errorAction = (values) => {
        this.setState({values});
    }

    changeStateCourseLevel = (key, value) => {
        this.state.selectedCourseLevel = value
        this.setState({ selectedCourseLevel: this.state.selectedCourseLevel, coursesFiltered: this.state.courses.filter(item => { return ((item.level.id == value)) }) })
    }

    changeStateCourse = (key, value) => {
        this.state.enrollment.change(key, value)
        this.setState({ enrollment: this.state.enrollment, classesFiltered: this.state.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.enrollment.period)) }) })
    }

    saveAction = () => {
        if (this.periodValidate()) {
            this.props.loadingAction(true)
            const client = this.props.location.state.client
            this.state.enrollment.user = client._id
            const route = new PedagogicRoute()
            console.log(this.state.enrollment);
            route.fetchCreateEnrollment(this.state.enrollment, this.successAction, (error) => {
                this.props.setAlert('warning', 'Ops!', error)
            })
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    successAction = (enrollment) => {
        this.props.history.push({
          pathname: pageLinks.clientDetail,
          state: {document: Helpers.onlyNumbers(this.props.location.state.client.document), enrollment: enrollment, enrollmentCreated: true}  
        })
      }

    periodValidate = () => {
        return (typeof this.state.enrollment.class === "string")
    }

    render() {
        if (this.state.enrollment.period == null && this.state.periods.length > 0) {
            this.state.enrollment.period = (this.state.periods[0]._id)
            this.setState({enrollment: this.state.enrollment})
        }
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja desativar este periodo letivo?"} isShow={this.state.isCancelling} defaultAction={this.cancelPeriodAction} closeAction={() => { this.setState({ isCancelling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja ativar este periodo letivo?"} isShow={this.state.isEnabling} defaultAction={this.enablePeriodAction} closeAction={() => { this.setState({ isEnabling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <PageTitle title="Informações da Turma" history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    {<SelectTextField id="period" defaultValue={this.state.enrollment.period ?? (this.state.periods.length > 0 ? this.state.periods[0]._id : 1)} title="Período Letivo *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                    {<SelectTextField id="office" defaultValue={this.state.enrollment.office ?? 1} title="Unidade *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                    {<SelectTextField id="selectedCourseLevel" defaultValue={this.state.selectedCourseLevel ?? -1} title="Nível *" isRequired={true} delegate={this.changeStateCourseLevel} width="25ch" options={this.state.levels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
                                    {<SelectTextField id="course" defaultValue={this.state.enrollment.course ?? 1} title="Curso *" isRequired={true} delegate={this.changeStateCourse} width="25ch" options={this.state.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                    {<SelectTextField id="class" defaultValue={this.state.enrollment.class ?? 1} title="Turma *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.classesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                </div>,

                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                // <SimpleButton title="Limpar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewEnrollmentPage);