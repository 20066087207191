import React, { Component } from 'react'
import UIAvatar from 'react-ui-avatars';
import Helpers from '../../Helpers/Helpers'

export default class ChatMessageItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const primaryColor = Helpers.primaryColor()
    const backColor = Helpers.primaryDetailColor()
    return (
        <div class={"chat-message-" + (this.props.isReveicedMessage ? "left pb-4" : "right mb-4")}>
        <div>
            <UIAvatar className="img-profile rounded-circle mr-1" width="40" height="40" name={this.props.username} color={this.props.isReveicedMessage ? primaryColor : '#ffffff'} background={this.props.isReveicedMessage ? '#dddddd' : primaryColor}/>
            <div class="text-muted small text-nowrap mt-2">{this.props.messageDate}</div>
        </div>
        <div class={"flex-shrink-1 bg-light rounded py-2 px-3 " + (this.props.isReveicedMessage ? "ml-3" : "mr-3")}>
            <div class="font-weight-bold mb-1">{this.props.username}</div>
            {this.props.messageText}
        </div>
    </div>
    )
  }
}