import { Link } from '@material-ui/core';
import React, { Component } from 'react'
import APIManager from '../../../API/APIManager';
import AuthRoute from '../../../API/Routes/AuthRoute';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import LeftMenuButtonViewModel from '../LeftMenu/LeftMenuButtonViewModel';
import ReactDOM from 'react-dom';
import UIAvatar from 'react-ui-avatars';
import Helpers from '../../../Helpers/Helpers'
import TopMenuButtonCell from './TopMenuButtonCell';
import ChatRoute from '../../../API/Routes/ChatRoute';
import { io } from "socket.io-client";
import ChatItemTopMenu from './ChatItemTopMenu';

export default class TopMenu extends Component {

  constructor(props) {
    super(props);

    this.user = APIManager.loadCache(User.keyClass)
    const hasCommunicationModule = this.user.company.plan.hasCommunicationModule
    this.showChat = (hasCommunicationModule && (this.user.level == 0 || this.user.level == 5 || this.user.level == 3))
    this.socket = io(new APIManager().baseURL, { transports : ['websocket'] });

    this.state = {
      pages: {
        profile: new LeftMenuButtonViewModel("Meu Usuário", pageLinks.userConfig, "fas fa-user", this.profileAction),
        settings: new LeftMenuButtonViewModel("Configurações da Empresa", pageLinks.companyConfig, "fas fa-cogs", this.settingsAction),
        logout: new LeftMenuButtonViewModel("Sair", pageLinks.dashboard, "fas fa-sign-out-alt", this.logoutAction),
      },
      badge: 0,
      chats: []
    }

    Object.values(this.state.pages).forEach((item) =>
      item.isSelectedPage = (window.location.pathname.includes(item.pageLink))
    )

    this.profileAction = this.logoutAction.bind(this)
    this.settingsAction = this.logoutAction.bind(this)
    this.logoutAction = this.logoutAction.bind(this)
    this.fetchGetBadgeValue = this.fetchGetBadgeValue.bind(this)

    if (this.showChat) {
      this.fetchGetBadgeValue()

      this.socket.on((this.user.level > 0 ? this.user.company._id : this.user._id), ( receive ) => {
        this.fetchGetBadgeValue()
      })
    }
  }

  fetchGetBadgeValue = () => {
    let route = new ChatRoute()
    route.fetchBadgeChat((response) => {
      this.state.badge = response.badge
      console.log(response);
      this.state.chats = response.chats
      this.setState({ badge: response.badge, chats: response.chats })
    }, {})
  }

  profileAction = (page) => { }
  settingsAction = (page) => {
    Object.values(this.state.pages).forEach((item) =>
      item.isSelectedPage = (page.props.viewModel === item)
    )
    this.setState({ pages: this.state.pages })
  }
  logoutAction = (page) => { AuthRoute.fetchLogout() }

  render() {
    const layout = APIManager.loadCache("companyLayout")
    const logoImg = layout.logo
    const primaryColor = Helpers.primaryColor()
    const user = APIManager.loadCache(User.keyClass);
    if (user == null) { AuthRoute.fetchLogout() }
    const chats = user.level > 0 
    ? this.state.chats == null ? null : this.state.chats.map((chat) => <ChatItemTopMenu chat={chat} isSelectedChat={false} selectAction={(chat)=>{}}/>)
    : [<ChatItemTopMenu chat={{id: '', title: "Suporte", lastMessageDate: "Canal de Atendimento", messagesNotReadCount: 0}} isSelectedChat={false} selectAction={null}/>]
    return (
      <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow">

        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={this.props.toogleAction}>
          <i className="fa fa-bars"></i>
        </button>

        {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input type="text" className="form-control bg-light border-0 small" placeholder="Buscar" aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form> */}

        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item dropdown no-arrow d-sm-none">
            <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-search fa-fw"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input type="text" className="form-control bg-light border-0 small" placeholder="Buscar" aria-label="Search" aria-describedby="basic-addon2" />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li> */}

          <li hidden={!this.showChat} className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-comments fa-fw"></i>
              <span hidden={this.state.badge == 0} className="badge badge-danger badge-counter">{this.state.badge}</span>
            </a>
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
              <h6 className="dropdown-header">
                Chats
              </h6>
              {chats}
              <a className="dropdown-item text-center small text-gray-500" href="/support">Ver mais</a>
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user.name}</span>
              <UIAvatar className="img-profile rounded-circle" name={user.name} color={primaryColor} />
            </a>
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <TopMenuButtonCell viewModel={this.state.pages.profile} />
              <TopMenuButtonCell hidden={user.level < 5} viewModel={this.state.pages.settings} />
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" data-toggle="modal" data-target="#logoutModal" onClick={this.state.pages.logout.bindAction}>
                <i className={this.state.pages.logout.pageIcon + " fa-sm fa-fw mr-2 text-gray-400"}></i>
                <span>{this.state.pages.logout.pageTitle}</span>
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    )
  }
}