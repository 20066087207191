import Plan from "../../Models/Plan/Plan"
import APIManager from "../APIManager"

export default class PlanRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `clientplans`
  }

  fetchCreate = (plan, callback, error) => {
    plan.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint, plan, 'POST', function (response) {
      try {
        const plan = Plan.init(response);
        console.log(plan);
        callback(plan);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdate = (plan, callback, error) => {
    plan.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint, plan, 'PUT', function (response) {
      console.log(response);
      try {
        const plan = Plan.init(response);
        callback(plan);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAll = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company, null, 'GET', function (response) {
      try {
        var plans = new Array()
        response.forEach(element => {
          const plan = Plan.init(element);
          plans.push(plan)
        });
        callback(plans);
      } catch {
        error(response.message);
      }
    })
  }

  fetchPlanDetail = (key, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/` + key, null, 'GET', function (response) {
      try {
        const client = Plan.init(response);
        console.log(client);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDelete = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/disable/' + company, keys, 'PUT', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchActive = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/enable/' + company, keys, 'PUT', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

}