import ModelJSON from "../ModelJSON";
import activeImage from '../../Assets/user_active_icon.png';
import blockedImage from '../../Assets/user_blocked_icon.png';
import deletedImage from '../../Assets/user_deleted_icon.png';

export default class ClientStatus extends ModelJSON {
  constructor(id, title, color, backColor, iconName) {
    super()
    this.id = id;
    this.title = title;
    this.color = color
    this.backColor = backColor
    this.iconName = iconName
  }

  static init(json) {
    return new ClientStatus(
      json.id,
      json.title,
      json.color,
      json.backColor,
      json.iconName
    )
  }

  image(){
    switch (this.id) {
      case 'active': return activeImage
      case 'blocked': return blockedImage
      case 'deleted': return deletedImage
      case 'conclued': return deletedImage
      default:
        break;
    }
  }
}