import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import FormComponent from '../../Views/Components/FormComponent';
import AuthRoute from '../../API/Routes/AuthRoute';
import UserRequestLogin from '../../Models/Auth/UserRequestLogin';
import User from '../../Models/Auth/User';
import APIManager from '../../API/APIManager';
import { pageLinks } from '../../Routes/PageLinks';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import LoadingOverlay from 'react-loading-overlay';
import Helpers from '../../Helpers/Helpers';

class SignInPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userAuth: new UserRequestLogin(),
      errorMessage: null,
      isLoading: false,
      layout: null
    }

    // this.props.setAlert()

    this.changeState = this.changeState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.forgotAction = this.saveAction.bind(this)
    this.showErrorAction = this.showErrorAction.bind(this)
    this.loggedAction = this.loggedAction.bind(this)
    this.overdueAction = this.overdueAction.bind(this)
    this.loadedLayout = this.loadedLayout.bind(this)

    this.state.userAuth.company = APIManager.loadCache("company")
    this.setState({userAuth: this.state.userAuth })
    this.setState({ isLoading: true })
    new AuthRoute().fetchCompanyLayout(APIManager.loadCache("company"), this.loadedLayout, this.showErrorAction)
  }

  loadedLayout = (layout) => {
    APIManager.saveCache("companyLayout", layout)
    this.setState({ layout: layout, isLoading: false })
    this.setLayout()
  }

  setLayout() {
    let root = document.documentElement
    root.style.setProperty('--primary', Helpers.primaryColor());
    root.style.setProperty('--primary-hover', Helpers.primaryDetailColor());
    root.style.setProperty('--primary-clear', Helpers.primaryClearColor());
  }

  changeState = (key, value) => {
    this.state.userAuth.change(key, value)
    this.setState({ userAuth: this.state.userAuth })
    console.log(this.state.userAuth);
  }

  saveAction = () => {
    this.setState({ isLoading: true })
    const route = new AuthRoute()
    APIManager.saveCache("company", APIManager.loadCache("company"))
    route.fetchLogin(this.state.userAuth, this.loggedAction, this.overdueAction, this.showErrorAction)
  }

  loggedAction = (user) => {
    this.setState({ isLoading: false })
    APIManager.saveCache(User.keyClass, user)
    APIManager.saveCache("company", APIManager.loadCache("company"))
    if (user['level'] == 0) {
      window.location.href = pageLinks.clientDashboard;
    } else if (user['level'] == 3) {
      window.location.href = pageLinks.publications;
    } else if (user['level'] >= 2 && user['level'] < 3) {
      window.location.href = pageLinks.clients;
    } else {
      window.location.href = pageLinks.dashboard;
    }
  }

  overdueAction = (invoices) => {
    this.setState({ isLoading: false })
    APIManager.saveCache("company", APIManager.loadCache("company"))
    APIManager.saveCache("overdueInvoicesSys", invoices)
    window.location.href = pageLinks.sysInvoiceOverdue;
  }

  showErrorAction = (error) => {
    this.setState({ errorMessage: error, isLoading: false })
  }


  forgotAction = () => {
    const route = new AuthRoute()
    route.fetchLogin(this.state.userAuth)
  }

  render() {
    if (APIManager.loadCache(User.keyClass) != null) {
      window.location.href = pageLinks.dashboard;
    }
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(224, 220, 220, 0.5)'
          }),
          spinner: (base) => ({
            ...base,
            width: '70px',
            '& svg circle': {
              stroke: Helpers.primaryColor()
            }
          }),
          wrapper: {
            height: this.state.isLoading ? '500px' : 'auto',
            // overflow: this.state.isLoading ? 'hidden' : 'scroll'
          }
        }}
      >
        <div class="container-fluid">
          <ModalAlertSimple title="Ops, algo deu errado..." desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={() => this.setState({ errorMessage: null })} />
          <div class="account-pages mt-5 mb-5" hidden={this.state.layout == null}>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-5">
                  <div class="card shadow mb-4">
                    <div class="card-header pt-4 pb-4 text-center bg-light">
                      <a>
                        <span><img src={this.state.layout != null ? "data:image/png;base64, " + this.state.layout.logo : ""} alt="" height="40" /></span>
                      </a>
                    </div>

                    <div class="card-body">

                      <div class="text-center w-75 m-auto">
                        <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Bem-vindo</h4>
                        <p class="text-muted mb-4">Insira seu usuário e senha.</p>
                      </div>

                      <FormComponent components={
                        [
                          <div class="col-lg-12 mt-4">
                            <SimpleTextField id="document" title="CPF/CNPJ" isRequired={true} delegate={this.changeState} width="97%" mask={new String(this.state.userAuth.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                            <SimpleTextField type="password" id="password" title="Senha" isRequired={true} delegate={this.changeState} width="97%" />
                            <a onClick={()=>{APIManager.saveCache("company", APIManager.loadCache("company")); APIManager.saveCache("firstPass", false); window.location.href = pageLinks.lostPass}} class="text-muted float-right"><span class="pointer h8 ml-2 mr-1 font-weight-bold text">Esqueceu sua senha?</span></a>
                          </div>,

                          <div class="col-12 mt-5 text-center">
                            <a className="colored-button-center btn-lg btn-primary shadow-sm" onClick={this.saveAction} >
                              <span class="h6 ml-5 mr-5 font-weight-bold text-white pointer">Entrar</span>
                            </a>
                          </div>
                        ]
                      } />


                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <p class="text-muted">Ainda não possui senha? <a onClick={()=>{
                        APIManager.saveCache("company", APIManager.loadCache("company"));
                        APIManager.saveCache("firstPass", true);
                        window.location.href = pageLinks.lostPass.replace(":company", this.state.userAuth.company);}} class="text-muted ml-1"><b className="pointer">Cadastrar senha</b></a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    )
  }
}

export default withRouter(SignInPage);