import Client from "../../Models/Client/Client"
import ClientLevel from "../../Models/Client/ClientLevel"
import ClassPedagogic from "../../Models/Pedagogic/Class"
import CoursePedagogic from "../../Models/Pedagogic/Course"
import EnrollmentPedagogic from "../../Models/Pedagogic/Enrollment"
import OfficePedagogic from "../../Models/Pedagogic/Office"
import PeriodPedagogic from "../../Models/Pedagogic/Period"
import DisciplinePedagogic from "../../Models/Pedagogic/Discipline"
import APIManager from "../APIManager"
import BoletimPedagogic from "../../Models/Pedagogic/Boletim"

export default class PedagogicRoute {
  constructor() {
    this.apiManager = new APIManager()
  }

  fetchCreateOffice = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('offices', object, 'POST', function (response) {
      try {
        const resp = OfficePedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdateOffice = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('offices', object, 'PUT', function (response) {
      try {
        const resp = OfficePedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllOffices = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`offices/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = OfficePedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeleteOffices = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('offices/delete/', keys, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCreateDiscipline = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('disciplines', object, 'POST', function (response) {
      try {
        const resp = DisciplinePedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdateDiscipline = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('disciplines', object, 'PUT', function (response) {
      try {
        const resp = DisciplinePedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllDisciplines = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`disciplines/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = DisciplinePedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeleteDisciplines = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('disciplines/delete/', keys, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCreateCourse = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('courses', object, 'POST', function (response) {
      try {
        // const resp = CoursePedagogic.init(response);
        callback(true);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdateCourse = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('courses', object, 'PUT', function (response) {
      try {
        // const resp = CoursePedagogic.init(response);
        callback(true);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllCourses = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`courses/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = CoursePedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeleteCourses = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('courses/delete/', keys, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCreateClass = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('classes', object, 'POST', function (response) {
      try {
        callback(true);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdateClass = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('classes', object, 'PUT', function (response) {
      try {
        callback(true);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCourseLevelList = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`courses/courseLevelList`, null, 'GET', function (response) {
      try {
        var classes = new Array()
        response.forEach(element => {
          const classLevel = ClientLevel.init(element);
          classes.push(classLevel)
        });
        callback(classes);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllClasses = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`classes/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = ClassPedagogic.init(element);
          list.push(item)
        });
        
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllStudentsInClasses = (classId, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`classes/allStudents/` + company + "/" + classId, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = Client.initForList(element);
          list.push(item)
        });
        
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllClassesFiltered = (period, course, office, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`classes/allFiltered/`, {"company": company, "period": period, "course": course, "office": office}, 'POST', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = ClassPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeleteClasses = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('classes/delete/', keys, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCreatePeriod = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('periods', object, 'POST', function (response) {
      try {
        const resp = PeriodPedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdatePeriod = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('periods', object, 'PUT', function (response) {
      try {
        const resp = PeriodPedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllPeriods = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`periods/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = PeriodPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeletePeriods = (keys, callback, error) => {
    console.log(keys);
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('periods/delete/', keys, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCreateEnrollment = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('enrollments', object, 'POST', function (response) {
      try {
        const resp = EnrollmentPedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchReEnrollments = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('enrollments/reenrollments', object, 'POST', function (response) {
      try {
        const resp = EnrollmentPedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdateEnrollment = (object, callback, error) => {
    object.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('enrollments', object, 'PUT', function (response) {
      try {
        const resp = EnrollmentPedagogic.init(response);
        callback(resp);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllEnrollments = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`enrollments/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = EnrollmentPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllEnrollmentsByUser = (userId, callback, error) => {
    console.log(userId);
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`enrollments/user/` + userId, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = EnrollmentPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdateStatusEnrollments = (keys, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('enrollments/updateStatus/', keys, 'PUT', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchBoletimList = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`boletims/all/` + company, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = BoletimPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchBoletimByEnrollment = (enrollmentId, boletimId, callback, error) => {
    const company = APIManager.loadCache("company")
    console.log(`grades/detail/` + company + `/` + enrollmentId + `/` + boletimId);
    this.apiManager.fetchJSON(`grades/detail/` + company + `/` + enrollmentId + `/` + boletimId, null, 'GET', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = BoletimPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchSaveBoletim = (boletim, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`grades`, {"company": company, "boletim": boletim}, 'POST', function (response) {
      try {
        var list = new Array()
        response.forEach(element => {
          const item = BoletimPedagogic.init(element);
          list.push(item)
        });
        callback(list);
      } catch {
        error(response.message);
      }
    })
  }

  fetchGetPDFBoletim = (enrollmentId, boletimId, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(`grades/pdf/` + company + `/` + enrollmentId + `/` + boletimId, null, 'GET', function (response) {
      try {
        callback(response["base64"]);
      } catch {
        error(response.message);
      }
    })
  }

  fetchGetPDFCallList = (classe, month, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON('classes/pdf/', {company: company, classe: classe, month: month}, 'POST', function (response) {
      try {
        callback(response["base64"]);
      } catch {
        error(response.message);
      }
    })
  }

  fetchImport = (endpoint, csv, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(endpoint + `/import/` + company, {base64: csv}, 'POST', function (response) {
      try {
        console.log(response);
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchExport = (endpoint, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(endpoint + `/export/` + company, null, 'GET', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

}