import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import DashboardRoute from '../../API/Routes/DashboardRoute';
import Helpers from '../../Helpers/Helpers';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import { pageLinks } from '../../Routes/PageLinks';
import Invoice from '../../Models/Invoice/Invoice';
import DashTopDetails from '../Dashboard/Components/DashTopDetails';
import PageTitle from '../../Views/Components/PageTitle';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';

class ClientInvoiceListPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        items: [],
        itemsFiltered: [],
        search: {
          status: '',
          name: '',
          document: '',
          year: new Date().getFullYear(),
        },
      }

      this.props.loadingAction(true)
      this.props.setAlert()
      this.loadInfos = this.loadInfos.bind(this)
      this.errorAction = this.errorAction.bind(this)
  
      new DashboardRoute().fetchInfosClient(this.loadInfos, this.errorAction)
      new InvoiceRoute().fetchInvoiceAllStatus(this.loadStatusList, this.errorAction)
    }

    loadInfos = (invoices) => {
      this.props.loadingAction(false)
      this.setState({
        items: invoices,
        itemsFiltered: invoices.filter(invoice => {return ((!invoice.status.id.includes("cancelled") && this.filterByYear(invoice)))})
      });
    }

    changeSearch = (key, value) => {
      this.state.search[key] = value
      this.setState({ search: this.state.search })
      this.setState({
        itemsFiltered: this.state.items.filter(client => {return ((this.state.search.status == 'all' ? (!client.status.id.includes("cancelled")) : (client.status.id.includes(this.state.search.status)))) && this.filterByYear(client)})
      })
    }

    getYearList = () => {
      var rows = [{id: 0, label: 'Todos'}]
      let actualYear = new Date().getFullYear()
      for (let index = 0; index < 100; index++) {
        rows.push({id: actualYear - index, label: actualYear - index})
      }
      return rows
    }
  
    filterByYear = (invoice) => {
      let check = (new Date(invoice.venciment).getFullYear() >= this.state.search.year)
      return check
    }

    loadStatusList = (values) => {
      var rows = [{ id: 'all', label: 'Ativo' }]
      values.forEach(element => {
        const data = { id: element.id, label: element.name }
        rows.push(data)
      });
      this.state.search.status = rows[0].id
      this.setState({
        statusList: rows,
        search: this.state.search
      });
    }

    changeState = (key, value) => {
      this.state.client.change(key, value)
      this.setState({ client: this.state.client })
    }

    errorAction = (values) => {
      this.setState({
        values
      });
    }

    getTable() {
      var rows = new Array()
      const items = this.state.itemsFiltered
      items.forEach(element => {
        const venciment = new Date(element.venciment)
        const datePayed = new Date(element.datePayed)

        const data = {
          id: element.provider.idTransaction,
          venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
          datePayed: element.datePayed != null ? (datePayed.getUTCDate() + '/' + (datePayed.getUTCMonth() + 1) + '/' + datePayed.getFullYear()) : "-",
          plan: <div><h8 hidden={element.plan != null}>-</h8><h6 hidden={element.plan == null} class="ml-0" align='center' style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.plan}</b></h6></div>,
          amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
          paymentType: element.paymentType.name,
          status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
        }
        rows.push(data)
      });
      return rows
    }

    selectItemAction = (value) => {
      let invoice = this.state.itemsFiltered.find((element) => { return element.provider.idTransaction == value})
      this.props.history.push({
        pathname: pageLinks.clientInvoiceDetail,
        state: {invoice: invoice, client: this.state.client}  
      })
    }
    
  render() {
    const actualMonthName = Helpers.getActualDate().toLocaleString('default', { month: 'long' })
    const headCells = [
      { id: 'id', label: 'Número', isButton: true },
      { id: 'venciment', label: 'Vencimento', isButton: false },
      { id: 'datePayed', label: 'Pago em', isButton: false },
      { id: 'plan', label: 'Plano', isButton: false },
      { id: 'amountTotal', label: 'Total', isButton: false },
      { id: 'paymentType', label: 'Tipo', isButton: false },
      { id: 'status', label: 'Situação', isButton: false }
    ];
    return (
      <div class="container-fluid">
          <PageTitle title="Cobranças" />
          <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Filtrar Cobranças</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
                <div>
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="status" defaultValue={this.state.search.status} title="Situação" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.state.statusList} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="year" defaultValue={this.state.search.year} title="Ano" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.getYearList()} />}
                </div>
              ]
            } />
          </div>
        </div>
        <div class="card shadow mb-4" hidden={this.state.itemsFiltered.length == 0}>
          <EnhancedTable
            titleTable='Lista de Cobranças'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='name'
            defaultRowsPerPage={12}
            delegate={this.selectItemAction}
            selectedList={[]}
            fastOptions={[]}
            selectedFastAction={null}
            individualOptions={[]}
            selectedIndividualAction={null}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(ClientInvoiceListPage);