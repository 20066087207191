import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import FormComponent from '../../Views/Components/FormComponent';
import AuthRoute from '../../API/Routes/AuthRoute';
import UserRequestLogin from '../../Models/Auth/UserRequestLogin';
import User from '../../Models/Auth/User';
import APIManager from '../../API/APIManager';
import { pageLinks } from '../../Routes/PageLinks';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import LoadingOverlay from 'react-loading-overlay';
import Helpers from '../../Helpers/Helpers';
import queryString from 'query-string';

class LostPasswordPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userAuth: new UserRequestLogin(),
            titleMessage: null,
            errorMessage: null,
            actionCloseMessage: null,
            isLoading: false,
            user: null,
            userId: null,
            password: null,
            repeatPass: null,
            token: null,
            validateCode: null,
            firstPass: false
        }

        // this.props.loadingAction(false)
        // this.props.setAlert()

        this.changeState = this.changeState.bind(this)
        this.saveAction = this.saveAction.bind(this)
        this.showErrorAction = this.showErrorAction.bind(this)
        this.loggedAction = this.loggedAction.bind(this)
        this.loadedLayout = this.loadedLayout.bind(this)

        this.state.userAuth.company = APIManager.loadCache('company')
        this.state.firstPass = APIManager.loadCache('firstPass')
        this.setState({userAuth: this.state.userAuth })
        this.setState({ isLoading: true })
        new AuthRoute().fetchCompanyLayout(this.state.userAuth.company, this.loadedLayout, this.showErrorAction)
    }

    changeState = (key, value) => {
        this.state.userAuth.change(key, value)
        this.setState({ userAuth: this.state.userAuth })
    }

    loadedLayout = (layout) => {
        APIManager.saveCache("companyLayout", layout)
        this.setState({ layout: layout, isLoading: false })
        this.setLayout()
      }

    setLayout() {
        let root = document.documentElement
        root.style.setProperty('--primary', Helpers.primaryColor());
        root.style.setProperty('--primary-hover', Helpers.primaryDetailColor());
        root.style.setProperty('--primary-clear', Helpers.primaryClearColor());
    }

    saveAction = () => {
        this.setState({ isLoading: true })
        if (this.state.token == null) {
            new AuthRoute().fetchMailLostPassword(this.state.userAuth, this.loggedAction, this.showErrorAction)
        } else {
            if (this.state.password === this.state.repeatPass) {
                new AuthRoute().fetchChangePass({"token": this.state.token, "password": this.state.password}, this.state.userId, this.state.validateCode.replace(/[^\d]/g, ""), this.changedAction, this.showErrorAction)
            } else {
                this.setState({ titleMessage: null, actionCloseMessage: null, errorMessage: 'As senhas não conferem!', isLoading: false })
            }
        }
    }

    loggedAction = (user) => {
        this.setState({userId: user.id, token: user.token, actionCloseMessage: null, titleMessage: "Tudo certo!", errorMessage: 'Um código de validação foi enviado para o seu email.', isLoading: false })
    }

    changedAction = (user) => {
        this.setState({token: null, actionCloseMessage: this.sendToLoginAction, titleMessage: "Tudo certo!", errorMessage: this.state.firstPass ? 'Sua senha foi cadastrada com sucesso!' : 'Sua senha foi alterada com sucesso!', isLoading: false })
    }

    sendToLoginAction = () => {
        APIManager.saveCache("company", APIManager.loadCache("company"));
        window.location.href = pageLinks.auth.replace(":company", this.state.userAuth.company);
    }

    showErrorAction = (error) => {
        this.setState({ token: null, titleMessage: null, actionCloseMessage: null, errorMessage: error, isLoading: false })
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.isLoading}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(224, 220, 220, 0.5)'
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: '70px',
                        '& svg circle': {
                            stroke: 'rgba(102, 16, 242, 1)'
                        }
                    }),
                    wrapper: {
                        height: this.state.isLoading ? '500px' : 'auto',
                        // overflow: this.state.isLoading ? 'hidden' : 'scroll'
                    }
                }}
            >
                <div class="container-fluid">
                    <ModalAlertSimple title={this.state.titleMessage == null ? "Ops, algo deu errado..." : this.state.titleMessage} desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={this.state.actionCloseMessage == null ? () => this.setState({ errorMessage: null }) : this.state.actionCloseMessage} />
                    <div class="account-pages mt-5 mb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-5">
                                    <div class="card shadow mb-4">
                                        <div class="card-header pt-4 pb-4 text-center bg-light">
                                            <a>
                                            <span><img src={this.state.layout != null ? "data:image/png;base64, " + this.state.layout.logo : ""} alt="" height="40" /></span>
                                            </a>
                                        </div>

                                        <div class="card-body">

                                            <div class="text-center w-75 m-auto">
                                                <h4 class="text-dark-50 text-center mt-0 font-weight-bold">{this.state.firstPass ? "Primeiro Acesso" : "Esqueceu a senha"}</h4>
                                                <p class="text-muted mb-4">{this.state.token == null ? "Insira o número do documento." : "Insira o código enviado para o seu email e a sua nova senha."}</p>
                                            </div>

                                            {
                                            <FormComponent components={
                                                [
                                                    <div class="col-lg-12 mt-4" hidden = {this.state.token != null}>
                                                        <SimpleTextField id="document" title="CPF/CNPJ" isRequired={true} delegate={this.changeState} width="97%" mask={new String(this.state.userAuth.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                                                    </div>,
                                                    <div class="col-lg-12 mt-4" hidden = {this.state.token == null}>
                                                    <SimpleTextField id="validateCode" title="Código de validação" value = "" mask= "9 9 9 9 9 9" isRequired={true} delegate = {(key, value) => {this.setState({ validateCode: value })}} width="97%" />
                                                    <SimpleTextField type="password" id="password" title="Nova Senha" isRequired={true} delegate = {(key, value) => {this.setState({ password: value })}} width="97%" />
                                                    <SimpleTextField type="password" id="repeatPass" title="Repita a Senha" isRequired={true} delegate={(key, value) => {this.setState({ repeatPass: value })}} width="97%" />
                                                </div>,

                                                    <div class="col-12 mt-5 text-center">
                                                        <a className="colored-button-center btn-lg btn-primary shadow-sm" onClick={this.saveAction} >
                                                            <span class="h6 ml-5 mr-5 font-weight-bold text-white pointer">{this.state.user == null ? "Entrar" : "Cadastrar senha"}</span>
                                                        </a>
                                                    </div>
                                                ]
                                            } />
                                        }


                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-12 text-center">
                                            <p class="text-muted">{this.state.firstPass ? "Já possuí uma senha?" : "Lembrou a senha?"} <a onClick={this.sendToLoginAction} class="text-muted ml-1"><b className="pointer">Efetuar Login</b></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

export default withRouter(LostPasswordPage);