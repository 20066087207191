import React, { Component } from 'react'
import BarButton from './BarButton';

export default class PublicationItem extends Component {
    constructor(props) {
        super(props);

        this.openLink = this.openLink.bind(this)
    }

    openLink() {
        if (this.props.link != null) {
            window.open(this.props.link)
        }
    }

    render() {
        const date = new Date(this.props.date)
        return (
            <div class="card col-xl-6 shadow mb-4 mr-2 ml-0">
                <div class="card-header-no-back py-3 d-flex flex-row align-items-center justify-content-between">
                    <div onClick={this.openLink}><h6 class={"m-0 font-weight-bold text-primary" + (this.props.link != null ? " pointer" : "")}>{this.props.title}<br /></h6><h8 class="ml-0">{date.getUTCDate() + '/' + (date.getUTCMonth() + 1) + '/' + date.getFullYear()}</h8></div>
                    <BarButton hidden={this.props.editHidden} title={"Editar"} clickAction={this.props.editAction} />
                </div>
                <div class={"card-body" + (this.props.link != null ? " pointer" : "")} onClick={this.openLink}>
                <h6 class="ml-0">{this.props.desc}</h6><br/>
                <div class="row float-end mt-0 mt-sm-6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0rem 1rem 0rem 1rem'}}>
                    <img hidden={this.props.image == null} class={"pub-image ml-2"} src={"data:image/jpeg;base64," + this.props.image} />
                </div>
                </div>
            </div>
        )
    }
}