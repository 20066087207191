import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import ColoredButton from '../../Views/Components/ColoredButton';
import BarButton from '../../Views/Components/BarButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import ClientRoute from '../../API/Routes/ClientRoute';
import NewClient from '../../Models/Client/Client';
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import TopButton from '../../Views/Components/TopButton';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import DatePicker from '../../Views/Components/DatePicker';
import PedagogicRoute from '../../API/Routes/PedagogicRoute';
import ImportButton from '../../Views/Components/ImportButton';
import APIManager from '../../API/APIManager';
import User from '../../Models/Auth/User';
import { Button, Modal } from 'react-bootstrap';

class InvoicesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      itemsFiltered: [],
      deleteItems: [],
      checkedList: [],
      periods: [],
      courses: [],
      coursesFiltered: [],
      classesFiltered: [],
      classes: [],
      offices: [],
      coursesLevels: [],
      payItems: [],
      errorMessage: null,
      isCancellingPlan: false,
      isEditing: false,
      statusList: [],
      isRelatoryOverdue: false,
      isRelatoryYear: false,
      paymentDate: new Date(),
      search: {
        status: '',
        name: '',
        document: '',
        initialDate: Helpers.initialMonthDate(),
        endDate: Helpers.endMonthDate(),
      },
      selected: {
        selectedPeriod: null,
        selectedCourse: null,
        selectedCourseLevel: null,
        selectedOffice: null,
        selectedClass: null
      },
      relatory: {
        coursesFiltered: [],
        classesFiltered: [],
        selectedPeriod: null,
        selectedCourse: null,
        selectedCourseLevel: null,
        selectedOffice: null,
        selectedClass: null,
        initialDate: Helpers.initialMonthDate(),
        endDate: Helpers.endMonthDate(),
      },
      individualOptions: [
        { id: '0', label: 'Quitar cobrança', action: (itemId) => this.setState({ payItems: [itemId] }) },
        { id: '1', label: 'Cancelar cobrança', action: (itemId) => this.setState({ deleteItems: [itemId] }) },
      ],
      fastOptions: [
        { id: '0', label: 'Quitar cobranças selecionados', action: (itemId) => this.setState({ payItems: itemId }) },
        { id: '1', label: 'Cancelar cobranças selecionadas', action: (itemId) => this.setState({ deleteItems: itemId }) },
        { id: '2', label: 'Download boletos das cobranças selecionadas', action: (itemId) => this.downloadItemsAction(itemId) }
      ]
    }

    this.props.loadingAction(true)
    this.props.setAlert()
    this.loadedInvoices = this.loadedInvoices.bind(this)
    this.editState = this.editState.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.cancelEditState = this.cancelEditState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.loadStatusList = this.loadStatusList.bind(this)
    this.exportAction = this.exportAction.bind(this)
    this.importAction = this.importAction.bind(this)
    this.showPdfInNewTab = this.showPdfInNewTab.bind(this)
    this.exportAction = this.exportAction.bind(this)
    this.relatoryByYearAction = this.relatoryByYearAction.bind(this)
    this.relatoryByInadimplentsAction = this.relatoryByInadimplentsAction.bind(this)

    new InvoiceRoute().fetchInvoiceAllStatus(this.loadStatusList, this.errorAction)
  }

  loadStatusList = (values) => {
    this.props.loadingAction(true)
    var rows = [{ id: 'all', label: 'Ativo' }]
    values.forEach(element => {
      const data = { id: element.id, label: element.name }
      rows.push(data)
    });
    this.state.search.status = rows[0].id
    this.setState({
      statusList: rows,
      search: this.state.search
    });

    const cacheSearch = APIManager.loadCache("invoiceSearch")
    const cacheFilterSelected = APIManager.loadCache("invoiceFilterSelected")
    if (cacheSearch != null) {
      this.state.search = cacheSearch
    }
    if (cacheFilterSelected != null) {
      this.state.selected = cacheFilterSelected
    }

    new InvoiceRoute().fetchAllInvoices(Helpers.formatAmericanDate(this.state.search.initialDate), Helpers.formatAmericanDate(this.state.search.endDate), this.loadedInvoices, this.errorAction)
  }

  fetchStacks = () => {
    let route = new PedagogicRoute()
    route.fetchAllCourses((courses) => { this.setState({ courses, coursesFiltered: courses }) }, this.errorAction)
    route.fetchAllPeriods((periods) => { this.setState({ periods }) }, this.errorAction)
    route.fetchAllOffices((offices) => { this.setState({ offices }) }, this.errorAction)
    route.fetchAllClasses((classes) => { this.setState({ classes }) }, this.errorAction)
    route.fetchCourseLevelList((coursesLevels) => { this.setState({ coursesLevels }) }, this.errorAction)
  }

  newInvoiceAction() {
    this.props.history.push({
      pathname: pageLinks.clients,
      state: {}
    })
  }

  showPdfInNewTab(base64Data) {
    this.props.loadingAction(false)
    var byteCharacters = atob(base64Data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  downloadItemsAction = (items) => {
    this.props.loadingAction(true)
    const ids = items.map(function (obj) { return obj._id; })
    new InvoiceRoute().fetchDownloadItems({ "ids": ids }, this.showPdfInNewTab, this.errorAction)
  }

  editState = () => {
    this.setState({ isEditing: true })
  }

  cancelEditState = () => {
    this.setState({ isEditing: false })
  }

  errorAction = (values) => {
    this.setState({
      values
    });
  }

  saveAction = () => {
    if (this.clientValidate()) {
      this.props.loadingAction(true)
      if (this.state.client.statusId != null) {
        this.state.client.statusId = this.state.client.status.id
      }
      const route = new ClientRoute()
      route.fetchUpdate(this.state.client, this.savedClient, (error) => {
        this.props.setAlert('warning', 'Ops!', error)
      })
    } else {
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  getTable() {
    var rows = new Array()
    const items = this.state.itemsFiltered.length > 0 ? this.state.itemsFiltered : []
    items.forEach(element => {
      const venciment = new Date(element.venciment)
      const data = {
        id: element.provider.idTransaction,
        venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
        client: element.client.name,
        plan: <div><h8 hidden={element.plan != null}>-</h8><h6 hidden={element.plan == null} class="ml-0" align='center' style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.plan}</b></h6></div>,
        amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
        paymentType: element.paymentType.name,
        status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
      }
      rows.push(data)
    });
    return rows
  }

  selectItemAction = (value) => {
    let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value })
    this.props.history.push({
      pathname: pageLinks.invoiceDetail,
      state: { invoice: invoice, client: this.state.client }
    })
  }

  updateListChecked = (values) => {
    const invoices = []
    values.forEach(value => {
      let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value && element.status.id != "allpayed" && element.status.id != "cancelled" })
      if (invoice != null) { invoices.push(invoice) }
    })
    this.setState({ checkedList: invoices })
  }

  fastAction = (value) => {
    if (this.state.checkedList.length == 0) {
      this.setState({ errorMessage: "Não é possível alterar o status destas cobranças!" })
    } else {
      const invoice = this.state.fastOptions.find((element) => { return element.id == value })
      invoice.action(this.state.checkedList)
    }
  }

  individualAction = (value) => {
    const client = this.state.individualOptions.find((element) => { return element.id == value.optionId; })
    let invoice = this.state.items.find((element) => { return element.provider.idTransaction == value.rowId && element.status.id != "allpayed" && element.status.id != "cancelled" })
    if (invoice == null) {
      this.setState({ errorMessage: "Não é possível alterar o status desta cobrança!" })
    } else {
      client.action(invoice)
    }
  }

  loadedInvoices = (invoices) => {
    this.props.loadingAction(false)
    this.setState({ items: invoices });
    this.setState({ itemsFiltered: this.getFiltered() });
  }

  deleteItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.deleteItems.map(function (obj) { return obj._id; })
    console.log("ids: " + ids);
    new InvoiceRoute().fetchCancelItems({ "ids": ids }, () => { window.location.reload(); }, this.errorAction)
  }

  payItemsAction = () => {
    this.props.loadingAction(true)
    const ids = this.state.payItems.map(function (obj) { return obj._id; })
    const amounts = this.state.payItems.map(function (obj) { return Helpers.stringToAmountNumber(obj.amountTotal); })
    new InvoiceRoute().fetchManualPayItems({"date": this.state.paymentDate, "ids": ids, "amounts": amounts }, () => { window.location.reload(); }, this.errorAction)
  }

  changeSearchDate = (key, value) => {
    if (key == "initialDate" && this.state.search["endDate"] < value) {
      let date = new Date(value)
      this.state.search["endDate"] = date.setDate(30)
    }
    this.state.search[key] = value
    this.setState({ search: this.state.search })
    this.props.loadingAction(true)
    APIManager.saveCache("invoiceSearch", this.state.search)
    new InvoiceRoute().fetchAllInvoices(Helpers.formatAmericanDate(this.state.search.initialDate), Helpers.formatAmericanDate(this.state.search.endDate), this.loadedInvoices, this.errorAction)
  }

  changeSearch = (key, value) => {
    this.state.search[key] = value
    this.setState({ search: this.state.search })
    this.setState({
      itemsFiltered: this.getFiltered()
    })
    APIManager.saveCache("invoiceSearch", this.state.search)
  }

  changeSearchPedagogic = (key, value) => {
    this.state.selected[key] = String(value) == "" ? null : value
    if (key != "selectedCourse" && key != "selectedClass") {
      this.state.selected["selectedCourse"] = null
      this.state.coursesFiltered = this.state.courses.filter(course => { return String(course.level.id) == String(this.state.selected.selectedCourseLevel) })
      this.setState({ coursesFiltered: this.state.coursesFiltered })
    }
    if (key == "selectedCourse") {
      this.setState({ classesFiltered: this.state.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.selected.selectedPeriod)) }) })
    }
    this.setState({ selected: this.state.selected })
    this.setState({
      itemsFiltered: this.getFiltered()
    })
    APIManager.saveCache("invoiceFilterSelected", this.state.selected)
  }

  changeSearchRelatory = (key, value) => {
    this.state.relatory[key] = String(value) == "" ? null : value
    if (key != "selectedCourse" && key != "selectedClass") {
      this.state.relatory["selectedCourse"] = null
      this.state.relatory.coursesFiltered = this.state.courses.filter(course => { return String(course.level.id) == String(this.state.relatory.selectedCourseLevel) })
      this.setState({ relatory: this.state.relatory })
    }
    if (key == "selectedCourse") {
      this.state.relatory.classesFiltered = this.state.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.relatory.selectedPeriod)) });
      this.setState({ relatory: this.state.relatory })
    }
    this.setState({ relatory: this.state.relatory })
  }

  changeSearchDateRelatory = (key, value) => {
    if (key == "initialDate" && this.state.relatory["endDate"] < value) {
      let date = new Date(value)
      this.state.relatory["endDate"] = date.setDate(30)
    }
    this.state.relatory[key] = value
    this.setState({ relatory: this.state.relatory })
  }

  changeStateCourse = (key, value) => {
    this.state.enrollment.change(key, value)
    this.setState({ enrollment: this.state.enrollment, classesFiltered: this.state.classes.filter(item => { return ((item.course._id == value && item.period._id == this.state.enrollment.period)) }) })
  }

  getFiltered() {
    var itens = this.state.items.filter(invoice => { return (invoice.client.name.includes(this.state.search.name) && (this.state.search.status == 'all' ? (!invoice.status.id.includes("cancelled")) : (invoice.status.id.includes(this.state.search.status)))) })
    itens = itens.filter(item => {
      if (item.enrollment != null) {
        return (
          this.state.selected.selectedOffice == null ? true : String(item.enrollment.class.office) == this.state.selected.selectedOffice &&
            this.state.selected.selectedPeriod == null ? true : String(item.enrollment.class.period._id) == this.state.selected.selectedPeriod &&
              this.state.selected.selectedCourseLevel == null ? true : String(item.enrollment.class.course.level) == this.state.selected.selectedCourseLevel &&
                this.state.selected.selectedCourse == null ? true : String(item.enrollment.class.course._id) == this.state.selected.selectedCourse &&
                  this.state.selected.selectedClass == null ? true : String(item.enrollment.class._id) == this.state.selected.selectedClass
        )
      }
      return false
    });
    return itens
  }

  exportAction() {
    this.props.loadingAction(true)
    let route = new InvoiceRoute()
    route.fetchExportAllInvoices(
      Helpers.formatAmericanDate(this.state.search.initialDate),
      Helpers.formatAmericanDate(this.state.search.endDate),
      (csvContent) => {
        this.props.loadingAction(false)
        Helpers.downloadCSV("invoices", csvContent)
      },
      this.errorAction
    )
  }

  relatoryByInadimplentsAction() {
    this.setState({ isRelatoryOverdue: false });
    this.props.loadingAction(true)
    let route = new InvoiceRoute()
    route.fetchRelatoryByInadimplents(
      Helpers.formatAmericanDate(this.state.relatory.initialDate),
      Helpers.formatAmericanDate(this.state.relatory.endDate),
      (csvContent) => {
        this.props.loadingAction(false)
        Helpers.downloadCSV("Relatório_De_Inadimplentes", csvContent)
        this.props.setAlert('success', 'Tudo certo!', 'Relatório gerado com sucesso!')
      },
      this.errorAction
    )
  }

  relatoryByYearAction() {
    this.setState({ isRelatoryYear: false });
    this.props.loadingAction(true)
    let route = new InvoiceRoute()
    let year = this.state.periods.filter(period => this.state.relatory.selectedPeriod == period._id)[0].name
    route.fetchRelatoryByYear(
      year,
      this.state.relatory.selectedCourse,
      (csvContent) => {
        this.props.loadingAction(false)
        Helpers.downloadCSV("Relatório_Anual_" + year, csvContent)
        this.props.setAlert('success', 'Tudo certo!', 'Relatório gerado com sucesso!')
      },
      this.errorAction
    )
  }

  componentDidMount() {
    let route = new PedagogicRoute()
    route.fetchAllClassesFiltered(null, null, null, this.loadRows, this.errorAction)
    this.fetchStacks()
  }

  exportAction() {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchExport('billings', (csvContent) => {
      this.props.loadingAction(false)
      Helpers.downloadCSV("billings", csvContent)
    }, this.errorAction)
  }

  importAction(file) {
    this.props.loadingAction(true)
    let route = new PedagogicRoute()
    route.fetchImport('billings', file, (csvContent) => {
      this.props.loadingAction(false)
      window.location.reload()
      alert("Dados importados com sucesso!")
    }, this.errorAction)
  }

  render() {
    if (this.state.selected.selectedPeriod == null && this.state.periods.length > 0) {
      this.state.selected.selectedPeriod = (this.state.periods[0]._id)
      this.state.relatory.selectedPeriod = (this.state.periods[0]._id)
      this.setState({ selected: this.state.selected })
    }

    const headCells = [
      { id: 'id', label: 'Número', isButton: true },
      { id: 'venciment', label: 'Vencimento', isButton: false },
      { id: 'client', label: 'Cliente', isButton: false },
      { id: 'plan', label: 'Plano', isButton: false },
      { id: 'amountTotal', label: 'Total', isButton: false },
      { id: 'paymentType', label: 'Tipo', isButton: false },
      { id: 'status', label: 'Situação', isButton: false }
    ];
    const isHiddenImports = APIManager.loadCache(User.keyClass).level < 99
    return (
      <div class="container-fluid">

        <Modal show={this.state.isRelatoryOverdue} onHide={() => { { this.setState({ isRelatoryOverdue: false }); } }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Relatório de Inadimplentes"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p class="text-muted">{"Selecione abaixo o período do relatório."}</p>
            {<DatePicker id="initialDate" defaultValue={this.state.relatory.initialDate} title="Data Inicial" isRequired={false} delegate={this.changeSearchDateRelatory} width="25ch" />}
            {<DatePicker id="endDate" defaultValue={this.state.relatory.endDate} title="Data Final" isRequired={false} delegate={this.changeSearchDateRelatory} width="25ch" />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.relatoryByInadimplentsAction}>{"Gerar relatório"}</Button>
            <Button variant="primary" onClick={() => { { this.setState({ isRelatoryOverdue: false }); } }}>{"Fechar"}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.isRelatoryYear} onHide={() => { { this.setState({ isRelatoryYear: false }); } }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Relatório Anual"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p class="text-muted">{"Selecione abaixo os filtros do relatório."}</p>
            {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedPeriod" defaultValue={this.state.relatory.selectedPeriod ?? (this.state.periods.length > 0 ? this.state.periods[0]._id : 1)} title="Período Letivo" isRequired={true} delegate={this.changeSearchRelatory} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
            {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedOffice" defaultValue={this.state.relatory.selectedOffice ?? 1} title="Unidade" isRequired={true} delegate={this.changeSearchRelatory} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
            {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedCourseLevel" defaultValue={this.state.relatory.selectedCourseLevel ?? -1} title="Nível" isRequired={true} delegate={this.changeSearchRelatory} width="25ch" options={this.state.coursesLevels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
            {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedCourse" defaultValue={this.state.relatory.selectedCourse ?? 1} title="Curso" isRequired={true} delegate={this.changeSearchRelatory} width="25ch" options={this.state.relatory.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.relatoryByYearAction}>{"Gerar relatório"}</Button>
            <Button variant="primary" onClick={() => { { this.setState({ isRelatoryYear: false }); } }}>{"Fechar"}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.payItems.length > 0} onHide={() => { if (this.state.payItems.length > 1) { window.location.reload(); }; this.setState({ payItems: [] }); }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Pagamento manual"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p class="text-muted">{this.state.payItems.length > 1 ? "Para quitar essas cobranças manualmente informe a data do pagamento." : "Para quitar essa cobrança manualmente informe a data do pagamento."}</p>
            <DatePicker id="datePay" defaultValue={this.state.paymentDate} title="Data do pagamento" isRequired={true} delegate={(key, value) => this.setState({ paymentDate: value })} width="25ch" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.payItemsAction}>{"Quitar Cobrança"}</Button>
            <Button variant="primary" onClick={() => { if (this.state.payItems.length > 1) { window.location.reload(); }; this.setState({ payItems: [] });  }}>{"Fechar"}</Button>
          </Modal.Footer>
        </Modal>

        <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja cancelar esta cobrança?" : "Tem certeza que deseja cancelar estas cobranças?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) { window.location.reload(); }; this.setState({ deleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" />
        <ModalAlertSimple title="Atenção" desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={() => { this.setState({ errorMessage: null }); }} closeTitle="OK" />
        <PageTitle title="Cobranças" />
        <div class="row">
          {/* <TopButton title="NOVA COBRANÇA" icon="plus" clickAction={this.newInvoiceAction} /> */}
          <TopButton title="RELATÓRIO DE INADIMPLENTES" icon="file-download" clickAction={()=>{this.setState({isRelatoryOverdue: true})}} />
          <TopButton title="RELATÓRIO ANUAL" icon="file-download" clickAction={()=>{this.setState({isRelatoryYear: true})}} />
          <TopButton title="EXPORTAR COBRANÇAS" icon="file-download" clickAction={this.exportAction} />
          <ImportButton hidden={isHiddenImports} title="IMPORTAR COBRANÇAS" icon="file-upload" changeAction={this.importAction} />
        </div>
        {/* <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Cliente</h6>
          </div>
        </div> */}
        {console.log(this.state.items)}
        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Filtrar Cobranças</h6>
            <BarButton title={"Limpar Filtros"} clickAction={() => { window.location.reload() }} />
          </div>

          <div class="card-body">
            <FormComponent components={
              [
                <div>
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="status" defaultValue={this.state.search.status} title="Situação" isRequired={false} delegate={this.changeSearch} width="25ch" options={this.state.statusList} />}
                  {this.state.items.length == 0 ? <div></div> : <SimpleTextField id="name" defaultValue={this.state.search.name} title="Cliente" isRequired={false} delegate={this.changeSearch} width="25ch" />}
                  {<DatePicker id="initialDate" defaultValue={this.state.search.initialDate} title="Data Inicial" isRequired={false} delegate={this.changeSearchDate} width="25ch" />}
                  {<DatePicker id="endDate" defaultValue={this.state.search.endDate} title="Data Final" isRequired={false} delegate={this.changeSearchDate} width="25ch" />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedPeriod" defaultValue={this.state.selected.selectedPeriod ?? (this.state.periods.length > 0 ? this.state.periods[0]._id : 1)} title="Período Letivo" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.periods.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedOffice" defaultValue={this.state.selected.selectedOffice ?? 1} title="Unidade" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.offices.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedCourseLevel" defaultValue={this.state.selected.selectedCourseLevel ?? -1} title="Nível" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.coursesLevels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedCourse" defaultValue={this.state.selected.selectedCourse ?? 1} title="Curso" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.coursesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                  {this.state.items.length == 0 ? <div></div> : <SelectTextField id="selectedClass" defaultValue={this.state.selected.selectedClass ?? 1} title="Turma *" isRequired={true} delegate={this.changeSearchPedagogic} width="25ch" options={this.state.classesFiltered.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                </div>
              ]
            } />
          </div>
        </div>

        <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
          <EnhancedTable
            titleTable='Lista de Cobranças'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='name'
            delegate={this.selectItemAction}
            selectedList={this.updateListChecked}
            fastOptions={this.state.fastOptions}
            selectedFastAction={this.fastAction}
            individualOptions={this.state.individualOptions}
            selectedIndividualAction={this.individualAction}
          />
        </div>

      </div>
    )
  }
}

export default withRouter(InvoicesPage);