import APIManager from "../API/APIManager";

export default class Helpers {

    static exportCSV(title, headers, rows) {
        var data = [headers];
        data = data.concat(rows)

        let csvContent = "data:text/csv;charset=utf-8,"
            + data.map(e => e.join(",")).join("\n");
        Helpers.downloadCSV(title, csvContent)
    }

    static downloadCSV(title, csvContent) {
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", title + ".csv");
        document.body.appendChild(link);
        link.click();
    }

    static documentFormat(document){
        if (document != null) {
            document = document.replace(/[^\d]/g, "");
            if (Helpers.isCPF(document)) {
                return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            }else if (Helpers.isCNPJ(document)){
                return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }else{
                return document
            }
        }
    }

    static isCPF(document){
        if (document != null) {
            document = document.replace(/[^\d]/g, "");
            return document.length == 11
        }
    }

    static isCNPJ(document){
        if (document != null) {
            document = document.replace(/[^\d]/g, "");
            return document.length == 14
        }
    }

    static documentTitle(document){
        if (document != null) {
            if (Helpers.isCPF(document)) { return "CPF"}
            else if (Helpers.isCNPJ(document)) { return "CNPJ"}
            else { return document }
        }
    }

    static onlyNumbers(value){
        if (value != null) {
            return value.replace(/[^\d]/g, "");
        }
    }

    static stringToAmountNumber(string){
        return new Number(Helpers.onlyNumbers(string))/100
    }

    static isEmptyTexts(values){
        if (values == null) {return true}
        return (Array.from(values).filter((value)=>{return (value == null || value == "")}).length > 0)
    }

    static formatDate(value) {
        let date = new Date(value)
        return date.getUTCDate() + '/' + (date.getUTCMonth() + 1) + '/' + date.getFullYear()
    }

    static formatAmericanDate(value) {
        let date = new Date(value)
        return date.getFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
    }

    static addZ(n){return n<10? '0'+n:''+n;}

    static generateMatricule() {
        let date = new Date()
        return "" + date.getFullYear() + (Helpers.addZ(date.getUTCMonth() + 1)) + Helpers.addZ(date.getUTCDate()) + Helpers.addZ(date.getHours()) + Helpers.addZ(date.getMinutes()) + Helpers.addZ(date.getMilliseconds())
    }

    static currency(amount) {
        if (amount == null) {return ''}
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2
        });

        return formatter.format(amount.valueOf())
    }

    static getActualDate() {
        return new Date(new Date().toLocaleDateString('en-US', { timeZone: 'America/Sao_Paulo' }))
    }

    static initialMonthDate(){
        return new Date(new Date(Helpers.getActualDate().setDate(1)))
    }

    static endMonthDate(){
        return new Date(new Date(Helpers.initialMonthDate()).setMonth(Helpers.initialMonthDate().getMonth() + 1)).setDate(0)
    }

    static primaryColor() {
        return APIManager.loadCache("companyLayout") == null ? '#000000' : APIManager.loadCache("companyLayout").primaryColor
    }
    static primaryDetailColor() {
        return APIManager.loadCache("companyLayout") == null ? '#000000' : APIManager.loadCache("companyLayout").primaryDetailColor
    }
    static primaryClearColor() {
        return APIManager.loadCache("companyLayout") == null ? '#000000' : APIManager.loadCache("companyLayout").primaryColor+'40'
    }

    static genreList() {
        return [
            { id: '0', label: 'Masculino' },
            { id: '1', label: 'Feminino' },
          ]
    }

    static typePeriodList() {
        return [
            { id: 0, label: 'Semestral' },
            { id: 1, label: 'Anual' },
          ]
    }

    static getYearList = () => {
        var rows = []
        let actualYear = new Date().getFullYear()
        for (let index = 0; index < 100; index++) {
          rows.push({ id: actualYear - index, label: actualYear - index })
        }
        return rows
    }

    static getGenreName(id) {
        let genre = Helpers.genreList().filter((value)=>{return (value.id == id)})[0]
        if (genre != null) { return genre.label }
        return ""
    }

    static getPeriodTypeName(id) {
        let typePeriod = Helpers.typePeriodList().filter((value)=>{return (value.id == id)})[0]
        if (typePeriod != null) { return typePeriod.label }
        return ""
    }
    
}