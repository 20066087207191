import Address from "../../Models/Address"
import User from "../../Models/Auth/User"
import Client from "../../Models/Client/Client"
import ClientStatus from "../../Models/Client/ClientStatus"
import DepositDay from "../../Models/Deposit/DepositDay"
import DepositList from "../../Models/Deposit/DepositList"
import Invoice from "../../Models/Invoice/Invoice"
import InvoiceStatus from "../../Models/Invoice/InvoiceStatus"
import APIManager from "../APIManager"

export default class InvoiceRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `billings`
  }

  fetchCreate = (invoice, callback, error) => {
    invoice.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint, invoice, 'POST', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdate = (client, callback, error) => {
    client.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + "/" + client._id, client, 'PUT', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        const client = Client.init(response);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllInvoicesByUser = (userId, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company + '/' + userId, null, 'GET', function (response) {
      try {
        var invoices = new Array()
        response.forEach(element => {
          if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
          const invoice = Invoice.init(element);
          invoices.push(invoice)
        });
        callback(invoices);
      } catch {
        error(response.message);
      }
    }, false)
  }

  fetchAllInvoicesBySystem = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/allSysAdmin/` + company, null, 'GET', function (response) {
      try {
        var invoices = new Array()
        response.forEach(element => {
          if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
          const invoice = Invoice.init(element);
          invoices.push(invoice)
        });
        callback(invoices);
      } catch {
        error(response.message);
      }
    })
  }

  fetchExportUser = (userId, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/export/` + company + '/' + userId, null, 'GET', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllInvoices = (initialDate, endDate, callback, error) => {
    const company = APIManager.loadCache("company")
    const body = {"initialDate": initialDate, "endDate": endDate}
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company, body, 'POST', function (response) {
      console.log(response);
      try {
        var invoices = new Array()
        response.forEach(element => {
          if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
          const invoice = Invoice.init(element);
          invoices.push(invoice)
        });
        callback(invoices);
      } catch {
        error(response.message);
      }
    })
  }

  fetchExportAllInvoices = (initialDate, endDate, callback, error) => {
    const company = APIManager.loadCache("company")
    const body = {"initialDate": initialDate, "endDate": endDate}
    this.apiManager.fetchJSON(this.endPoint + `/export/` + company, body, 'POST', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

  fetchRelatoryByInadimplents = (initialDate, endDate, callback, error) => {
    const company = APIManager.loadCache("company")
    const body = {"initialDate": initialDate, "endDate": endDate}
    this.apiManager.fetchJSON(this.endPoint + `/relatoryByInadimplents/` + company, body, 'POST', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

  fetchRelatoryByYear = (year, courseId, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/relatoryByYear/` + company + "/" + (courseId ?? 'all') + "/" + year, null, 'GET', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

  fetchInvoiceDetail = (id, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/` + id, null, 'GET', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        const invoice = Invoice.init(response[0]);
        console.log(invoice);
        callback(invoice);
      } catch {
        error(response.message);
      }
    })
  }

  fetchInvoiceSysDetail = (id, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/sysadmin/` + id, null, 'GET', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        const invoice = Invoice.init(response[0]);
        console.log(invoice);
        callback(invoice);
      } catch {
        error(response.message);
      }
    })
  }

  fetchClientInvoiceDetail = (id, callback, error) => {
    const user = APIManager.loadCache(User.keyClass)
    let clientId = user._id
    this.apiManager.fetchJSON(this.endPoint + `/getClientInvoice/` + id, {clientId: clientId}, 'PUT', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        const invoice = Invoice.init(response[0]);
        console.log(invoice);
        callback(invoice);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCancelItems = (ids, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/cancel/' + company, ids, 'PUT', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        callback(true);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDownloadItems = (ids, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/download/' + company, ids, 'PUT', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        callback(response.archiveData);
      } catch {
        error(response.message);
      }
    })
  }

  fetchManualPayItems = (params, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/manualPay/' + company, params, 'PUT', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        callback(true);
      } catch {
        error(response.message);
      }
    })
  }

  fetchInvoiceAllStatus = (callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/invoiceStatusList`, null, 'GET', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        var invoices = new Array()
        response.forEach(element => {
          const invoice = InvoiceStatus.init(element);
          invoices.push(invoice)
        });
        callback(invoices);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeposits = (office, month, year, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/depositList/`+ office + `/`+ month + `/` + year, null, 'GET', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        callback(DepositList.init(response));
      } catch {
        error(response.message);
      }
    })
  }

  fetchDepositDay = (office, day, month, year, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/depositDay/`+ office + `/`+ day + `/`+ month + `/` + year, null, 'GET', function (response) {
      try {
        if (response.code != null && ![200, 201].includes(response.code)) {return error(response.message)}
        callback(DepositDay.init(response));
      } catch {
        error(response.message);
      }
    })
  }
}