
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import { pageLinks } from '../../Routes/PageLinks';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import PageTitle from '../../Views/Components/PageTitle';
import ClientRoute from '../../API/Routes/ClientRoute';
import Helpers from '../../Helpers/Helpers';
import EmployeeRoute from '../../API/Routes/EmployeeRoute';
import Address from '../../Models/Address';
import Client from '../../Models/Client/Client';

class EmployeeDetailPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      client: new Client(),
      levelList: [],
      isEditing: false
    }

    this.props.loadingAction(false)
    this.props.setAlert()
    this.updateAddressWithZipCode = this.updateAddressWithZipCode.bind(this)
    this.changeStateZipCode = this.changeStateZipCode.bind(this)
    this.changeState = this.changeState.bind(this)
    this.clearState = this.clearState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.successAction = this.successAction.bind(this)
    this.loadClient = this.loadClient.bind(this)
    let route = new EmployeeRoute()
    new ClientRoute().fetchClientAllUserLevel(this.loadLevelList, this.errorAction)
    route.fetchDetail(this.props.location.state.document, this.loadClient, this.errorAction)
  }

  errorAction = (values) => {
    this.props.loadingAction(false)
    this.setState({values});
  }

  changeState = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })
    console.log(this.state.client);
  }

  changeStateZipCode = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressWithZipCode, function (error) { })
    }
  }

  updateAddressWithZipCode = (address) => {
    this.state.client.address = address
    this.setState({ client: this.state.client })
  }

  clearState = () => {
    this.state.client.clear()
    this.state.client.address.clear()
    this.setState({ client: this.state.client })
  }

  saveAction = () => {
    this.props.loadingAction(true)
    if (this.clientValidate()) {
      const route = new EmployeeRoute()
      route.fetchUpdate(this.state.client, this.successAction, (error) => {
        this.props.setAlert('warning', 'Ops!', error)
      })
    }else{
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  loadLevelList = (values) => {
    this.props.loadingAction(false)
    var rows = []
    values.forEach(element => {
      if (element.id > 0) {
        const data = { id: element.id, label: element.title }
        rows.push(data)
      }
    });
    this.setState({ levelList: rows })
  }

  clientValidate = () => {
    return !Helpers.isEmptyTexts(
      [this.state.client.document,
      this.state.client.name,
      this.state.client.email,
      this.state.client.level
      ]
      )
  }

  successAction = (client) => {
    this.setState({ clientCreated: true });
    this.loadClient(client)
  }

  loadClient = (client) => {
    this.props.loadingAction(false)
    this.props.setAlert(this.props.location.state.clientCreated ? 'success' : null, 'Tudo certo!', 'O colaborador foi cadastrado com sucesso.')
    this.state.client = client
    this.setState({
      client: this.state.client,
      isEditing: false,
    });
  }

  render() {
    return (
      <div class="container-fluid">
        <PageTitle title="Colaborador" history={this.props.history}/>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Colaborador</h6>
          </div>
          <div class="card-body" hidden={this.state.isEditing}>
            <div class="text-left">
              <p class="text-muted" hidden={this.state.client.status == null}><strong>Status :</strong><span class="ml-2" style={{ color: this.state.client.status.color, backgroundColor: this.state.client.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}><i class={`fas fa-` + this.state.client.status.iconName + ` fa-1x`}></i> <strong>{this.state.client.status.title}</strong></span></p>
              <p class="text-muted" hidden={this.state.client.name == null}><strong>Nome :</strong><span class="ml-2">{this.state.client.name}</span></p>
              <p class="text-muted" hidden={this.state.client.document == null}><strong>{Helpers.documentTitle(this.state.client.document)} :</strong><span class="ml-2">{Helpers.documentFormat(this.state.client.document)}</span></p>
              <p class="text-muted" hidden={this.state.client.levelTitle == null}><strong>Tipo :</strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{this.state.client.levelTitle}</span></p>
              <p class="text-muted" hidden={this.state.client.email == null}><strong>Email :</strong> <span class="ml-2">{this.state.client.email}</span></p>
              <p class="text-muted" hidden={this.state.client.phone == null}><strong>Telefone :</strong> <span class="ml-2">{this.state.client.phone}</span></p>
              <p class="text-muted" hidden={this.state.client.address.zipCode == null}><strong>Endereço :</strong> <span class="ml-2">{Address.init(this.state.client.address).getCompleteAddress()}</span></p>
              <p class="text-muted" hidden={this.state.client.obs == null}><strong>Observações :</strong> <span class="ml-2">{this.state.client.obs}</span></p>
              {this.state.client.plan == null ? <div/> : <p class="text-muted" hidden={this.state.client.plan == null}><strong>Plano Atual :</strong><span class="ml-2" style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '10px', paddingRight: '10px' }}>{this.state.client.plan.name}</span></p>}
            </div>
            <ColoredButton title="Editar" clickAction={()=> { this.setState({ isEditing: true}); }} />
          </div>
          <div class="card-body" hidden={!this.state.isEditing}>
            <FormComponent components={
              [
                <div>
                  <SimpleTextField id="document" isDisabled={true} defaultValue={this.state.client.document} title="CPF/CNPJ" isRequired={true} delegate={this.changeState} width="20ch" mask={new String(this.state.client.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                  <SimpleTextField id="name" defaultValue={this.state.client.name} title="Nome" isRequired={true} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                  <SimpleTextField type="phone" id="phone" defaultValue={this.state.client.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                  <SimpleTextField type="email" id="email" defaultValue={this.state.client.email} title="Email" isRequired={true} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                    <div>
                    <SelectTextField id="level" defaultValue={this.state.client.level ?? 0} title="Tipo *" isRequired={false} delegate={this.changeState} width="25ch" options={this.state.levelList} />
                        <SimpleTextField id="address/zipCode" defaultValue={this.state.client.address.zipCode} title="CEP" isRequired={false} delegate={this.changeStateZipCode} width="20ch" mask="99999-999" />
                    </div>
                    <div hidden={this.state.client.address.addressStreet == null}>
                        <SimpleTextField id="address/addressStreet" isLoad defaultValue={this.state.client.address.addressStreet} title="Endereço" isRequired={false} delegate={this.changeState} width="61%" />
                        <SimpleTextField id="address/number" isLoad defaultValue={this.state.client.address.number} title="Número" isRequired={false} delegate={this.changeState} width="15%" />
                        <SimpleTextField id="address/complement" isLoad defaultValue={this.state.client.address.complement} title="Complemento" isRequired={false} delegate={this.changeState} width="15%" />
                        <SimpleTextField id="address/neighborhood" isLoad defaultValue={this.state.client.address.neighborhood} title="Bairro" isRequired={false} delegate={this.changeState} width="15%" />
                        <SimpleTextField id="address/city" isLoad defaultValue={this.state.client.address.city} title="Cidade" isRequired={false} delegate={this.changeState} width="20ch" />
                        <SimpleTextField id="address/uf" isLoad defaultValue={this.state.client.address.uf} title="Estado" isRequired={false} delegate={this.changeState} width="20ch" />
                    </div>
                </div>,

                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                <SimpleButton title="Limpar" clickAction={this.clearState} />
              ]
            } />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EmployeeDetailPage);