import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Helpers from '../../Helpers/Helpers';
import { pageLinks } from '../../Routes/PageLinks';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import TopButton from '../../Views/Components/TopButton';

class DepositDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            itemModel: null,
            items: [],
            errorMessage: null,
            date: this.props.location.state.date,
            office: this.props.location.state.office,
        }

        this.props.loadingAction(true)
        this.props.setAlert()
        this.loadedInvoices = this.loadedInvoices.bind(this)
        this.errorAction = this.errorAction.bind(this)
    }

    fetchDeposits = () => {
        new InvoiceRoute().fetchDepositDay(this.state.office._id, this.state.date.getUTCDate(), this.state.date.getUTCMonth() + 1, this.state.date.getFullYear(), this.loadedInvoices, this.errorAction)
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }

    getTable() {
        var rows = new Array()
        const items = this.state.items.length > 0 ? this.state.items : []
        const primaryColor = Helpers.primaryColor()
        const backColor = Helpers.primaryClearColor()
        items.forEach(element => {
            const venciment = new Date(element.DepositDate)
            const number = Number(element.PaymentNumber)
            const data = {
                transaction: element.IdTransaction,
                id: element.Description.split(" (")[0],
                Amount: <b class="text-gray-800">{element.Amount > 0 ? Helpers.currency(element.Amount) : ""}</b>,
                Tax: <b class="text-gray-800">{element.Amount > 0 ? Helpers.currency(element.Tax) : ""}</b>,
                Type: <h6 class="ml-0" align='center' style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.PaymentMethod.Name}</b></h6>
            }
            rows.push(data)
        });
        return rows
    }

    loadedInvoices = (invoices) => {
        this.props.loadingAction(false)
        this.setState({ items: invoices.Extracts, itemModel: invoices });
    }

    changeSearchPedagogic = (key, value) => {
        this.state.selected[key] = String(value) == "" ? null : value
        this.setState({ selected: this.state.selected })
        this.fetchDeposits()
    }

    componentDidMount() {
        this.fetchDeposits()
    }

    selectItemAction = (value) => {
        this.props.history.push({
            pathname: pageLinks.clientDetail,
            state: { name: value, clientCreated: false }
        })
    }

    render() {
        const headCells = [
            { id: 'transaction', label: 'Nº Transação', isButton: false },
            { id: 'id', label: 'Aluno', isButton: true },
            { id: 'Amount', label: 'Valor', isButton: false },
            { id: 'Tax', label: 'Taxa', isButton: false },
            { id: 'Type', label: 'Tipo', isButton: false }
        ];

        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={this.state.errorMessage} isShow={this.state.errorMessage != null} closeAction={() => { this.setState({ errorMessage: null }); }} closeTitle="OK" />
                <PageTitle title="Depósito" history={this.props.history} />
                <div class="row">
                    <TopButton title="EXIBIR COMPROVATE" icon="file-download" clickAction={() => { window.open(this.state.itemModel.HashConfirmation); }} />
                </div>
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Detalhes do Depósito</h6>
                        {/* <BarButton title="Cancelar cobrança" clickAction={() => { this.setState({ isCancelling: true }) }} hidden={this.state.invoice.status.id == "cancelled" || this.state.invoice.status.id == "allpayed"} /> */}
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="float-end mt-3 mt-sm-6" hidden={this.state.itemModel == null}>
                                    <h8>Nº do pagamento </h8><br />
                                    <strong><span class="ml-2">{this.state.itemModel == null ? '' : Number(this.state.itemModel.PaymentNumber)}</span></strong>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="float-end mt-3 mt-sm-6" hidden={this.state.itemModel == null}>
                                    <h8>Data </h8><br />
                                    <strong><span class="ml-2">{this.state.itemModel == null ? '' : Helpers.formatDate(this.state.date)}</span></strong>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="float-end mt-3 mt-sm-6" hidden={this.state.itemModel == null}>
                                    <h8>Valor depositado </h8><br />
                                    <h5 class={`text-primary`}><b>{Helpers.currency(this.state.itemModel != null ? this.state.itemModel.Amount : "")}</b></h5>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="float-end mt-3 mt-sm-6" hidden={this.state.itemModel == null}>
                                    <h8>Total de Taxas </h8><br />
                                    <h5><b>{Helpers.currency(this.state.itemModel != null ? this.state.itemModel.Tax : "")}</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-4" hidden={this.state.items.length == 0}>
                    <EnhancedTable
                        titleTable='Lista de Recebimentos'
                        rows={this.getTable()}
                        headCells={headCells}
                        defaultOrderId='DepositDate'
                        delegate={this.selectItemAction}
                        selectedList={this.updateListChecked}
                        fastOptions={[]}
                        selectedFastAction={null}
                        individualOptions={[]}
                        selectedIndividualAction={null}
                    />
                </div>

            </div>
        )
    }
}

export default withRouter(DepositDetailPage);