import React, { Component } from 'react'

export default class TopButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="col-xl-3 col-md-6 mb-4" hidden={this.props.hidden}>
        <div class={`pointer card background-white` + ` shadow h-100 py-2`} onClick={e => {this.props.clickAction(this.props.type ?? "")}}>
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col-auto">
                <div class="icon-circle background-icon">
                  <i class={`fas fa-` + this.props.icon + ` fa-1x text-primary`}></i>
                </div>
              </div>
              <div class="col ml-4">
                <div class="h7 mb-0 font-weight-bold text-gray-800">{this.props.title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}