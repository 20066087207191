
export default class LeftMenuButtonViewModel {
  constructor(pageTitle, pageLink, pageIcon, bindAction) {
    this.pageTitle = pageTitle;
    this.pageLink = pageLink;
    this.pageIcon = pageIcon;
    this.isSelectedPage = false
    this.getStatus = this.getStatus.bind(this)
    this.bindAction = bindAction
  }

  getStatus = () => {
    if (this.isSelectedPage) {
      return  "nav-item active"
    }else{
      return  "nav-item"
    }
  }

  clearState = () => {
    this.isSelectedPage = false
  }
}