import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PeriodPedagogic from '../../../Models/Pedagogic/Period';
import APIManager from '../../../API/APIManager';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import ColoredButton from '../../../Views/Components/ColoredButton';
import PageTitle from '../../../Views/Components/PageTitle';
import FormComponent from '../../../Views/Components/FormComponent';
import SelectTextField from '../../../Views/Components/SelectTextField';
import SimpleTextField from '../../../Views/Components/SimpleTextField';

class NewPeriodPage extends Component {
    constructor(props) {
        super(props)

        this.user = APIManager.loadCache(User.keyClass)
        this.company = this.user.company

        this.props.setAlert()

        let editItem = this.props.location.state.period
        if (editItem != null) {
            this.state = {
                period: new PeriodPedagogic(editItem._id, editItem.name, editItem.year, editItem.type)
            }
        } else {
            this.state = {
                period: PeriodPedagogic.init({'name': new Date().getFullYear(), 'year': new Date().getFullYear(), 'type': 1})
            }
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
    }

    changeState = (key, value) => {
        this.state.period.change(key, value)
        this.setState({ period: this.state.period })
    }

    loadedPeriod = (period) => {
        this.props.loadingAction(false)
        this.setState({period: period});
    }

    errorAction = (values) => {
        this.setState({values});
    }

    saveAction = () => {
        if (this.periodValidate()) {
            this.props.loadingAction(true)
            const route = new PedagogicRoute()
            if (this.props.location.state.period == null) {
                route.fetchCreatePeriod(this.state.period, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            } else {
                route.fetchUpdatePeriod(this.state.period, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            }
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    successAction = (period) => {
        this.props.history.push({
          pathname: pageLinks.pedagogicPeriod,
          state: {period: period, periodCreated: true}  
        })
      }

    periodValidate = () => {
        return !Helpers.isEmptyTexts([this.state.period.name, this.state.period.year]) && Number(this.state.period.type) >= 0
    }

    render() {
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja desativar este periodo letivo?"} isShow={this.state.isCancelling} defaultAction={this.cancelPeriodAction} closeAction={() => { this.setState({ isCancelling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja ativar este periodo letivo?"} isShow={this.state.isEnabling} defaultAction={this.enablePeriodAction} closeAction={() => { this.setState({ isEnabling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <PageTitle title="Informações do Período Letivo" history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="name" defaultValue={this.state.period.name} title="Nome" isRequired={true} delegate={this.changeState} width="25ch" />
                                    {<SelectTextField id="year" defaultValue={this.state.period.year ?? ('' + new Date().getFullYear())} title="Ano de Referência" isRequired={true} delegate={this.changeState} width="25ch" options={Helpers.getYearList()} />}
                                    {<SelectTextField id="type" defaultValue={this.state.period.type ?? 1} title="Tipo do Período Letivo" isRequired={true} delegate={this.changeState} width="25ch" options={Helpers.typePeriodList()} />}
                                </div>,

                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                // <SimpleButton title="Limpar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewPeriodPage);