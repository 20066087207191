import React from 'react'
import { Link } from "react-router-dom";
import { Component } from "react";

export default class TopMenuButtonCell extends Component {

    render() {
        return (
            <Link hidden={this.props.hidden} className="dropdown-item" to={this.props.viewModel.pageLink} onClick={this.props.viewModel.bindAction.bind(this, this)}>
                <i className={this.props.viewModel.pageIcon + " fa-sm fa-fw mr-2 text-gray-400"} />
                <span className="text-gray-600">{this.props.viewModel.pageTitle}</span>
            </Link>
        )
    }
}