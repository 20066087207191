import React, { Component } from 'react'
import { Link } from "react-router-dom";
import LeftMenuButtonCell from './LeftMenuButtonCell';
import LeftMenuButtonViewModel from './LeftMenuButtonViewModel';
import { pageLinks } from '../../../Routes/PageLinks';
import APIManager from '../../../API/APIManager';
import User from '../../../Models/Auth/User';
import AuthRoute from '../../../API/Routes/AuthRoute';

export default class LeftMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pages: {
        //ADMIN
        dashboard: new LeftMenuButtonViewModel("Início", pageLinks.dashboard, "fas fa-fw fa-home", this.updateSelectPageList),
        publications: new LeftMenuButtonViewModel("Publicações", pageLinks.publications, "fas fa-fw fa-newspaper", this.updateSelectPageList),
        clients: new LeftMenuButtonViewModel("Alunos", pageLinks.clients, "fas fa-fw fa-user", this.updateSelectPageList),
        responsibles: new LeftMenuButtonViewModel("Responsáveis", pageLinks.responsibles, "fas fa-fw fa-restroom", this.updateSelectPageList),
        employees: new LeftMenuButtonViewModel("Colaboradores", pageLinks.employees, "fas fa-fw fa-users", this.updateSelectPageList),
        plans: new LeftMenuButtonViewModel("Planos", pageLinks.plans, "fas fa-fw fa-file-invoice-dollar", this.updateSelectPageList),
        charges: new LeftMenuButtonViewModel("Cobranças", pageLinks.invoices, "fas fa-fw fa-dollar-sign", this.updateSelectPageList),
        deposits: new LeftMenuButtonViewModel("Depósitos", pageLinks.depositList, "fas fa-fw fa-dollar-sign", this.updateSelectPageList),
        reports: new LeftMenuButtonViewModel("Relatórios", pageLinks.reports, "fas fa-fw fa-table", this.updateSelectPageList),
        settings: new LeftMenuButtonViewModel("Ajustes", pageLinks.companyConfig, "fas fa-fw fa-cog", this.updateSelectPageList),
        support: new LeftMenuButtonViewModel("Suporte", pageLinks.support, "fas fa-fw fa-question-circle", this.updateSelectPageList),
        sysInvoices: new LeftMenuButtonViewModel("Faturas", pageLinks.sysInvoices, "fas fa-fw fa-money-check-dollar", this.updateSelectPageList),
        //PEDAGOGIC
        pedagogicPeriods: new LeftMenuButtonViewModel("Períodos Letivos", pageLinks.pedagogicPeriod, "fas fa-fw fa-calendar", this.updateSelectPageList),
        pedagogicCourses: new LeftMenuButtonViewModel("Cursos", pageLinks.pedagogicCourses, "fas fa-fw fa-graduation-cap", this.updateSelectPageList),
        pedagogicClasses: new LeftMenuButtonViewModel("Turmas", pageLinks.pedagogicClass, "fas fa-fw fa-users", this.updateSelectPageList),
        pedagogicOffices: new LeftMenuButtonViewModel("Unidades", pageLinks.pedagogicOffice, "fas fa-fw fa-institution", this.updateSelectPageList),
        pedagogicDisciplines: new LeftMenuButtonViewModel("Disciplinas", pageLinks.pedagogicDiscipline, "fas fa-fw fa-institution", this.updateSelectPageList),
        //CLIENT
        dashboardClient: new LeftMenuButtonViewModel("Início", pageLinks.clientDashboard, "fas fa-fw fa-home", this.updateSelectPageList),
        invoicesClient: new LeftMenuButtonViewModel("Cobranças", pageLinks.clientInvoiceList, "fas fa-fw fa-dollar-sign", this.updateSelectPageList),
      }
    }

    Object.values(this.state.pages).forEach((item) =>
      item.isSelectedPage = (window.location.pathname.includes(item.pageLink))
    )

    this.updateSelectPageList = this.updateSelectPageList.bind(this)
  }

  updateSelectPageList = (page) => {
    Object.values(this.state.pages).forEach((item) =>
      item.isSelectedPage = (page.props.viewModel === item)
    )
    this.setState({ pages: this.state.pages })
  }

  render() {
    const layout = APIManager.loadCache("companyLayout")
    const client = APIManager.loadCache(User.keyClass)
    if (client.level >= 1) { new AuthRoute().fetchLoadCompanyDetails(null, null) }
    const logoImg = layout.logo
    const hasCommunicationModule = client.company.plan.hasCommunicationModule
    const hasPedagogicModule = client.company.plan.hasPedagogicModule
    return (
      <div className={"bg-gradient-primary"}>
        <div className={"containerLeftImageMenu"}>
        <Link className="d-none d-md-block align-items-center justify-content-center" to={pageLinks.dashboard} >
          <div className="ml-2 logobar justify-content-center align-items-center">
            <img className={"img-logo" + " d-md-inline-block"} src={layout != null ? "data:image/png;base64, " + logoImg : ""} />
          </div>
        </Link>
        <Link className="d-md-none align-items-center justify-content-center" to={pageLinks.dashboard} >
          <div className="ml-2 logobar justify-content-center align-items-center">
            <img className={this.props.isToogled ? "d-none img-logo-small" : "img-logo-small" + " d-md-inline-block"} src={layout != null ? "data:image/png;base64, " + logoImg : ""} />
          </div>
        </Link>
        </div>
        <ul className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" + (this.props.isToogled ? ` toggled` : ``)} id="accordionSidebar">
          {/* <Link className="d-flex align-items-center justify-content-center" to={pageLinks.dashboard} >
            <div className="logobar justify-content-center align-items-center">
              <img className={this.props.isToogled ? "img-logo-small" : "img-logo" + " d-none d-md-inline-block"} src={layout != null ? "data:image/png;base64, " + logoImg : ""} />
            </div>
          </Link> */}

          <div hidden={!(client.level == 0)}>
            <LeftMenuButtonCell viewModel={this.state.pages.dashboardClient} />
            <LeftMenuButtonCell viewModel={this.state.pages.invoicesClient} />
            <LeftMenuButtonCell viewModel={this.state.pages.support} />
          </div>
          <div hidden={!(client.level >= 1 && client.level <= 100)}>
            <hr className="sidebar-divider my-0"></hr>
            <LeftMenuButtonCell hidden={client.level < 4} viewModel={this.state.pages.dashboard} />
            <LeftMenuButtonCell hidden={!hasCommunicationModule || (client.level != 3 && client.level != 2.1 && client.level < 5)} viewModel={this.state.pages.publications} />
            <div hidden={client.level < 2}>
            <hr className="sidebar-divider"></hr>
            <div className="sidebar-heading">
              Usuários
            </div>
            <LeftMenuButtonCell viewModel={this.state.pages.clients} />
            <LeftMenuButtonCell hidden={!(client.level >= 5 && client.level <= 100)} viewModel={this.state.pages.employees} />
            </div>
            {/* <LeftMenuButtonCell viewModel={this.state.pages.responsibles} /> */}
            {/* <li className="nav-item">
          <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            <i className="fas fa-fw fa-cog"></i>
            <span>Components</span>
          </a>
          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <Link className="collapse-item" to="/page2">Buttons</Link>
              <Link className="collapse-item" to="/page2">Cards</Link>
            </div>
          </div>
        </li> */}

            {/* <li className="nav-item">
          <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
            <i className="fas fa-fw fa-wrench"></i>
            <span>Utilities</span>
          </a>
          <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Utilities:</h6>
              <a className="collapse-item" href="utilities-color.html">Colors</a>
              <a className="collapse-item" href="utilities-border.html">Borders</a>
              <a className="collapse-item" href="utilities-animation.html">Animations</a>
              <a className="collapse-item" href="utilities-other.html">Other</a>
            </div>
          </div>
        </li> */}
            <div hidden={!(client.level == 3 || client.level == 2.1 || client.level == 4.3 || client.level >= 5 || client.level >= 100)}>
            <hr className="sidebar-divider"></hr>
            <div className="sidebar-heading">
              Pedagógico
            </div>
            <LeftMenuButtonCell viewModel={this.state.pages.pedagogicPeriods} />
            <LeftMenuButtonCell viewModel={this.state.pages.pedagogicCourses} />
            <LeftMenuButtonCell viewModel={this.state.pages.pedagogicClasses} />
            <LeftMenuButtonCell viewModel={this.state.pages.pedagogicDisciplines} />
            {/* <LeftMenuButtonCell viewModel={this.state.pages.reports} /> */}
            <hr className="sidebar-divider my-0"></hr>
            </div>

            <div hidden={client.level < 4}>
            <hr className="sidebar-divider"></hr>
            <div className="sidebar-heading">
              Financeiro
            </div>
            <LeftMenuButtonCell viewModel={this.state.pages.plans} />
            <LeftMenuButtonCell viewModel={this.state.pages.charges} />
            {/* <LeftMenuButtonCell viewModel={this.state.pages.reports} /> */}
            <hr className="sidebar-divider my-0"></hr>
            </div>
            {/* <li className="nav-item">
          <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
            <i className="fas fa-fw fa-folder"></i>
            <span>Pages</span>
          </a>
          <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Login Screens:</h6>
              <a className="collapse-item" href="login.html">Login</a>
              <a className="collapse-item" href="register.html">Register</a>
              <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
              <div className="collapse-divider"></div>
              <h6 className="collapse-header">Other Pages:</h6>
              <a className="collapse-item" href="404.html">404 Page</a>
              <a className="collapse-item" href="blank.html">Blank Page</a>
            </div>
          </div>
        </li> */}
        <hr className="sidebar-divider"></hr>
        <div className="sidebar-heading">
              Sistema
            </div>
            <LeftMenuButtonCell hidden={client.level < 5} viewModel={this.state.pages.settings} />
            <LeftMenuButtonCell hidden={client.level < 5} viewModel={this.state.pages.pedagogicOffices} />
            <LeftMenuButtonCell hidden={client.level < 5} viewModel={this.state.pages.deposits} />
            <LeftMenuButtonCell hidden={client.level < 5} viewModel={this.state.pages.sysInvoices} />
            <LeftMenuButtonCell hidden={client.level == 0} viewModel={this.state.pages.support} />
          </div>
          <hr className="sidebar-divider d-none d-md-block"></hr>
          <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle" onClick={this.props.toogleAction}></button>
          </div>
        </ul >
      </div>
    )
  }
}