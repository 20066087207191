import Address from "../../Models/Address"
import User from "../../Models/Auth/User"
import Client from "../../Models/Client/Client"
import ClientLevel from "../../Models/Client/ClientLevel"
import ClientStatus from "../../Models/Client/ClientStatus"
import BusinessPlan from "../../Models/Plan/BusinessPlan"
import APIManager from "../APIManager"

export default class ClientRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `user`
  }

  fetchCreate = (client, callback, error) => {
    client.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + "/register", client, 'POST', function (response) {
      try {
        callback(response.user.document);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdate = (client, callback, error) => {
    client.company = APIManager.loadCache("company")
    client.plan = client.plan == null ? null : client.plan['id']
    this.apiManager.fetchJSON(this.endPoint + "/" + client._id, client, 'PUT', function (response) {
      try {
        const client = Client.init(response);
        console.log(client);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchGetEnrollmentPrint = (clientIds, periodName, className, courseName, courseLevel, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + "/rematricula/" + company, {
      "userIds": clientIds, "enrollment": {
        "periodName": periodName,
        "className": className,
        "courseName": courseName,
        "courseLevel": courseLevel
      }
    }, 'POST', function (response) {
      try {
        callback(response["base64"]);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAllClients = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company, null, 'GET', function (response) {
      try {
        var clients = new Array()
        response.forEach(element => {
          console.log(element);
          const client = Client.initForList(element);
          clients.push(client)
        });
        console.log(clients);
        callback(clients);
      } catch {
        error(response.message);
      }
    })
  }

  fetchImportClients = (csv, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/import/` + company, { base64: csv }, 'POST', function (response) {
      try {
        console.log(response);
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchExportClients = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/export/` + company, null, 'GET', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

  fetchClientDetail = (document, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/details/` + company + '/' + document, null, 'GET', function (response) {
      try {
        const client = Client.init(response);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchClientDetailByName = (name, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/detailsByName/` + company + '/' + name, null, 'GET', function (response) {
      try {
        const client = Client.init(response);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDeleteClients = (documents, callback, error) => {
    console.log("AAAAAAAAA");
    console.log(documents);
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/' + company, documents, 'DELETE', function (response) {
      try {
        var clients = new Array()
        response.forEach(element => {
          console.log(element);
          const client = Client.initForList(element);
          clients.push(client)
        });
        console.log(clients);
        callback(clients);
      } catch {
        error(response.message);
      }
    })
  }

  fetchZipCodeDetails = (zipcode, callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/zipaddress/` + zipcode.replace(/\D/g, ""), null, 'GET', function (response) {
      try {
        const address = Address.init(response);
        callback(address);
      } catch {
        error(response.message);
      }
    })
  }

  fetchClientAllStatus = (callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/userStatusList`, null, 'GET', function (response) {
      try {
        var clients = new Array()
        response.forEach(element => {
          const client = ClientStatus.init(element);
          clients.push(client)
        });
        console.log(clients);
        callback(clients);
      } catch {
        error(response.message);
      }
    })
  }

  fetchClientAllUserLevel = (callback, error) => {
    this.apiManager.fetchJSON(this.endPoint + `/userLevelList`, null, 'GET', function (response) {
      try {
        var clients = new Array()
        response.forEach(element => {
          const client = ClientLevel.init(element);
          clients.push(client)
        });
        console.log(clients);
        callback(clients);
      } catch {
        error(response.message);
      }
    })
  }

  fetchCancelPlan = (document, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/cancelplan/` + company + '/' + document, null, 'PUT', function (response) {
      try {
        const client = Client.init(response);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchGetPlanBusiness = (callback, error) => {
    const company = APIManager.loadCache(User.keyClass).company
    this.apiManager.fetchJSON(`plans/id/` + company.plan._id, null, 'GET', function (response) {
      try {
        const plan = BusinessPlan.init(response);
        callback(plan);
      } catch {
        error(response.message);
      }
    })
  }

}