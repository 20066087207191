import React, { Component } from 'react'

export default class DashTopDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="col-xl-3 col-md-6 mb-4">
        <div class={`card border-left-` + this.props.type + ` shadow h-100 py-2`}>
          <div class="card-body">
            <div class="row no-gutters align-items-top">
              <div class="col mr-2">
                <div class={"text-xs font-weight-bold text-" + this.props.type + " text-uppercase mb-1"}>{this.props.title}</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800 mb-3">{this.props.amount}</div>
                {/* <div class={`h9 font-weight-bold text-` + this.props.type + ` text-uppercase mb-0`}>{(this.props.tax > 0 ? `↑ ` : `↓ `) + this.props.tax + this.props.taxSymbol}</div> */}
                <div class={`h9 font-weight-bold text-` + this.props.type + ` text-uppercase mb-0`}>{this.props.invoices}</div>
                <div class="text-min mb-0 font-weight-regular text-gray-500 text-uppercase">{this.props.detail}</div>
              </div>
              <div class="col-auto">
                <i class={`fas fa-`+ this.props.icon + ` fa-2x text-gray-300`}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}