import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '30ch',
    },
  },
}));

export default function CustomizedForms(props) {
  const classes = useStyles();

  return (
    <form id="teste" className={classes.root} noValidate autoComplete="off">
      {props.components}
    </form>
  );
}