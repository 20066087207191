import React, { Component } from 'react'

export default class SimpleButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="col-lg-12 mt-4">
          <a className="pointer colored-button btn-lg btn-secondary mr-3" onClick={this.props.clickAction} >
            <span class="h6 ml-4 mr-4 font-weight-bold text-primary">{this.props.title}</span>
          </a>
      </div>
    )
  }
}