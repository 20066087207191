import Address from "../Address";
import Bank from "../Bank";
import ModelJSON from "../ModelJSON";

export default class OfficePedagogic extends ModelJSON {
  constructor(id, name, fantasy, document, phone, email, address = new Address(), bank = new Bank()) {
    super()
    this._id = id;
    this.name = name;
    this.fantasy = fantasy;
    this.document = document;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.bank = bank;
  }

  static init(json) {
    return new OfficePedagogic(
      json._id,
      json.name,
      json.fantasy,
      json.document,
      json.phone,
      json.email,
      Address.init(json.address),
      Bank.init(json.bank)
    )
  }
}