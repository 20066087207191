import ModelJSON from "../ModelJSON";

export default class DashClientInfo extends ModelJSON {
  constructor(totalUsers = "", totalActiveUsers = "", monthsTitle = [], monthsValue = []) {
    super()
    this.totalUsers = totalUsers
    this.totalActiveUsers = totalActiveUsers
    this.monthsTitle = monthsTitle
    this.monthsValue = monthsValue
  }

  static init(json) {
    return new DashClientInfo(
        json.totalUsers,
        json.totalActiveUsers,
        json.monthsTitle,
        json.monthsValue
    )
  }
}