import ModelJSON from "../ModelJSON";

export default class InvoiceProvider extends ModelJSON {
  constructor(id, digitableLine, barcode, bankSlipUrl, pixKey, billetArchive) {
    super()
    this.idTransaction = id;
    this.digitableLine = digitableLine;
    this.barcode = barcode
    this.bankSlipUrl = bankSlipUrl
    this.pixKey = pixKey
    this.billetArchive = billetArchive
  }

  static init(json) {
    return new InvoiceProvider(
      json.idTransaction,
      json.digitableLine,
      json.barcode,
      json.bankSlipUrl,
      json.pixKey,
      json.billetArchive
    )
  }
}