import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import ModalAlertSimple from '../../../Views/Components/ModalAlertSimple';
import PeriodPedagogic from '../../../Models/Pedagogic/Period';
import APIManager from '../../../API/APIManager';
import PedagogicRoute from '../../../API/Routes/PedagogicRoute';
import Helpers from '../../../Helpers/Helpers';
import User from '../../../Models/Auth/User';
import { pageLinks } from '../../../Routes/PageLinks';
import ColoredButton from '../../../Views/Components/ColoredButton';
import PageTitle from '../../../Views/Components/PageTitle';
import FormComponent from '../../../Views/Components/FormComponent';
import SelectTextField from '../../../Views/Components/SelectTextField';
import SimpleTextField from '../../../Views/Components/SimpleTextField';
import CoursePedagogic from '../../../Models/Pedagogic/Course';
import ClientLevel from '../../../Models/Client/ClientLevel';
import EnhancedTable from '../../../Views/Components/EnhancedTable';
import TopButton from '../../../Views/Components/TopButton';

class NewCoursePage extends Component {
    constructor(props) {
        super(props)

        this.props.loadingAction(true)
        this.user = APIManager.loadCache(User.keyClass)
        this.company = this.user.company

        this.props.setAlert()
        let editCourse = this.props.location.state.course

        this.state = {
            course: this.props.location.state.course != null ? new CoursePedagogic(editCourse._id, editCourse.name, editCourse.desc, editCourse.level.id, editCourse.level.id, editCourse.disciplines, editCourse.boletim) : CoursePedagogic.init({ 'name': '', 'desc': '', 'level': 0, 'boletim': 0 }),
            disciplines: this.props.location.state.course != null ? editCourse.disciplines : [],
            otherDisciplines: [],
            levels: [],
            boletins: [],
            itemsDisabled: [],
            isShowDisabled: false,
            checkedList: [],
            checkedOtherDisciplinesList: [],
            selectedItem: '',
            deleteItems: [],
            includeItems: [],
            individualOptions: [
                { id: '0', label: 'Remover disciplina do curso', action: (clientDocuments) => this.deleteItemsAction([clientDocuments])}
            ],
            fastOptions: [
                { id: '0', label: 'Remover disciplinas do curso', action: (clientDocuments) => this.deleteItemsAction(clientDocuments)}
            ],
            individualOtherDisciplinesOptions: [
                { id: '0', label: 'Adicionar disciplina ao curso', action: (clientDocuments) => this.includeItemsAction([clientDocuments])}
            ],
            fastOtherDisciplinesOptions: [
                { id: '0', label: 'Adicionar disciplinas ao curso', action: (clientDocuments) => this.includeItemsAction(clientDocuments)}
            ]
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)
        this.loadAllOtherDisciplines = this.loadAllOtherDisciplines.bind(this)
        new PedagogicRoute().fetchCourseLevelList((levels) => { this.setState({ levels }) }, this.errorAction)
        new PedagogicRoute().fetchBoletimList((boletins) => { this.setState({ boletins }) }, this.errorAction)
        this.loadAllOtherDisciplines()
    }

    loadAllOtherDisciplines = () => {
        new PedagogicRoute().fetchAllDisciplines((otherDisciplines) => { 
            this.props.loadingAction(false)
            this.state.otherDisciplines = otherDisciplines.filter((element) => { return !(this.state.disciplines.map((element2) => { return element2._id }).indexOf(element._id) > -1) })
            this.setState({ otherDisciplines: this.state.otherDisciplines })
         }, this.errorAction)
    }

    changeState = (key, value) => {
        this.state.course.change(key, value)
        this.setState({ course: this.state.course })
    }

    loadedPeriod = (period) => {
        this.props.loadingAction(false)
        this.setState({ period: period });
    }

    errorAction = (values) => {
        this.setState({ values });
    }

    getTableDisciplinesInCourse() {
        var rows = new Array()
        const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.disciplines)
        items.forEach(element => {
            const data = {
                name: element.name,
                id: element._id
            }
            rows.push(data)
        });
        return rows
    }

    getTableDisciplines() {
        var rows = new Array()
        const items = (this.state.isShowDisabled ? this.state.itemsDisabled : this.state.otherDisciplines)
        items.forEach(element => {
            const data = {
                name: element.name,
                id: element._id
            }
            rows.push(data)
        });
        return rows
    }

    saveAction = () => {
        if (this.periodValidate()) {
            this.props.loadingAction(true)
            let disciplinesIds = this.state.disciplines.map((element) => { return element._id })
            this.state.course.disciplines = disciplinesIds
            this.setState({ course: this.state.course })

            const route = new PedagogicRoute()
            if (this.props.location.state.course == null) {
                route.fetchCreateCourse(this.state.course, this.successAction, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            } else {
                route.fetchUpdateCourse(this.state.course, ()=>{
                    this.props.setAlert('success', 'Tudo certo!', "Curso atualizado com sucesso!")
                }, (error) => {
                    this.props.setAlert('warning', 'Ops!', error)
                })
            }
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    successAction = (course) => {
        this.props.history.push({
            pathname: pageLinks.pedagogicCourses,
            state: { course: course, courseCreated: true }
        })
    }

    periodValidate = () => {
        return !Helpers.isEmptyTexts([this.state.course.name, this.state.course.boletim]) && Number(this.state.course.level) >= 0
    }

    fastAction = (value) => {
        const client = this.state.fastOptions.find((element) => { return element.id == value })
        client.action(this.state.checkedList)
    }

    individualAction = (value) => {
        const client = this.state.individualOptions.find((element) => { return element.id == value.optionId; })
        client.action(value.rowId)
    }

    fastOtherDisciplinesAction = (value) => {
        const client = this.state.fastOtherDisciplinesOptions.find((element) => { return element.id == value })
        client.action(this.state.checkedOtherDisciplinesList)
    }

    individualOtherDisciplinesAction = (value) => {
        const client = this.state.individualOtherDisciplinesOptions.find((element) => { return element.id == value.optionId; })
        client.action(value.rowId)
    }

    updateListChecked = (value) => {
        this.setState({ checkedList: value })
    }

    updateListOtherDisciplinesChecked = (value) => {
        this.setState({ checkedOtherDisciplinesList: value })
    }

    deleteItemsAction = (deleteItems) => {
        let copyDisciplines = []
        this.state.disciplines.forEach((element, index) => { 
            if (deleteItems.includes(element._id)) {
                this.state.otherDisciplines.push(element)
            } else {
                copyDisciplines.push(element)
            }
        })

        this.state.disciplines = copyDisciplines

        this.setState({ otherDisciplines: this.state.otherDisciplines, disciplines: this.state.disciplines, includeItems: [], checkedList: [], checkedOtherDisciplinesList: [] })
    }

    includeItemsAction = (items) => {
        let copyOthersDisciplines = []
        this.state.otherDisciplines.forEach((element, index) => { 
            if (items.includes(element._id)) {
                this.state.disciplines.push(element)
            } else {
                copyOthersDisciplines.push(element)
            }
        })
        this.state.otherDisciplines = copyOthersDisciplines

        this.setState({ disciplines: this.state.disciplines, otherDisciplines: this.state.otherDisciplines, includeItems: [], checkedList: [], checkedOtherDisciplinesList: [] })
    }

    selectItemAction = (value) => {
        // const items = this.state.items
        // let item = items.find((element) => { return element._id == value})
        // this.props.history.push({
        //   pathname: pageLinks.newPedagogicDiscipline,
        //   state: {discipline: item}
        // })
    }

    render() {
        const headCells = [
            { id: 'name', label: 'Nome', isButton: true },
            { id: 'id', label: '', isButton: false, isHidden: true }
        ];
        console.log("Numero disciplinas: " + this.state.disciplines.length);
        console.log("Numero disciplinas NAO: " + this.state.otherDisciplines.length);
        return (
            <div class="container-fluid">
                {/* <ModalAlertSimple title="Atenção" desc={this.state.deleteItems.length == 1 ? "Tem certeza que deseja remover esta disciplina do curso?" : "Tem certeza que deseja remover estas disciplinas do curso?"} isShow={this.state.deleteItems.length > 0} defaultAction={this.deleteItemsAction} closeAction={() => { if (this.state.deleteItems.length > 1) { window.location.reload(); }; this.setState({ deleteItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" /> */}
                {/* <ModalAlertSimple title="Atenção" desc={this.state.includeItems.length == 1 ? "Tem certeza que deseja adicionar esta disciplina ao curso?" : "Tem certeza que deseja adicionar estas disciplinas ao curso?"} isShow={this.state.includeItems.length > 0} defaultAction={this.includeItemsAction} closeAction={() => { if (this.state.includeItems.length > 1) { window.location.reload(); }; this.setState({ includeItems: [] }); }} buttonTitle="Sim" closeTitle="Cancelar" /> */}
                <PageTitle title="Informações do Curso" history={this.props.history} />
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="name" defaultValue={this.state.course.name} title="Nome" isRequired={true} delegate={this.changeState} width="25ch" />
                                    <SimpleTextField id="desc" defaultValue={this.state.course.desc} title="Descrição" isRequired={false} delegate={this.changeState} width="25ch" />
                                    {<SelectTextField id="level" defaultValue={this.state.course.level ?? 0} title="Nível de ensino *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.levels.map((obj) => { return { id: obj.id, label: obj.title } })} />}
                                    {<SelectTextField id="boletim" defaultValue={this.state.course.boletim ?? 0} title="Boletim *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.boletins.map((obj) => { return { id: obj._id, label: obj.name } })} />}
                                </div>,

                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                // <SimpleButton title="Limpar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
                <div class="card shadow mb-4" hidden={this.state.disciplines.length == 0}>
                    <EnhancedTable
                        titleTable='Disciplinas Inclusas no Curso'
                        rows={this.getTableDisciplinesInCourse()}
                        headCells={headCells}
                        delegate={this.selectItemAction}
                        selectedList={this.updateListChecked}
                        fastOptions={this.state.fastOptions}
                        selectedFastAction={this.fastAction}
                        individualOptions={this.state.individualOptions}
                        selectedIndividualAction={this.individualAction}
                    />
                </div>

                <div class="card shadow mb-4" hidden={this.state.otherDisciplines.length == 0}>
                    <EnhancedTable
                        titleTable='Disciplinas Não Inclusas no Curso'
                        rows={this.getTableDisciplines()}
                        headCells={headCells}
                        delegate={this.selectItemAction}
                        selectedList={this.updateListOtherDisciplinesChecked}
                        fastOptions={this.state.fastOtherDisciplinesOptions}
                        selectedFastAction={this.fastOtherDisciplinesAction}
                        individualOptions={this.state.individualOtherDisciplinesOptions}
                        selectedIndividualAction={this.individualOtherDisciplinesAction}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(NewCoursePage);