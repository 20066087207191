import ModelJSON from "../ModelJSON";

export default class ChatMessage extends ModelJSON {
  constructor(id, isImage64, sender, messageSendedForMe, date, chat, text, sendId) {
    super()
    this.id = id
    this.isImage64 = isImage64;
    this.sender = sender;
    this.messageSendedForMe = messageSendedForMe
    this.date = date;
    this.chat = chat;
    this.text = text;
    this.sendId = sendId
  }

  static init(json) {
    return new ChatMessage(
        json._id,
        json.isImage64,
        json.sender,
        json.messageSendedForMe,
        json.date,
        json.chat,
        json.text,
        json.sendId
    )
  }
}