import React, { Component } from 'react'

export default class ImportButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
    }

    this.changeAction = this.changeAction.bind(this)
    this.emptyAction = this.emptyAction.bind(this)
  }

  emptyAction = () => {}

  changeAction = (value) => {
    this.getBase64(value, (result) => {
      if (this.props.changeAction != null) {
        if (value.size > 4194304) {
          alert("O arquivo deve possuir no máximo um tamanho de 4 mb");
          return
        };
        const image = result.replace("data:text/csv;base64,", "")
        this.props.changeAction(image)
      }
    })
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

  render() {
    return (
        <div class="col-xl-3 col-md-6 mb-4" hidden={this.props.hidden}>
        <div class={`pointer card background-white` + ` shadow h-100 py-2`} onClick={()=>{document.getElementById('file-id').click(); (this.props.openAction == null ? this.emptyAction() : this.props.openAction(this.props.type))}}>
        <input hidden='true' id="file-id" type="file" onChange={e => this.changeAction(e.target.files[0])} accept="text/csv"></input>
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col-auto">
                <div class="icon-circle background-icon">
                  <i class={`fas fa-` + this.props.icon + ` fa-1x text-primary`}></i>
                </div>
              </div>
              <div class="col ml-4">
                <div class="h7 mb-0 font-weight-bold text-gray-800">{this.props.title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}