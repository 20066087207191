import ClientLevel from "../Client/ClientLevel";
import ModelJSON from "../ModelJSON";
import DisciplinePedagogic from "./Discipline";

export default class CoursePedagogic extends ModelJSON {
  constructor(id, name, desc, level = new ClientLevel('', ''), levelId, disciplines = new Array(), boletim) {
    super()
    this._id = id;
    this.name = name;
    this.desc = desc;
    this.level = level;
    this.levelId = levelId;
    this.disciplines = disciplines;
    this.boletim = boletim;
  }

  static init(json) {
    let disciplinesArray = new Array()
    if (json.disciplines != null) {
      let isArrayInit = typeof json.disciplines === 'array'
      let list = (isArrayInit ? json.disciplines[0] : json.disciplines)
      let mapList = list.flatMap(element => {
        return isArrayInit ? element : element.map(el => { return el })
      })

      mapList.forEach(element => {
        let dis = DisciplinePedagogic.init(element)
        disciplinesArray.push(dis)
      });
    }

    return new CoursePedagogic(
      json._id,
      json.name,
      json.desc,
      ClientLevel.init(json.level),
      json.level,
      disciplinesArray,
      json.boletim
    )
  }

  static initWithStringDisciplines(json) {

    return new CoursePedagogic(
      json._id,
      json.name,
      json.desc,
      ClientLevel.init(json.level),
      json.level,
      json.disciplines,
      json.boletim
    )
  }
}