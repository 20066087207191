import ModelJSON from "../ModelJSON";

export default class User extends ModelJSON {
    
    static keyClass = 'user'

    constructor(json) {
        super()
        this._id = json._id;
        this.email = json.email;
        this.phone = json.phone;
        this.document = json.document;
        this.name = json.name;
        this.level = json.level;
        this.token = json.token;
        this.company = json.company;
        this.address = json.address;
    }
  }