import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import DashTopDetails from './Components/DashTopDetails';
import DashChartLine from './Components/DashChartLine';
import DashChartBar from './Components/DashChartBar';
import DashInfo from '../../Models/Dashboard/DashInfo';
import DashboardRoute from '../../API/Routes/DashboardRoute';
import Helpers from '../../Helpers/Helpers';
import EnhancedTable from '../../Views/Components/EnhancedTable';
import { pageLinks } from '../../Routes/PageLinks';
import InvoiceRoute from '../../API/Routes/InvoiceRoute';
import APIManager from '../../API/APIManager';

class DashboardPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        dashInfo: new DashInfo(),
        sysInvoices: [],
        overdueSysInvoices: []
      }

      APIManager.removeCache("invoiceSearch")
      APIManager.removeCache("invoiceFilterSelected")

      this.props.loadingAction(true)
      this.props.setAlert()
      this.loadInfos = this.loadInfos.bind(this)
      this.errorAction = this.errorAction.bind(this)
  
      let route = new DashboardRoute()
      route.fetchInfos(this.loadInfos, this.errorAction)
      new InvoiceRoute().fetchAllInvoicesBySystem(this.loadedInvoices, this.errorAction)
    }

    loadInfos = (dashInfo) => {
      this.props.loadingAction(false)
      this.setState({
        dashInfo: dashInfo
      });
    }


  loadedInvoices = (sysInvoices) => {
    this.setState({sysInvoices, overdueSysInvoices: sysInvoices.filter(invoice => {return (invoice.status.id.includes("overdue"))})});
  }

    errorAction = (values) => {
      this.setState({
        values
      });
    }

    getTable() {
      var rows = new Array()
      const items = this.state.dashInfo.lastPayedInvoices
      items.forEach(element => {
        const venciment = new Date(element.venciment)
        const datePayed = new Date(element.datePayed)
        const data = {
          id: element.provider.idTransaction,
          venciment: venciment.getUTCDate() + '/' + (venciment.getUTCMonth() + 1) + '/' + venciment.getFullYear(),
          datePayed: datePayed.getUTCDate() + '/' + (datePayed.getUTCMonth() + 1) + '/' + datePayed.getFullYear(),
          client: element.client.name,
          plan: <div><h8 hidden={element.plan != null}>-</h8><h6 hidden={element.plan == null} class="ml-0" align='center' style={{ color: Helpers.primaryColor(), backgroundColor: Helpers.primaryClearColor(), borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.plan}</b></h6></div>,
          amountTotal: <b class="text-gray-800">{element.amountTotal}</b>,
          paymentType: element.paymentType.name,
          status: <h6 class="ml-0" align='center' style={{ color: element.status.color, backgroundColor: element.status.backColor, borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b>{element.status.name}</b></h6>
        }
        rows.push(data)
      });
      return rows
    }

    selectItemAction = (value) => {
      let invoice = this.state.dashInfo.lastPayedInvoices.find((element) => { return element.provider.idTransaction == value})
      this.props.history.push({
        pathname: pageLinks.invoiceDetail,
        state: {invoice: invoice, client: this.state.client}  
      })
    }

    openInvoicesListAction = () => {
      this.props.history.push({
        pathname: pageLinks.sysInvoices
      })
    }
    
  render() {
    const actualMonthName = Helpers.getActualDate().toLocaleString('default', { month: 'long' })
    const headCells = [
      { id: 'id', label: 'Número', isButton: true },
      { id: 'venciment', label: 'Vencimento', isButton: false },
      { id: 'datePayed', label: 'Pago em', isButton: false },
      { id: 'client', label: 'Cliente', isButton: false },
      { id: 'plan', label: 'Plano', isButton: false },
      { id: 'amountTotal', label: 'Total', isButton: false },
      { id: 'paymentType', label: 'Tipo', isButton: false },
      { id: 'status', label: 'Situação', isButton: false }
    ];
    
    return (
      <div class="container-fluid">
        {/* <DashBusinessButton /> */}
        <div class={"alert alert-danger pointer"} onClick={this.openInvoicesListAction} hidden={this.state.overdueSysInvoices == null || this.state.overdueSysInvoices.length == 0}><strong>Atenção!  </strong>{(this.state.overdueSysInvoices?.length > 1 ? "Existem " : "Existe ") + (this.state.overdueSysInvoices?.length) + (this.state.overdueSysInvoices?.length > 1 ? " faturas do sistema vencidas" : " fatura do sistema vencida") + ", evite o bloqueio! clique aqui para verificar"}</div>
        <div class="row">
          <DashTopDetails title={"Recebidos de " + actualMonthName} icon="dollar-sign" detail="COBRANÇAS" amount={this.state.dashInfo.billingInfo.receivedAmount} invoices={this.state.dashInfo.billingInfo.received} type="success" />
          <DashTopDetails title={"Em Atraso de " + actualMonthName} icon="dollar-sign" detail="COBRANÇAS" amount={this.state.dashInfo.billingInfo.overdueAmount} invoices={this.state.dashInfo.billingInfo.overdue} type="error" />
          <DashTopDetails title={"A receber em " + actualMonthName} icon="dollar-sign" detail="COBRANÇAS" amount={this.state.dashInfo.billingInfo.lastReceiveAmount} invoices={this.state.dashInfo.billingInfo.lastReceive} type="warning" />
          <DashTopDetails title="Total de clientes Ativos" detail="TOTAL DE CLIENTES" icon="user-graduate" amount={this.state.dashInfo.clientInfo.totalActiveUsers} invoices={this.state.dashInfo.clientInfo.totalUsers} type="info" />
        </div>
        <div class="row">
          <DashChartLine hidden={this.state.dashInfo.billingInfo.monthsTitle.length == 0} title="Receita Mensal Detalhada" classes={this.state.dashInfo.billingInfo.monthsTitle} data={this.state.dashInfo.billingInfo.monthsAmount}/>
          <DashChartBar hidden={this.state.dashInfo.clientInfo.monthsTitle.length == 0} title="Total de Novos Clientes" classes={this.state.dashInfo.clientInfo.monthsTitle} data={this.state.dashInfo.clientInfo.monthsValue}/>
        </div>
        <div class="card shadow mb-4" hidden={this.state.dashInfo.lastPayedInvoices.length == 0}>
          <EnhancedTable
            titleTable='Ultimos Pagamentos'
            rows={this.getTable()}
            headCells={headCells}
            defaultOrderId='name'
            delegate={this.selectItemAction}
            selectedList={[]}
            fastOptions={[]}
            selectedFastAction={null}
            individualOptions={[]}
            selectedIndividualAction={null}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(DashboardPage);