import Client from "../../Models/Client/Client"
import APIManager from "../APIManager"

export default class EmployeeRoute {
  constructor() {
    this.apiManager = new APIManager()
    this.endPoint = `user`
  }

  fetchCreate = (client, callback, error) => {
    client.company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + "/register/employee", client, 'POST', function (response) {
      try {
        callback(response.user.document);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDelete = (documents, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + '/' + company + '/employee/delete', documents, 'DELETE', function (response) {
      try {
        callback(response);
      } catch {
        error(response.message);
      }
    })
  }

  fetchDetail = (document, callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/details/` + company + '/' + document, null, 'GET', function (response) {
      try {
        const client = Client.initEmployee(response);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchUpdate = (client, callback, error) => {
    client.company = APIManager.loadCache("company")
    client.plan = client.plan == null ? null : client.plan['id']
    this.apiManager.fetchJSON(this.endPoint + "/" + client._id + "/employee", client, 'PUT', function (response) {
      try {
        const client = Client.initEmployee(response);
        callback(client);
      } catch {
        error(response.message);
      }
    })
  }

  fetchAll = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/all/` + company + "/employees", null, 'GET', function (response) {
      try {
        callback(response)
      } catch {
        error(response.message);
      }
    })
  }

  fetchExport = (callback, error) => {
    const company = APIManager.loadCache("company")
    this.apiManager.fetchJSON(this.endPoint + `/export/` + company + "/employees", null, 'GET', function (response) {
      try {
        callback(response['data']);
      } catch {
        error(response.message);
      }
    })
  }

}