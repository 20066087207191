import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TopButton from '../../Views/Components/TopButton';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import PageTitle from '../../Views/Components/PageTitle';
import Helpers from '../../Helpers/Helpers';
import PlanRoute from '../../API/Routes/PlanRoute';
import ColoredButton from '../../Views/Components/ColoredButton';
import ItemListWithTitle from '../../Views/Components/ItemListWithTitle';
import SimpleTextField from '../../Views/Components/SimpleTextField';
import FormComponent from '../../Views/Components/FormComponent';
import SimpleButton from '../../Views/Components/SimpleButton';
import CurrencyField from '../../Views/Components/CurrencyTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import Plan from '../../Models/Plan/Plan';

class PlanDetailPage extends Component {
    constructor(props) {
        super(props)

        this.props.setAlert()
        this.state = {
            plan: this.props.location.state.plan,
            isEnabling: false,
            isCancelling: false,
            isEditing: false,
        }

        this.props.setAlert()
        this.errorAction = this.errorAction.bind(this)

        this.loadPlan()
    }

    typeInvoiceBillet = [
        { id: 'monthly', label: 'Cobrança Mensal' },
        { id: 'collection', label: 'Carnê de Cobrança' }
    ]

    typeInvoicePix = [
        { id: 'monthly', label: 'Cobrança Mensal' }
    ]

    loadPlan = () => {
        const planCreated = this.props.location.state.planCreated
        this.props.setAlert(planCreated ? 'success' : null, 'Tudo certo!', 'O plano foi cadastrado com sucesso.')
        if (!planCreated) {
            this.props.loadingAction(true)
            let route = new PlanRoute()
            route.fetchPlanDetail(this.props.location.state.plan._id, this.loadedPlan, this.errorAction)
        }
    }

    changeState = (key, value) => {
        this.state.plan.change(key, value)
        this.setState({ plan: this.state.plan })
    }

    loadedPlan = (plan) => {
        const isCancelling = this.state.isCancelling
        const isEnabling = this.state.isEnabling
        this.props.loadingAction(false)
        this.setState({
            plan: plan,
            isCancelling: false,
            isEnabling: false,
        });
        this.props.setAlert((isEnabling || isCancelling) ? 'success' : null, 'Tudo certo!', 'O plano foi ' + (isEnabling ? 'ativado' : 'desativado') + ' com sucesso.')
    }

    errorAction = (values) => {
        this.setState({
            values
        });
    }

    cancelPlanAction = () => {
        this.props.loadingAction(true)
        new PlanRoute().fetchDelete({ "ids": [this.state.plan._id] }, this.loadPlan, this.errorAction)
    }

    enablePlanAction = () => {
        this.props.loadingAction(true)
        new PlanRoute().fetchActive({ "ids": [this.state.plan._id] }, this.loadPlan, this.errorAction)
    }

    saveAction = () => {
        if (this.planValidate()) {
            this.props.loadingAction(true)
            const route = new PlanRoute()
            route.fetchUpdate(this.state.plan, this.savedPlan, (error) => {
                this.props.setAlert('warning', 'Ops!', error)
            })
        } else {
            this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
        }
    }

    savedPlan = (plan) => {
        this.props.loadingAction(false)
        this.props.setAlert('success', 'Tudo certo!', 'O plano foi alterado com sucesso.')
        this.setState({
            plan: plan,
            isEditing: false
        });
    }

    planValidate = () => {
        return !Helpers.isEmptyTexts(
            [this.state.plan.title,
            this.state.plan.desc,
            this.state.plan.amount,
            this.state.plan.amountFees,
            this.state.plan.amountPenalty,
            this.state.plan.paymentTypeId,
            this.state.plan.clientPlanRecurrence,
            ]
        )
    }

    render() {
        return (
            <div class="container-fluid">
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja desativar este plano?"} isShow={this.state.isCancelling} defaultAction={this.cancelPlanAction} closeAction={() => { this.setState({ isCancelling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <ModalAlertSimple title="Atenção" desc={"Tem certeza que deseja ativar este plano?"} isShow={this.state.isEnabling} defaultAction={this.enablePlanAction} closeAction={() => { this.setState({ isEnabling: false }); }} buttonTitle="Sim" closeTitle="Não" />
                <PageTitle title="Plano" history={this.props.history} />
                <div class="row">
                    <TopButton title={this.state.plan.active ? "DESATIVAR PLANO" : "ATIVAR PLANO"} icon={this.state.plan.active ? "ban" : "check"} clickAction={() => { this.setState(this.state.plan.active ? { isCancelling: true } : { isEnabling: true }) }} />
                </div>
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Informações do Plano</h6>
                    </div>
                    <div class="card-body" hidden={this.state.isEditing}>
                        <div class="row">
                            <ItemListWithTitle title='Nome' value={this.state.plan.title} />
                            <ItemListWithTitle title='Descrição' value={this.state.plan.desc} />
                        </div>
                        <div class="row">
                            <ItemListWithTitle title='Valor' value={Helpers.currency(this.state.plan.amount)} />
                            <ItemListWithTitle title='Multa' value={this.state.plan.amountPenalty + '%'} />
                            <ItemListWithTitle title='Juros' value={this.state.plan.amountFees + '%'} />
                            <ItemListWithTitle title='Tipo' value={this.state.plan.paymentType.name} />
                            <ItemListWithTitle title='Recorrência' value={this.state.plan.recurrence.name} />
                            <ItemListWithTitle title='Número de Parcelas' value={this.state.plan.recurrence == "collection" ? this.state.plan.totalParcels + "x" : null} />
                        </div>
                        <ColoredButton title="Editar" clickAction={() => { this.setState({ isEditing: true }) }} />
                    </div>
                    <div class="card-body" hidden={!this.state.isEditing}>
                        <FormComponent components={
                            [
                                <div>
                                    <SimpleTextField id="title" defaultValue={this.state.plan.title} title="Nome" isRequired={true} delegate={this.changeState} width="98%" />
                                    <SimpleTextField id="desc" defaultValue={this.state.plan.desc} title="Descrição" isRequired={true} delegate={this.changeState} width="98%" />
                                </div>,
                                <div>
                                    <CurrencyField id="amount" defaultValue={this.state.plan.amount} title="Valor" isRequired={true} delegate={this.changeState} width="20%" />
                                    <CurrencyField id="amountPenalty" defaultValue={this.state.plan.amountPenalty} title="Multa por atraso" symbol="%" isRequired={false} delegate={this.changeState} width="20%" />
                                    <CurrencyField id="amountFees" defaultValue={this.state.plan.amountFees} title="Juros ao mês" symbol="%" isRequired={false} delegate={this.changeState} width="20%" />
                                </div>,
                                <div>
                                    {/* {!this.state.isEditing ? <div></div> : <SelectTextField id="paymentTypeId" defaultValue={this.state.plan.paymentTypeId} title="Tipo de Cobrança *" isRequired={true} delegate={this.changeState} width="25ch" options={[
                                        { id: 'billet', label: 'Boleto' },
                                        { id: 'pix', label: 'PIX' },
                                    ]} disabled={this.state.plan.clientPlanRecurrence == "collection"} />}
                                    {!this.state.isEditing ? <div></div> : <SelectTextField id="clientPlanRecurrence" defaultValue={this.state.plan.clientPlanRecurrence} title="Tipo de Recorrência *" isRequired={true} delegate={this.changeState} width="25ch" options={this.state.plan.paymentTypeId == 'pix' ? this.typeInvoicePix : this.typeInvoiceBillet} disabled={this.state.plan.paymentTypeId != null}/>} */}
                                </div>,
                                <div hidden={this.state.plan.clientPlanRecurrence != "collection" || this.state.plan.paymentTypeId == 'pix'}>
                                {(!this.state.isEditing || this.state.plan.clientPlanRecurrence != "collection" || this.state.plan.paymentTypeId == 'pix') ? <div/> : <SelectTextField id="totalParcels" defaultValue={this.state.plan.totalParcels} title="Quantidade de parcelas *" isRequired={true} delegate={this.changeState} width="30ch" options={
                                  Array.from(Array(24).keys()).map(item => { return { id: item + 1, label: item + 1 } })
                                } />}
                                </div>,
                                <div>
                                    <p class="text-muted"><strong>*OBS:</strong><span class="ml-2">{"As alterações no plano não alterarão as cobranças já existentes!"}</span></p>
                                </div>,


                                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                                <SimpleButton title="Cancelar" clickAction={() => { this.setState({ isEditing: false }) }} />
                            ]
                        } />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PlanDetailPage);