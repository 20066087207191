import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom";
import PageTitle from '../../Views/Components/PageTitle';
import FormComponent from '../../Views/Components/FormComponent';
import SimpleTextField from '../../Views/Components/SimpleTextField';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import APIManager from '../../API/APIManager';
import SelectImage from '../../Views/Components/SelectImage';
import ColorInput from '../../Views/Components/ColorInput';
import CurrencyField from '../../Views/Components/CurrencyTextField';
import User from '../../Models/Auth/User';
import CompanyLayout from '../../Models/Auth/CompanyLayout';
import AuthRoute from '../../API/Routes/AuthRoute';
import ClientRoute from '../../API/Routes/ClientRoute';
import ModalAlertSimple from '../../Views/Components/ModalAlertSimple';
import Helpers from '../../Helpers/Helpers';

class CompanyConfigPage extends Component {
  constructor(props) {
    super(props);

    const layout = APIManager.loadCache(CompanyLayout.keyClass)
    const user = APIManager.loadCache(User.keyClass)

    this.state = {
      company: user.company,
      layout: layout,
      isShowSuccess: false,
      isEditing: false,
      logo: layout.logo,
      plan: null
    }

    this.props.loadingAction(false)
    this.props.setAlert()
    this.savedAction = this.savedAction.bind(this)
    this.errorAction = this.errorAction.bind(this)
    this.changeStateZipCode = this.changeStateZipCode.bind(this)
    this.fetchGetCompanyPlan()
  }

  changeLayoutValues = (key, value) => {
    this.state.layout[key] = value
    this.setState({ layout: this.state.layout })
  }

  errorAction = (values) => {
    this.setState({
      values
    });
  }

  editState = () => {
    this.setState({ isEditing: true })
  }

  cancelEditState = () => {
    this.setState({ isEditing: false })
  }

  changeState = (key, value) => {
    this.state.company[key] = value
    this.setState({ company: this.state.company })
  }

  changeStateAddress = (key, value) => {
    this.state.company.address[key] = value
    this.setState({ company: this.state.company })
  }

  changeStateZipCode = (key, value) => {
    this.state.company.address[key] = value
    this.setState({ company: this.state.company })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressWithZipCode, function (error) { })
    }
  }

  fetchGetCompanyPlan = () => {
    this.props.loadingAction(true)
    let route = new ClientRoute()
    route.fetchGetPlanBusiness((plan)=>{
      this.props.loadingAction(false)
      this.state.plan = plan
      this.setState({ plan: this.state.plan })
    }, this.errorAction)
  }

  updateAddressWithZipCode = (address) => {
    this.state.company.address = address
    this.setState({ company: this.state.company })
  }

  saveAction = () => {
    this.props.loadingAction(true)
    console.log(this.state.company.layout);
    this.state.company.layout = this.state.layout
    this.state.company.layout.logoUrl = ""
    new AuthRoute().fetchUpdateCompany(this.state.company, this.savedAction, this.errorAction)
  }

  savedAction = (callback) => {
    APIManager.saveCache(CompanyLayout.keyClass, new CompanyLayout(callback.layout))
    const user = APIManager.loadCache(User.keyClass)

    user.company.name = callback.name
    user.company.email = callback.email
    user.company.phone = callback.phone
    user.company.amountFees = callback.amountFees
    user.company.amountPenalty = callback.amountPenalty
    user.company.amountPenalty = callback.amountPenalty
    user.company.address = callback.address
    APIManager.saveCache(User.keyClass, user)
    this.props.loadingAction(false)
    this.setState({ isShowSuccess: true })
  }

  getCompleteAddress() {
    const address = this.state.company.address
    return address.addressStreet + (address.number != "" ? " " + address.number : "") + (address.complement != "" ? ", " + address.complement : "") + ", " + address.neighborhood + ", " + address.city + " - " + address.uf + ", " + address.zipCode
  }

  render() {
    let hasCommunicationModule = (this.state.plan ? this.state.plan.hasCommunicationModule : false)
    return (
      <div class="container-fluid">
        <ModalAlertSimple title="Tudo certo!" desc={"As alterações foram salvas com sucesso!"} isShow={this.state.isShowSuccess} closeAction={() => { window.location.reload() }} closeTitle="Fechar" />
        <PageTitle title="Configurações"/>

        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Informações da Empresa</h6>
          </div>
          <div class="card-body" hidden={this.state.isEditing}>
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-2" hidden={this.state.layout.logo == null} >
                    <div class="float-end mt-3 mt-sm-6">
                      <div class={"card-image-box mt-4 mr-4"} style={{ overflow: 'clip' }}>
                        <img className="card-image-no-editable" src={"data:image/png;base64, " + this.state.logo} />
                      </div>
                      <br /><br /><br /><br /><br /><br /><br /><br />
                      <h8>Cor Primária: </h8><br />
                      <strong><input className="ml-3" disabled={true} type="color" value={this.state.layout.primaryColor} style={{ backgroundColor: this.state.layout.primaryColor }} /></strong><br /><br />
                      <h8>Cor Secundária: </h8><br />
                      <strong><input className="ml-3" disabled={true} type="color" value={this.state.layout.primaryDetailColor} style={{ backgroundColor: this.state.layout.primaryDetailColor }} /></strong>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-5" hidden={this.state.company.name == null} >
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Nome: </h8><br />
                      <strong><span class="ml-2">{this.state.company.name}</span></strong><br /><br />
                      <h8>{Helpers.documentTitle(this.state.company.document)}: </h8><br />
                      <strong><span class="ml-2">{Helpers.documentFormat(this.state.company.document)}</span></strong><br /><br />
                      <h8>Email: </h8><br />
                      <strong><span class="ml-2">{this.state.company.email}</span></strong><br /><br />
                      <h8>Telefone: </h8><br />
                      <strong><span class="ml-2">{this.state.company.phone}</span></strong><br /><br />
                      <h8>Endereço: </h8><br />
                      <strong><span class="ml-2">{this.getCompleteAddress()}</span></strong>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm" hidden={this.state.company.name == null} >
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Multa por atraso: </h8><br />
                      <strong><span class="ml-2">{this.state.company.amountPenalty}%</span></strong><br /><br />
                      <h8>Juros ao mês por atraso: </h8><br />
                      <strong><span class="ml-2">{this.state.company.amountFees}%</span></strong>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <ColoredButton title="Editar" clickAction={this.editState} />
          </div>
          <div class="card-body" hidden={!this.state.isEditing}>
            <FormComponent components={
              [
                <SelectImage image={this.state.layout.logo} isEditable={true} changeAction={(logo) => { this.changeLayoutValues("logo", logo) }} />,
                <SimpleTextField id="name" defaultValue={this.state.company.name} title="Nome da Empresa" isRequired={false} delegate={this.changeState} width="80%" />,
                <SimpleTextField isDisabled={true} id="document" defaultValue={this.state.company.document} title="CNPJ" isRequired={true} delegate={this.changeState} width="39%" mask={new String(this.state.company.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />,
                <SimpleTextField type="email" id="email" defaultValue={this.state.company.email} title="Email" isRequired={true} delegate={this.changeState} width="40%" />,
                <SimpleTextField type="phone" id="phone" defaultValue={this.state.company.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.company.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />,
                <CurrencyField id="amountPenalty" defaultValue={this.state.company.amountPenalty} title="Multa por atraso" symbol="%" isRequired={false} delegate={this.changeState} width="20%" />,
                <CurrencyField id="amountFees" defaultValue={this.state.company.amountFees} title="Juros ao mês por atraso" symbol="%" isRequired={false} delegate={this.changeState} width="20%" />,
                <div>
                  <ColorInput title="Cor Primária" color={this.state.layout.primaryColor} changeAction={(color) => { this.changeLayoutValues("primaryColor", color) }} />
                  <ColorInput title="Cor Secundária" color={this.state.layout.primaryDetailColor} changeAction={(color) => { this.changeLayoutValues("primaryDetailColor", color) }} />
                </div>
              ]
            } />
          </div>
          <div class="card-header py-3" hidden={!this.state.isEditing}>
            <h6 class="m-0 font-weight-bold text-primary">Endereço</h6>
          </div>
          <div class="card-body" hidden={!this.state.isEditing}>
            <FormComponent components={
              [
                <div>
                  <SimpleTextField id="zipCode" defaultValue={this.state.company.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateZipCode} width="20ch" mask="99999-999" />
                </div>,
                <div hidden={this.state.company.address.addressStreet == null}>
                  <SimpleTextField id="addressStreet" isLoad defaultValue={this.state.company.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeStateAddress} width="61%" />
                  <SimpleTextField id="number" isLoad defaultValue={this.state.company.address.number} title="Número" isRequired={true} delegate={this.changeStateAddress} width="15%" />
                  <SimpleTextField id="complement" isLoad defaultValue={this.state.company.address.complement} title="Complemento" isRequired={false} delegate={this.changeStateAddress} width="15%" />
                  <SimpleTextField id="neighborhood" isLoad defaultValue={this.state.company.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeStateAddress} width="15%" />
                  <SimpleTextField id="city" isLoad defaultValue={this.state.company.address.city} title="Cidade" isRequired={true} delegate={this.changeStateAddress} width="20ch" />
                  <SimpleTextField id="uf" isLoad defaultValue={this.state.company.address.uf} title="Estado" isRequired={true} delegate={this.changeStateAddress} width="20ch" />
                </div>,

                <ColoredButton title="Salvar" clickAction={this.saveAction} />,
                <SimpleButton title="Cancelar" clickAction={this.cancelEditState} />
              ]
            } />
          </div>
        </div>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Plano Contratado</h6>
          </div>
          <div class="card-body" hidden={this.state.isEditing}>
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-7" hidden={this.state.company.name == null} >
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Plano: </h8><br />
                      <strong><span class="ml-2">{this.state.plan ? this.state.plan.title : ""}</span></strong><br /><br />
                      <h8>Descrição: </h8><br />
                      <strong><span class="ml-2">{this.state.plan ? this.state.plan.desc : ""}</span></strong><br /><br />
                      <h8>Taxa por pix pago: <i class={`tooltip fas fa-info-circle fa-1x text-primary`}><span class="tooltiptext tooltip-left">{"Uma taxa de transação, de " + (this.state.plan ? this.state.plan.transactionTax : "") +", é cobrada pelo banco quando o pagamento de um PIX é efetuado."}</span></i></h8><br />
                      <strong><span class="ml-2">{this.state.plan ? this.state.plan.transactionTax : ""}</span></strong><br /><br />
                      <h8>Taxa por boleto pago: <i class={`tooltip fas fa-info-circle fa-1x text-primary`}><span class="tooltiptext tooltip-left">{"Uma taxa de transação, de " + (this.state.plan ? this.state.plan.transactionTax : "") +", é cobrada pelo banco quando o pagamento de um boleto é efetuado."}</span></i></h8><br />
                      <strong><span class="ml-2">{this.state.plan ? this.state.plan.transactionTax : ""}</span></strong>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-2" hidden={this.state.company.name == null} >
                    <div class="float-end mt-3 mt-sm-6">
                      <h8>Total de clientes <i class={`tooltip fas fa-info-circle fa-1x text-primary`}><span class="tooltiptext tooltip-left">Esse é o numero de clientes contratados para a sua empresa, caso necessite cadastrar mais clientes entre em contato conosco!</span></i></h8><br />
                      <strong><span class="ml-2">{this.state.plan ? this.state.plan.maxClients : ""}</span></strong><br /><br />
                      <h8>Módulo de comunicação <i class={`tooltip fas fa-info-circle fa-1x text-primary`}><span class="tooltiptext tooltip-left">Este módulo facilita a comunicação com o seu cliente através do Feed de Publicações e o Chat, ambos disponiveis tambem em um app mobile! </span></i></h8><br />
                      <h6 class="ml-0" align='center' style={{ color: hasCommunicationModule ? "#279855" : "#747474", backgroundColor: hasCommunicationModule ? "#CAF8DD" : "#E6E6E6", borderRadius: '5px', padding: '5px', paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem' }}><b><i className={"fas fa-fw fa-" + (hasCommunicationModule ? "check" : "ban")}></i> {hasCommunicationModule ? "Ativo" : "Inativo"}</b></h6>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CompanyConfigPage);