import ModelJSON from "../ModelJSON";

export default class ClientLevel extends ModelJSON {
  constructor(id, title) {
    super()
    this.id = id;
    this.title = title;
  }

  static init(json) {
    return new ClientLevel(
      json.id,
      json.title
    )
  }
}