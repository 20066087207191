import ModelJSON from "../ModelJSON";

export default class DisciplinePedagogic extends ModelJSON {
  constructor(id, name, order, details) {
    super()
    this._id = id;
    this.name = name
    this.order = order;
    this.details = details;
  }

  static init(json) {
    return new DisciplinePedagogic(
      json._id,
      json.name,
      json.order,
      json.details
    )
  }
}