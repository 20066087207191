import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import SimpleTextField from '../../Views/Components/SimpleTextField';
import SelectTextField from '../../Views/Components/SelectTextField';
import FormComponent from '../../Views/Components/FormComponent';
import { pageLinks } from '../../Routes/PageLinks';
import ColoredButton from '../../Views/Components/ColoredButton';
import SimpleButton from '../../Views/Components/SimpleButton';
import PageTitle from '../../Views/Components/PageTitle';
import ClientRoute from '../../API/Routes/ClientRoute';
import NewClient from '../../Models/Client/Client';
import Helpers from '../../Helpers/Helpers';
import DatePicker from '../../Views/Components/DatePicker';
import Responsible from '../../Models/Client/Responsible';
import Address from '../../Models/Address';

class NewClientPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      client: new NewClient()
    }

    this.state.client.document = Helpers.generateMatricule()
    this.state.client.responsible = new Responsible()
    this.state.client.responsible.address = new Address()

    this.props.loadingAction(false)
    this.props.setAlert()
    this.updateAddressWithZipCode = this.updateAddressWithZipCode.bind(this)
    this.changeStateZipCode = this.changeStateZipCode.bind(this)
    this.changeState = this.changeState.bind(this)
    this.clearState = this.clearState.bind(this)
    this.saveAction = this.saveAction.bind(this)
    this.successAction = this.successAction.bind(this)
    this.copyClientDates = this.copyClientDates.bind(this)
  }

  changeState = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })
    console.log(this.state.client);
  }

  changeStateZipCode = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressWithZipCode, function (error) { })
    }
  }

  copyClientDates = () => {
    var client = this.state.client
    client.responsible = new Responsible(client._id, client.name, client.documentGov, client.phone, client.email, new Address(client.address.zipCode, client.address.addressStreet, client.address.number, client.address.complement, client.address.city, client.address.uf, client.address.neighborhood))
    this.setState({ client })
  }

  updateAddressWithZipCode = (address) => {
    this.state.client.address = address
    this.setState({ client: this.state.client })
  }

  changeStateResponsibleZipCode = (key, value) => {
    this.state.client.change(key, value)
    this.setState({ client: this.state.client })

    if (value.replace(/\D/g, "").length == 8) {
      const route = new ClientRoute()
      route.fetchZipCodeDetails(value, this.updateAddressResponsibleWithZipCode, function (error) { })
    }
  }

  updateAddressResponsibleWithZipCode = (address) => {
    this.state.client.responsible.address = address
    this.setState({ client: this.state.client })
  }

  clearState = () => {
    this.state.client.clear()
    this.state.client.address.clear()
    this.setState({ client: this.state.client })
  }

  saveAction = () => {
    if (this.clientValidate()) {
      const route = new ClientRoute()
      route.fetchCreate(this.state.client, this.successAction, (error) => {
        this.props.setAlert('warning', 'Ops!', error)
      })
    }else{
      this.props.setAlert('warning', 'Ops!', 'Você precisa preencher todos os campos obrigatórios (*)')
    }
  }

  clientValidate = () => {
    return !Helpers.isEmptyTexts(
      [this.state.client.document,
      this.state.client.name,
      this.state.client.address.zipCode,
      this.state.client.address.addressStreet,
      this.state.client.address.number,
      this.state.client.address.neighborhood,
      this.state.client.address.city,
      this.state.client.address.uf,
      this.state.client.responsible.document,
      this.state.client.responsible.name,
      this.state.client.responsible.address.zipCode,
      this.state.client.responsible.address.addressStreet,
      this.state.client.responsible.address.number,
      this.state.client.responsible.address.neighborhood,
      this.state.client.responsible.address.city,
      this.state.client.responsible.address.uf
    ]
      )
  }

  successAction = (document) => {
    this.props.history.push({
      pathname: pageLinks.clientDetail,
      state: {document: document, clientCreated: true}  
    })
  }

  render() {
    return (
      <div class="container-fluid">
        <PageTitle title="Novo Aluno" history={this.props.history}/>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Informações do Aluno</h6>
          </div>
          <div class="card-body">
            <FormComponent components={
              [
                <div>
                  <SimpleTextField id="document" defaultValue={this.state.client.document} title="Nº do Aluno" isRequired={true} delegate={this.changeState} width="20ch" mask={new String(this.state.client.document)} />
                  <SimpleTextField id="documentGov" defaultValue={this.state.client.documentGov} title="CPF/CNPJ" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.documentGov).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                  <SimpleTextField id="name" defaultValue={this.state.client.name} title="Nome" isRequired={true} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                  {/* <DatePicker id="birthDate" defaultValue={this.state.client.birthDate} title="Data de Nascimento" isRequired={false} delegate={this.changeState} width="20ch" /> */}
                  <SimpleTextField type="phone" id="phone" defaultValue={this.state.client.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                  <SimpleTextField type="email" id="email" defaultValue={this.state.client.email} title="Email" isRequired={false} delegate={this.changeState} width="78%" />
                </div>,
                <div>
                  <DatePicker id="birthDate" defaultValue={this.state.client.birthDate} title="Data de Nascimento" isRequired={false} delegate={this.changeState} width="18ch" />
                  <SelectTextField id="genre" defaultValue={this.state.client.genre + ""} title="Gênero" isRequired={false} delegate={this.changeState} width="20ch" options={Helpers.genreList()} />
                  <SimpleTextField id="fatherName" defaultValue={this.state.client.fatherName} title="Nome do Pai" isRequired={false} delegate={this.changeState} width="78%" />
                  <SimpleTextField type="phone" id="fatherPhone" defaultValue={this.state.client.fatherPhone} title="Telefone do Pai" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.fatherPhone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                  <SimpleTextField id="motherName" defaultValue={this.state.client.motherName} title="Nome da Mãe" isRequired={false} delegate={this.changeState} width="78%" />
                  <SimpleTextField type="phone" id="motherPhone" defaultValue={this.state.client.motherPhone} title="Telefone da Mãe" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.motherPhone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                  <div>
                    <SimpleTextField id="address/zipCode" defaultValue={this.state.client.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateZipCode} width="20ch" mask="99999-999" />
                  </div>
                  <div hidden={this.state.client.address.addressStreet == null}>
                    <SimpleTextField id="address/addressStreet" isLoad defaultValue={this.state.client.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeState} width="61%" />
                    <SimpleTextField id="address/number" isLoad defaultValue={this.state.client.address.number} title="Número" isRequired={true} delegate={this.changeState} width="15%" />
                    <SimpleTextField id="address/complement" isLoad defaultValue={this.state.client.address.complement} title="Complemento" isRequired={false} delegate={this.changeState} width="15%" />
                    <SimpleTextField id="address/neighborhood" isLoad defaultValue={this.state.client.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeState} width="15%" />
                    <SimpleTextField id="address/city" isLoad defaultValue={this.state.client.address.city} title="Cidade" isRequired={true} delegate={this.changeState} width="20ch" />
                    <SimpleTextField id="address/uf" isLoad defaultValue={this.state.client.address.uf} title="Estado" isRequired={true} delegate={this.changeState} width="20ch" />
                  </div>
                  <div>
                    <SimpleTextField id="obs" defaultValue={this.state.client.obs} title="Observações" isRequired={false} delegate={this.changeState} width="98%" />
                  </div>
                </div>
              ]
            } />
          </div>
        </div>
        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary">Responsável Financeiro</h6>
          </div>
            <div class="card-body">
              <FormComponent components={
                [
                  <div>
                    <SimpleTextField id="responsible/document" isLoad defaultValue={this.state.client.responsible.document} title={"CPF/CNPJ"} isRequired={true} delegate={this.changeState} width="20ch" mask={new String(this.state.client.responsible.document).replace(/[^\d]/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"} />
                    <SimpleTextField id="responsible/name" isLoad defaultValue={this.state.client.responsible.name} title="Nome" isRequired={true} delegate={this.changeState} width="78%" />
                  </div>,
                  <div>
                    <SimpleTextField type="phone" id="responsible/phone" isLoad defaultValue={this.state.client.responsible.phone} title="Telefone" isRequired={false} delegate={this.changeState} width="20ch" mask={new String(this.state.client.responsible.phone).replace(/[^\d]/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"} />
                    <SimpleTextField type="email" id="responsible/email" isLoad defaultValue={this.state.client.responsible.email} title="Email" isRequired={false} delegate={this.changeState} width="78%" />
                  </div>,
                  <div>
                    <div>
                      <SimpleTextField id="responsible/address/zipCode" isLoad defaultValue={this.state.client.responsible.address.zipCode} title="CEP" isRequired={true} delegate={this.changeStateResponsibleZipCode} width="18ch" mask="99999-999" />
                    </div>
                    <div hidden={this.state.client.responsible.address.addressStreet == null}>
                      <SimpleTextField id="responsible/address/addressStreet" isLoad defaultValue={this.state.client.responsible.address.addressStreet} title="Endereço" isRequired={true} delegate={this.changeState} width="61%" />
                      <SimpleTextField id="responsible/address/number" isLoad defaultValue={this.state.client.responsible.address.number} title="Número" isRequired={true} delegate={this.changeState} width="15%" />
                      <SimpleTextField id="responsible/address/complement" isLoad defaultValue={this.state.client.responsible.address.complement} title="Complemento" isRequired={false} delegate={this.changeState} width="15%" />
                      <SimpleTextField id="responsible/address/neighborhood" isLoad defaultValue={this.state.client.responsible.address.neighborhood} title="Bairro" isRequired={true} delegate={this.changeState} width="15%" />
                      <SimpleTextField id="responsible/address/city" isLoad defaultValue={this.state.client.responsible.address.city} title="Cidade" isRequired={true} delegate={this.changeState} width="20ch" />
                      <SimpleTextField id="responsible/address/uf" isLoad defaultValue={this.state.client.responsible.address.uf} title="Estado" isRequired={true} delegate={this.changeState} width="20ch" />
                    </div>
                  </div>,
                  <SimpleButton title="O aluno é o responsável financeiro" clickAction={this.copyClientDates} />,
                ]
              } />
            </div>
        </div>

        <ColoredButton title="Salvar" clickAction={this.saveAction} />
      </div>
    )
  }
}

export default withRouter(NewClientPage);