import React, { Component } from 'react'
import UIAvatar from 'react-ui-avatars';
import Helpers from '../../Helpers/Helpers'

export default class ChatItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const primaryColor = Helpers.primaryColor()
    return (
        <a class={"list-group-item" + (this.props.isSelectedChat ? "-selected" : " list-group-item-action") + " border-0 pointer"} onClick={()=>{this.props.selectAction(this.props.chat)}}>
        <div class="badge badge-danger float-right">{this.props.chat.messagesNotReadCount == 0 ? null : this.props.chat.messagesNotReadCount}</div>
        <div class="d-flex align-items-start">
            <UIAvatar className="img-profile rounded-circle mr-1" width="40" height="40" name={this.props.chat.title} color={primaryColor} />
            <div class={(this.props.isSelectedChat ? "text-white" : "text-secondary") + " flex-grow-1 ml-3"}>
                {this.props.chat.title}
                <div class="small ">{this.props.chat.lastMessageDate}</div>
            </div>
        </div>
        </a>
    )
  }
}