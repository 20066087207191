import React, { Component } from 'react'
import APIManager from '../../../API/APIManager'

export default class FooterView extends Component {

  render() {
    const layout = APIManager.loadCache("companyLayout")
    return (
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            
            <span>Copyright &copy; 2024 {layout.name} - Todos os Direitos Reservados.</span>
          </div>
        </div>
      </footer>
    )
  }
}