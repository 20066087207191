export default class ModelJSON {
  constructor() {}

  clear() {
    Object.keys(this).forEach((key) => (typeof this[key] === 'object') ? (typeof this[key] !== ModelJSON ?? this[key].clear()) : this[key] = '')
  }

  change(key, value) {
    const keys = key.split("/")
    var temp = this
    var lastKey = keys.pop()
    keys.map((key) => temp = temp[key])
    temp[lastKey] = value
    return this
  }
}