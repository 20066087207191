import React, { Component } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import TopMenu from "./Views/Menu/TopMenu/TopMenu";
import LeftMenu from "./Views/Menu/LeftMenu/LeftMenu";
import FooterView from './Views/Menu/SubMenu/Footer';
import FloatsView from './Views/Menu/SubMenu/Floats';
import Routes from './Routes/Routes';
import SignInPage from './Pages/Auth/SignInPage';
import { pageLinks } from './Routes/PageLinks';
import LostPasswordPage from './Pages/Auth/LostPasswordPage';
import SysLockOverdue from './Pages/SystemInvoices/SysLockOverdue';
import SysLockOverdueDetail from './Pages/SystemInvoices/SysLockOverdueDetail';
import APIManager from './API/APIManager';
import AuthRoute from './API/Routes/AuthRoute';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isToogled: true,
      update: false
    }

    this.toogleAction = this.toogleAction.bind(this)
    let nameid = window.location.hostname.split('.')[0]
    
    if (APIManager.loadCache("company") == null) {
      new AuthRoute().fetchCompanyByNameId(nameid, (company => {
        APIManager.saveCache("company", company)
        this.setState({ update: true })
      }))
    }
  }

  toogleAction = () => {
    this.setState({ isToogled: !this.state.isToogled })
  }

  componentDidMount() {
    document.title = APIManager.loadCache("companyLayout") == null ? "VivaEdu" : APIManager.loadCache("companyLayout").name
  }

  render() {
    if (APIManager.loadCache("company") == null) { return (<div></div>) }
    const leftMenu = <LeftMenu isToogled={this.state.isToogled} toogleAction={this.toogleAction} />
    const topMenu = <TopMenu toogleAction={this.toogleAction} />
    return (
      <Router>
        <Switch>
        <Route exact path={pageLinks.auth}><SignInPage /></Route>
        <Route path={pageLinks.lostPass}><LostPasswordPage /></Route>
        <Route path={pageLinks.sysInvoiceOverdue}><SysLockOverdue /></Route>
        <Route path={pageLinks.sysInvoiceOverdueDetail}><SysLockOverdueDetail /></Route>

        <div id="wrapper">
          {leftMenu}
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              {topMenu}
              <Routes/>
            </div>
            <FooterView />
          </div>
        </div>
        <FloatsView />
        </Switch>
      </Router>
    )
  }
}
