import Done from './Assets/Done.png'
import EmptyInbox from './Assets/EmptyInbox.png'
import Error from './Assets/Error.png'
import NoConnection from './Assets/NoConnection.png'
import NoCreditCard from './Assets/NoCreditCard.png'
import NoDocuments from './Assets/NoDocuments.png'
import NoGPS from './Assets/NoGPS.png'
import NoImages from './Assets/NoImages.png'
import NoItemsCart from './Assets/NoItemsCart.png'
import NoMessages from './Assets/NoMessages.png'
import NoSearchResult from './Assets/NoSearchResult.png'
import NoTasks from './Assets/NoTasks.png'

export default class EmptyStateEnum {

    static TextEnum = Object.freeze({
        "noResults": "Opss... A lista está vazia. ",
        "noImages":"Nenhuma imagem foi selecionada",
        "noMessages": "Opss... Ainda não há nenhuma mensagem, insira e envie a primeira! ",
        "outher": ""
    })

    static ImageEnum = Object.freeze({
        "noResults": EmptyInbox,
        "noImages": NoImages,
        "noMessages": NoMessages,
        "outher": ""
    })

  }